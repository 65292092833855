import TextFieldFormik from "../../../TextFieldFormik";
import SelectFormik from "../../../ui/SelectFormik";

import { schoolClasses } from "../../../../../assets/lists/schoolClasses";
import { alphabets } from "../../../../../assets/lists/alphabet";
import { schoolTerms } from "../../../../../assets/lists/schoolTerms";
import { getYearRange } from "../../../../../utils/getYearRange";

const StudentEditableSchoolInfo = () => {
  return (
    <div>
      <p className="mt-[56px] mb-[24px] text-[24px] font-bold">School info</p>
      <div className="grid grid-cols-2 gap-x-[8px] gap-y-[3px]">
        <SelectFormik
          label="Current Class"
          name="currentClass"
          options={schoolClasses}
          showDefaultOption={false}
          useUpperCase
          height="h-[48px]"
        />
        <SelectFormik
          label="Sub Class"
          name="subClass"
          options={alphabets}
          showDefaultOption={false}
          height="h-[48px]"
        />
        <SelectFormik
          label="Current Term"
          name="currentTerm"
          options={schoolTerms}
          showDefaultOption={false}
          height="h-[48px]"
        />
        <SelectFormik
          label="Current Year"
          name="currentYear"
          options={getYearRange()}
          showDefaultOption={false}
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Former School"
          name="formerSchoolName"
          width="w-[232px]"
          height="h-[48px]"
        />
      </div>
    </div>
  );
};

export default StudentEditableSchoolInfo;
