import _ from "lodash";
import dayjs from "dayjs";

/// This function takes an object and trims the values of String removing white
/// spaces from the left and right
export const removeWhiteSpacesFromObjValues = obj => {
  let result = {};
  for (let key in obj) {
    const value = obj[key];

    let updatedValue = {};

    if (typeof value === "string" || value instanceof String) {
      updatedValue = { [`${key}`]: value.trim() };
    } else {
      updatedValue = { [`${key}`]: value };
    }

    result = { ...result, ...updatedValue };
  }

  return result;
};

/// This function removes the first zero of the value passed in as a parameter
/// if it contains "0"
export const removeInitialZeroFromPhoneNumber = phoneNumber => {
  if (phoneNumber[0] === "0") {
    let newNumber = phoneNumber.substring(1);
    return newNumber;
  }

  return phoneNumber;
};

export const extractFirstWordToLowerCase = sentence => {
  return sentence.split(" ")[0].toLowerCase();
};

export const extractFirstWordToUpperCase = (theString, lowercase = false) => {
  return lowercase
    ? theString.charAt(0).toLowerCase() + theString.slice(1)
    : theString.charAt(0).toUpperCase() + theString.slice(1);
};

/// This function converts any phrase/sentence passed in as a parameter into
/// word-per-word capitalization e.g. => hello world == Hello World
export const toTitleCase = phrase => {
  return phrase
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const extractFirstTwoLetterOfWordToUpperCase = sentence => {
  let result = "";
  const sentenceToWordsInArray = sentence.split(" ");
  for (let index = 0; index < sentenceToWordsInArray.length; index++) {
    if (index === 2) {
      break;
    }

    const word = sentenceToWordsInArray[index];
    const letter = word.charAt(0).toUpperCase();

    result = `${result}${letter}`;
  }

  return result;
};

export const allObjectValuesAreValid = obj => {
  let result = true;

  for (const value in obj) {
    if (obj[value] === "" || !obj[value] || obj[value]?.length === 0) {
      result = false;
      break;
    }
  }

  return result;
};

/// This function check the difference in 2 objects are return it
export const extractDifferenceInObject = (firstObject, secondObject) => {
  return Object.keys(firstObject).reduce((diff, key) => {
    if (firstObject[key] === secondObject[key]) return diff;

    if (key === "currentClass") {
      return {
        [key]: secondObject[key].toLowerCase(),
      };
    }

    return {
      ...diff,
      [key]: secondObject[key],
    };
  }, {});
};

/// This method takes in the "position" as an argument, which will be in the
/// format of [1, 2, 3, 4, ...] and return a readable position string in the
/// format of [1st, 2nd, 3rd, 4th, ...]
export const positionFormatter = position => {
  let formattedPosition = position.toString();

  if (formattedPosition.charAt(formattedPosition.length - 1) === "1") {
    return `${formattedPosition}st`;
  }

  if (formattedPosition.charAt(formattedPosition.length - 1) === "2") {
    return `${formattedPosition}nd`;
  }

  if (formattedPosition.charAt(formattedPosition.length - 1) === "3") {
    return `${formattedPosition}rd`;
  }

  return `${formattedPosition}th`;
};

/// This function takes two objects as arguments and return "true" if both objects
/// are equal or "false" if not
///
/// NOTE: lodash package is used to achieve this
export const areTwoObjectsEqual = (obj1, obj2) => {
  return _.isEqual(obj1, obj2);
};

export const reduceStringLength = (string, length = 10) => {
  const trimmedString = string.substring(0, length);
  return string.length > length ? `${trimmedString}...` : string;
};

/// This function is responsible for extracting paginated data set
export const paginate = (items, pageNumber, pageSize) => {
  const startIndex = (pageNumber - 1) * pageSize;

  return _(items).slice(startIndex).take(pageSize).value();
};

/// This function help convert a DateTime instance to a readable format
/// e.g. January 20, 2015
export const convertToReadableDate = date => dayjs(date).format("MMMM D, YYYY");

export const convertToDate = date => dayjs(date).format("D M YYYY");

/**
 *
 * @description This function check if a value is empty or contains data
 * @param {object} value - Any object
 * @param {array} value - Any array
 * @param {collection} value - Any collection
 * @param {map} value - Any map
 * @param {set} value - Any set
 * @returns {boolean} returns true if value is not empty
 */
export const isEmpty = value => {
  return _.isEmpty(value);
};
