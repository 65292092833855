import classImage from "../../../assets/images/auth_img.png.png";

const RegistrationFormLayout = ({ children }) => {
  return (
    <div className='h-screen w-full bg-[#C4CBD61A] lg:p-6'>
      <div className='flex h-full  items-center justify-center rounded-2xl bg-white shadow-md md:gap-[32px] xl:gap-[120px] 2xl:rounded-2xl'>

        <img
          className='hidden lg:block xl:col-span-2 xl:pt-[178px] xl:pb-[128px] '
          src={classImage}
          alt='Teacher in a classroom'
        />
        <div className='w-full h-full sm:w-[450px] md:grid md:w-[500px] md:place-items-stretch lg:col-span-1 lg:pl-12 xl:col-span-2 xl:w-[400px]'>
          {children}
        </div>
      
      </div>
    </div>
  );
};

export default RegistrationFormLayout;
