import { subjectActions } from "./subjectActions";
import { debugPrint } from "../../utils/debugPrint";

export const initialState = {
  isLoading: false,
  error: null,
  currentPage: 1,
  allSubjects: [],
};

const subjectReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case subjectActions.UPDATE_LOADING_STATE:
      return {
        ...state,
        isLoading: payload.isLoading,
      };

    case subjectActions.ADD_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case subjectActions.REQUEST_SUCCESS:
      return {
        ...state,
        allSubjects: [...payload?.allSubjects],
      };
    case subjectActions.UPDATE_SUBJECTS:
      return {
        ...state,
        allSubjects: [...payload?.allSubjects, ...state.allSubjects],
      };

    default:
      throw new Error(`No case for type ${type} found in subjectReducer`);
  }
};

export default subjectReducer;
