export const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  teacherEmail: "",

  country: "",
  state: "",
  address: "",
  countryCode: "+234",
  phoneNumber: "",

  dateOfBirth: null,
  profileImageUrl: null,
  classes: [],
  subjects: [],
  formerEmployerName: "",
  formerJobTitle: "",
  yearsOfExperience: "",

  LGA: "",
  documents: null,

  salary: "",
  salaryDuration: "month",
  accountName: "",
  accountNumber: "",
  bank: "",
  bankCode: "",
};
