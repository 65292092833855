import { useEffect, useState } from "react";

import StudentPane from "../../../shared/components/StudentPane";
import DashBoardTopItem from "../../../shared/components/DashBoardTopItem";
import PageLoading from "../../../shared/components/ui/PageLoading";
import NormalSelect from "../../../shared/components/ui/NormalSelect";
import PaginationIndicator from "../../components/pagination/PaginationIndicator";
import PageSearchIconAndLabel from "../../../shared/components/PageSearchIconAndLabel";
import { AddStudentButton } from "../../../shared/components/ui/AddStudentButton";
import deleteIcon from "../../../assets/images/Icon.png";

import { schoolClasses } from "../../../assets/lists/schoolClasses";
import { getYearRange } from "../../../utils/getYearRange";
import { accountType } from "../../../constants/accountType";
import { useStudent } from "../../../store/studentStore/studentContext";
import { useAuth } from "../../../store/authContext";
import search from "../../../assets/images/Search.png";
import vector from "../../../assets/images/Vector.png";
import { areTwoObjectsEqual } from "../../../utils/helperFunctions";

const Students = () => {
  const { user } = useAuth();
  const { userType, schoolCurrentYear, schoolCurrentTerm } = user || {};
  const { state, fetchNextData, fetchPreviousData, handleFilterUpdate } =
    useStudent();
  const { isLoading, error, allStudents } = state;

  const [classesOption, setClassesOption] = useState([
    "all classes",
    ...schoolClasses,
  ]);
  const [filterParams, setFilterParams] = useState({
    yearFilter: "", // Initialize yearFilter to an empty string
    classFilter: "",
    termFilter: "",
  });

  useEffect(() => {
    const checkUserTypeAndMaybeSetClassFilter = () => {
      if (userType === accountType.SCHOOL) {
        setFilterParams(prevState => ({
          ...prevState,
          yearFilter: schoolCurrentYear,
          termFilter: schoolCurrentTerm,
        }));
      }

      if (userType === accountType.TEACHER) {
        setFilterParams(prevState => ({
          ...prevState,
          classFilter: user?.teacherClasses[0] ?? "",
        }));

        // If the userType is "teacher" the class dropdown field is restricted
        // to only classes the teacher has been assigned to
        setClassesOption([...(user?.teacherClasses ?? "")]);
      }
    };
    checkUserTypeAndMaybeSetClassFilter();
  }, []);

  const handleFilterSelection = (name, value) => {
    const prevFilterParams = { ...filterParams };

    let updatedFilterParams = { ...filterParams };

    updatedFilterParams = {
      ...updatedFilterParams,
      [name]: value === "all year" ? "" : value,
    };

    setFilterParams(updatedFilterParams);

    if (!areTwoObjectsEqual(prevFilterParams, updatedFilterParams)) {
      handleFilterUpdate(updatedFilterParams);
    }
  };

  return (
    <div className="flex h-full w-full flex-col bg-[#F3F9FE] p-[0px] px-3 lg:p-[20px] lg:px-6">
      <div className="w-full">
        <h2 className="mb-[10px] font-body text-[28px] font-semibold leading-[38px] text-[#272D37]">
          Students
        </h2>
      </div>
      <div className="flex h-fit  w-full flex-col overflow-x-scroll  scroll-smooth  rounded-[10px] bg-[#fff] pb-12 pt-6 shadow-sm">
        <div className="flex w-full flex-row justify-between gap-x-[12px] border-b pb-[30px]">
          <div className="ml-3 flex items-center  gap-[16px]">
            <div className="flex h-full w-[40px] items-center justify-center">
              <img
                src={deleteIcon}
                className="h-auto w-4 cursor-pointer"
                alt="delete"
              />
            </div>
            <div className="flex items-center rounded-[8px] border-[1px] border-[#D0D5DD] border-r-transparent ">
              <img src={search} alt="" className="ml-2 h-4 w-4" />
              <input
                type="text"
                className="h-[40px] w-[200px] rounded-[8px] border-[1px] border-[#D0D5DD] border-b-transparent border-t-transparent border-l-transparent text-[16px] placeholder:text-[16px] placeholder:font-normal  placeholder:leading-[24px] placeholder:text-[#BDBDBD] md:w-[260px]"
                placeholder="Search..."
              />
            </div>
            <img src={vector} alt="" className="block lg:hidden" />
          </div>
          <DashBoardTopItem extraClassName={``}></DashBoardTopItem>
          <div className="hidden flex-row items-center  justify-between gap-x-2 lg:flex ">
            <NormalSelect
              placeholder="all year"
              options={["all year", ...getYearRange()]}
              value={filterParams.yearFilter}
              handleSelect={event => {
                handleFilterSelection("yearFilter", event.value);
              }}
            />
            <NormalSelect
              value={filterParams.classFilter}
              placeholder="all classes"
              options={classesOption}
              handleSelect={event => {
                handleFilterSelection("classFilter", event.value);
              }}
            />
            <AddStudentButton />
          </div>
        </div>
        {isLoading ? (
          <PageLoading classes="h-full w-full mt-12" />
        ) : error ? (
          <p>An error has occurred</p>
        ) : allStudents?.length === 0 ? (
          <div className="flex h-full items-center justify-center">
            <PageSearchIconAndLabel label="No students found" />
          </div>
        ) : (
          <>
            <table className="'w-full">
              <thead className="h-full w-full  ">
                <tr className="border-b-[1px] border-b-[#EAEBF0]">
                  <th className="items-start px-[24px] text-start">
                    <input
                      name="rememberMe"
                      type="checkbox"
                      id="rememberMe"
                      className="h-[20px] w-[20px] items-center rounded  border-[1px] text-primary focus:ring-[#D8E0F0]"
                    />
                  </th>
                  <th className="py-[12px] px-[4px] text-left font-body  text-sm font-medium leading-[18px] tracking-wide text-[#272D37]">
                    ID
                  </th>
                  <th className="py-[12px] px-[24px]  text-left font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]">
                    Students
                  </th>
                  <th className="py-[12px] px-[24px] text-left font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]">
                    DOB
                  </th>
                  <th className="py-[12px]  text-left font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]">
                    Parent
                  </th>
                  <th className="py-[12px]  text-left font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]">
                    Action
                  </th>
                </tr>
              </thead>
              {allStudents?.map((studentData, index) => {
                const {
                  studentAuthId,
                  studentId,
                  id,
                  passportImageUrl,
                  firstName,
                  lastName,
                  middleName,
                  studentEmail,
                  phoneNumber,
                  gender,
                  dateOfBirth,
                  hobbies,
                  skills,
                  currentClass,
                  subClass,
                  currentTerm,
                  currentYear,
                  firstSchoolLeavingCertificateUrl,
                  formerSchoolName,
                  country,
                  state,
                  LGA,
                  community,
                  homeTown,
                  address,
                  fatherFullName,
                  motherFullName,
                  guardianFullName,
                  guardianAddress,
                  guardianEmail,
                  guardianOccupation,
                  guardianPhoneNumber,
                } = studentData;

                const mainInfo = {
                  "User ID": studentId ?? id,
                  "First Name": firstName,
                  "Last Name": lastName,
                  "Middle Name": middleName,
                  Email: studentEmail,
                  Contact: phoneNumber,
                  Gender: gender,
                  "Date of Birth": dateOfBirth,
                  Hobbies: hobbies,
                  Skills: skills,
                };

                const schoolInfo = {
                  "Current Class": currentClass,
                  "Sub Class": subClass,
                  "Current Term": currentTerm,
                  "Current Year": currentYear,
                  "First School Leaving Certificate":
                    firstSchoolLeavingCertificateUrl,
                  "Former School Name": formerSchoolName,
                };

                const locationInfo = {
                  Country: country,
                  State: state,
                  LGA: LGA,
                  Community: community,
                  "Home Town": homeTown,
                  Address: address,
                };

                const guardianInfo = {
                  "Father's Name": fatherFullName,
                  "Mother's Name": motherFullName,
                  "Guardian's Name": guardianFullName,
                  "Guardian's Address": guardianAddress,
                  Email: guardianEmail,
                  Occupation: guardianOccupation,
                  "Phone Number": guardianPhoneNumber,
                };

                return (
                  <StudentPane
                    key={id}
                    firstName={firstName}
                    lastName={lastName}
                    studentAuthId={studentAuthId}
                    profileImage={passportImageUrl}
                    mainInfo={mainInfo}
                    schoolInfo={schoolInfo}
                    locationInfo={locationInfo}
                    guardianInfo={guardianInfo}
                  />
                );
              })}
            </table>
            <PaginationIndicator
              classes="self-end"
              currentPageNumber={state.currentPage}
              itemPerPage={state.dataPerPage}
              itemCount={state.currentPageCount}
              totalCount={state.totalCount}
              onBackArrowClick={fetchPreviousData}
              onForwardArrowClick={fetchNextData}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Students;
