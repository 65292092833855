import { useNavigate } from "react-router-dom";

import DownloadResultPDF from "../buttons/DownloadResultPDF";
import ShareResult from "../buttons/ShareResult";
import { BackArrow } from "../icons";

const ResultTerminalReportHeader = ({ studentResult }) => {
  const {
    schoolAuthId,
    studentAuthId,
    class: studentClass,
    term: studentTerm,
    year: studentYear,
  } = studentResult;

  const navigate = useNavigate();

  const handleBackNavigation = () => {
    navigate(-1);
  };

  return (
    <div className="mb-[44px] flex w-full items-center justify-between">
      <div
        className="flex cursor-pointer items-center space-x-[14px]"
        onClick={handleBackNavigation}
      >
        <BackArrow className="text-primary" />
        <p className="text-[18px] font-bold">Result spreadsheet</p>
      </div>
      <div className="self-center">
        <p className="text-[26px] font-bold">Terminal Report Sheet</p>
      </div>

      <div className="flex space-x-[16px]">
        <DownloadResultPDF
          schoolAuthId={schoolAuthId}
          studentAuthId={studentAuthId}
          studentClass={studentClass}
          studentTerm={studentTerm}
          studentYear={studentYear}
        />
        {/* <ShareResult /> */}
      </div>
    </div>
  );
};

export default ResultTerminalReportHeader;
