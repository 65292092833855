import axios from "axios";

import { getJwtToken } from "../../services/authService";
import urlSettings from "./apiConstants";

import { debugPrint } from "../../utils/debugPrint";

/// TODO: Activate the service timeout when api service is hosted on a paid plan
// axiosClient.defaults.timeout = 5000;

const fileUploadHeader = {
  authorization: getJwtToken(),
  "Content-Type": "multipart/form-data",
};

/// Authentication axios instance
const authClient = axios.create({
  baseURL: urlSettings.AUTH_URL,
});

/// School service axios instance
const schoolClient = axios.create({
  baseURL: urlSettings.SCHOOL_URL,
});

/// Teacher service axios instance
const teacherClient = axios.create({ baseURL: urlSettings.TEACHER_URL });

/// Student service axios instance
const studentClient = axios.create({ baseURL: urlSettings.STUDENT_URL });

const fileUploadClient = axios.create({
  baseURL: urlSettings.IMAGE_UPLOAD_URL,
  headers: fileUploadHeader,
});

let schoolRetryCount = 0;

/// Request interceptor for [schoolClient] axios instance
schoolClient.interceptors.request.use(
  (request) => {
    const token = getJwtToken();

    // if (request.url === "get-results-by-subjects") {
    //   request.headers["accept"] = "application/json";
    //   request.headers["content-type"] = "application/json";
    // }

    if (token) {
      // request.headers.Authorization = token;
      request.headers["authorization"] = token;
      // FIXME: Comment this line back if header error start occurring 👇🏿
      // request.headers.common["Accept"] = "application/json";
      // request.headers.Accept = "application/json";
      // request.headers["Content-Type"] = "application/json";
    }

    return request;
  },
  (error) => Promise.reject(error)
);

schoolClient.interceptors.response.use(
  (response) => {
    schoolRetryCount = 0;

    return response;
  },
  (error) => {
    debugPrint("schoolClient - error --", error);
    if (!error.response) return Promise.reject(error);
    if (error.response.status === 401 && schoolRetryCount < 1) {
      schoolRetryCount += 1;
      return schoolClient(error.config);
    }

    schoolRetryCount = 0;
    return Promise.reject(error);
  }
);

/// Request interceptor for [teacherClient] axios instance
teacherClient.interceptors.request.use(
  (request) => {
    const token = getJwtToken();

    if (token) {
      // request.headers.common["authorization"] = token;
      // request.headers.common["Accept"] = "application/json";
      request.headers["authorization"] = token;
      request.headers.Accept = "application/json";
    }

    return request;
  },
  (error) => Promise.reject(error)
);

// Request interceptor for [studentClient] axios instance
studentClient.interceptors.request.use(
  (request) => {
    const token = getJwtToken();

    if (token) {
      request.headers["authorization"] = token;
      // request.headers.common["Accept"] = "application/json";
    }

    return request;
  },
  (error) => Promise.reject(error)
);

const client = {
  authClient,
  schoolClient,
  teacherClient,
  studentClient,
  fileUploadClient,
};

export default client;
