import { createContext, useContext, useEffect, useReducer } from "react";

import { subjectActions } from "./subjectActions";
import subjectReducer, { initialState } from "./subjectReducer";

import {
  deleteSchoolSubject,
  fetchAllSubjects,
} from "../../features/school/services/schoolService";
import { getTeacherAllSubjects } from "../../features/teacher/services/teacherService";

import { showErrorToast, showSuccessToast } from "../../utils/toastHandler";

import { accountType } from "../../constants/accountType";

import { useAuth } from "../authContext";

const SubjectContext = createContext(initialState);

SubjectContext.displayName = "SubjectContext";

const SubjectProvider = ({ children }) => {
  const { user } = useAuth();

  const { userType, teacherSubjects, studentSubjects } = user || {};

  // const userType = user?.userType;

  const [state, dispatch] = useReducer(subjectReducer, initialState);

  const _handleSubjectsDataFetch = async () => {
    const { currentPage } = state;

    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolSubjectData } = await fetchAllSubjects(currentPage);
        return schoolSubjectData;

      case accountType.TEACHER:
        const { data: teacherSubjectData } = await getTeacherAllSubjects(
          currentPage
        );

        return teacherSubjectData;
      default:
        throw new Error("Something went wrong. Please try again");
    }
  };

  useEffect(() => {
    const fetchAllSubjects = async () => {
      dispatch({
        type: subjectActions.UPDATE_LOADING_STATE,
        payload: { isLoading: true },
      });

      if (userType === accountType.STUDENT) {
        dispatch({
          type: subjectActions.REQUEST_SUCCESS,
          payload: { allSubjects: studentSubjects },
        });
        dispatch({
          type: subjectActions.UPDATE_LOADING_STATE,
          payload: { isLoading: false },
        });
        return;
      }

      try {
        const response = await _handleSubjectsDataFetch();

        if (response.status === false) {
          throw new Error(
            response?.message ?? "Something went wrong. Try again"
          );
        }

        let allSubjects = [];

        const cachedData = [...response?.data];

        if (cachedData.length > 0) {
          allSubjects = [...response?.data[0]?.listOfSubjects];
        }

        dispatch({
          type: subjectActions.REQUEST_SUCCESS,
          payload: { allSubjects },
        });
      } catch (e) {
        dispatch({
          type: subjectActions.ADD_ERROR,
          payload: { error: e?.message ?? "Something went wrong. Try again" },
        });
      } finally {
        dispatch({
          type: subjectActions.UPDATE_LOADING_STATE,
          payload: { isLoading: false },
        });
      }
    };

    // Check if userType is teacher here

    if (userType === accountType.TEACHER) {
      dispatch({
        type: subjectActions.REQUEST_SUCCESS,
        payload: { allSubjects: [...teacherSubjects] },
      });

      return;
    }

    fetchAllSubjects();
  }, []);

  const handleDeleteSubject = async (subject) => {
    const cachedSubjects = [...state.allSubjects];

    const indexOfSubjectToDelete = cachedSubjects.findIndex(
      (value) => value === subject
    );

    if (indexOfSubjectToDelete === -1) {
      showErrorToast("Error deleting teacher");
      return;
    }

    const removedData = cachedSubjects[indexOfSubjectToDelete];

    cachedSubjects.splice(indexOfSubjectToDelete, 1);

    dispatch({
      type: subjectActions.REQUEST_SUCCESS,
      payload: { allSubjects: [...cachedSubjects] },
    });

    try {
      // const { data } = await deleteTeacher(teacherAuthId);
      const payload = {
        subject,
      };
      const { data } = await deleteSchoolSubject(payload);

      if (data?.status === true) {
        showSuccessToast(data?.message ?? "Success");
      } else {
        throw new Error(
          data?.message ?? "Couldn't delete teacher at this time"
        );
      }
    } catch (e) {
      showErrorToast(e?.message ?? "Something went wrong");

      cachedSubjects.splice(indexOfSubjectToDelete, 0, removedData);

      dispatch({
        type: subjectActions.REQUEST_SUCCESS,
        payload: { allTeachers: [...cachedSubjects] },
      });
    }
  };

  const updateAllSubjectsList = (newlyAddedSubjects) => {
    dispatch({
      type: subjectActions.UPDATE_SUBJECTS,
      payload: { allSubjects: newlyAddedSubjects },
    });
  };

  const values = {
    state,
    updateAllSubjectsList,
    handleDeleteSubject,
  };

  return (
    <SubjectContext.Provider value={values}>{children}</SubjectContext.Provider>
  );
};

const useSubject = () => {
  const context = useContext(SubjectContext);

  if (context === undefined) {
    throw new Error("useSubject must be used within SubjectContext");
  }

  return context;
};

export { SubjectProvider, useSubject };
