import { useState, useEffect } from "react";

import AddBulkScoresModal from "../../../shared/components/modals/AddBulkScoresModal";
import NormalSelect from "../../../shared/components/ui/NormalSelect";
import UndismissibleModal from "../../../shared/components/modals/UndismissibleModal";
import PageLoading from "../../../shared/components/ui/PageLoading";
import PaginationIndicator from "../../components/pagination/PaginationIndicator";
import PageSearchIconAndLabel from "../../../shared/components/PageSearchIconAndLabel";
import MarkTile from "../../../shared/components/MarkTile";
import { AddButtonPlain } from "../../../shared/components/ui/Buttons";

import { schoolTerms } from "../../../assets/lists/schoolTerms";
import { schoolClasses } from "../../../assets/lists/schoolClasses";
import { alphabets } from "../../../assets/lists/alphabet";
import { getYearRange } from "../../../utils/getYearRange";
import { accountType } from "../../../constants/accountType";
import { paginateData } from "../../../utils/clientSidePagination";

import { useMarks } from "../../../store/marksStore/marksContext";
import menuLeft from "../../../assets/svg/menu-left.svg";
import TickBox from "../../components/ui/TickBox";

import { useAuth } from "../../../store/authContext";

const Marks = () => {
  const { user } = useAuth();

  const { userType } = user || {};

  const { state, fetchInitialData } = useMarks();

  const { isInitial, isLoading, error, message, allStudents } = state;


  const [filterParams, setFilterParams] = useState({
    termFilter: "",
    classFilter: "",
    subClassFilter: "",
    yearFilter: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [classesOption, setClassesOption] = useState([...schoolClasses]);

  const [currentPage, setCurrentPage] = useState(1);

  const [selected, setSelected] = useState([]);

  function handleSelect(value, name) {
    if (value) {
      setSelected([...selected, name]);
    } else {
      setSelected(selected.filter(item => item !== name));
    }
  }

  function selectAll(value) {
    if (value) {
      // if true
      setSelected(allStudents); // select all
    } else {
      // if false
      setSelected([]); // unselect all
    }
  }

  const handleNextPage = () => {
    setCurrentPage(prevState => prevState + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevState => prevState - 1);
  };

  useEffect(() => {
    const checkUserTypeAndMaybeSetClassFilter = () => {
      if (userType === accountType.TEACHER) {
        setFilterParams(prevState => {
          return { ...prevState, classFilter: user?.teacherClasses[0] ?? "" };
        });

        // If the userType is "teacher" the class dropdown field is restricted
        // to only classes the teacher has been assigned to
        setClassesOption([...user?.teacherClasses]);
      }
    };

    checkUserTypeAndMaybeSetClassFilter();
  }, []);

  useEffect(() => {
    const handleFilter = () => {
      // Check if all three necessary filter parameters are present
      if (
        filterParams.termFilter &&
        filterParams.classFilter &&
        filterParams.subClassFilter &&
        filterParams.yearFilter
      ) {
        fetchInitialData(filterParams);
      }
    };

    // Check if any of the filter parameters changes and trigger filtering
    if (
      filterParams.termFilter ||
      filterParams.classFilter ||
      filterParams.subClassFilter ||
      filterParams.yearFilter
    ) {
      handleFilter();
    }
  }, [
    filterParams.termFilter,
    filterParams.classFilter,
    filterParams.subClassFilter,
    filterParams.yearFilter,
  ]);

  /// This function is responsible for updating the value of the "filterParams"
  /// state
  const handleFilterSelection = (name, value) => {
    const updatedObj = {
      [name]: value,
    };

    setFilterParams(prevState => {
      return { ...prevState, ...updatedObj };
    });
  };

  return (
    <>
      <UndismissibleModal
        showModal={showModal}
        setShowModal={setShowModal}
        header={<p className='text-[22px] font-bold'>Add Score</p>}
        width='712px'
      >
        <AddBulkScoresModal
          schoolAuthId={allStudents[0]?.schoolAuthId}
          studentClass={filterParams.classFilter}
          studentTerm={filterParams.termFilter}
          studentYear={filterParams.yearFilter}
          onCloseModal={() => setShowModal(false)}
        />
      </UndismissibleModal>
      <div className='flex h-full w-full flex-col p-0 lg:pb-[32px]'>
        <h2 className='mb-[16px] text-[28px] font-semibold leading-[38px] text-black'>
          Manage Marks
        </h2>

        <div className='flex w-full flex-col items-start rounded-[10px] bg-[#fff] shadow-sm'>
          <div className='flex w-full items-center justify-end self-stretch border-b-[1px] border-b-[#EAEBF0] py-[16px] px-8'>
            {/* <div className='flex h-full w-[40px] items-center justify-center '>
              <img src={deleteIcon} className='h-auto w-5' alt='delete' />
            </div> */}

            <div className='hidden items-center lg:flex'>
              <NormalSelect
                placeholder='Term'
                className={``}
                value={filterParams.termFilter}
                options={schoolTerms}
                handleSelect={event =>
                  handleFilterSelection("termFilter", event.value)
                }
              />

              <NormalSelect
                placeholder='Class'
                className={`px-6`}
                options={classesOption}
                value={filterParams.classFilter}
                handleSelect={event =>
                  handleFilterSelection("classFilter", event.value)
                }
              />

              <NormalSelect
                placeholder='Sub-class'
                className={``}
                value={filterParams.subClassFilter}
                options={alphabets}
                handleSelect={event =>
                  handleFilterSelection("subClassFilter", event.value)
                }
              />

              <NormalSelect
                placeholder='Year'
                className={`px-6`}
                value={filterParams.yearFilter}
                options={getYearRange()}
                handleSelect={event =>
                  handleFilterSelection("yearFilter", event.value)
                }
              />

              {/* <AddButtonPlain
                className={`px-[50px] text-white ${
                  isFilterButtonActive ? "bg-[#437EF7]" : "bg-gray-500"
                }`}
                showIcon={false}
                clicked={() => fetchInitialData(filterParams)}
                disabled={!isFilterButtonActive}
              >
                filter
              </AddButtonPlain> */}

              {/* {allStudents.length >= 1 && ( */}
              {allStudents.length >= 1 && <AddButtonPlain
                showIcon={false}
                className={`bg-primary px-[40px]`}
                clicked={() => setShowModal(true)}
              >
                Add Score
              </AddButtonPlain>}
              {/* )} */}
            </div>

            <div className='flex h-[24px] w-[24px] items-center justify-end lg:hidden'>
              <img src={menuLeft} className='h-full w-full' alt='menu-left' />
            </div>
          </div>

          {isInitial ? (
            <div className='flex h-full w-full items-center justify-center'>
              <PageSearchIconAndLabel label='Search for Marks' />
            </div>
          ) : isLoading ? (
            <PageLoading classes='h-full w-full' />
          ) : error ? (
            <div className='flex h-full w-full items-center justify-center'>
              <PageSearchIconAndLabel label={error ?? "Something went wrong"} />
            </div>
          ) : allStudents.length === 0 ? (
            <div className='flex h-full w-full items-center justify-center'>
              <PageSearchIconAndLabel label={message} />
            </div>
          ) : (
            <>
              <div className='relative h-full w-full relative overflow-x-auto scroll-smooth rounded-b-[10px]'>
                <table className='w-full text-left text-sm rtl:text-right'>
                  <thead className=''>
                    <tr className='border-b-[1px] border-b-[#EAEBF0]'>
                      <th scope='col' className='p-4'>
                        <div className='flex items-center'>
                          <TickBox
                            name='all'
                            value={selected.length === allStudents.length}
                            updateValue={selectAll}
                          />
                        </div>
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-8 font-body text-sm font-medium leading-[18px] text-[#5F6D7E]'
                      >
                        ID
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-8 font-body text-sm font-medium leading-[18px] text-[#5F6D7E]'
                      >
                        Students name
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-8 text-center font-body text-sm font-medium leading-[18px] text-[#5F6D7E]'
                      >
                        Marks
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-8 text-center font-body text-sm font-medium leading-[18px] text-[#5F6D7E]'
                      >
                        Grade Points
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-8 font-body text-sm font-medium leading-[18px] text-[#5F6D7E]'
                      >
                        Comments
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-8 text-center font-body text-sm font-medium leading-[18px] text-[#5F6D7E]'
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  {paginateData(allStudents, currentPage).map(
                    (allStudent, index) => {
                      const firstName = allStudent?.student?.firstName;
                      // console.log(allStudent, "allstudent");
                      const lastName = allStudent?.student?.lastName;
                      const profileImage =
                        allStudent?.student?.passportImageUrl;
                      const id =
                        allStudent?.studentId ?? allStudent?.student?.id;
                      const studentAuthId = allStudent?.studentAuthId;
                      const resultTotal = allStudent?.resultTotal;
                      const gradePoint = allStudent?.grade;
                      const gradeComment = allStudent?.gradeComment;

                      return (
                        <MarkTile
                          key={id}
                          id={id}
                          firstName={firstName}
                          lastName={lastName}
                          profileImage={profileImage}
                          resultTotal={resultTotal}
                          gradePoint={gradePoint}
                          gradeComment={gradeComment}
                          studentAuthId={studentAuthId}
                          classFilter={filterParams.classFilter}
                          termFilter={filterParams.termFilter}
                          yearFilter={filterParams.yearFilter}
                          name={allStudent}
                          value={selected.includes(allStudent)}
                          updateValue={handleSelect}
                        />
                      );
                    }
                  )}
                </table>
              </div>

              <PaginationIndicator
                classes='self-end'
                currentPageNumber={currentPage}
                itemCount={paginateData(allStudents, currentPage)?.length}
                totalCount={allStudents?.length}
                onBackArrowClick={handlePreviousPage}
                onForwardArrowClick={handleNextPage}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Marks;
