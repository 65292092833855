export const generateId = () => {
  const existingIDs = [
    "asncmdAkdhDHFIEHOEINEHGIELSNCEWHieeb78943no903489g34b9asf",
    "43nnHOGOH43NGOH3GIHEKALNGFOIAHGAOSFASFDSDFSDFSDFSD",
  ];

  const getRandomLetters = (length = 1) =>
    Array(length)
      .fill()
      .map((e) => String.fromCharCode(Math.floor(Math.random() * 26) + 65))
      .join("");

  const getRandomDigits = (length = 1) =>
    Array(length)
      .fill()
      .map((e) => Math.floor(Math.random() * 10))
      .join("");

  const generateUniqueID = () => {
    let id = getRandomLetters(2) + getRandomDigits(4);
    while (existingIDs.includes(id))
      id = getRandomLetters(2) + getRandomDigits(4);
    return id;
  };

  const newID = generateUniqueID();

  return newID;
};

/// This function generates an ID using "Date.now()" which return the DateTime
/// as number of milliseconds elapsed since January 1, 1970 AND a random number
/// between 0 - 144,000
///
/// NOTE: This function is not guaranteed to return a unique ID and should only
/// be used when IDs are not generated in quick succession and uniqueness is not
/// primarily a requirement
export const generateRandomId = () => {
  const firstRandomNumber = Math.floor(Math.random() * 144000);
  const secondRandomNumber = Date.now();

  const randomId = `${firstRandomNumber}-${secondRandomNumber}`;
  return randomId;
};
