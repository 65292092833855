import ReportSheetBodyHeader from "./ReportSheetBodyHeader";
import ResultSpreadSheet from "./ResultSpreadSheet";

const ResultTerminalReportBody = ({ studentResult }) => {
  return (
    <div className="mb-[32px] flex flex-col">
      <ReportSheetBodyHeader studentResult={studentResult} />
      <ResultSpreadSheet studentResult={studentResult} />
    </div>
  );
};

export default ResultTerminalReportBody;
