import { Outlet } from "react-router-dom";

const Assessments = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default Assessments;
