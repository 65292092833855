import * as React from "react";

const SvgAdmin = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 34 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.298 30.596h-4.19v-9.331c0-1.05-.854-1.905-1.904-1.905h-1.416c-.373-1.627-1.115-3.024-2.174-4.074a6.804 6.804 0 0 0-2.301-1.506c1.842-1.589 3.044-4.336 3.044-6.819 0-2.252-.627-4.02-1.862-5.255C20.377.59 18.823 0 17 0c-1.822 0-3.377.59-4.495 1.706-1.236 1.235-1.862 3.003-1.862 5.255 0 2.483 1.202 5.23 3.044 6.82-.86.34-1.634.844-2.3 1.505-1.06 1.05-1.802 2.447-2.175 4.074H7.796c-1.05 0-1.904.855-1.904 1.905v9.33h-4.19a.952.952 0 0 0 0 1.905h30.596a.952.952 0 0 0 0-1.904ZM17 1.904c1.398 0 3.593.465 4.256 3.229-.97-.124-1.524-.398-1.958-.612-.425-.21-.907-.45-1.495-.314-.477.109-.819.378-1.149.639-.601.475-1.48 1.166-4.073 1.306.323-3.673 2.864-4.248 4.419-4.248Zm-4.357 6.152c3.234-.17 4.442-1.124 5.191-1.715a4.35 4.35 0 0 1 .335-.249c.076.032.189.088.285.135.56.277 1.438.712 2.997.84-.025 1.401-.547 2.956-1.408 4.181-.879 1.25-1.988 1.968-3.043 1.968-1.055 0-2.164-.717-3.043-1.968a8.004 8.004 0 0 1-1.314-3.192Zm3.85 7.115h1.015c2.642 0 4.57 1.545 5.318 4.19H11.174c.748-2.645 2.676-4.19 5.318-4.19ZM7.795 30.596v-9.331h18.408v9.33H7.796Z"
      fill="currentColor"
    />
    <path
      d="M17 26.343a.952.952 0 1 0 0-1.905.952.952 0 0 0 0 1.905Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgAdmin;
