import TextFieldFormik from "../../TextFieldFormik";
import NormalButton from "../../ui/NormalButton";
import Select from "../../ui/Select";

const FormAddStudent1 = ({ values, errors, onClick, mb }) => {
  return (
    <>
      <div className={`flex flex-row justify-between space-x-[16px] ${mb}`}>
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="First Name"
          name="firstName"
          placeholder="James"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Middle Name"
          name="middleName"
          placeholder="Ellis"
        />
      </div>
      <TextFieldFormik
        extraClasses="mb-[15px]"
        label="Last Name"
        name="lastName"
        placeholder="Johnson"
      />
      <TextFieldFormik
        extraClasses="mb-[15px]"
        label="Email Address"
        name="studentEmail"
        placeholder="Ellis@gmail.com"
      />
      <div className="flex flex-col">
        <span className="mb-2 text-[14px] font-semibold text-[#7D8592]">
          Mobile Number
        </span>
        <div className="flex flex-row space-x-[16px]">
          <Select
            name="countryCode"
            showDefaultOption={false}
            hiddenLabel="code"
            options={["+234", "+233"]}
          />
          <span className="w-full">
            <TextFieldFormik
              extraClasses="mb-[15px]"
              name="phoneNumber"
              placeholder="345 567-23-56"
            />
          </span>
        </div>
      </div>

      <div className="my-[30px] flex w-full justify-end self-end ">
        <NormalButton
          showIcon={false}
          extraStyle=""
          clicked={onClick}
          label="Next"
          disabled={
            errors.firstName ||
            values.firstName === "" ||
            errors.middleName ||
            errors.lastName ||
            values.lastName === "" ||
            errors.phoneNumber ||
            values.phoneNumber === ""
              ? true
              : false
          }
        />
      </div>
    </>
  );
};

export default FormAddStudent1;
