import { createContext, useContext, useEffect, useReducer } from "react";

import { studentDashboardActions } from "./studentDashboardActions";
import studentDashboardReducer, {
  initialState,
} from "./studentDashboardReducer";

import { getStudentDashboardDetails } from "../services/studentService";
import { useAuth } from "../../../store/authContext";
import { debugPrint } from "../../../utils/debugPrint";

const StudentDashboardContext = createContext(null);

StudentDashboardContext.displayName = "StudentDashboardContext";

const StudentDashboardProvider = ({ children }) => {
  const { user } = useAuth();

  debugPrint(
    "studentDashboardContext - StudentDashboardProvider -- user ->",
    user
  );

  // TODO: Comment this back in after the data has been added to login payload
  // const {subjects, classes} = user;

  const studentAuthId = user?.id;

  const [state, dispatch] = useReducer(studentDashboardReducer, initialState);

  useEffect(() => {
    const fetchStudentDashboardData = async () => {
      dispatch({
        type: studentDashboardActions.UPDATE_LOADING_STATE,
        payload: { isLoading: true },
      });

      try {
        const { data } = await getStudentDashboardDetails(studentAuthId);

        debugPrint("studentDashboardContext - useEffect -- data ->", data);

        if (data.status === true) {
          const { data: innerData } = data;

          const { firstName } = innerData;

          dispatch({
            type: studentDashboardActions.REQUEST_SUCCESS,
            payload: {
              firstName,
              // subjects: user?.subjects ?? 0,
              // classes: user?.classes ?? 0,
              subjects: 0,
              classes: 0,
            },
          });
        } else {
          throw new Error(data?.message ?? "Something went wrong");
        }
      } catch (e) {
        dispatch({
          type: studentDashboardActions.REQUEST_FAILURE,
          payload: { error: e?.message ?? "Something went wrong" },
        });
      } finally {
        dispatch({
          type: studentDashboardActions.UPDATE_LOADING_STATE,
          payload: { isLoading: false },
        });
      }
    };

    fetchStudentDashboardData();
  }, []);

  const values = {
    state,
  };

  return (
    <StudentDashboardContext.Provider value={values}>
      {children}
    </StudentDashboardContext.Provider>
  );
};

const useStudentDashboard = () => {
  const context = useContext(StudentDashboardContext);

  if (context === undefined) {
    throw new Error(
      "useStudentDashboard must be used within StudentDashboardContext"
    );
  }

  return context;
};

export { StudentDashboardProvider, useStudentDashboard };
