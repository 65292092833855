import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PinField from "react-pin-field";
import imag from "../../../assets/images/logo.png";
import RegistrationFormLayout from "../../../shared/components/layouts/RegistrationFormLayout";
import { PointingRightArrow } from "../../../shared/components/icons";
import Button from "../../../shared/components/ui/Button";

import { resendEmailCode, verifyAccount } from "../../../services/authService";
import { showErrorToast, showSuccessToast } from "../../../utils/toastHandler";

import { useAuth } from "../../../store/authContext";
import SideBarLayout from "../../components/layouts/SideBarLayout";

const EnterEmailCode = () => {
  const { handleUserToken } = useAuth();

  let location = useLocation();
  let navigate = useNavigate();

  const email = location?.state?.email;
  const id = location?.state?.id;

  useEffect(() => {
    const validateEmailAndIdAreProvided = () => {
      if (!email || !id) {
        navigate("/login", {
          replace: true,
        });
      }
    };
    validateEmailAndIdAreProvided();
  }, []);

  const [emailCode, setEmailCode] = useState(null);
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmailCodeComplete = value => {
    setEmailCode(value);
    setShowError(false);
  };

  const handleOnSubmit = async () => {
    if (emailCode === null || emailCode.length < 4) {
      setShowError(true);
      return;
    }

    setIsSubmitting(true);

    try {
      const payload = {
        email: location.state.email,
        authId: location.state.id,
        emailCode: emailCode,
      };

      const response = await verifyAccount(payload);

      if (response.status === true) {
        // Save user's token to storage
        handleUserToken(response?.token);

        navigate("/onboarding/school-contact-info", {
          replace: true,
          state: { hasOnboarded: false },
        });
        // navigate("/login", {
        //   replace: true,
        // });
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const resendEmailVerificationCode = async () => {
    try {
      const payload = {
        email,
      };
      const response = await resendEmailCode(payload);

      if (response.status !== true) {
        throw new Error(response.message);
      }
      showSuccessToast(response?.message ?? "Email code sent successfully!..");
    } catch (err) {
      showErrorToast(err.message);
    }
  };

  return (
    <SideBarLayout firstIsActive={true}>
      <div className="flex flex-col items-stretch justify-center px-[300px]">
        <div className=" mb-[80px] flex items-center justify-center text-center">
          <img
            src={imag}
            alt="image for login reset and verify pages"
            className=" h-[34.11px] w-[144.8px]"
          />
        </div>
        <h1 className="mb-4 text-center text-[24px] font-bold leading-[29.05px] text-[#0A1629]">
          Verify
        </h1>

        <p className="mb-4 px-6 text-center text-[14px] font-normal leading-[20.02px] text-[#7D8592] md:px-0 lg:px-0 lg:text-justify xl:px-0 2xl:px-0">
          Enter the code that has been sent to your email
        </p>

        <p className="mb-1 text-center text-[14px] font-bold leading-[24px] text-[#7D8592] lg:text-justify">
          Code from {email}
        </p>
        <div className="mb-5 hidden place-items-center lg:grid">
          <div className="mb-[24px] flex items-stretch justify-between">
            <PinField
              className="mr-[9px] h-[48px]  w-[58px] rounded-[14px] border-[1px] border-[#D8E0F0] text-center"
              length={6}
              onComplete={handleEmailCodeComplete}
            />
            {showError && (
              <p className="text-[14px] text-accent">Fields cannot be empty</p>
            )}
          </div>
        </div>

        <div className="mb-5 grid place-items-center lg:hidden">
          <PinField
            className="mr-[9px] h-[30px] w-[40px] rounded-[8px] border-[1px] border-[#D8E0F0] text-center"
            length={6}
            onComplete={handleEmailCodeComplete}
          />
          {showError && (
            <p className="text-[14px] text-accent">Fields cannot be empty</p>
          )}
        </div>

        {/* //TODO: Debounce the click of this button */}

        <Button
          onClick={handleOnSubmit}
          extraClasses="self-center"
          isLoading={isSubmitting}
        >
          <div className="flex items-center justify-center space-x-2 ">
            <span className="text-[16px] font-bold leading-[19.36px] text-[#FFFFFF]">
              Proceed
            </span>
            {/* <PointingRightArrow color="bg-white" /> */}
          </div>
        </Button>
        <p className=" mt-4 text-center text-[14px] font-normal leading-[16.94px] text-[#4F4F4F]">
          Didn't get the code{" "}
          <span
            onClick={resendEmailVerificationCode}
            className="cursor-pointer self-center font-semibold text-[#2969FF]   "
          >
            Resend(1/3)
          </span>
        </p>
      </div>
    </SideBarLayout>
  );
};

export default EnterEmailCode;
