import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const SpreadSheetTableHead = () => {
  const location = useLocation();

  const studentResult = location?.state?.studentResult;

  const [subjectExamTypes, setSubjectExamTypes] = useState();

  /// Get the result details of the first key (which will be a subject) in the
  /// "resultCompilationSheet" object
  ///
  /// NOTE: No guarantee that a subject key will be present
  useEffect(() => {
    const extractResultDetailsOfFirstSubject = () => {
      let subjectKeys;
      for (const subject in studentResult.resultCompilationSheet) {
        const firstSubjectResult =
          studentResult.resultCompilationSheet[subject];
        subjectKeys = Object.keys(firstSubjectResult);
        break; // Only the first item is
      }

      // Removing "subjectGrade" and "totalScore" from the array
      const arrayWithoutSubjectGradeAndTotalScore = subjectKeys.filter(
        (value) => value !== "subjectGrade" && value !== "totalScore" && value !== "subjectComment"
      );

      return arrayWithoutSubjectGradeAndTotalScore;
    };

    const result = extractResultDetailsOfFirstSubject();

    setSubjectExamTypes(result);
  }, [studentResult]); // Will only run once

  return (
    <thead className="text-[15.65px] leading-[22px]">
      <tr className="border-b-[4px] border-[#B8B8B8]">
        <th className="w-[64px] p-[10px] text-left font-normal">S/N</th>
        <th className="p-[10px] text-left font-normal w-[176px]">Subjects</th>
        {subjectExamTypes?.map((examType, index) => (
          <th
            key={`${examType}${index}`}
            className="p-[10px] text-left font-normal w-[176px]"
          >
            {examType}
          </th>
        ))}
        <th className="p-[10px] text-left font-normal w-[176px]">Comment</th>
        <th className="p-[10px] text-left font-normal w-[176px]">Total Score</th>
        <th className="p-[10px] text-left font-normal w-[176px]">Grade</th>
        {/* <th className="p-[10px] text-left font-normal w-[176px]">Remark</th> */}
        {/* <th className="bg-[#F8F8F8] p-[10px] text-left font-normal w-[176px]">
          Teacher in charge
        </th> */}
      </tr>
    </thead>
  );
};

export default SpreadSheetTableHead;
