import { useNavigate } from "react-router-dom";

import { NotFound } from "../../shared/components/icons";
import Button from "../../shared/components/ui/Button";

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center">
      <NotFound className="text-[512px]" />
      <p>
        Oops! The page you are looking for might have been removed or had its
        name
      </p>
      <p className="mb-[32px]">changed or is temporarily unavailable.</p>
      <Button onClick={handleNavigation}>go back</Button>
    </div>
  );
};

export default PageNotFound;
