import { Route, Routes } from "react-router-dom";

import { ClassSubjectProvider } from "../../../../store/classStore/classContext";
import ClassDashboard from "./ClassDashboard";
import ClassSubjects from "./ClassSubjects";

const Class = () => {
  return (
    <ClassSubjectProvider>
      <Routes>
        <Route index element={<ClassDashboard />} />
        <Route path=":id" element={<ClassSubjects />} />
      </Routes>
    </ClassSubjectProvider>
  );
};

export default Class;
