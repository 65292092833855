import { ExaminationProvider } from '../../../store/examinationStore/examinationContext'
import ExaminationsDashboard from './ExaminationsDashboard'

const Examinations = () => {
  return (
    <ExaminationProvider>
        <ExaminationsDashboard />
    </ExaminationProvider>
  )
}

export default Examinations