import React,{ useState } from "react";
import * as Icons from "../../components/icons";

const SearchBar = () => {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState('');

  const handleIconClick = () => {
    setIsActive(!isActive);
  };

  return (
    <label className=" flex items-center justify-center gap-1">
      <span
        className=" flex  justify-center items-center h-auto w-[20px]"
        onClick={handleIconClick}
      >
        <Icons.Search
          className={["text-[20px] text-[#828282] ", isActive ? 'active-icon-class' : 'inactive-icon-class'].join(" ")}
          color={isActive ? 'active-icon-color' : 'inactive-icon-color'}
        />
      </span>
      {isActive && (
        <input
          value={value}
          onChange={(event) => setValue(event.target.value)}
          className={[
            " md:block hidden w-full h-[20px] rounded-[14px] border-0 py-2 pl-9 pr-3 transition duration-150 ease-in placeholder:text-inActive-shade1 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm",
          ].join(" ")}
          type="text"
          placeholder="Search..."
        />
      )}
    </label>
  );
};


// const SearchBar = ({
//   className,
//   iconClassName,
//   inputClassName,
//   iconColor = "#333333",
//   placeholder = "Search",
//   onChange = () => {},
//   value,
// }) => {
//   return (
//     <label className="relative block bg">
//       <span className="absolute inset-y-0 left-0 flex items-center pl-2">
//         <Icons.Search
//           className={["text-[17px]", iconClassName].join(" ")}
//           color={iconColor}
//         />
//       </span>
//       <input
//         value={value}
//         onChange={(event) => onChange(event.target.value)}
//         className={[
//           "block w-full rounded-[14px] border-0 py-2 pl-9 pr-3 transition duration-150 ease-in placeholder:text-inActive-shade1 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm",
//           inputClassName,
//         ].join(" ")}
//         type="text"
//         placeholder={placeholder}
//       />
//     </label>
//   );
// };
export default SearchBar;
