import { useState } from "react";
import { Form, Formik } from "formik";

import UndismissibleModal from "../modals/UndismissibleModal";
import { Picture } from "../icons";

import { bulkStudentUploadSchema } from "../../../utils/validationSchemas/bulkStudentUploadSchema";

import { showErrorToast, showSuccessToast } from "../../../utils/toastHandler";

import client from "../../../config/apiConfig/apiConfig";
import LoadingSpinner from "../ui/LoadingSpinner";

const UploadBulkStudent = ({ onUpload }) => {
  const [selectedFile, setSelectedFile] = useState();

  const [uploadProgress, setUploadProgress] = useState(0);

  /// This state will be used to handle showing a modal if an error occurs while
  /// uploading bulk student data
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [excelStudentErrorArray, setExcelStudentErrorArray] = useState([]);

  const initialValues = {
    studentsExcelSheet: null,
  };

  const handleFileSelection = (event, touched, errors, handleSubmit) => {
    if (!event.target.files || event.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(event.target.files[0]);

    if (!touched.studentsExcelSheet && !errors.studentsExcelSheet) {
      handleSubmit();
    }
  };

  const formHandler = async (values) => {
    const { studentsExcelSheet } = values;

    if (studentsExcelSheet == null) {
      return;
    }

    onUpload(true);

    try {
      const excelUploadFormData = new FormData();
      excelUploadFormData.append("studentExcelData", studentsExcelSheet);

      // const { data } = await uploadStudentsExcelBulkUpload(excelUploadFormData);

      const { data } = await client.schoolClient.post(
        "student-excel-bulk-account-creation",
        excelUploadFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setUploadProgress(percentCompleted);
          },
        }
      );

      const { status, message } = data;

      if (status === false) {
        throw new Error(message ?? "Something went wrong try again");
      }

      showSuccessToast(message ?? "Upload successful");

      const { data: innerData } = data;

      /// This will contain an array of the status of each of the student detail
      /// in the excel spreadsheet
      const arrayOfStudentDetails = [...innerData];

      const studentsWithErrorFromUploadedExcelSheet = [];

      /// Run a check from the uploaded excel spreadsheet to check if there was
      /// any error in any of the inputted data
      for (let index = 0; index < arrayOfStudentDetails.length; index++) {
        const studentDetail = arrayOfStudentDetails[index];

        const { status, message, excleErrorLine } = studentDetail;

        if (status === false) {
          const obj = {
            index: excleErrorLine ?? index + 2,
            message,
          };

          studentsWithErrorFromUploadedExcelSheet.push(obj);
        }
      }

      if (studentsWithErrorFromUploadedExcelSheet.length !== 0) {
        setExcelStudentErrorArray(studentsWithErrorFromUploadedExcelSheet);
        setShowErrorModal(true);

        return;
      }
      window.location.reload();
    } catch (e) {
      showErrorToast(e?.message ?? "Something went wrong. Try again");
      setSelectedFile(undefined);
    } finally {
      onUpload(false);
    }
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <UndismissibleModal
          header={
            <p className="text-[22px] font-bold">{`Excel Sheet ${
              excelStudentErrorArray.length > 1 ? "Errors" : "Error"
            }`}</p>
          }
          maxHeight="600px"
          // width="700px"
          showModal={showErrorModal}
          setShowModal={setShowErrorModal}
        >
          <table className="w-[640px] table-fixed border-collapse overflow-auto">
            <thead className="text-[15.65px] leading-[22px]">
              <tr className="border-b-[4px] border-[#B8B8B8]">
                <th className="w-[64px] p-[10px] text-left font-normal">
                  Line
                </th>
                <th className="w-full p-[10px] text-center font-normal">
                  Message
                </th>
              </tr>
              <tr></tr>
            </thead>
            <tbody className="bg-white">
              {excelStudentErrorArray?.map((data) => (
                <tr
                  key={data?.index}
                  className="cursor-default border-b-[2px] border-[#E5E5E5]"
                >
                  <td className="border-r-[1px] border-grey-light p-[10px]">
                    {data?.index}
                  </td>
                  <td className="border-r-[1px] border-grey-light p-[10px]">
                    {data?.message}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </UndismissibleModal>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={bulkStudentUploadSchema}
        onSubmit={formHandler}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          isSubmitting,
          setFieldTouched,
          setTouched,
          setFieldValue,
        }) => (
          <Form
            onSubmit={handleSubmit}
            className="flex flex-col"
            autoComplete="off"
          >
            <div className="flex h-[161px] lg:w-[327px] px-3 lg:px-0 cursor-pointer flex-col items-center justify-center space-y-[18.33px] rounded-[16px] border-2 border-dashed border-[#D0DBEA]">
              <label className="block cursor-pointer">
                <span className="sr-only text-center">Upload students excel sheet</span>
                <input
                  className="hidden"
                  name="studentsExcelSheet"
                  type="file"
                  onChange={(event) => {
                    if (isSubmitting) {
                      return;
                    }

                    setTouched({ studentsExcelSheet: true }, false);
                    setFieldValue("studentsExcelSheet", event.target.files[0]);
                    handleFileSelection(event, touched, errors, handleSubmit);
                  }}
                />
                <div className="flex flex-col items-center justify-center">
                  <Picture
                    width="37.92px"
                    height="37.92px"
                    color="#9FA5C0"
                    className={
                      selectedFile &&
                      !errors.studentsExcelSheet &&
                      "text-primary"
                    }
                  />
                  {selectedFile && !errors.studentsExcelSheet ? (
                    <div className="flex flex-col">
                      <p className="mt-[8px] mb-[10px] text-[15px] font-bold text-[#3E5481]">
                        {selectedFile?.name ?? "Student excel sheet"}
                      </p>

                      {isSubmitting && (
                        <span className="self-center">
                          {/* {`Upload...${uploadProgress}%`} */}
                          <LoadingSpinner spinnerColor="border-primary" />
                        </span>
                      )}
                    </div>
                  ) : (
                    <>
                      <p className="mt-[8px] text-[15px] font-bold text-[#3E5481]">
                        Upload bulk from Excel Sheet
                      </p>
                      <p className="self-center text-[12px] text-[#9FA5C0]">
                        (up to 12 Mb)
                      </p>
                    </>
                  )}
                </div>
              </label>

              {errors.studentsExcelSheet && touched.studentsExcelSheet && (
                <span className="ml-[8px] text-[14px] text-accent">
                  {errors.studentsExcelSheet}
                </span>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UploadBulkStudent;
