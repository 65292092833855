import { createContext, useContext, useEffect, useReducer } from "react";

import { classSubjectActions } from "./classActions";
import { classInitialState, classSubjectsReducer } from "./classReducer";

import { fetchClassSubject } from "../../features/school/services/schoolService";
import { getTeacherSubjectsByClass } from "../../features/teacher/services/teacherService";

import { accountType } from "../../constants/accountType";

import { useAuth } from "../authContext";

const ClassSubjectContext = createContext(classInitialState);

ClassSubjectContext.displayName = "ClassSubjectContext";

const ClassSubjectProvider = ({ children }) => {
  const { user } = useAuth();
  const userType = user?.userType;

  const [state, dispatch] = useReducer(classSubjectsReducer, classInitialState);

  const _handleClassSubjectDataFetch = async (selectedClass) => {
    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolClassSubjects } = await fetchClassSubject(
          selectedClass
        );
        return schoolClassSubjects;
      case accountType.TEACHER:
        const { data: teacherClassSubjects } = await getTeacherSubjectsByClass(
          selectedClass
        );
        return teacherClassSubjects;

      default:
        throw new Error("Something went wrong. Try again");
    }
  };

  const fetchSubjectsByClass = async (selectedClass) => {
    try {
      dispatch({
        type: classSubjectActions.IS_LOADING,
        payload: { isLoading: true },
      });

      const response = await _handleClassSubjectDataFetch(selectedClass);

      const { status, message, data } = response;

      if (status === true) {
        dispatch({
          type: classSubjectActions.UPDATE_CLASS_SUBJECTS,
          payload: { listOfSubjects: [...data] },
        });

        return;
      }

      dispatch({
        type: classSubjectActions.SHOW_ERROR,
        payload: {
          error: true,
          message: message.includes(
            '"studentClass" must be one of [jss1, jss2, jss3, sss1, sss2, sss3]'
          )
            ? "The class you need to choose must be one of these JSS1, JSS2, JSS3, SSS1, SSS2 and SSS3"
            : data.message,
        },
      });
    } catch (error) {
      // dispatch({
      //   type: classSubjectActions.SHOW_ERROR,
      //   payload: {
      //     error: true,
      //     // message: "an unlikely error occurred, pls check your network!.",
      //     message: error ?? "Something went wrong. Please try again",
      //   },
      // });
      // TODO: Show toast here
    } finally {
      dispatch({
        type: classSubjectActions.IS_LOADING,
        payload: { isLoading: false },
      });
    }
  };

  const updateNewlyAddedClassSubjects = (arrayOfSubjects) => {
    dispatch({
      type: classSubjectActions.UPDATE_CLASS_SUBJECTS,
      payload: {
        listOfSubjects: [...arrayOfSubjects, ...state.listOfSubjects],
      },
    });
  };

  const resetError = () => {
    dispatch({
      type: classSubjectActions.SHOW_ERROR,
      payload: { error: false },
    });
    dispatch({
      type: classSubjectActions.UPDATE_CLASS_SUBJECTS,
      payload: { listOfSubjects: [] },
    });
  };

  const handleInitialSubjectAssign = () => {
    dispatch({ type: classSubjectActions.UPDATE_INITIAL_SUBJECT_ASSIGNING });
  };

  const value = {
    state,
    fetchSubjectsByClass,
    updateNewlyAddedClassSubjects,
    resetError,
    handleInitialSubjectAssign,
  };

  return (
    <ClassSubjectContext.Provider value={value}>
      {children}
    </ClassSubjectContext.Provider>
  );
};

const useClassSubjectContext = () => {
  const context = useContext(ClassSubjectContext);

  if (context === undefined) {
    throw new Error(
      "useClassSubjectContext must be used within ClassSubjectContext"
    );
  }
  return context;
};

export { ClassSubjectProvider, useClassSubjectContext };
