import { useState } from "react";

import DashBoardTopItem from "../../../shared/components/DashBoardTopItem";
import TeacherCard from "../../../shared/components/TeacherCard";
import NormalSelect from "../../../shared/components/ui/NormalSelect";
import PageLoading from "../../../shared/components/ui/PageLoading";
import PageSearchIconAndLabel from "../../../shared/components/PageSearchIconAndLabel";

import { AddTeacherButton } from "../../../shared/components/ui/AddTeacherButton";
import menuLeft from "../../../assets/svg/menu-left.svg";
import { AddButtonPlain } from "../../../shared/components/ui/Buttons";
import filterIcon from "../../../assets/svg/filter_line.svg";
import deleteIcon from "../../../assets/svg/delete.svg";
import { accountType } from "../../../constants/accountType";
import TickBox from "../../components/ui/TickBox";
import { useTeacher } from "../../../store/teacherStore/teacherContext";
import { useAuth } from "../../../store/authContext";
import { AddStudentButton } from "../../components/ui/AddStudentButton";
import SearchField from "../../components/ui/SearchField";
import PaginationIndicator from "../../components/pagination/PaginationIndicator";
import { debugPrint } from "../../../utils/debugPrint";

export default function Teachers() {
  const { user } = useAuth();

  const userType = user?.userType;

  const {
    state,
    handleFilterUpdate,
    handleDeleteTeacher,
    fetchNextData,
    fetchPreviousData,
  } = useTeacher();

  const {
    isLoading,
    error,
    allTeachers,
    currentPage,
    currentPageCount,
    dataPerPage,
    totalCount,
  } = state;

  const [filterParams, setFilterParams] = useState({
    subjectFilter: "",
    classFilter: "",
  });
  const [selected, setSelected] = useState([]);

  function handleSelect(value, name) {
    if (value) {
      setSelected([...selected, name]);
    } else {
      setSelected(selected.filter(item => item !== name));
    }
  }

  function selectAll(value) {
    if (value) {
      // if true
      setSelected(allTeachers); // select all
    } else {
      // if false
      setSelected([]); // unselect all
    }
  }

  /// This function is responsible for updating the value of the "filterParams"
  /// state
  const handleFilterSelection = (name, value) => {
    let filterValue = value;
    if (value === "all subjects" || value === "all classes") {
      filterValue = "";
    }
    const updatedObj = {
      [name]: filterValue,
    };

    setFilterParams(prevState => {
      return { ...prevState, ...updatedObj };
    });
  };

  return (
    <div className="flex h-full w-full flex-col bg-[#F3F9FE] p-[0px] lg:p-[20px]">
      {/* <DashBoardTopItem> */}
      <div className="w-full">
        <h2 className="mb-[10px] font-body text-[28px] font-semibold leading-[38px] text-[#272D37]">
          Teachers
        </h2>
      </div>
      {/* </DashBoardTopItem> */}

      <div className="relative flex w-full flex-col items-start overflow-x-auto scroll-smooth rounded-[10px] bg-[#fff] shadow-sm">
        {/* card header */}
        <div className="w-full flex-col items-start gap-[16px] self-stretch border-b-[1px] border-b-[#EAEBF0] py-[16px] px-[24px]">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-[16px]">
              <div className="flex h-full w-[40px] items-center justify-center">
                <img src={deleteIcon} className="h-auto w-5" alt="delete" />
              </div>

              <SearchField className="ml-auto" />
            </div>

            <div className="hidden items-center gap-[16px] lg:flex">
              <AddButtonPlain
                className={"bg-white"}
                showIcon={false}
                clicked={() => handleFilterUpdate(filterParams)}
              >
                <img src={filterIcon} className="w-[20px]" alt="filter" />
                <span className="text-black"> All Teachers</span>
              </AddButtonPlain>

              {userType === accountType.SCHOOL && <AddTeacherButton />}
            </div>

            <div className="flex h-[24px] w-[24px] items-center justify-end lg:hidden">
              <img src={menuLeft} className="h-full w-full" alt="menu-left" />
            </div>
          </div>
        </div>
        {/* Card Table */}

        {isLoading ? (
          <PageLoading classes="h-full w-full my-12" />
        ) : error ? (
          <div className="flex h-full w-full items-center justify-center">
            <PageSearchIconAndLabel label={error ?? "Something went wrong"} />
          </div>
        ) : allTeachers?.length === 0 ? (
          <div className="flex h-full w-full items-center justify-center">
            <PageSearchIconAndLabel />
          </div>
        ) : (
          <table className="relative w-full text-left rtl:text-right">
            {/* h-full */}
            <thead className="">
              <tr className="border-b-[1px] border-b-[#EAEBF0]">
                <th scope="col" className="pl-8">
                  <div className="flex items-center">
                    <TickBox
                      name="all"
                      value={selected.length === allTeachers.length}
                      updateValue={selectAll}
                    />
                  </div>
                </th>

                <th
                  scope="col"
                  className="py-8 px-6 font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]"
                >
                  ID
                </th>

                <th
                  scope="col"
                  className="py-8 px-6 font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]"
                >
                  Teacher
                </th>

                <th
                  scope="col"
                  className="py-8 px-6 font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]"
                >
                  {" "}
                  Subjects
                </th>
                <th
                  scope="col"
                  className="py-8 px-6 font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]"
                >
                  {" "}
                  Classes
                </th>

                <th
                  scope="col"
                  className="flex justify-center py-8 px-6 font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]"
                >
                  {" "}
                  Action
                </th>
              </tr>
            </thead>

            {allTeachers?.map((eachTeacher, index) => {
              debugPrint("Teachers - eachTeacher --", eachTeacher);
              const {
                teacherAuthId,
                id,
                profileImageUrl,
                firstName,
                lastName,
                middleName,
                phoneNumber,
                dateOfBirth,
                teacherEmail, // <- Main info stops here
                subjects,
                classes,
                yearsOfExperience, // <- Job info stops here
                country,
                state,
                LGA,
                address, // <- Location info stops here
                formerEmployerName,
                formerJobTitle,
                documents, // <- Other info stops here
                salary,
                accountName,
                accountNumber,
                bank,
                bankCode, // <- Salary info stop here
              } = eachTeacher;

              const mainInfo = {
                "User ID": id,
                "First Name": firstName,
                "Last Name": lastName,
                "Middle Name": middleName,
                Email: teacherEmail,
                Contact: phoneNumber,
                "Date of Birth": dateOfBirth,
              };

              const jobInfo = {
                Subjects: subjects,
                Classes: classes,
                "Years of Experience": yearsOfExperience,
              };

              const locationInfo = {
                Country: country,
                State: state,
                LGA: LGA,
                Address: address,
              };

              const otherInfo = {
                "Former Job Title": formerJobTitle,
                "Former Employer Name": formerEmployerName,
                Documents: documents,
              };

              const salaryInfo = {
                salary,
                accountName,
                accountNumber,
                bank,
                bankCode,
              };

              return (
                <TeacherCard
                  key={id}
                  teacherAuthId={teacherAuthId}
                  profileImage={profileImageUrl}
                  mainInfo={mainInfo}
                  jobInfo={jobInfo}
                  locationInfo={locationInfo}
                  otherInfo={otherInfo}
                  salaryInfo={salaryInfo}
                  onDelete={() => handleDeleteTeacher(teacherAuthId)}
                  name={eachTeacher}
                  value={selected.includes(eachTeacher)}
                  updateValue={handleSelect}
                />
              );
            })}
          </table>
        )}
      </div>

      <PaginationIndicator
        classes="self-end"
        currentPageNumber={currentPage}
        itemPerPage={dataPerPage}
        itemCount={currentPageCount}
        totalCount={totalCount}
        onBackArrowClick={fetchPreviousData}
        onForwardArrowClick={fetchNextData}
      />
    </div>
  );
}
