import { useState } from "react";
import PropTypes from "prop-types";

const NormalSelect = ({
  label,
  name,
  selectClasses,
  showDefaultOption,
  hiddenLabel,
  onChange,
  handleSelect,
  options,
  className,
  placeholder = "Select",
  value,
  onKeyPress,
  autoFocus,
  errorMessage,
}) => {
  //this state changes the visual look of the selected option....by default it should be grayed out!
  const [changeOptionColor, seChangeOptionColor] = useState("#353935");

  return (
    <div className={`${className} flex flex-col`}>
      {label && (
        <label
          htmlFor={name}
          className="mb-[10px] text-[14px] font-semibold capitalize text-[#7D8592]"
        >
          {label}
        </label>
      )}
      <select
        autoFocus={autoFocus}
        onKeyPress={onKeyPress}
        value={value}
        className={
          selectClasses
            ? `${selectClasses}`
            : " h-[40px] w-full rounded-[8px] border-[1px] border-[#D0D5DD] bg-white uppercase"
        }
        style={{
          color: changeOptionColor,
          fontSize: 14,
        }}
        onChange={event => handleSelect(event.currentTarget)}
      >
        <option value="" hidden className="text-[#D0D5DD]">
          {placeholder}
        </option>
        {options?.map((opt, index) => (
          <option key={index} value={opt}>
            {opt.toString().toUpperCase()}
          </option>
        ))}
      </select>
      <p className="">{errorMessage}</p>
    </div>
  );
};

NormalSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default NormalSelect;
