import { extractFirstTwoLetterOfWordToUpperCase } from "../../utils/helperFunctions";

const ImageAvatar = ({ imageUrl, fullName, alt = "Profile avatar" }) => {
  return imageUrl ? (
    <img className="h-full w-full object-cover" src={imageUrl} alt={alt} />
  ) : fullName ? (
    <p className="font-semibold">
      {extractFirstTwoLetterOfWordToUpperCase(fullName)}
    </p>
  ) : null;
};

export default ImageAvatar;
