import { useState, useRef } from "react";
import { PropTypes } from "prop-types";

import SchoolCard from "../../../shared/components/SchoolCard";
import SideNavLink from "../../../shared/components/SideNavLink";
import { SideNavItem } from "../../../shared/components/SideNavItem";
import * as Icons from "../../../shared/components/icons";
import blackLogo from "../../../assets/images/logo.png";

import { comingSoonRoutes } from "../../../utils/routeNames";

/**
 * Layout component that wraps its children elements.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child elements to be wrapped by the Layout component.
 * @param {String} props.extraClassName - Extra tailwind css class name for the component
 * @returns {JSX.Element} The rendered SchoolSideNav component.
 */
const SchoolSideNav = ({ children, extraClassName }) => {
  const container = useRef();

  const [navItemId, setNavItemId] = useState(-1);

  const handleNavItem = id => {
    setNavItemId(prevState => {
      if (prevState !== id) {
        return id;
      } else {
        return navItemId ? false : id;
      }
    });
  };

  // const handleClickOutside = event => {
  //   if (container.current && !container.current.contains(event.target)) {
  //     setNavItemId(-1);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => document.removeEventListener("mousedown", handleClickOutside);
  // });

  return (
    <div
      className={`hidden h-full w-full flex-col items-center justify-between self-stretch py-6 lg:flex ${extraClassName}`}
      ref={container}
    >
      <div>
        <div className="mb-[32px] rounded-[5px] py-0 px-[56px]">
          <img src={blackLogo} className="h-[35px] w-[145px]" alt="logo" />
        </div>

        <div className="flex flex-col items-start">
          {/* Dashboard */}
          <SideNavItem
            icon={<Icons.Dashboard className={`flex-0 text-[22px]`} />}
            title="Dashboard"
            href="dashboard"
            clicked={() => handleNavItem(1)}
            active={navItemId === 1}
          />

          {/* Users */}
          <SideNavItem
            icon={<Icons.UserGroup className="flex-0 text-[22px]" />}
            title="Users"
            className
            clicked={event => {
              event.preventDefault();
              handleNavItem(3);
            }}
            href="users"
            active={navItemId === 3}
          >
            <SideNavLink href={`/${comingSoonRoutes.ADMIN}`} inactive={true}>
              Admin
            </SideNavLink>
            <SideNavLink href="users/students">Students</SideNavLink>
            <SideNavLink href="users/teachers">Teachers</SideNavLink>
            <SideNavLink href={`/${comingSoonRoutes.PARENTS}`} inactive={true}>
              Parents
            </SideNavLink>
          </SideNavItem>

          {/* Academic */}
          <SideNavItem
            icon={<Icons.GradHat className="flex-0 text-[22px]" />}
            title="Academic"
            clicked={event => {
              event.preventDefault();
              handleNavItem(2);
            }}
            href="academic"
            active={navItemId === 2}
          >
            <SideNavLink
              href={`/${comingSoonRoutes.ATTENDANCE}`}
              inactive={true}
            >
              Attendance
            </SideNavLink>
            <SideNavLink href="academic/class">Class</SideNavLink>
            <SideNavLink href="academic/subjects">Subjects</SideNavLink>
            <SideNavLink href={`/${comingSoonRoutes.SYLLABUS}`} inactive={true}>
              Syllabus
            </SideNavLink>
            <SideNavLink href={`/${comingSoonRoutes.EVENT}`} inactive={true}>
              Event Calender
            </SideNavLink>
            <SideNavLink href={`/${comingSoonRoutes.SCHEDULE}`} inactive={true}>
              Schedule
            </SideNavLink>
          </SideNavItem>

          {/* Assessments */}
          <SideNavItem
            icon={<Icons.Assignment className="flex-0 text-[22px]" />}
            title="Assessments"
            clicked={event => {
              event.preventDefault();
              handleNavItem(4);
            }}
            href="assessments"
            active={navItemId === 4}
          >
            {/* prefeix is assessments/ */}
            <SideNavLink
              href={`/${comingSoonRoutes.ONLINE_COURSES}`}
              inactive={true}
            >
              Online courses
            </SideNavLink>
            <SideNavLink href="assessments/marks">Marks</SideNavLink>
            <SideNavLink href="assessments/exam-types">Exam Types</SideNavLink>
            <SideNavLink href="assessments/result-comments">
              Result Comments
            </SideNavLink>
            <SideNavLink href="assessments/promotion">Promotion</SideNavLink>
            <SideNavLink href="assessments/results">Results</SideNavLink>
          </SideNavItem>

          {/* Back Office */}
          <SideNavItem
            icon={<Icons.Books className="flex-0 text-[22px]" />}
            title="Back office"
            clicked={event => {
              event.preventDefault();
              handleNavItem(5);
            }}
            href="back-office"
            active={navItemId === 5}
          >
            <SideNavLink href="back-office/session">Session</SideNavLink>
            <SideNavLink href="back-office/payroll-manager">
              Payroll Manager
            </SideNavLink>
          </SideNavItem>

          {/* Accounting */}
          <SideNavItem
            icon={
              <Icons.Accounting
                className="flex-0 text-[22px]"
                color={navItemId === 6 ? "#333333" : "#333333"}
              />
            }
            title="Accounting"
            clicked={event => {
              event.preventDefault();
              handleNavItem(6);
            }}
            href="accounting"
            active={navItemId === 6}
          >
            <SideNavLink
              href={`/${comingSoonRoutes.SCHOOL_FEES_MANAGER}`}
              inactive={true}
            >
              School Fees manager
            </SideNavLink>
            <SideNavLink
              href={`/${comingSoonRoutes.EXPENSE_MANAGER}`}
              inactive={true}
            >
              Expense manager
            </SideNavLink>
            <SideNavLink
              href={`/${comingSoonRoutes.PAYROLL_MANAGER}`}
              inactive={true}
            >
              Payroll management
            </SideNavLink>
          </SideNavItem>

          {/* Settings  */}
          <SideNavItem
            icon={
              <Icons.Settings
                className="flex-0 text-[22px]"
                color={navItemId === 7 ? "#333333" : "#333333"}
              />
            }
            title="Settings"
            clicked={event => {
              event.preventDefault();
              handleNavItem(7);
            }}
            href="settings"
            active={navItemId === 7}
          >
            <SideNavLink
              href={`/${comingSoonRoutes.SYSTEM_SETTINGS}`}
              inactive={true}
            >
              System settings
            </SideNavLink>
            <SideNavLink
              href={`/${comingSoonRoutes.SCHOOL_SETTINGS}`}
              inactive={true}
            >
              School settings
            </SideNavLink>
            <SideNavLink
              href={`/${comingSoonRoutes.PAYMENT_SETTINGS}`}
              inactive={true}
            >
              Payment settings
            </SideNavLink>
            <SideNavLink
              href={`/${comingSoonRoutes.LIVE_CLASS_SETTINGS}`}
              inactive={true}
            >
              Live class settings
            </SideNavLink>
            <SideNavLink href={`/${comingSoonRoutes.ABOUT}`} inactive={true}>
              About
            </SideNavLink>
          </SideNavItem>
        </div>
      </div>

      <div className="w-full px-[24px]">
        <SchoolCard className="mt-[24px]" />
      </div>
    </div>
  );
};

SchoolSideNav.propTypes = {
  children: PropTypes.node.isRequired,
  extraClassName: PropTypes.string,
};

export default SchoolSideNav;
