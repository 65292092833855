import { PointingLeftArrow, PointingRightArrow } from "../icons";

const PaginationIndicator = ({
  currentPageNumber, // Current page number pagination data
  itemPerPage = 15, // Variable holding the amount of data per require
  itemCount, // Amount of data provided for the current paginated data-set
  totalCount, // Total amount of data available in the database
  classes,
  onBackArrowClick,
  onForwardArrowClick,
}) => {
  let toCount = currentPageNumber * itemPerPage;

  if (toCount > totalCount) {
    toCount = totalCount;
  }

  let fromCount = 1;

  if (currentPageNumber > 1) {
    let number = currentPageNumber - 1;
    fromCount = number * itemPerPage + 1;
  }

  const handleForwardClick = () => {
    if (toCount < totalCount) {
      onForwardArrowClick();
    }
  };

  const handleBackwardClick = () => {
    if (currentPageNumber > 1) {
      onBackArrowClick();
    }
  };

  return (
    <>
      {itemCount !== 0 && totalCount !== itemCount ? (
        <div
          className={`flex items-center space-x-[24px] rounded-[14px] bg-white py-[12px] px-[18px] ${classes}`}
        >
          <span className="text-[16px] text-black">
            {fromCount}-{toCount} of {totalCount}
          </span>

          {currentPageNumber >= 1 && itemCount <= totalCount ? (
            <span className="flex space-x-[12px]">
              <PointingLeftArrow
                className={`${
                  currentPageNumber > 1
                    ? "cursor-pointer text-primary"
                    : "cursor-not-allowed text-[#C9CCD1]"
                }`}
                onClick={handleBackwardClick}
              />

              <PointingRightArrow
                className={`${
                  toCount < totalCount
                    ? "cursor-pointer text-primary"
                    : "cursor-not-allowed text-[#C9CCD1]"
                }`}
                onClick={handleForwardClick}
              />
            </span>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default PaginationIndicator;
