import * as React from "react";

const SvgAccounting = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.638 12.02h-4.53a.337.337 0 1 0 0 .674h4.53a.337.337 0 0 0 0-.674ZM7.638 14.959h-4.53a.337.337 0 0 0 0 .674h4.53a.337.337 0 0 0 0-.674ZM7.638 17.898h-4.53a.337.337 0 1 0 0 .673h4.53a.337.337 0 0 0 0-.674ZM11.416 14.539h.715a.337.337 0 1 0 0-.674h-.715a.337.337 0 1 0 0 .674ZM14.303 13.865h-.715a.337.337 0 1 0 0 .674h.715a.337.337 0 1 0 0-.674ZM16.476 13.865h-.715a.337.337 0 1 0 0 .674h.715a.337.337 0 1 0 0-.674ZM18.648 13.865h-.715a.337.337 0 1 0 0 .674h.715a.337.337 0 1 0 0-.674ZM11.416 16.29h.715a.337.337 0 0 0 0-.673h-.715a.337.337 0 0 0 0 .674ZM14.303 15.617h-.715a.337.337 0 0 0 0 .674h.715a.337.337 0 1 0 0-.674ZM16.476 15.617h-.715a.337.337 0 0 0 0 .674h.715a.337.337 0 0 0 0-.674ZM18.648 15.617h-.715a.337.337 0 0 0 0 .674h.715a.337.337 0 0 0 0-.674ZM11.416 18.043h.715a.337.337 0 1 0 0-.674h-.715a.337.337 0 1 0 0 .674ZM14.303 17.369h-.715a.337.337 0 1 0 0 .674h.715a.337.337 0 1 0 0-.674ZM16.476 17.369h-.715a.337.337 0 1 0 0 .674h.715a.337.337 0 1 0 0-.674ZM18.648 17.369h-.715a.337.337 0 1 0 0 .674h.715a.337.337 0 1 0 0-.674ZM3.851 8.665a.337.337 0 0 0-.368.564c.493.323.826.412 1.268.432v.454a.337.337 0 1 0 .673 0v-.479c.856-.151 1.355-.849 1.465-1.5.138-.824-.293-1.54-1.099-1.825a15.357 15.357 0 0 1-.365-.134V4.293c.327.064.524.232.54.246a.337.337 0 0 0 .454-.497 1.924 1.924 0 0 0-.994-.431v-.406a.337.337 0 1 0-.674 0v.432c-.067.013-.135.03-.204.051-.529.16-.924.612-1.031 1.182-.098.519.067 1.018.43 1.303.202.158.455.303.805.457v2.357c-.328-.018-.54-.086-.9-.322Zm1.574-1.769.14.05c.736.26.69.891.659 1.078a1.159 1.159 0 0 1-.8.92V6.896ZM4.362 5.642c-.164-.129-.235-.377-.184-.647.047-.252.22-.559.563-.662l.01-.002v1.553a2.432 2.432 0 0 1-.389-.242Z"
      fill="currentColor"
    />
    <path
      d="M17.91 21.517a.337.337 0 0 0-.336.337v.241a.732.732 0 0 1-.731.731H1.667a.732.732 0 0 1-.731-.73V1.904c0-.403.328-.731.731-.731h11.788a.73.73 0 0 1 .105.008V4c0 .654.533 1.187 1.188 1.187h2.817c.005.035.009.07.009.106V6.9a.337.337 0 1 0 .673 0V5.293c0-.386-.184-.768-.404-.986L14.448.91l-.007-.007-.001-.001L14.437.9a1.406 1.406 0 0 0-.982-.4H1.667C.892.5.262 1.13.262 1.905v20.19c0 .775.63 1.405 1.405 1.405h15.176c.774 0 1.404-.63 1.404-1.405v-.24a.337.337 0 0 0-.336-.338ZM14.235 1.65l2.863 2.863h-2.35A.514.514 0 0 1 14.235 4V1.65Z"
      fill="currentColor"
    />
    <path
      d="M19.568 8.145h-9.072c-.645 0-1.17.525-1.17 1.17v1.133a.337.337 0 0 0 .675 0V9.315c0-.274.222-.496.495-.496h9.072c.273 0 .496.222.496.496v10.134a.496.496 0 0 1-.496.495h-9.072a.496.496 0 0 1-.495-.495v-7.426a.337.337 0 1 0-.674 0v7.426c0 .645.524 1.17 1.17 1.17h9.071a1.17 1.17 0 0 0 1.17-1.17V9.315a1.17 1.17 0 0 0-1.17-1.17Z"
      fill="currentColor"
    />
    <path
      d="M18.985 10.374a.597.597 0 0 0-.596-.596h-6.714a.597.597 0 0 0-.596.596v1.724c0 .329.268.596.596.596h6.714a.597.597 0 0 0 .596-.596v-1.724Zm-.674 1.646h-6.558v-1.568h6.558v1.568Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgAccounting;
