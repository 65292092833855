import Button from "../../ui/Button";
import NormalSelect from "../../ui/NormalSelect";
import TextFieldFormik from "../../TextFieldFormik";

import useGetAllBanks from "../../../../hooks/useGetAllBanks";

const FormAddTeacher4 = ({
  values,
  errors,
  touched,
  setFieldTouched,
  setTouched,
  setFieldValue,
  isSubmitting,
}) => {
  const { isLoading, banks } = useGetAllBanks();

  return (
    <div className="flex flex-col">
      <TextFieldFormik
        extraClasses="mb-[15px]"
        label="Salary"
        name="salary"
        placeholder="Input salary"
      />
      <TextFieldFormik
        extraClasses="mb-[15px]"
        label="Account Name"
        name="accountName"
        placeholder="Enter account name"
      />
      <TextFieldFormik
        extraClasses="mb-[15px]"
        label="Account Number"
        name="accountNumber"
        placeholder="Enter account number"
      />

      <NormalSelect
        label="Banks"
        placeholder="Select bank"
        value={values.bank}
        options={
          isLoading ? ["Loading..."] : banks.map(bank => bank?.name ?? "")
        }
        onFocus={() => {
          setFieldTouched("bank", true, false);
        }}
        handleSelect={option => {
          setFieldValue("bank", option.value);

          for (const bank of banks) {
            if (bank.name === option.value) {
              setFieldValue("bankCode", bank?.code ?? "");

              break;
            }
          }
        }}
      />

      <div className="my-[80px] flex w-full justify-end">
        <Button
          type="submit"
          extraClasses="self-end"
          isLoading={isSubmitting}
          disabled={
            errors.salary ||
            errors.accountName ||
            errors.accountNumber ||
            errors.bank
              ? true
              : false
          }
        >
          Submit Now
        </Button>
      </div>
    </div>
  );
};

export default FormAddTeacher4;
