import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../store/authContext";

const ProtectedRouteFromAuthenticatedUser = () => {
  const { user } = useAuth();

  return !user ? <Outlet /> : <Navigate to="/" replace={true} />;
};

export default ProtectedRouteFromAuthenticatedUser;
