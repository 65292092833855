import { useState } from "react";

import * as Icons from "../../components/icons";
import AddExam from "../forms/AddExam";
import UndismissibleModal from "../modals/UndismissibleModal";

export const AddExamButton = ({ classes, disabled = false }) => {
  const [showExamModal, setShowExamModal] = useState(false);

  const shouldShowExamModal = () => {
    setShowExamModal((prev) => !prev);
  };

  return (
    <>
      <UndismissibleModal showModal={showExamModal} showCloseButton={false}>
        <AddExam closeModal={shouldShowExamModal} />
      </UndismissibleModal>
      <button
        className={`inline-flex w-[156px] h-[40px] items-center justify-center whitespace-nowrap rounded-[8px] ${
          disabled ? "bg-grey" : "bg-[#437EF7]"
        }  text-[14px] font-semibold text-white ${classes}`}
        onClick={shouldShowExamModal}
        disabled={disabled}
      >
        <span className="mr-[12px] inline-flex h-[14px] w-[14px]">
          <Icons.Plus className="flex-0 h-full" />
        </span>
        Add Exam
      </button>
    </>
  );
};
