import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";

import useGetExamTypes from "../../../hooks/useGetExamTypes";
import useGetSchoolSubjects from "../../../hooks/useGetSchoolSubjects";

import NormalSelect from "../ui/NormalSelect";
import PageLoading from "../ui/PageLoading";
import Button from "../ui/Button";
import StudentAndScore from "../ui/StudentAndScore";
import { AddButtonPlain } from "../ui/Buttons";

import { removeWhiteSpacesFromObjValues } from "../../../utils/helperFunctions";
import { showErrorToast, showSuccessToast } from "../../../utils/toastHandler";
import { addSingleStudentResultSchema } from "../../../utils/validationSchemas/addSingleStudentResultSchema";

import {
  getSingleStudentResult,
  singleStudentResultUpload,
} from "../../../features/school/services/schoolService";

import {
  teacherGetSingleStudentResult,
  teacherSingleStudentResultUpload,
} from "../../../features/teacher/services/teacherService";

import { accountType } from "../../../constants/accountType";

import { useMarks } from "../../../store/marksStore/marksContext";
import { useAuth } from "../../../store/authContext";

const AddSingleStudentResultModal = ({
  studentId,
  studentAuthId,
  studentClass,
  studentTerm,
  studentYear,
  closeModal,
}) => {
  const navigate = useNavigate();

  const { user } = useAuth();

  const userType = user?.userType;

  const { handleSingleStudentResultUpload } = useMarks();

  const { isLoading: isGetExamTypesLoading, examTypes } = useGetExamTypes();

  const { isLoading: isGetSchoolSubjectsLoading, subjects } =
    useGetSchoolSubjects(studentClass);

  const [scoreSearchParams, setScoreSearchParams] = useState({
    examType: "",
    studentSubject: "",
  });

  const [studentResult, setStudentResult] = useState({
    isInitial: true, // <- Used to indicate we're fetching data for the first time
    examType: "",
    studentSubject: "",
    isLoading: false,
    error: null,
    maxScore: 0,
    data: null, // <- Data will be an object
  });

  /// Student result data to be used by formik for input values
  const [initialValues, setInitialValues] = useState({
    id: "",
    studentScore: "",
    fullName: "",
  });

  const handleExamTypeNavigation = () => {
    navigate("/assessments/exam-types");
  };

  const handleSubjectNavigation = () => {
    navigate("/academic/subjects");
  };

  const _handleFetchInitialStudentResult = async () => {
    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolStudentData } = await getSingleStudentResult(
          studentAuthId,
          studentClass,
          scoreSearchParams.studentSubject,
          scoreSearchParams.examType,
          studentTerm,
          studentYear
        );
        return schoolStudentData;

      case accountType.TEACHER:
        const { data: teacherStudentData } =
          await teacherGetSingleStudentResult(
            studentAuthId,
            studentClass,
            scoreSearchParams.studentSubject,
            scoreSearchParams.examType,
            studentTerm,
            studentYear
          );

        return teacherStudentData;

      default:
        break;
    }
  };

  const fetchInitialStudentResult = async () => {
    setStudentResult((prevState) => {
      return { ...prevState, isInitial: false, isLoading: true, data: [] };
    });

    try {
      const responseData = await _handleFetchInitialStudentResult();

      if (responseData?.status === true) {
        const { data: studentData } = responseData;

        /// Modelling the data for consumer by Formik
        const resultData = {
          id: studentData?.id,
          fullName: `${studentData?.firstName} ${studentData?.lastName}`,
          studentScore: studentData?.subjectAndExamTypeScore,
        };

        setInitialValues(() => {
          return { ...resultData };
        });

        setStudentResult((prevState) => {
          return {
            ...prevState,
            maxScore: responseData?.maxScoreForExamType,
            examType: scoreSearchParams.examType,
            studentSubject: scoreSearchParams.studentSubject,
            data: { ...studentData },
          };
        });
      } else {
        throw new Error(responseData?.message ?? "Couldn't fetch result");
      }
    } catch (err) {
      showErrorToast(err?.message ?? "Something went wrong");
      setStudentResult((prevState) => {
        return {
          ...prevState,
          error: err?.message ?? "Something went wrong",
        };
      });
    } finally {
      setStudentResult((prevState) => {
        return {
          ...prevState,
          isLoading: false,
        };
      });
    }
  };

  /// This function is responsible for setting the filter params which are
  /// "Exam Types" and "Subjects" with their values used for making a get request
  /// to fetch the students result data
  const handleFilterSelection = (name, value) => {
    const updatedObj = {
      [name]: value,
    };

    setScoreSearchParams((prevState) => {
      return { ...prevState, ...updatedObj };
    });
  };

  /// This function uses values from [scoreSearchParams] and [studentResult.examType]
  /// && [studentResult.studentSubject] to determine whether to show or hide the
  /// modal "Search" button
  const shouldShowFilterSearchButton = () => {
    if (
      scoreSearchParams.examType !== studentResult.examType ||
      scoreSearchParams.studentSubject !== studentResult.studentSubject
    ) {
      if (scoreSearchParams.examType && scoreSearchParams.studentSubject) {
        return true;
      }
      return false;
    }

    return false;
  };

  /// This function is responsible for updating the score of the student cached
  /// in state (studentResult.data.subjectAndExamTypeScore)
  ///
  /// This is to ensure that when the user clicks on submit again after successfully
  /// updating a student score additional post request is not made
  const updateCachedStudentScoreAfterSuccessfulUpload = (newScore) => {
    const cachedStudentResult = {
      ...studentResult.data,
      subjectAndExamTypeScore: newScore,
    };

    setStudentResult((prevState) => {
      return { ...prevState, data: cachedStudentResult };
    });
  };

  const _handleSingleStudentResultUpload = async (payload) => {
    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolStudentResults } = await singleStudentResultUpload(
          payload
        );

        return schoolStudentResults;

      case accountType.TEACHER:
        const { data: teacherStudentResults } =
          await teacherSingleStudentResultUpload(payload);

        return teacherStudentResults;

      default:
        throw new Error("Something went wrong");
    }
  };

  /// Function responsible for processing form submission
  const formHandler = async (value, setSubmitting) => {
    const trimmedValue = removeWhiteSpacesFromObjValues(value);

    const originalExamScore = studentResult.data?.subjectAndExamTypeScore;

    const formExamScore = +trimmedValue.studentScore;

    if (originalExamScore === formExamScore) {
      showSuccessToast("Success");
      setSubmitting(false);

      return;
    }

    const payload = {
      studentAuthId: studentAuthId,
      studentClass: studentClass,
      currentYear: studentYear,
      currentTerm: studentTerm,
      studentSubject: studentResult.studentSubject,
      studentScores: {
        [studentResult.examType]: formExamScore,
      },
    };

    try {
      const responseData = await _handleSingleStudentResultUpload(payload);

      const { response, status, message } = responseData;

      if (response?.status === true) {
        // const { response } = data;

        /// Passing the response back to the "MarksContext" to process and update
        /// the UI with the new information
        handleSingleStudentResultUpload(response);

        updateCachedStudentScoreAfterSuccessfulUpload(formExamScore);

        showSuccessToast(message ?? "Success");

        closeModal();
      } else {
        throw new Error(response?.message ?? "Failed to upload result");
      }
    } catch (err) {
      showErrorToast(err?.message ?? "Failed to upload. Try again");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="flex min-h-[240px] min-w-[400px] max-w-full flex-col ">
      <NormalSelect
        label="Exam Types"
        placeholder={
          isGetExamTypesLoading
            ? "Loading..."
            : !isGetExamTypesLoading && examTypes?.length === 0
            ? "No exam type found"
            : "Select"
        }
        options={examTypes?.map((exam) => Object.keys(exam)[0])}
        handleSelect={(event) => handleFilterSelection("examType", event.value)}
      />

      {!isGetExamTypesLoading && examTypes?.length === 0 && (
        <span
          className="mt-[8px] mb-[16px] cursor-pointer text-[12px] text-accent"
          onClick={handleExamTypeNavigation}
        >
          click here to add an exam type
        </span>
      )}

      <NormalSelect
        label="Subject"
        placeholder={
          isGetSchoolSubjectsLoading
            ? "Loading..."
            : !isGetSchoolSubjectsLoading && subjects?.length === 0
            ? "No subjects found"
            : "Select"
        }
        options={subjects?.map((subject) => subject)}
        handleSelect={(event) =>
          handleFilterSelection("studentSubject", event.value)
        }
      />

      {!isGetSchoolSubjectsLoading && subjects?.length === 0 && (
        <span
          className="mt-[8px] mb-[16px] cursor-pointer text-[12px] text-accent"
          onClick={handleSubjectNavigation}
        >
          click here to add an exam type
        </span>
      )}

      {shouldShowFilterSearchButton() && !studentResult.isLoading && (
        <AddButtonPlain
          className={`mt-[16px] self-end px-[50px] bg-[#437ef7]`}
          showIcon={false}
          clicked={fetchInitialStudentResult}
        >
          Search
        </AddButtonPlain>
      )}

      {studentResult.isInitial ? null : (
        <>
          {studentResult.isLoading && <PageLoading classes="mt-[24px]" />}
          {!studentResult.isLoading && !studentResult.error ? (
            <>
              {studentResult.data ? (
                <Formik
                  // enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={addSingleStudentResultSchema(
                    studentResult.maxScore
                  )}
                  onSubmit={(values, { setSubmitting }) => {
                    formHandler(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form
                      onSubmit={handleSubmit}
                      className="flex flex-col "
                      autoComplete="off"
                    >
                      <div className="mt-[26px] grid grid-cols-3 gap-x-[112px]">
                        <p className="col-span-2 text-[18px] font-semibold text-[#7D8592]">
                          Students
                        </p>
                        <p className="mb-[18px] text-[18px] font-semibold text-[#7D8592]">
                          Score
                        </p>
                        <StudentAndScore
                          key={values.id}
                          classes="mb-[32px] col-span-2"
                          name="studentScore"
                          id={studentId ?? values.id}
                          fullName={values.fullName}
                          score={values.studentScore}
                          error={errors ? errors : null}
                          onChange={handleChange}
                        />
                        <Button
                          extraClasses="col-start-3"
                          type="submit"
                          height="h-[48px]"
                          width="w-auto"
                          padding="py-[8px]"
                          onClick={handleSubmit}
                          isLoading={isSubmitting}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : (
                <p>No data found</p>
              )}
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default AddSingleStudentResultModal;
