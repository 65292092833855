import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

import * as Icons from "./icons/index";
import { OutlinedButton } from "./ui/Buttons";

const StrengthCard = ({
  title = "Title",
  href = "",
  value = "...",
  icon,
  className,
  onAddClicked,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={[
        "flex-col rounded-[10px] bg-white px-[20px] py-[16px] text-[24px] font-semibold text-[#272D37] shadow-md md:mb-0 xl:mb-0 2xl:mb-0",
        className,
      ].join(" ")}
    >
      <div className="mb-[20px] flex justify-between">
        <h2 className="text-[28px] font-semibold">{value}</h2>
        <span
          className="cursor-pointer pt-[8px] pr-[8px] text-[16px] text-primary"
          onClick={e => {
            if (e != null) {
              e.stopPropagation();
            }

            onAddClicked();
          }}
        >
          <Icons.Plus />
        </span>
      </div>

      <p className="mb-[20px] text-[14px] font-medium leading-[20px] text-[#272D37]">
        {title}
      </p>
      <span onClick={() => navigate(href)}>
        <OutlinedButton>View {title}</OutlinedButton>
      </span>
    </div>
  );
};

export default StrengthCard;

// =========================================================== predefined components ==============================================================

export const Student = ({ value = "...", className, onAddClicked }) => {
  return (
    // <NavLink to="/users/students">
    <StrengthCard
      className={className}
      icon={<Icons.Student className="text-[35px]" color="#DE6944" />}
      title="Students"
      value={value}
      href="/users/students"
      onAddClicked={onAddClicked}
    />
    // </NavLink>
  );
};
StrengthCard.Student = Student;

export const Teacher = ({ value = "...", className, onAddClicked }) => {
  return (
    <StrengthCard
      className={className}
      icon={<Icons.Teacher className="text-[35px]" color="#DE6944" />}
      title="Teachers"
      value={value}
      href="/users/teachers"
      onAddClicked={onAddClicked}
    />
  );
};
StrengthCard.Teacher = Teacher;

export const Subject = ({ value = "...", className }) => {
  return (
    <NavLink to="/academic/subjects">
      <StrengthCard
        className={className}
        icon={<Icons.Subject className="text-[35px]" color="#DE6944" />}
        title="Subjects"
        value={value}
      />
    </NavLink>
  );
};
StrengthCard.Subject = Subject;

export const Admin = ({ value = "...", className }) => {
  return (
    <StrengthCard
      className={className}
      icon={<Icons.Admin className="text-[35px]" color="#DE6944" />}
      title="Admins"
      value={value}
    />
  );
};
StrengthCard.Admin = Admin;

export const Classes = ({ value = "-- --", className }) => {
  return (
    <NavLink to="/academic/class">
      <StrengthCard
        className={className}
        icon={<Icons.Classes className="text-[35px]" color="#DE6944" />}
        title="Classes"
        value={value}
      />
    </NavLink>
  );
};

StrengthCard.Classes = Classes;
