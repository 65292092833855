import SpreadSheetTableBody from "./SpreadSheetTableBody";
import SpreadSheetTableHead from "./SpreadSheetTableHead";

// TODO: Remove the passed in props and access data in individual components
// using the location object from 'react-router-dom'
const ResultSpreadSheet = ({ studentResult }) => {
  return (
    <table className="max-h-[800px] table-fixed border-collapse overflow-auto">
      <SpreadSheetTableHead />
      <SpreadSheetTableBody studentResult={studentResult} />
    </table>
  );
};

export default ResultSpreadSheet;
