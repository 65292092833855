export const routeNames = {
  //we gonna store all available routes in a const here.
};

export const comingSoonRoutes = {
  SYLLABUS: "SYLLABUS",
  EVENT: "EVENT",
  SCHEDULE: "SCHEDULE",
  ONLINE_COURSES: "ONLINE_COURSES",
  SCHOOL_MANAGER: "SCHOOL_MANAGER",
  SCHOOL_FEES_MANAGER: "SCHOOL_FEES_MANAGER",
  EXPENSE_MANAGER: "EXPENSE_MANAGER",
  SYSTEM_SETTINGS: "SYSTEM_SETTINGS",
  SCHOOL_SETTINGS: "SCHOOL_SETTINGS",
  PAYMENT_SETTINGS: "PAYMENT_SETTINGS",
  LIVE_CLASS_SETTINGS: "LIVE_CLASS_SETTINGS",
  ABOUT: "ABOUT",
  PARENTS: "PARENTS",
  ADMIN: "ADMIN",
  ATTENDANCE: "ATTENDANCE",
  PROMOTIONS: "PROMOTIONS",
  MY_ACCOUNT: "MY_ACCOUNT",
  SETTINGS: "SETTINGS",
};
