import Select, { components } from "react-select";

export const MultiCheckBox = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const opt = [
  // { value: "ocean1", label: "Ocean1" },
  // { value: "blue2", label: "Blue2" },
];

export const Selector = ({
  isOptionSelectedFunc,
  options = opt,
  showValues = false,
  closeMenuOnSelect = false,
  noOptionsMessage = "add message",
  handleSelection,
  onFocus,
  isMulti = true,
  placeholder = "add a placeholder",
  value,
  style,
  isLoading = false,
  shouldDisable = false,
  label,
  extraClasses,
  labelFieldSpacing,
}) => {
  const colorStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderRadius: "14px",
      minHeight: "48px",
      border: "1px solid #D8E0F0",
    }),
    // valueContainer: (base) => ({
    //   ...base,
    //   overflowX: 'scroll',
    // }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        // backgroundColor: isDisabled ? "red" : "blue",
        color: "#000",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  return (
    <div
      className={`flex w-full flex-col justify-start ${extraClasses} ${labelFieldSpacing}`}
    >
      {/* <span
        className="d-inline-block"
        data-toggle="popover"
        data-trigger="focus"
        data-content="Please select account(s)"
      > */}
      {label && (
        <label
          className="mb-[2px] text-[14px] font-semibold text-[#7D8592]"
          // htmlFor={field.name}
        >
          {label}
        </label>
      )}
      <Select
        className={extraClasses}
        // className="overflow-x-scroll overflow-y-clip"
        // className="basic-multi-select mb-[24px] rounded-[14px] border border-[#D8E0F0] bg-accent"
        value={value}
        // defaultValue={value}
        styles={colorStyles}
        // styles={colorStyles}
        // value={value}
        placeholder={placeholder}
        noOptionsMessage={() => noOptionsMessage}
        options={options}
        // controlShouldRenderValue={showValues}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        components={{
          MultiCheckBox,
        }}
        // onChange={(newValue, option, val2) => {
        //   handleSelection(newValue?.value ?? option?.option?.value);
        // }}
        onChange={(value) => handleSelection(value)}
        onFocus={onFocus}
        // onChange={handleChange}

        // allowSelectAll={true}
        // hideSelectedOptions={false}

        // isOptionSelected={(option, selectValue) =>
        //   isOptionSelectedFunc
        //     ? isOptionSelectedFunc(option?.value, selectValue)
        //     : null
        // }
        // scroll
        // isLoading={isLoading}
        isDisabled={shouldDisable}
      />
      {/* </span> */}
    </div>
  );
};
