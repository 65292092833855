import { useState } from "react";

import MoreVertContext from "../../../store/moreVertContext";

import { VerticalEllipsis } from "../icons";

const MoreVertButton = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false);

  const handlePopupClick = e => {
    if (e != null) {
      e.stopPropagation();
    }
    setShowPopup(prevState => !prevState);
  };

  const value = {
    handlePopupClick,
  };

  return (
    <MoreVertContext.Provider value={value}>
      <div className="relative">
        <div
          className="flex cursor-pointer items-center justify-center rounded-[14px] bg-active py-[13px] px-[14px]"
          onClick={handlePopupClick}
        >
          <VerticalEllipsis className="hover:text-primary" />
        </div>
        {showPopup && (
          <>
            <div
              className="fixed inset-0 top-0 left-0 z-40 h-screen w-screen overflow-y-auto overflow-x-hidden bg-modal py-[24px] outline-none focus:outline-none"
              onClick={handlePopupClick}
            ></div>
            <div className="absolute right-0 bottom-[-24px] z-50">
              {children}
            </div>
          </>
        )}
      </div>
    </MoreVertContext.Provider>
  );
};

export default MoreVertButton;
