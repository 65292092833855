import { useState } from "react";

import * as Icons from "../../components/icons";
import AddTeacher from "../forms/AddTeacher";
import UndismissibleModal from "../modals/UndismissibleModal";
import DismissibleModel from "../modals/DismissibleModal";
import Modal from "./Modal";

export const AddTeacherButton = ({ className, incrementTeacherCount }) => {
  const [showTeacherModal, setShowTeacherModal] = useState(false);

  const shouldShowTeacherModal = () => {
    setShowTeacherModal(prev => !prev);
  };

  // h-[500px] w-[400px]

  return (
    <>
      {/* <UndismissibleModal showModal={showTeacherModal} showCloseButton={false}>
        <AddTeacher
          closeModal={shouldShowTeacherModal}
          incrementTeacherCount={incrementTeacherCount}
        />
      </UndismissibleModal> */}
      <Modal
        showModal={showTeacherModal}
        onClose={() => setShowTeacherModal(false)}
      >
        <AddTeacher
          closeModal={shouldShowTeacherModal}
          incrementTeacherCount={incrementTeacherCount}
        />
      </Modal>
      <button
        className={[
          "inline-flex h-[40px] w-[156px] transform items-center justify-center gap-[6px] rounded-[5px] bg-[#437EF7] py-[8px] px-[12px] text-[14px] font-semibold text-white shadow-sm",
          className,
        ].join(" ")}
        onClick={shouldShowTeacherModal}
      >
        Add Teacher
      </button>
    </>
  );
};
