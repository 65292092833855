import { useRef } from "react";
import uploadCloud from "../../../assets/svg/upload_cloud.svg";

const InputFile = ({ placeholder, fileName, error, touched, onChange, extraClasses, }) => {
  const fileRef = useRef(null);

  return (
    <div className={`flex w-full cursor-pointer flex-col ${extraClasses}`}>
      <input
        ref={fileRef}
        hidden
        id='files'
        className='w-full rounded-2xl border border-[#D8E0F0] placeholder-[#DADADA] placeholder-opacity-[50px] file:mr-[4px] file:rounded-[14px] file:border file:border-[#D8E0F0] file:bg-white file:px-[12px] file:text-[#DADADA] file:hover:bg-[#D8E0F0] file:hover:text-grey-darker '
        type='file'
        onChange={onChange}
      />
      <div
        htmlFor='files'
        className='flex h-[126px] w-full flex-col items-center justify-center gap-1 rounded-[8px] border border-[#D8E0F0] py-[16px] px-[24px] placeholder-[#DADADA] placeholder-opacity-[50px]'
        onClick={() => {
          fileRef.current.click();
        }}
      >
        <img
          src={uploadCloud}
          className='flex h-[40px] w-[40px] items-center justify-center '
          alt='pics of uploading cloud'
        />
        <div>
          <span
            className={`${!fileName && "font-body font-semibold text-[#2F80ED] text-[14px]"}`}
          >
            {fileName ? fileName : placeholder ?? "Click to upload logo"}
          </span>{" "}
          <span className='text-[12px] text-[#667085]'>or drag and drop</span>
        </div>

        <p className='text-center font-body text-sm font-normal leading-[18px] text-[#667085]'>
          SVG, PNG, JPG or GIF (max. 800x400px)
        </p>
      </div>
      {error && touched && (
        <span className='text-[14px] text-accent'>{error}</span>
      )}
    </div>
  );
};

export default InputFile;
