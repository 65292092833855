import { useEffect, useState } from "react";

import UndismissibleModal from "./UndismissibleModal";
import UploadBulkStudent from "../forms/UploadBulkStudent";
import { Plus } from "../icons";

import { getStudentExcelTemplate } from "../../../features/school/services/schoolService";

const AddStudentOptionsModal = ({
  showModal,
  setShowModal,
  setShowAddStudentModal,
  closeOptionModal,
  showCloseOptionModal = true,
}) => {
  const [download, setDownload] = useState(false);

  const [isUploadingBulkStudents, setIsUploadingBulkStudents] = useState(false);

  useEffect(() => {
    const downloadExcelSheet = async () => {
      try {
        const response = await getStudentExcelTemplate();

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "students_bulk_upload.xlsx");
        link.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 0);
        setDownload(false);
      } catch (e) {}
    };

    if (download) {
      downloadExcelSheet();
    }
  }, [download]);

  return (
    <UndismissibleModal
      showModal={showModal}
      setShowModal={
        !isUploadingBulkStudents ? () => setShowModal(false) : () => {}
      }
      header={
        <h3 className="mt-[8px] text-[22px] font-semibold leading-[33px]">
          Add Student
        </h3>
      }
    >
      {showCloseOptionModal && (
        <span
          onClick={closeOptionModal}
          className="flex h-[44px] w-[44px] cursor-pointer items-center justify-center justify-self-end rounded-[14px] bg-[#F4F9FD] p-[14px] text-2xl text-black"
        >
          ×
        </span>
      )}
      <div className="my-[56px] mx-6 flex flex-col items-center justify-center">
        <div className="flex lg:space-x-[58px] space-x-4">
          <div
            onClick={
              !isUploadingBulkStudents
                ? () => setShowAddStudentModal(true)
                : () => {}
            }
            className="flex h-[161px] lg:w-[327px] w-[200px] cursor-pointer flex-col items-center justify-center space-y-[20.54px] rounded-[16px] border-2 border-dashed border-[#D0DBEA]"
          >
            <Plus width="37.92px" height="37.92px" color="#9FA5C0" />
            <p className="text-[15px] font-bold text-[#3E5481] text-center">
              Add a single student
            </p>
          </div>
          <UploadBulkStudent
            onUpload={(uploadStatus) =>
              setIsUploadingBulkStudents(uploadStatus)
            }
          />
        </div>
        <button
          disabled={download}
          className={`${
            download
              ? "cursor-not-allowed bg-inActive text-white"
              : "bg-[#E5E5E5] transition delay-100 ease-in hover:bg-black hover:text-[#E5E5E5]"
          } mt-[8px] flex h-[48px] w-[224px] items-center justify-center self-end rounded-[14px] bg-slate-100 px-[17.89px] py-[13.42px]`}
          onClick={
            !isUploadingBulkStudents ? () => setDownload(true) : () => {}
          }
        >
          {download ? (
            <div className="h-[24px] w-[24px] place-self-center self-center">
              <svg
                className="h-full w-full animate-spin self-center rounded-full border-l-[4px] border-white"
                viewBox="0 0 24 24"
              ></svg>
            </div>
          ) : (
            <span>Download Excel Sheet</span>
          )}
        </button>
      </div>
    </UndismissibleModal>
  );
};

export default AddStudentOptionsModal;
