import { useContext } from "react";

import MoreVertContext from "../../../store/moreVertContext"

const IconAndLabel = ({ children, label, onClick }) => {
  const { handlePopupClick } = useContext(MoreVertContext);
  return (
    <div
      className="flex h-[48px] w-[192px] cursor-pointer items-center justify-start space-x-[24px] rounded-[14px] px-[16px] text-[#7D8592] hover:bg-active hover:text-black"
      onClick={(e) => {
        handlePopupClick(e);
        onClick();
      }}
    >
      {children}
      <p>{label}</p>
    </div>
  );
};

export default IconAndLabel;
