import { useEffect, useState } from "react";

import { accountType } from "../constants/accountType";
import { getTeacherAllExamTypes } from "../features/teacher/services/teacherService";

import { getAllExamTypes } from "../features/school/services/schoolService";
import { useAuth } from "../store/authContext";

const useGetExamTypes = () => {
  const { user } = useAuth();
  const userType = user?.userType;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [examTypes, setExamTypes] = useState(null);

  const _handleGetExamTypes = async () => {
    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolExamTypes } = await getAllExamTypes();
        return schoolExamTypes;

      case accountType.TEACHER:
        const { data: teacherExamTypes } = await getTeacherAllExamTypes();
        return teacherExamTypes;

      default:
        throw new Error(`No case for type ${userType} found in subjectReducer`);
    }
  };

  /// This side-effect is responsible for getting all the exam types the school offers
  useEffect(() => {
    const fetchExamTypes = async () => {
      setIsLoading(true);

      try {
        const response = await _handleGetExamTypes();

        if (response.status === true) {
          const examTypesData = response?.data.map((type) => {
            const examName = {
              [type.examType]: type.totalScore,
            };

            return examName;
          });

          setExamTypes(examTypesData);
        } else {
          throw new Error(response?.message ?? "Error fetching exam types");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchExamTypes();
  }, []);

  return { isLoading, error, examTypes };
};

export default useGetExamTypes;
