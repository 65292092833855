import ImageTitleSubtitle from "../ui/ImageTitleSubtitle";
import ClassPositionPromoted from "./ClassPositionPromoted";

import { positionFormatter } from "../../../utils/helperFunctions";

import { useAuth } from "../../../store/authContext";
import { toWordCase } from "../../../utils/stringUtil";

const ReportSheetBodyHeader = ({ studentResult }) => {
  const { user } = useAuth();

  const { logoUrl, schoolName, state, country } = user;

  const {
    class: studentClass,
    subClass,
    position,
    promoted,
    studentId,
    student,
    numberOfStudentInClass,
  } = studentResult;

  const fullClass = `${studentClass}${subClass}`;

  const { id, firstName, lastName, passportImageUrl: profileImage } = student;

  return (
    <div className="mb-[12px] flex items-center justify-between">
      <ImageTitleSubtitle
        image={logoUrl}
        title={schoolName}
        subtitle={toWordCase(`${state} ${country}`)}
        borderRadius="rounded-[10px]"
      />
      <ClassPositionPromoted
        studentClass={fullClass}
        numberInClass={numberOfStudentInClass}
        positionInClass={positionFormatter(position)}
        promoted={promoted}
      />
      <ImageTitleSubtitle
        image={profileImage}
        imagePlaceHolderText={`${firstName} ${lastName}`}
        name={`${firstName} ${lastName}`}
        title={`${firstName} ${lastName}`}
        subtitle={`ID- ${studentId ?? id}`}
      />
    </div>
  );
};

export default ReportSheetBodyHeader;
