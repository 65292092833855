export const payrollActions = {
  UPDATE_LOADING_STATE: "updateLoadingState",
  REQUEST_SUCCESS: "requestSuccess",
  ADD_PAYROLL_ERROR: "addPayrollError",
  UPDATE_SELECTED_STAFF: "updateSelectedStaff",
  SELECT_ALL_STAFF: "selectAllStaff",
  CLEAR_SELECTED_STAFF: "clearSelectedStaff",
  UPDATE_PAYROLL: "updatePayroll",

  UPDATE_TEACHER_LOADING_STATE: "updateTeacherLoadingState",
  REQUEST_SUCCESS_TEACHER: "requestSuccessTeacher",
  ADD_TEACHERS_ERROR: "addTeachersError",

  UPDATE_CREATING_PAYROLL_LOADING_STATE: "updateCreatingPayrollLoadingState",
  REQUEST_SUCCESS_CREATE_PAYROLL: "requestSuccessCreatePayroll",
  ADD_CREATED_PAYROLL: "addCreatedPayroll",
};
