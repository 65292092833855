const TitleAndDetail = ({ title, detail, detailClasses }) => {
  return (
    <div className="flex items-center space-x-[10px]">
      <p className="text-[14px] font-semibold leading-[24px]">
        {title}
      </p>
      <p className={`text-[24px] font-semibold leading-[36px] ${detailClasses}`}>{detail}</p>
    </div>
  );
};

export default TitleAndDetail;
