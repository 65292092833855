import CountrySelect from "../../../ui/CountrySelect";
import StateSelect from "../../../ui/StateSelect";
import TextFieldFormik from "../../../TextFieldFormik";

const StudentEditableLocationInfo = ({ values }) => {
  return (
    <div>
      <p className="mt-[56px] mb-[24px] text-[24px] font-bold">Location info</p>
      <div className="grid grid-cols-2 gap-2">
        <CountrySelect
          label="Country"
          name="country"
          width="w-[232px]"
          height="h-[48px]"
          borderColor="border-[#D8E0F0]"
          marginBottom="mb-[15px]"
        />
        <StateSelect
          country={values.country}
          label="State"
          name="state"
          width="w-[232px]"
          height="h-[48px]"
          borderColor="border-[#D8E0F0]"
          marginBottom="mb-[15px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="LGA"
          name="LGA"
          width="w-[232px]"
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Community"
          name="community"
          width="w-[232px]"
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Home Town"
          name="homeTown"
          width="w-[232px]"
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Address"
          name="address"
          width="w-[232px]"
          height="h-[48px]"
        />
      </div>
    </div>
  );
};

export default StudentEditableLocationInfo;
