import * as React from "react";

const SvgBooks = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5 0H6.517a.383.383 0 0 0-.384.383v2.684H.383A.383.383 0 0 0 0 3.45v19.167c0 .211.172.383.383.383H11.5a.383.383 0 0 0 .383-.383V.383A.383.383 0 0 0 11.5 0Zm-.383 19.933H6.9v-.766h4.217v.766ZM.767 5.75h5.366v11.5H.767V5.75Zm10.35-1.533H6.9v-1.15h4.217v1.15Zm-10.35 13.8h5.366v1.15H.767v-1.15ZM6.9 18.4V4.983h4.217V18.4H6.9ZM11.117.767V2.3H6.9V.767h4.217ZM6.133 3.833v1.15H.767v-1.15h5.366Zm-5.366 16.1h5.366v2.3H.767v-2.3Zm6.133 2.3V20.7h4.217v1.533H6.9Z"
      fill="currentColor"
    />
    <path
      d="m22.988 20.987-4.6-17.633a.382.382 0 0 0-.47-.274l-5.75 1.533a.383.383 0 0 0-.272.467l4.6 17.633a.382.382 0 0 0 .47.273l5.75-1.533a.383.383 0 0 0 .272-.466Zm-9.643-13.39 5.009-1.335.18.69-5.009 1.337-.18-.692Zm5.383.098 2.223 8.529-5.007 1.336-2.224-8.53 5.008-1.335Zm2.418 9.27.393 1.505-5.01 1.335-.39-1.503 5.007-1.336ZM17.743 3.92l.418 1.6-5.01 1.335-.417-1.6 5.01-1.335Zm-.603 18.227-.417-1.6 5.01-1.336.416 1.6-5.009 1.336ZM4.983 6.9H1.917a.383.383 0 0 0-.384.384v3.066c0 .212.172.384.384.384h3.066a.383.383 0 0 0 .384-.384V7.284a.383.383 0 0 0-.384-.384ZM4.6 9.967H2.3v-2.3h2.3v2.3Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBooks;
