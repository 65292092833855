import * as Yup from "yup";

export const addStudentsResultSchema = (maxScore) =>
  Yup.object().shape({
    results: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        studentAuthId: Yup.string(),
        studentScore: Yup.number()
          .max(maxScore, `Should be ${maxScore} or less`)
          .typeError("Enter valid number"),
        fullName: Yup.string(),
      })
    ),
  });
