import { sessionActions } from "./sessionActions";

export const initialState = {
  selectedTerm: "",
  selectedYear: "",
  isTogglingSession: false,
  error: null,
  message: "",
  isSessionLoading: false,
  isActivating: false,
  allSessions: [],
  statusLoadingStack: [],
};

const sessionReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case sessionActions.UPDATE_SESSION_LOADING_STATE:
      return {
        ...state,
        isSessionLoading: payload.isSessionLoading,
      };
    case sessionActions.UPDATE_IS_ACTIVATING_STATE:
      return {
        ...state,
        isActivating: payload.isActivating,
      };

    case sessionActions.SESSION_REQUEST_SUCCESS:
      return {
        ...state,
        allSessions: [...payload.allSessions],
      };
    case sessionActions.TOGGLE_SESSION:
      return {
        ...state,
        allSessions: [...payload.allSessions],
      };
    case sessionActions.CLEAR_LOADING_STACK:
      return {
        ...state,
        statusLoadingStack: [],
      };
    default:
      throw new Error(`No case for type ${type} found in sessionReducer`);
  }
};

export default sessionReducer;
