import ImageTitleSubtitle from "./ImageTitleSubtitle";

const StudentAndScore = ({
  fullName,
  id,
  score,
  classes,
  name,
  error,
  onChange,
}) => {

  return (
    <>
      <ImageTitleSubtitle title={fullName} subtitle={`ID- ${id}`} classes={classes} />
      {/* <p>Test</p> */}

      {/* <TextFieldFormik name={name} /> */}

      <div className="flex flex-col">
        <input
          name={name}
          value={score}
          type="text"
          className="h-[48px] rounded-[14px] border border-[#D8E0F0]"
          onChange={onChange}
        />
        {error && (
          <p className="text-[12px] text-accent">{error?.studentScore}</p>
        )}
      </div>
    </>
  );
};

export default StudentAndScore;
