export const promotionActions = {
  INITIAL_DEFAULT_LOAD: "INITIAL_DEFAULT_LOAD",
  UPDATE_LOADING_STATE: "UPDATE_LOADING_STATE",
  CACHE_FILTER_PARAMS: "CACHE_FILTER_PARAMS",
  REQUEST_SUCCESS: "REQUEST_SUCCESS",
  ADD_ERROR: "ADD_ERROR",
  UPDATE_CHECKED_STUDENTS: "UPDATE_CHECKED_STUDENTS",
  DELETE_STUDENTS_FROM_ALL_STUDENTS: "DELETE_STUDENTS_FROM_ALL_STUDENTS",
  UPDATE_IS_PROMOTING_STATE: "UPDATE_IS_PROMOTING_STATE",
  UPDATE_IS_PUBLISHING_STATE: "UPDATE_IS_PUBLISHING_STATE",
  UPDATE_PUBLISHED_ERRORS: "UPDATE_PUBLISHED_ERRORS",
  CLEAR_PUBLISHED_ERRORS: "CLEAR_PUBLISHED_ERRORS",
  UPDATE_PAGINATED_DATA: "UPDATE_PAGINATED_DATA",
  INCREMENT_PAGE_COUNT: "INCREMENT_PAGE_COUNT",
  DECREMENT_PAGE_COUNT: "DECREMENT_PAGE_COUNT",
};
