import { useState } from "react";

import FundWalletForm from "./FundWalletForm";
import { PaymentMethod } from "../../../../constants/paymentMethod";
import { PaymentType } from "../../../../constants/paymentType";

const FundWallet = ({ paymentMethod, paymentTypeHandler }) => {
  const [isQuerying, setIsQuerying] = useState(false);

  const handleIsQuery = (queryState) => {
    setIsQuerying(queryState);
  };

  return (
    <div className="mt-[36px] flex max-h-[576px] min-h-[320px] w-[576px] flex-col items-center">
      <div className="relative mb-[36px] flex h-[48px] w-[352px] items-center justify-between rounded-[24px] bg-[#E6EDF5] px-[40px]">
        <p
          className={`z-10 cursor-default text-[16px] ${
            paymentMethod === PaymentMethod.CARD
              ? "font-medium text-white"
              : "font-normal text-black-shade1"
          }`}
        >
          Card Payment
        </p>
        <p
          className={`z-10 cursor-default text-[16px] ${
            paymentMethod === PaymentMethod.BANK_TRANSFER
              ? "font-medium text-white"
              : "font-normal text-black-shade1"
          }`}
        >
          Bank Transfer
        </p>
        <div
          className="absolute top-0 bottom-0 left-0 z-20 h-[48px] w-[176px] bg-transparent"
          onClick={
            isQuerying ? null : () => paymentTypeHandler(PaymentMethod.CARD)
          }
        ></div>
        <div
          className="absolute top-0 bottom-0 right-0 z-20 h-[48px] w-[176px] bg-transparent"
          onClick={
            isQuerying
              ? null
              : () => paymentTypeHandler(PaymentMethod.BANK_TRANSFER)
          }
        ></div>
        <div
          className={`absolute top-[4px] bottom-[4px] left-[4px] h-[40px] w-[176px] rounded-[24px] bg-primary transition delay-75 duration-200 ease-in-out ${
            paymentMethod === PaymentMethod.CARD
              ? "translate-x-0 transform"
              : "translate-x-[168px] transform"
          }`}
        ></div>
      </div>

      {paymentMethod === PaymentMethod.CARD && (
        <FundWalletForm
          paymentMethod={paymentMethod}
          paymentType={PaymentType.WALLET_FUNDING}
          onIsQuerying={(query) => handleIsQuery(query)}
        />
      )}

      {paymentMethod === PaymentMethod.BANK_TRANSFER && (
        <FundWalletForm
          paymentMethod={paymentMethod}
          paymentType={PaymentType.WALLET_FUNDING}
          onIsQuerying={(query) => handleIsQuery(query)}
        />
      )}

      {/* {paymentMethod === PaymentMethod.CARD ? (
        <FundWalletForm
          paymentMethod={paymentMethod}
          paymentType={PaymentType.WALLET_FUNDING}
        />
      ) : (
        <FundWalletForm
          paymentMethod={paymentMethod}
          paymentType={PaymentType.WALLET_FUNDING}
        />
      )} */}
    </div>
  );
};

export default FundWallet;
