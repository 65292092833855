import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import RegistrationFormLayout from "../../shared/components/layouts/RegistrationFormLayout";
import TextFieldFormik from "../../shared/components/TextFieldFormik";
import Button from "../../shared/components/ui/Button";
import { PointingRightArrow } from "../../shared/components/icons";

import { showErrorToast, showSuccessToast } from "../../utils/toastHandler";

import { changeDefaultPassword, updatePassword } from "../../services/authService";

const CreateNewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState(null);

  const isDefaultPassword = location?.state?.isDefaultPassword;

  useEffect(() => {
    const validate = () => {
      const email = location?.state?.email;

      if (email == null) {
        navigate("/", { replace: true });
        return;
      }
      setEmail(email);
    };

    validate();
  }, []);

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const newPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Passwords does not match")
      .required("Confirm password is required"),
  });

  const formHandler = async (values) => {
    const { newPassword } = values;

    try {
      const payload = {
        email: email,
        password: newPassword,
      };

      let response;

      if (isDefaultPassword === true) {
        response = await changeDefaultPassword(payload);
      } else {

        response = await updatePassword(payload);
      }

      
      const { data } = response;

      if (data.status === true) {
        showSuccessToast(data?.message ?? "Successful");
        navigate("/login", {
          replace: true,
        });
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  return (
    <RegistrationFormLayout>
      <div className="flex flex-col items-stretch justify-center">
        <h1 className="mb-[35px] text-center text-[36px] font-bold">
          Create new password
        </h1>

        <Formik
          initialValues={initialValues}
          validationSchema={newPasswordSchema}
          onSubmit={formHandler}
        >
          {({ handleChange, isSubmitting }) => (
            <Form className="flex flex-col">
              <div className="space-y-[17.78px]">
                <TextFieldFormik
                  extraClasses="mb-[15px]"
                  label="Enter password"
                  name="newPassword"
                  placeholder="********"
                  type="password"
                />
                <TextFieldFormik
                  extraClasses="mb-[15px]"
                  label="Confirm Password"
                  name="confirmPassword"
                  placeholder="********"
                  type="password"
                />
              </div>

              <Button
                type="submit"
                extraClasses="self-center mt-[50px]"
                isLoading={isSubmitting}
              >
                <div className="flex items-center justify-center space-x-2">
                  <span>Next</span>
                  <PointingRightArrow color="bg-white" />
                </div>
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </RegistrationFormLayout>
  );
};

export default CreateNewPassword;
