import * as React from "react";

const SvgVerticalEllipsis = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 4 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.963 2c0 1.105-.887 2-1.982 2A1.99 1.99 0 0 1 0 2C0 .895.887 0 1.981 0a1.99 1.99 0 0 1 1.982 2ZM3.963 9c0 1.105-.887 2-1.982 2A1.99 1.99 0 0 1 0 9c0-1.105.887-2 1.981-2a1.99 1.99 0 0 1 1.982 2ZM1.981 18a1.99 1.99 0 0 0 1.982-2c0-1.105-.887-2-1.982-2A1.99 1.99 0 0 0 0 16c0 1.105.887 2 1.981 2Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgVerticalEllipsis;
