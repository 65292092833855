import { useEffect, useState } from "react";
import { accountType } from "../constants/accountType";

import {
  fetchAllSubjects,
  fetchClassSubject,
} from "../features/school/services/schoolService";
import { useAuth } from "../store/authContext";

const useGetSchoolSubjects = studentClass => {
  const { user } = useAuth();
  const { userType, teacherSubjects } = user || {};

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [subjects, setSubjects] = useState(null);

  const _handleSubjectFetch = async () => {
    if (studentClass != null) {
      const { data: schoolSubjectData } = await fetchClassSubject(studentClass);
      return schoolSubjectData;
    }
    const { data: schoolSubjects } = await fetchAllSubjects();

    return schoolSubjects;
  };

  /// This side-effect is responsible for getting all the subjects a school offers
  useEffect(() => {
    const fetchSchoolSubjects = async () => {
      setIsLoading(true);
      try {
        if (userType === accountType.TEACHER) {
          setSubjects(teacherSubjects);
          return;
        }

        const response = await _handleSubjectFetch();

        if (response.status === true) {
          const { data } = response;
          if (studentClass != null) {
            const listOfSubjects = data ?? null;
            setSubjects(listOfSubjects);

            return;
          }

          const listOfSubjects = data[0]?.listOfSubjects;
          setSubjects(listOfSubjects);
        } else {
          throw new Error(response?.message ?? "Error retrieving subjects");
        }
      } catch (err) {
        setError(err?.message ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSchoolSubjects();
  }, []);
  return { isLoading, error, subjects };
};

export default useGetSchoolSubjects;
