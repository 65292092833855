import * as React from "react";

const SvgPageSearchIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 141 141"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#page_search_icon_svg__a)">
      <path
        d="M24.955 91.157a5.533 5.533 0 0 1 5.545-5.545h33.218a39.489 39.489 0 0 0 3.743 10.907c-.31.056-.588.183-.915.183H30.5a5.547 5.547 0 0 1-5.545-5.545ZM30.5 74.521h32.746c.217-2.163.566-4.315 1.137-6.45.427-1.597.982-3.133 1.592-4.647H30.5a5.54 5.54 0 0 0-5.545 5.545 5.549 5.549 0 0 0 5.545 5.552Zm52.4-30.6c1.852-1.07 3.81-1.936 5.8-2.685H30.5a5.54 5.54 0 0 0-5.545 5.546 5.54 5.54 0 0 0 5.545 5.545h42.263c2.861-3.3 6.25-6.16 10.137-8.407Zm29.091 80.387c-.354.033-.715.117-1.081.117H22.182c-6.122 0-11.091-4.969-11.091-11.091V24.605c0-6.122 4.969-11.09 11.091-11.09h88.728c6.122 0 11.091 4.968 11.091 11.09v19.038c4.209 2.324 7.952 5.43 11.091 9.128v-28.16c0-12.228-9.954-22.182-22.182-22.182H22.182C9.954 2.429 0 12.383 0 24.611v88.728c0 12.228 9.954 22.182 22.182 22.182h88.728c2.407 0 4.68-.488 6.843-1.203l-1.331-2.341-4.431-7.669Zm-37.742-29.48c-9.084-15.738-3.671-35.913 12.067-44.996 15.716-9.072 35.912-3.677 44.985 12.056 9.083 15.727 3.671 35.907-12.056 44.985-15.727 9.067-35.924 3.693-44.996-12.045Zm4.73-2.745c7.58 13.115 24.411 17.668 37.538 10.071 13.131-7.592 17.629-24.423 10.065-37.549-7.587-13.12-24.417-17.635-37.549-10.065-13.126 7.592-17.651 24.411-10.054 37.543Zm48.523 12.76-14.157 8.191 10.896 18.849 14.158-8.179-10.897-18.861Zm12.406 21.45-14.18 8.197c2.257 3.909 7.259 5.235 11.179 2.989 3.921-2.252 5.252-7.259 3.001-11.186Z"
        fill="#B0B9C7"
      />
    </g>
    <defs>
      <clipPath id="page_search_icon_svg__a">
        <path fill="#fff" d="M0 0h141v141H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgPageSearchIcon;
