import React, { useState } from "react";

export default function NormalTextInput({
  label,
  type = "text",
  autoComplete = "off",
  width = "w-full",
  height = "",
  onChange,
  extraClasses,
  inputClasses,
  disabled = false,
  errorMessage,
  value,
  onKeyPress,
  autoFocus,
  placeHolder,
}) {
  return (
    <div
      className={`mb-[15px] flex w-full flex-col justify-start ${extraClasses}`}
    >
      {label && (
        <label
          className="mb-[10px] text-[14px] font-semibold capitalize text-[#7D8592]"
          htmlFor={label}
        >
          {label}
        </label>
      )}
      <div className="relative block">
        <input
          pattern={type === "number" ? "[0-9]*" : "[aA-zZ]*"}
          autoFocus={autoFocus}
          onKeyPress={onKeyPress}
          value={value}
          type={type}
          placeholder={placeHolder}
          disabled={disabled}
          className={`rounded-[14px] border border-[#D8E0F0] py-2 pr-4 pl-4 placeholder-[#DADADA] placeholder-opacity-50 focus:border-primary focus:ring-primary ${width} ${height} ${inputClasses}`}
          // autoComplete={autoComplete}
          onChange={(text) => onChange(text.target.value)}
        />
      </div>
      <p className="text-[14px] text-accent">{errorMessage}</p>
    </div>
  );
}
