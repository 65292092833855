import { createContext, useContext, useEffect, useReducer } from "react";

import studentReducer, { initialState } from "./studentReducer";
import { studentActions } from "./studentActions";

import {
  getAllFilteredStudents,
  getAllStudents,
} from "../../features/school/services/schoolService";
import { getTeacherStudents } from "../../features/teacher/services/teacherService";

import { accountType } from "../../constants/accountType";

import { useAuth } from "../authContext";
import { debugPrint } from "../../utils/debugPrint";

const StudentContext = createContext(initialState);

StudentContext.displayName = "StudentContext";

const StudentProvider = ({ children }) => {
  const { user } = useAuth();

  const { userType, schoolCurrentYear, schoolCurrentTerm, teacherClasses } =
    user || {};

  const [state, dispatch] = useReducer(studentReducer, initialState);

  /// This function uses the [user] object from [useAuth] to fetch student data
  /// based on the user type [school, teacher]
  const _handleStudentDataFetch = async () => {
    const {
      currentPage,
      cachedTermFilter,
      cachedClassFilter,
      // cachedSubClassFilter,
      cachedYearFilter,
    } = state;

    switch (userType) {
      case accountType.SCHOOL:
        let schoolTermFilter, schoolYearFilter;
        if (cachedTermFilter === "") {
          schoolTermFilter = schoolCurrentTerm;
        } else {
          schoolTermFilter = cachedTermFilter;
        }
        if (cachedYearFilter === "") {
          schoolYearFilter = schoolCurrentYear;
        } else {
          schoolYearFilter = cachedYearFilter;
        }
        const { data: schoolStudentData } = await getAllFilteredStudents(
          currentPage,
          cachedClassFilter,
          schoolTermFilter,
          schoolYearFilter
          // cachedSubClassFilter,
        );

        // const {data: schoolStudentData} = await getAllStudents(currentPage);

        return schoolStudentData;

      case accountType.TEACHER:
        let teacherClassFilter;
        if (cachedClassFilter === "") {
          teacherClassFilter = teacherClasses[0];
        } else {
          teacherClassFilter = cachedClassFilter;
        }

        const { data: teacherStudentData } = await getTeacherStudents(
          currentPage,
          teacherClassFilter,
          cachedTermFilter,
          // cachedSubClassFilter,
          cachedYearFilter
        );

        return teacherStudentData;
      default:
        throw new Error("Something went wrong. Please try again later");
    }
  };

  useEffect(() => {
    const fetchStudentsData = async () => {
      dispatch({
        type: studentActions.INITIALIZE_DEFAULT_LOAD,
        payload: { isLoading: true, isInitial: true },
      });

      try {
        const data = await _handleStudentDataFetch();

        const {
          status,
          message,
          dataPerCurrentPage,
          totalDataPerPage,
          totalFilterCount,
        } = data;

        if (status === true) {
          const { data: innerData } = data;

          dispatch({
            type: studentActions.REQUEST_SUCCESS,
            payload: {
              allStudents: [...innerData],
              currentPageCount: dataPerCurrentPage,
              dataPerPage: totalDataPerPage,
              totalCount: totalFilterCount,
            },
          });
        } else {
          throw new Error(message ?? "Something went wrong");
        }
      } catch (e) {
        // Handle error here
        debugPrint("studentContext - fetchStudentsData -- e ->", e);
      } finally {
        dispatch({
          type: studentActions.UPDATE_LOADING_STATE,
          payload: { isLoading: false },
        });
      }
    };

    fetchStudentsData();
  }, [
    state.currentPage,
    state.cachedYearFilter,
    state.cachedClassFilter,
    state.cachedTermFilter,
  ]);

  const fetchNextData = () => {
    dispatch({ type: studentActions.INCREMENT_PAGE_COUNT });
  };

  const fetchPreviousData = () => {
    dispatch({ type: studentActions.DECREMENT_PAGE_COUNT });
  };

  /// This function is responsible for handling student profile data edit and
  /// updating the state accordingly
  const handleStudentEdit = (studentAuthId, updatedData) => {
    let previousData = [...state.allStudents]; // array of objects

    let indexOfStudent;

    let found = previousData.find((data, index) => {
      indexOfStudent = index;
      return data["studentAuthId"] === studentAuthId;
    });

    found = { ...found, ...updatedData };

    previousData[indexOfStudent] = found;

    dispatch({
      type: studentActions.UPDATE_STUDENT_DETAIL,
      payload: { allStudents: previousData },
    });
  };

  const handleFilterUpdate = filterParams => {
    const { yearFilter, classFilter, termFilter } = filterParams;

    dispatch({
      type: studentActions.CACHE_FILTER_PARAMS,
      payload: {
        currentPage: 1,
        cachedYearFilter: yearFilter,
        cachedClassFilter: classFilter,
        cachedTermFilter: termFilter,
      },
    });
  };

  const values = {
    state,
    fetchNextData,
    fetchPreviousData,
    handleStudentEdit,
    handleFilterUpdate,
  };

  return (
    <StudentContext.Provider value={values}>{children}</StudentContext.Provider>
  );
};

const useStudent = () => {
  const context = useContext(StudentContext);

  if (context === undefined) {
    throw new Error("useStudent must be used within StudentContext");
  }

  return context;
};

export { StudentProvider, useStudent };
