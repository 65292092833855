const DismissibleModal = ({
  width,
  height,
  showModal,
  children,
  headerText,
  contentStyle,
  centerHeading = false,
  onCloseButtonClicked,
}) => {
  return (
    <>
      {showModal && (
        <div
          className={`fixed top-0 left-0 z-50 flex h-screen w-screen items-center justify-center bg-modal ${
            showModal ? "block" : "hidden"
          }`}
        >
          <div
            className={`relative overflow-y-auto rounded-lg bg-white p-6 shadow-xl ${width} ${height}`}
          >
            <div>
              <button
                className="absolute top-0 right-0 m-4 rounded-[14px] bg-[#F4F9FD] p-[14px]"
                onClick={onCloseButtonClicked}
              >
                <svg
                  className="h-6 w-6 text-black"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              {headerText && (
                <p
                  className={`text-[22px] font-bold ${
                    centerHeading && "flex justify-center"
                  }`}
                >
                  {headerText}
                </p>
              )}
            </div>
            <span className={["flex h-full", contentStyle].join(" ")}>
              {children}
            </span>
            {/* {children} */}
          </div>
        </div>
      )}
    </>
  );
};

export default DismissibleModal;
