import PageLoading from "../../../shared/components/ui/PageLoading";
import StrengthCard from "../../../shared/components/StrengthCard";
import AttendanceCard from "../../../shared/components/AttendanceCard";
import LatestEvents from "../../../shared/components/LatestEvents";
import WelcomeNameAndDate from "../../../shared/components/WelcomeNameAndDate";
import MarkAttendance from "../../teacher/components/MarkAttendance";

import { useStudentDashboard } from "../store/studentDashboardContext";
import PageSearchIconAndLabel from "../../../shared/components/PageSearchIconAndLabel";
import { useAuth } from "../../../store/authContext";

const StudentDashboard = () => {
  const { user } = useAuth();
  const {studentClasses, studentSubjects} = user;

  const { state } = useStudentDashboard();

  const {
    isLoading,
    error,
    firstName,
    subjects,
    classes,
    // library,
    // assignment,
  } = state;

  return (
    <>
      {error ? (
        <PageSearchIconAndLabel label="Something went wrong" />
      ) : isLoading ? (
        <PageLoading />
      ) : (
        <>
          <div className="grid grid-cols-12 content-start justify-items-stretch gap-x-6">
            {/* Welcome text and Mark Attendance button */}
            <div className="col-span-12 mb-[32px]">
              <div className="flex items-start justify-between">
                <WelcomeNameAndDate name={firstName} />
                <MarkAttendance />
              </div>
            </div>

            {/* Dashboard cards */}
            <div className="col-span-8 row-span-2 grid grid-cols-2 gap-6">
              <StrengthCard.Subject value={studentSubjects?.length ?? 0} />
              {/* <StrengthCard.Teacher value={teachers} /> */}
              {/* <StrengthCard.Subject value={library} /> */}
              <StrengthCard.Classes value={studentClasses?.length ?? 0} />
            </div>

            {/* Event panel */}
            <div className="col-span-4 row-span-6 grid grid-cols-1 gap-y-[36px]">
              <AttendanceCard />

              <div className="flex flex-col">
                <LatestEvents href="/admin/academic/event-calender">
                  {/* <LatestEvents.Company
                    title="Presentation of the new department"
                    time="Today | 5:00 PM"
                    duration="4h"
                  />
                  <LatestEvents.Student
                    title="PTA online meeting"
                    time="Today | 6:00 PM"
                    duration="4h"
                  ></LatestEvents.Student>
                  <LatestEvents.Birthday
                    title="Ray’s Birthday"
                    time="Tomorrow | 2:00 PM"
                    duration="4h"
                  ></LatestEvents.Birthday> */}
                </LatestEvents>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default StudentDashboard;
