import { PropTypes } from "prop-types";
import Pagination from "rc-pagination/lib/Pagination";

import { PointingLeftArrow, PointingRightArrow } from "../icons";

const PaginationAllData = ({
  className,
  totalData,
  currentPage,
  pageSize,
  onChangePage,
}) => {
  const prevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button className="mr-[10px] flex items-center space-x-[6px] text-[14px]">
          <span className="text-[12px]">
            <PointingLeftArrow />
          </span>
          <span>Prev</span>
        </button>
      );
    }
    if (type === "next") {
      return (
        <button className="flex items-center space-x-[6px] text-[14px]">
          <span>Next</span>
          <span className="text-[12px]">
            <PointingRightArrow />
          </span>
        </button>
      );
    }

    if (type === "jump-prev") {
      return <button className="text-[14px]">...</button>;
    }
    if (type === "jump-next") {
      return <button className="text-[14px]">...</button>;
    }

    if (type === "page") {
      return (
        <button
          className={`mr-[10px] ${current === currentPage && "text-primary"}`}
        >
          {current}
        </button>
      );
    }
    return originalElement;
  };

  return (
    <Pagination
      className={`${className} flex cursor-pointer items-center justify-center space-x-[10px] text-[14px] font-medium text-[#5F6D7E]`}
      onChange={onChangePage}
      total={totalData}
      current={currentPage}
      pageSize={pageSize}
      showSizeChanger={false}
      itemRender={prevNextArrow}
    />
  );
};

PaginationAllData.propTypes = {
  className: PropTypes.string,
  totalData: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  onChangePage: PropTypes.func,
};

export default PaginationAllData;
