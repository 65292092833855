import * as React from "react";

const SvgGradHat = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5 13.574c.095 0 .19-.024.276-.073l4.636-2.639v3.86c-.969.414-2.32.702-3.777.797a.559.559 0 0 0 .073 1.115c1.394-.09 2.674-.34 3.704-.712v1.896a.558.558 0 0 0-.431.543v1.963a.559.559 0 0 0 .99.351.556.556 0 0 0 .99-.351V18.36a.558.558 0 0 0-.432-.543V9.813c0-.2-.108-.386-.282-.485l-5.315-3.026a.559.559 0 1 0-.553.97l4.54 2.585-4.42 2.515-9.81-5.585 9.81-5.585 5.459 3.106a.559.559 0 0 0 .552-.97L11.776.072a.559.559 0 0 0-.553 0L.284 6.302a.56.56 0 0 0 0 .97l10.94 6.23a.56.56 0 0 0 .277.072ZM22.717 6.302l-3.18-1.811a.558.558 0 1 0-.553.97l2.327 1.326-1.67.95a.559.559 0 0 0 .553.972l2.523-1.436a.559.559 0 0 0 0-.971ZM4.64 11.843a.559.559 0 0 0-.559.559v1.1c0 1.262 1.442 2.292 3.957 2.828a.56.56 0 0 0 .663-.43.559.559 0 0 0-.43-.663c-2.017-.43-3.073-1.197-3.073-1.734v-1.1c0-.31-.25-.56-.558-.56Z"
      fill="currentColor"
    />
    <path
      d="M9.781 15.646a.564.564 0 0 0-.164.394.563.563 0 0 0 .56.56.56.56 0 0 0 .558-.56c0-.147-.06-.29-.164-.394a.58.58 0 0 0-.79 0Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgGradHat;
