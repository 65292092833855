import * as Yup from "yup";

/// Create school account validation schema
export const createSchoolAccountSchema = Yup.object().shape({
  schoolName: Yup.string().required("School name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords does not match")
    .required("Confirm password is required"),
});
