import * as React from "react";

const SvgPicture = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.444 53.667h23.11c9.042 0 15.113-6.342 15.113-15.778V16.11c0-9.436-6.071-15.778-15.11-15.778H15.444C6.406.333.334 6.675.334 16.111V37.89c0 9.436 6.072 15.778 15.11 15.778Zm2.22-29.334c-3.676 0-6.663-2.991-6.663-6.666C11 13.99 13.988 11 17.664 11c3.674 0 6.664 2.991 6.664 6.667 0 3.675-2.99 6.666-6.664 6.666Zm30.192 10.49c.893 2.29.43 5.042-.525 7.31-1.132 2.697-3.299 4.739-6.029 5.63-1.212.397-2.483.57-3.751.57H15.077c-2.236 0-4.215-.536-5.838-1.535-1.016-.628-1.196-2.075-.442-3.014a752.211 752.211 0 0 0 3.76-4.73c2.391-3.038 4.003-3.92 5.794-3.146.727.32 1.456.798 2.207 1.305 2 1.359 4.781 3.228 8.444 1.2 2.507-1.404 3.96-3.813 5.227-5.91l.021-.035.267-.44c.426-.705.846-1.4 1.32-2.04.596-.8 2.803-3.305 5.661-1.521 1.821 1.123 3.352 2.642 4.99 4.268a6.015 6.015 0 0 1 1.368 2.089Z"
      fill="#9FA5C0"
    />
  </svg>
);

export default SvgPicture;
