import * as React from "react";

const SvgSearch = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.625.25a6.375 6.375 0 1 0 3.943 11.385l.302.296 2.35 2.35.07.062a.75.75 0 0 0 .99-1.123l-2.355-2.356-.296-.289A6.375 6.375 0 0 0 6.625.25Zm0 1.5a4.875 4.875 0 1 1 0 9.75 4.875 4.875 0 0 1 0-9.75Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSearch;
