import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import axios from "axios";
import SideBarLayout from "../../components/layouts/SideBarLayout";
import SchoolContactInfo from "./SchoolContactInfo";
import SchoolRegistrationInfo from "./SchoolRegistrationInfo";
import SchoolSuperAdmin from "./SchoolSuperAdmin";

import { schoolOnboardingSchema } from "../../../utils/validationSchemas/schoolOnboardingSchema";
import {
  removeWhiteSpacesFromObjValues,
  removeInitialZeroFromPhoneNumber,
  extractFirstWordToLowerCase,
  extractFirstWordToUpperCase,
  convertToDate,
} from "../../../utils/helperFunctions";
import { removeNullOrUndefinedValuesFromObject } from "../../../utils/objectHelperFunctions";
import { showErrorToast, showSuccessToast } from "../../../utils/toastHandler";

import { uploadImage } from "../../../services/fileService";
import { completeOnboarding } from "../../../features/school/services/schoolService";
import urlSettings from "../../../config/apiConfig/apiConstants";
import { debugPrint } from "../../../utils/debugPrint";

const Onboarding = () => {
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const validate = () => {
      const hasOnboarded = location?.state?.hasOnboarded;

      if (hasOnboarded === true) {
        navigate("/", { replace: true });
      }
    };

    validate();
  }, []);

  // const { handleLogin } = useContext(AuthContext);

  // TODO: use the useEffect hook to check if the user hasOnboard and if so
  // redirect the user to the dashboard

  const initialValues = {
    // countryCode: "+234",
    phone: "",
    country: "",
    state: "",
    LGA: "",
    postalCode: "",
    address: "",
    logoUrl: null,
    currentTerm: "",
    currentYear: "",
    schoolRegistrationNumber: "",
    yearOfRegistration: "",
    schoolApprovalCertificateUrl: null,
    // firstName: "",
    // lastName: "",
    fullName: "",
    email: "",
    signature: null,
    homeAddress: "",
    principalPhone: "",
    // principalCountryCode: "+234",
  };

  const formHandler = async values => {
    const formattedData = removeWhiteSpacesFromObjValues(values);

    const {
      // countryCode,
      phone,
      logoUrl,
      currentTerm,
      currentYear,
      yearOfRegistration,
      schoolApprovalCertificateUrl,
      // firstName, // Principal's data
      // lastName, // Principal's data
      fullName, // Principal's data
      email, // Principal's data
      signature, // Principal's data
      homeAddress, // Principal's data
      // principalCountryCode, // Principal's data
      principalPhone, // Principal's data
      ...params
    } = formattedData;

    // const logoFormData = new FormData();
    // logoFormData.append("file", logoUrl);
    // logoFormData.append("upload_preset", "project-image");

    // const schoolCertificateFormData = new FormData();
    // schoolCertificateFormData.append("file", schoolApprovalCertificateUrl);
    // schoolCertificateFormData.append("upload_preset", "project-image");

    // const logoImageUpload = uploadImage(logoFormData);
    // const schoolCertificateImageUpload = uploadImage(schoolCertificateFormData);

    // const uploadPromise = Promise.all([
    // 	logoImageUpload,
    // 	schoolCertificateImageUpload,
    // ]);

    try {
      let logoUploadedUrl;
      if (logoUrl != null) {
        const logoFormData = new FormData();
        logoFormData.append("file", logoUrl);
        logoFormData.append("upload_preset", "project-image");

        const logoCloudinary = await axios.post(
          urlSettings.CLOUDINARY_URL,
          logoFormData
        );

        logoUploadedUrl = logoCloudinary.data.secure_url;
      }

      let schCertUploadedUrl;
      if (schoolApprovalCertificateUrl != null) {
        const schoolCertificateFormData = new FormData();
        schoolCertificateFormData.append("file", schoolApprovalCertificateUrl);
        schoolCertificateFormData.append("upload_preset", "project-image");

        const schCertCloudinary = await axios.post(
          urlSettings.CLOUDINARY_URL,
          schoolCertificateFormData
        );

        schCertUploadedUrl = schCertCloudinary.data.secure_url;
      }

      let signatureUploadedUrl;
      if (signature != null) {
        const signatureFormData = new FormData();
        signatureFormData.append("file", signature);
        signatureFormData.append("upload_preset", "project-image");

        const signatureCloudinary = await axios.post(
          urlSettings.CLOUDINARY_URL,
          signatureFormData
        );

        signatureUploadedUrl = signatureCloudinary.data.secure_url;
      }

      let principalData = {
        fullName: fullName,
        email: email,
        signature: signatureUploadedUrl,
        address: homeAddress,
        // phone: `${principalCountryCode}${removeInitialZeroFromPhoneNumber(
        //   principalPhone
        // )}`,
        phone: `+234${principalPhone}`,
        startDate: convertToDate(new Date().toString()), // Hard-coded data
        endDate: convertToDate(new Date().toString()), // Hard-coded data
        isCurrentPrincipal: true, // Hard-coded data
      };

      principalData = removeNullOrUndefinedValuesFromObject(principalData);

      const principals = [];

      principals.push(principalData);

      let payload = {
        ...params,
        // phone: `${countryCode}${removeInitialZeroFromPhoneNumber(phone)}`,
        phone: `+234${phone}`,
        currentYear: +currentYear,
        yearOfRegistration: +yearOfRegistration,
        currentTerm: extractFirstWordToUpperCase(currentTerm.split(" ")[0]),
        logoUrl: logoUploadedUrl,
        schoolApprovalCertificateUrl: schCertUploadedUrl,
        schoolPrincipalRecords: principals,
        schoolType: "secondary", // Hard-coded data
      };

      payload = removeNullOrUndefinedValuesFromObject(payload);

      debugPrint("Onboarding - formHandler -- payload -> ", payload);

      const response = await completeOnboarding(payload);

      const { data } = response;

      // handleLogin(data);

      if (data.status === true) {
        showSuccessToast("Success");
        // TODO: Navigate user to the login screen.
        // NOTE: If user data payload can be provided here, navigate user to dashboard
        navigate("/login", { replace: true });
        // navigate("/dashboard", { replace: true });
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  return (
    <SideBarLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={schoolOnboardingSchema}
        onSubmit={formHandler}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
          setFieldValue,
          setTouched,
        }) => (
          <Form
            onSubmit={handleSubmit}
            className='flex flex-col'
            // autoComplete="off"
            autoComplete='new-password'
          >
            <Routes>
              <Route
                path={"/*" || "school-contact-info"}
                element={
                  <SchoolContactInfo
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                }
              />
              <Route
                path="school-registration-info"
                element={
                  <SchoolRegistrationInfo
                    values={values}
                    errors={errors}
                    touched={touched}
                    setTouched={setTouched}
                    setFieldValue={setFieldValue}
                  />
                }
              />
              <Route
                path="school-super-admin"
                element={
                  <SchoolSuperAdmin
                    values={values}
                    errors={errors}
                    touched={touched}
                    setTouched={setTouched}
                    setFieldValue={setFieldValue}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                  />
                }
              />
            </Routes>
          </Form>
        )}
      </Formik>
    </SideBarLayout>
  );
};

export default Onboarding;