import React from "react";
import { NavLink } from "react-router-dom";
import * as Icons from "../components/icons";

const AttendanceCard = ({ className, value = '0', href = '#' }) => {
    return (
        <div className={['rounded-[14px] py-[12px] px-[46px] h-[208px] text-white bg-[#0078CE] flex flex-col justify-center items-center', className].join(' ')} >
            <h3 className='font-semibold text-[24px]'>Todays Attendance</h3>
            <p className="text-[12px]">(Coming soon)</p>
            {/* <h3 className='font-semibold text-[24px] mb-[23px]'>Todays Attendance</h3> */}
            {/* <p className='inline-flex items-center font-medium text-[18px] mb-[13px] leading-none'><span className='font-semibold text-[36px] mr-[22px]'>{value}</span> Students attending today</p>
            <div className='flex justify-center'>
                <NavLink to={href} className='inline-flex items-center px-[18px] py-[10px] text-[16px] border-1 border-white rounded-[16px]'> Go to attendance <span className='inline-flex items-center justify-center ml-[12px] h-[10px] w-[10px]' >
                    <Icons.RightArrow className='h-full' />
                </span> </NavLink>
            </div> */}
        </div>
    )
}

export default AttendanceCard;
