import { ErrorMessage, useField } from "formik";

const TextAreaInputFormik = ({
  label,
  className,
  children,
  placeholder = "Add some text here...",
  width = "w-full",
  disabled = false,
  row = "5",
  ...props
}) => {
  const [field] = useField(props);

  return (
    <div className={`flex flex-col space-y-[6px] ${width}`}>
      {label && (
        <label
          htmlFor={field.name}
          className="text-[14px] font-semibold text-[#7D8592]"
        >
          {label}
        </label>
      )}

      <textarea
        disabled={disabled}
        className="resize-none rounded-[14px] border-[1px] border-[#D8E0F0] p-[10px] text-[14px] placeholder-gray-400"
        placeholder={placeholder}
        rows={row}
        // cols="40"
        {...field}
        {...props}
      ></textarea>
      <ErrorMessage
        name={field.name}
        component="span"
        className="text-[14px] text-accent"
      />
    </div>
  );
};

export default TextAreaInputFormik;
