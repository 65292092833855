import { examinationActions } from "./examinationActions";

export const initialState = {
  termFilter: "",
  classFilter: "",
  subClassFilter: "",
  yearFilter: "",
  isLoading: false,
  error: null,
  currentPage: 1,
  currentPageCount: 0, // <- This holds the current data size being displayed
  totalCount: 0, // <- This holds the value of the total number of student in the DB
  dataPerPage: 0, // <- This holds the paginated data size per request
  examinations: [], // <- Array that holds all the students result data
};

const examinationReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case examinationActions.UPDATE_LOADING_STATE:
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    case examinationActions.REQUEST_SUCCESS:
      return {
        ...state,
        examinations: payload.examinations,
        totalCount: payload.totalCount,
        dataPerPage: payload.dataPerPage,
        currentPageCount: payload.currentPageCount,
      };
    case examinationActions.ADD_ERROR:
      return {
        ...state,
        allStudents: [...payload.allStudents], // <- FIXME: Error should be added here
      };
    case examinationActions.UPDATE_EXAM_TYPE:
      return {
        ...state,
        examinations: [...payload.examinations],
      };
    case examinationActions.DELETE_EXAM_TYPE:
      return {
        ...state,
        examinations: payload.examinations,
        // totalCount: payload.totalCount,
        // currentPageCount: payload.currentPageCount,
      };
    case examinationActions.REVERSE_DELETE_EXAM_TYPE:
      return {
        ...state,
        examinations: payload.examinations,
        // totalCount: payload.totalCount,
        // currentPageCount: payload.currentPageCount,
      };
    case examinationActions.APPEND_NEW_EXAM_TYPES:
      return {
        ...state,
        examinations: [...payload.examinations],
      };
    case examinationActions.GO_TO_NEXT_PAGE:
      return {
        ...state,
        currentPage: payload.currentPage,
      };
    case examinationActions.GO_TO_PREVIOUS_PAGE:
      return {
        ...state,
        currentPage: payload.currentPage,
      };

    default:
      throw new Error(`No case for type ${type} found in examinationReducer`);
  }
};

export default examinationReducer;
