import { useState } from "react";

import GridTileCard from "../GridTileCard";
import MoreVertButton from "../ui/MoreVertButton";
import IconAndLabel from "../ui/IconAndLabel";
import { Delete, Edit } from "../icons";
import UndismissibleModal from "../modals/UndismissibleModal";
import UpdateExamTypeModal from "../modals/UpdateExamTypeModal";

import { accountType } from "../../../constants/accountType";

import { useAuth } from "../../../store/authContext";
import TickBox from "../ui/TickBox";

function ExamPane({ exam, onDelete, value, name, updateValue }) {
  const { user } = useAuth();
  const userType = user?.userType;

  const { examType, totalScore } = exam;

  const [showModal, setShowModal] = useState(false);

  const handleOnSuccess = () => {
    setShowModal(false);
  };

  return (
    <>
      <UndismissibleModal
        showModal={showModal}
        setShowModal={setShowModal}
        header={<p className="text-[22px] font-bold">Edit Exam Type</p>}
      >
        <UpdateExamTypeModal exam={exam} onSuccess={handleOnSuccess} />
      </UndismissibleModal>
      <tbody className="h-full w-full">
        <tr className="border-b-[1px] border-b-[#EAEBF0]">
          {/* <td className='pl-8'>
           
              <div className="flex items-center">
              <TickBox
                name={name}
                value={value}
                updateValue={updateValue}
              />
              </div>
          
           
            
          </td> */}

          <td className="px-[24px] py-8 text-[16px]">{examType}</td>

          <td className="py-8 text-[16px]">{totalScore}</td>
          {/* {userType === accountType.SCHOOL && (
          <span className="col-span-2 place-self-center">
            <MoreVertButton>
              <div className="rounded-[24px] bg-white p-[24px]">
                <IconAndLabel label="Edit" onClick={() => setShowModal(true)}>
                  <Edit className="text-black" />
                </IconAndLabel>
                <IconAndLabel label="Delete" onClick={onDelete}>
                  <Delete className="text-danger" />
                </IconAndLabel>
              </div>
            </MoreVertButton>
          </span>
        )} */}
        </tr>
      </tbody>
    </>
  );
}

export default ExamPane;
