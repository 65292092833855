import * as React from "react";

const SvgTimer = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm.993-11.117A1 1 0 0 0 7 5v3.75l.007.12a1 1 0 0 0 .608.803l3 1.25.11.039a1 1 0 0 0 1.198-.577l.039-.11a1 1 0 0 0-.577-1.198L9 8.083V5l-.007-.117Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTimer;
