//disables the body from scrolling whenever a modal is opened

// export const useToggleBodyScroll = (dep1 = false) => {
//   if (dep1) document.body.style.overflow = "hidden";//hidden
//   else document.body.style.overflow = "scroll";
// };
export const useToggleBodyScroll = (dep1 = false, dep2 = false) => {
  if (dep1 || dep2) document.body.style.overflow = "hidden";//hidden
  else document.body.style.overflow = "visible";
};
