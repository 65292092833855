const GridTileCard = ({
  children,
  gridColumns = "grid-cols-12",
  verticalPadding = "py-[32px]",
  classes,
  onClick,
}) => {
  return (

    <div
      onClick={onClick}
      className={`grid w-full h-full pl-12 px-[28px] ${verticalPadding} ${gridColumns} ${classes}`}
    >
      
      {children}
    </div>
  );
};

export default GridTileCard;
