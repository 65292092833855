import TitleAndDetail from "./TitleAndDetail";

const ClassPositionPromoted = ({
  studentClass,
  numberInClass = "0",
  positionInClass,
  promoted = false,
}) => {

  return (
    <div className="flex space-x-[24px]">
      <TitleAndDetail
        title="Class"
        detail={studentClass}
        detailClasses="uppercase"
      />
      <TitleAndDetail
        title="No. in class"
        detail={numberInClass}
      />
      <TitleAndDetail title="Position in class" detail={positionInClass} />
      <TitleAndDetail title="Promoted" detail={promoted ? "Yes" : "No"} />
    </div>
  );
};

export default ClassPositionPromoted;
