import { useEffect, useState } from "react";

import { getAllBanks } from "../features/school/services/schoolService";

import { debugPrint } from "../utils/debugPrint";

const useGetAllBanks = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    const fetchBanks = async () => {
      setIsLoading(true);

      try {
        const { data: bankResponse } = await getAllBanks();

        debugPrint(
          "useGetAllBanks - useEffect -- bankResponse ->",
          bankResponse
        );

        if (bankResponse.status === false) {
          throw new Error(bankResponse?.message ?? "Couldn't fetch banks");
        }

        setBanks(bankResponse.data);
      } catch (err) {
        setError(err?.message ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBanks();
  }, []);

  return { isLoading, error, banks };
};

export default useGetAllBanks;
