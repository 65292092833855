import client from "../../../config/apiConfig/apiConfig";
import { removeEmptyKeyValuesFromObject } from '../../../utils/objectHelperFunctions';

export const getStudentDashboardDetails = async (studentAuthId) => {
  const queryParameters = {
    studentAuthId,
  };

  const response = client.studentClient.get("get-student-details-app", {
    params: queryParameters,
  });

  return response;
};


/// Function responsible for fetching student's result
export const getStudentResult = async () => {
  const response = client.studentClient.get("result-display");

  return response;
}


export const getStudentsStudentResult = async (
  studentClass,
  studentTerm,
  studentYear
) => {
  const queryParameters = {
    studentClass,
    studentTerm,
    studentYear,
  };

  const response = client.studentClient.get("get-single-students-result", {
    params: queryParameters,
  });

  return response;
};



/// Method that fetches teachers of a school
export const getStudentTeachers = async (
  pageNumber = 1,
  teacherClass,
  subject
) => {
  let queryParameters = {
    page: +pageNumber,
    teacherClass,
    subject,
  };

  queryParameters = removeEmptyKeyValuesFromObject(queryParameters);

  const response = client.studentClient.get("sort-teachers", {
    params: queryParameters,
  });

  return response;
};

/// Function for downloading student's result
export const studentDownloadPdfResult = async (
  schoolAuthId,
  studentAuthId,
  studentClass,
  studentTerm,
  studentYear
) => {
  const queryParameters = {
    schoolAuthId,
    studentAuthId,
    studentClass,
    studentTerm,
    studentYear,
  }; 

  const response = client.studentClient.get("download-result-sheet", { 
    responseType: "blob",
    params: queryParameters,
  });

  return response;
};
