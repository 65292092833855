import { marksActions } from "./marksActions";

export const initialState = {
  isInitial: true,
  isLoading: false,
  error: null,
  message: null,
  currentPage: 1,
  currentPageCount: 0,
  totalCount: 0, // <- This holds the value of the total number of student in the DB
  dataPerPage: 0,
  allStudents: [], // <- Array that holds all the students result data for school and teacher
  student: null, // <- Object containing a single
  cachedTermFilter: "",
  cachedClassFilter: "",
  cachedSubClassFilter: "",
  cachedYearFilter: "",
};

const marksReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case marksActions.UPDATE_LOADING_STATE:
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    case marksActions.INITIALIZE_DEFAULT_LOAD:
      return {
        ...state,
        isLoading: payload.isLoading,
        isInitial: payload.isInitial,
        error: null, // <- Resetting error to null
        currentPage: payload.currentPage, // <- Resetting currentPage value to 1
        allStudents: [], // <- Resetting the allStudents array
      };

    case marksActions.CACHE_FILTER_PARAMS:
      return {
        ...state,
        cachedTermFilter: payload.cachedTermFilter,
        cachedClassFilter: payload.cachedClassFilter,
        cachedSubClassFilter: payload.cachedSubClassFilter,
        cachedYearFilter: payload.cachedYearFilter,
      };

    case marksActions.REQUEST_SUCCESS:
      return {
        ...state,
        allStudents: [...payload.allStudents],
        error: null,
      };

      case marksActions.ADD_ERROR:
        return {
          ...state,
          error: [payload.error]
        }

    case marksActions.UPDATE_STUDENTS_MARKS:
      return {
        ...state,
        allStudents: [...payload.allStudents],
      };

    default:
      throw new Error(`No case for type ${type} found in marksReducer`);
  }
};

export default marksReducer;
