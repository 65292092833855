import { useNavigate } from "react-router-dom";

import { NotFound } from "../components/icons";
import Button from "../components/ui/Button";

const ComingSoon = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <p className="text-6xl text-gray-500">Coming Soon</p>
    </div>
  );
};

export default ComingSoon;
