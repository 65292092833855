import * as React from "react";

const SvgUserGroup = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5 0C9.57 0 8 1.57 8 3.5S9.57 7 11.5 7 15 5.43 15 3.5 13.43 0 11.5 0Zm0 6A2.503 2.503 0 0 1 9 3.5C9 2.122 10.122 1 11.5 1S14 2.122 14 3.5 12.878 6 11.5 6ZM18.5 4A2.503 2.503 0 0 0 16 6.5C16 7.878 17.122 9 18.5 9S21 7.878 21 6.5 19.878 4 18.5 4Zm0 4c-.827 0-1.5-.673-1.5-1.5S17.673 5 18.5 5s1.5.673 1.5 1.5S19.327 8 18.5 8ZM4.571 4a2.503 2.503 0 0 0-2.5 2.5c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5S5.951 4 4.571 4Zm0 4c-.827 0-1.5-.673-1.5-1.5S3.744 5 4.571 5s1.5.673 1.5 1.5S5.398 8 4.571 8ZM11.5 8A6.508 6.508 0 0 0 5 14.5a.5.5 0 0 0 1 0C6 11.467 8.467 9 11.5 9s5.5 2.467 5.5 5.5a.5.5 0 0 0 1 0c0-3.584-2.916-6.5-6.5-6.5Z"
      fill="currentColor"
    />
    <path
      d="M18.5 10a4.5 4.5 0 0 0-2.331.65.5.5 0 0 0 .519.855A3.504 3.504 0 0 1 22 14.5a.5.5 0 0 0 1 0c0-2.481-2.019-4.5-4.5-4.5ZM6.83 10.649a4.504 4.504 0 0 0-2.33-.65A4.506 4.506 0 0 0 0 14.5a.5.5 0 0 0 1 0 3.504 3.504 0 0 1 5.311-2.995.5.5 0 0 0 .519-.855Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgUserGroup;
