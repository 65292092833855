import ImageTitleSubtitle from "../../../shared/components/ui/ImageTitleSubtitle";
import LabelAndBorderedContext from "../../../shared/components/ui/LabelAndBorderedContext";

const StudentTeacherModal = ({
  id,
  fullName,
  profileImage,
  phoneNumber,
  subjects,
  classInCharge,
}) => {
  return (
    <div className="mt[30px] flex w-[584px] flex-col">
      <ImageTitleSubtitle
        image={profileImage}
        imagePlaceHolderText={fullName}
        placeHolderStyle="text-[24px]"
        avatarHeight="h-[107px]"
        avatarWidth="w-[107px]"
        title={fullName}
        titleStyle="text-[24px] font-bold text-black-shade1"
        subtitle={id}
        contentPosition="justify-center"
      />

      <div className="mt-[42px] mb-[59px] h-[1px] w-full bg-[#E4E6E8]"></div>

      <div className="flex flex-col items-center">
        <h1 className="mb-[24px] self-start text-[24px] font-bold">
          Main info
        </h1>

        <div className="flex w-full justify-between space-x-[16px]">
          <LabelAndBorderedContext
            width="w-[256px]"
            label="User ID"
            content={id}
          />
          <LabelAndBorderedContext
            width="w-[256px]"
            label="Contact"
            content={phoneNumber}
          />
        </div>
        <div className="flex w-full justify-between space-x-[16px]">
          <LabelAndBorderedContext
            width="w-[256px]"
            label="Subject"
            content={subjects}
          />
          <LabelAndBorderedContext
            width="w-[256px]"
            label="Class incharge"
            content={classInCharge.map((value) => value?.toUpperCase())}
          />
        </div>

        <LabelAndBorderedContext
          width="w-[256px] self-start"
          label="Phone Number"
          content={phoneNumber}
        />
      </div>
    </div>
  );
};

export default StudentTeacherModal;
