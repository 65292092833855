import client from "../config/apiConfig/apiConfig";
import { accountType } from "../constants/accountType";

// TODO: Create a constant for "user"
/// This will return the user data object
export function getUserData() {
  try {
    let userData =
      localStorage.getItem("user") || sessionStorage.getItem("user");

    userData = JSON.parse(userData);

    return userData;
  } catch (err) {
    return null;
  }
}

// TODO: Create a constant for "token"
export function getJwtToken() {
  const token = localStorage.getItem("token");

  if (token) {
    return token;
  }

  return null;
}

export const loginUser = async (payload) => {
  const { data: response } = await client.authClient.post("log-in", payload);

  return response;
};

export const saveUserToken = (token) => {
  if (token) {
    localStorage.setItem("token", token);
  }
};

/// This method clears user data when a logout action is triggered
export const clearUserDataAndToken = () => {
  // localStorage.removeItem("user");
  // sessionStorage.removeItem("user");
  // localStorage.removeItem("token");
  localStorage.clear();
  sessionStorage.clear();
};

export const saveUserDataToStorage = (response, rememberMe) => {
  const { data } = response;

  const userType = data?.userType;

  /// This variable will hold a stringified value of the userData object
  let userData;

  switch (userType) {
    case accountType.SCHOOL:
      userData = _handleSavingUserDataForSchool(data);
      break;
    case accountType.TEACHER:
      userData = _handleSavingUserDataForTeacher(data);
      break;
    case accountType.STUDENT:
      userData = _handleSavingUserDataForStudent(data);
      break;
    default:
      throw new Error("Something went wrong");
  }

  // localStorage.setItem("rememberMe", rememberMe);

  if (rememberMe) {
    localStorage.setItem("user", userData);
    localStorage.setItem("rememberMe", rememberMe);
  } else {
    sessionStorage.setItem("user", userData);
  }
};

/// This method handles extracting the required information needed
function _handleSavingUserDataForSchool(data) {
  const {
    id,
    userType,
    logoUrl,
    state,
    country,
    schoolName,
    schoolPrincipalRecords,
    currentTerm: schoolCurrentTerm,
    currentYear: schoolCurrentYear,

    // For first time school log when certain data hasn't been filled yet
    areSubjectsCreated,
    areSubjectsAssignedToClasses,
    areExamTypesCreated,
    areResultCustomsCommentCreated,
  } = data;

  const fullName = schoolPrincipalRecords[0].fullName;

  const userData = {
    id,
    userType,
    logoUrl,
    state,
    country,
    schoolName,
    fullName,
    schoolCurrentTerm,
    schoolCurrentYear,

    areSubjectsCreated,
    areSubjectsAssignedToClasses,
    areExamTypesCreated,
    areResultCustomsCommentCreated,
  };

  return JSON.stringify(userData);
}

function _handleSavingUserDataForTeacher(data) {
  const {
    id,
    schoolAuthId,
    userType,
    firstName,
    middleName,
    lastName,
    passportImageUrl: profileImage,
    schoolDetails,
    teacherDetails,
  } = data || {};

  const fullName = `${firstName} ${middleName} ${lastName}`;

  const { logoUrl, state, country, schoolName } = schoolDetails;

  // Extracting the first class assigned to a teacher
  const { classes: teacherClasses, subjects: teacherSubjects } = teacherDetails;

  const userData = {
    id,
    schoolAuthId,
    userType,
    logoUrl,
    profileImage,
    state,
    country,
    schoolName,
    fullName,
    teacherClasses,
    teacherSubjects,
  };

  return JSON.stringify(userData);
}

function _handleSavingUserDataForStudent(data) {
  const {
    id,
    schoolAuthId,
    userType,
    firstName,
    middleName,
    lastName,
    currentClassSubjects: studentSubjects, // An array of the student's class subjects
    passportImageUrl: profileImage,
    studentClasses, // An array of the student's classes
    schoolDetails,
  } = data || {};
  const fullName = `${firstName} ${middleName} ${lastName}`;

  const { logoUrl, state, country, schoolName, subjects } = schoolDetails;

  const userData = {
    id,
    schoolAuthId,
    userType,
    logoUrl,
    state,
    country,
    schoolName,
    fullName,
    subjects,
    studentSubjects,
    studentClasses,
    profileImage,
  };

  return JSON.stringify(userData);
}

export const logOut = () => {
  localStorage?.removeItem("token");
  sessionStorage?.removeItem("token");
};

/// Function responsible for creating a new school account
export const createSchoolAccount = async (payload) => {
  const { data: response } = await client.authClient.post(
    "school-registration",
    payload
  );
  return response;
};

/// Function for resending email verification code to user's email
export const resendEmailCode = async (payload) => {
  const { data: response } = await client.authClient.post(
    "re-send-email-code",
    payload
  );
  return response;
};

/// Function call responsible for verifying an account after user has received
/// email verification code
export const verifyAccount = async (payload) => {
  const { data: response } = await client.authClient.post(
    "verify-account",
    payload
  );
  return response;
};

export const sendPasswordResendCode = async (payload) => {
  const response = await client.authClient.post(
    "send-password-reset-code",
    payload
  );

  return response;
};

export const validatePasswordResetCode = async (payload) => {
  const response = await client.authClient.post(
    "/validate-password-reset-code",
    payload
  );

  return response;
};

export const updatePassword = async (payload) => {
  const response = await client.authClient.put("/update-password", payload);

  return response;
};

export const changeDefaultPassword = async (payload) => {
  const response = await client.authClient.put(
    "/change-default-password",
    payload
  );

  return response;
};
