import TextFieldFormik from "../../TextFieldFormik";
import Select from "../../ui/Select";
import InputFile from "../../ui/InputFile";
import NormalButton from "../../ui/NormalButton";
import DatePickerNew from "../../ui/DatePickerNew";
import { Selector } from "../../MultiCheckBox";

import { schoolClassListLabelAndValue } from "../../../../assets/lists/schoolClasses";
import InputFileSingle from "../../ui/InputFileSingle";
import { getCalendarYear } from "../../../../utils/calendarFunctions";

const FormAddTeacher2 = ({
  values,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
  onClick,
}) => {
  const isButtonDisabled = () => {
    if (
      errors.LGA ||
      values.LGA === "" ||
      errors.phoneNumber ||
      values.phoneNumber === "" ||
      errors.dateOfBirth ||
      values.dateOfBirth === null ||
      errors.profileImageUrl ||
      values.profileImageUrl === null ||
      errors.classes ||
      values.classes.length === 0
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className="flex flex-col">
      <TextFieldFormik
        extraClasses="mb-[15px]"
        label="L.G.A"
        name="LGA"
        placeholder="Municipal "
      />
      <div className="grid space-x-[16px] bg-white ">
        <div className="flex flex-col">
          <span className="mb-2 text-[14px] font-semibold text-[#7D8592]">
            Mobile Number
          </span>
          <div className="flex flex-row space-x-[16px]">
            <Select
              hiddenLabel={"code"}
              name="countryCode"
              showDefaultOption={false}
              options={["+234", "+233"]}
            />
            <span className="w-full">
              <TextFieldFormik
                extraClasses="mb-[15px]"
                name="phoneNumber"
                placeholder="345 567-23-56"
              />
            </span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-6">
          <DatePickerNew
            name="dateOfBirth"
            dropdownMode="select"
            maxDate={getCalendarYear(18)}
          />
        </div>
        <div className="col-span-6">
          <InputFileSingle
            name="profileImageUrl"
            label="profile Picture"
            placeholder="Upload profile picture"
            fileName={values.profileImageUrl?.name}
            error={errors.profileImageUrl}
            touched={touched.profileImageUrl}
            onFieldTouched={() => {
              setFieldTouched("profileImageUrl", true, false);
            }}
            onChange={event => {
              setFieldValue("profileImageUrl", event.target.files[0]);
            }}
          />
        </div>
      </div>

      <Selector
        label="Class(es)"
        value={values.classes}
        placeholder="Select class(es)"
        options={schoolClassListLabelAndValue}
        noOptionsMessage="No available classes"
        onFocus={() => {
          setFieldTouched("classes", true, false);
        }}
        handleSelection={array => {
          // handleClassSelect(array, setFieldValue);
          setFieldValue("classes", array);
        }}
      />

      <div className="my-[30px] flex w-full justify-end">
        <NormalButton
          showIcon={false}
          extraStyle="self-end"
          clicked={onClick}
          label="Next"
          disabled={isButtonDisabled()}
        />
      </div>
    </div>
  );
};

export default FormAddTeacher2;
