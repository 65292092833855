import { useState, useEffect } from "react";

import StudentEditableMainInfo from "./StudentEditableMainInfo";
import StudentEditableSchoolInfo from "./StudentEditableSchoolInfo";
import StudentEditableLocationInfo from "./StudentEditableLocationInfo";
import StudentEditableGuardianInfo from "./StudentEditableGuardianInfo";

import ProfileInputFile from "../../../ui/ProfileInputFile";
import Button from "../../../ui/Button";

const EditingStudentProfile = ({
  profileImage,
  values,
  touched,
  errors,
  setFieldValue,
  setTouched,
  isSubmitting,
  onCancel,
  onSubmit,
}) => {
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleFileSelection = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(event.target.files[0]);
  };

  return (
    <div className="flex flex-col">
      <div className="mb-[42px] flex items-center justify-between">
        <div className="flex items-center space-x-6">
          <div className="shrink-0">
            <div className="h-[107px] w-[107px] rounded-full bg-grey">
              {selectedFile ? (
                <img
                  className="h-[107px] w-[107px] rounded-full object-cover"
                  src={preview}
                  alt="Current profile avatar"
                />
              ) : (
                profileImage && (
                  <img
                    className="h-[107px] w-[107px] rounded-full object-cover"
                    src={profileImage}
                    alt="Current profile avatar"
                  />
                )
              )}
            </div>
          </div>
          <ProfileInputFile
            name="profileImage"
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            setTouched={setTouched}
            onFileSelected={handleFileSelection}
          />
        </div>

        <div className="flex cursor-pointer items-center space-x-[16px]">
          <Button
            type="submit"
            height="h-[40px]"
            width="w-[128px]"
            padding="py-[8px]"
            buttonColor="bg-success"
            onClick={onSubmit}
            isLoading={isSubmitting}
          >
            Save changes
          </Button>
          <Button
            height="h-[40px]"
            width=""
            padding="px-[10px] py-[8px]"
            buttonColor="bg-accent"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
      <div className="relative h-[2px] w-full overflow-visible bg-grey"></div>

      <div className="grid grid-cols-2 gap-2">
        <StudentEditableMainInfo
          values={values}
          setFieldValue={setFieldValue}
        />
        <StudentEditableSchoolInfo />
        <StudentEditableLocationInfo values={values} />
        <StudentEditableGuardianInfo />
      </div>
    </div>
  );
};

export default EditingStudentProfile;
