import { SessionProvider } from "../../../../store/sessionStore/sessionContext";
import SessionDashboard from "./SessionDashboard";

const Session = () => {
  return (
    <SessionProvider>
      <SessionDashboard />
    </SessionProvider>
  );
};

export default Session;
