import { useEffect, useState } from "react";

import TextFieldFormik from "../../TextFieldFormik";
import { Selector } from "../../MultiCheckBox";
import InputFileSingle from "../../ui/InputFileSingle";
import NormalButton from "../../ui/NormalButton";

const FormAddTeacher3 = ({
  subjects,
  values,
  errors,
  touched,
  setFieldTouched,
  setTouched,
  setFieldValue,
  onClick,
}) => {
  const [subjectValuesAndLabels, setSubjectValuesAndLabels] = useState();

  useEffect(() => {
    const subjectValueLabel = subjects?.map(subject => {
      return { value: subject, label: subject };
    });

    setSubjectValuesAndLabels(subjectValueLabel);
  }, []);

  const isButtonDisabled = () => {
    if (
      errors.subjects ||
      values.subjects.length === 0 ||
      errors.documents ||
      values.documents === null ||
      errors.formerEmployerName ||
      values.formerEmployerName === "" ||
      errors.formerJobTitle ||
      values.formerJobTitle === "" ||
      errors.yearsOfExperience ||
      values.yearsOfExperience === ""
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className="flex flex-col">
      <Selector
        extraClasses="mb-[10px]"
        label="Assign Subject(s)"
        value={values.subjects}
        placeholder="Select subject(s)"
        options={subjectValuesAndLabels}
        noOptionsMessage="No available subjects"
        onFocus={() => setFieldTouched("subjects", true, false)}
        handleSelection={array => setFieldValue("subjects", array)}
      />

      <InputFileSingle
        name="documents"
        label="documents"
        fileName={values.documents?.name}
        error={errors.documents}
        touched={touched.documents}
        onChange={event => {
          setTouched({ document: true }, false);
          setFieldValue("documents", event.target.files[0]);
        }}
      />
      <div className="grid bg-white">
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Former Employer Full Name"
          name="formerEmployerName"
          placeholder="Enter full name"
        />

        <div className="flex w-full flex-row  space-x-[39px]">
          <TextFieldFormik
            extraClasses="mb-[15px]"
            label="Former job title"
            name="formerJobTitle"
            placeholder="Enter job title"
          />
          <TextFieldFormik
            extraClasses="mb-[15px]"
            label="Years of experience"
            name="yearsOfExperience"
            placeholder="0 years"
          />
        </div>

        <div className="my-[30px] flex w-full items-end justify-end self-end">
          <NormalButton
            showIcon={false}
            extraStyle="self-end"
            clicked={onClick}
            label="Next"
            disabled={isButtonDisabled()}
          />
        </div>
      </div>
    </div>
  );
};

export default FormAddTeacher3;
