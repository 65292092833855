import { useEffect, useState } from "react";

const CheckBox = ({ checked = false, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleClick = (event) => {
    if (event != null) {
      // event.preventDefault();
      event.stopPropagation();
    }
    const checkStatus = event.currentTarget.checked;
    setIsChecked(checkStatus);
    onChange();
  };

  return (
    <div className="flex h-[44px] w-[44px] items-center justify-center rounded-[14px] bg-active">
      <input
        className="border-primary"
        type="checkbox"
        checked={isChecked}
        onChange={handleClick}
      />
    </div>
  );
};

export default CheckBox;
