import * as Yup from "yup";
import {
  IMAGE_SIZE_LIMIT,
  SUPPORTED_IMAGE_FORMAT,
  SUPPORTED_SIGNATURE_FORMAT,
} from "../supportedImageTypes";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const schoolOnboardingSchema = Yup.object().shape({
  // countryCode: Yup.string(),
  phone: Yup.string()
    .matches(phoneRegExp, "Enter valid number")
    .matches(/^\d{11}$/, "Phone number must be exactly 11 digits")
    .required("Phone number is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  LGA: Yup.string().required("LGA is required"),
  postalCode: Yup.string().required("Postal code is required"),
  address: Yup.string().required("Address is required"),
  logoUrl: Yup.mixed()
    .required("Image is required")
    .test(
      "fileSize",
      "File too large",
      (value) => value && value.size <= IMAGE_SIZE_LIMIT
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => value && SUPPORTED_IMAGE_FORMAT.includes(value.type)
    ),
  currentTerm: Yup.string().required("Term is required"),
  currentYear: Yup.string().required("Year is required"),
  schoolRegistrationNumber: Yup.string().required(
    "Registration number is required"
  ),
  yearOfRegistration: Yup.string().required("Year is required"),
  schoolApprovalCertificateUrl: Yup.mixed()
    // .required("Image is required")
    .test(
      "fileSize",
      "File too large",
      (value) => !value || (value && value.size <= IMAGE_SIZE_LIMIT)
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) =>
        !value || (value && SUPPORTED_IMAGE_FORMAT.includes(value?.type))
    ),
  // firstName: Yup.string().required("First name is required"),
  // lastName: Yup.string().required("Last name is required"),
  fullName: Yup.string().required("Full name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  signature: Yup.mixed()
    .test(
      "fileSize",
      "File too large",
      (value) => !value || (value && value.size <= IMAGE_SIZE_LIMIT)
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) =>
        !value || (value && SUPPORTED_SIGNATURE_FORMAT.includes(value?.type))
    ),
  homeAddress: Yup.string().required("Address is required"),
  principalPhone: Yup.string()
    .matches(phoneRegExp, "Enter valid number")
    .matches(/^\d{11}$/, "Phone number must be exactly 11 digits")
    .required("Phone number is required"),
  // principalCountryCode: Yup.string(),
});
