import * as Yup from "yup";

const IMAGE_TARGET_SIZE = 12 * (1024 * 1024);

const SUPPORTED_IMAGE_FORMAT = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

export const bulkStudentUploadSchema = Yup.object().shape({
  studentsExcelSheet: Yup.mixed()
    .optional()
    .test("fileSize", "File is too large", (value) => {
      if (!value) {
        return true;
      }

      return value && value.size <= IMAGE_TARGET_SIZE;
    })
    .test("fileFormat", "Unsupported Format", (value) => {
      if (!value) {
        return true;
      }

      return value && SUPPORTED_IMAGE_FORMAT.includes(value.type);
    }),
});
