import { createContext, useContext, useEffect, useReducer } from "react";

import examinationReducer, { initialState } from "./examinationReducer";
import { examinationActions } from "./examinationActions";

import {
  deleteExamType,
  getAllExamTypes,
} from "../../features/school/services/schoolService";

import { showErrorToast, showSuccessToast } from "../../utils/toastHandler";
import { accountType } from "../../constants/accountType";
import { getTeacherAllExamTypes } from "../../features/teacher/services/teacherService";

import { useAuth } from "../authContext";


const ExaminationContext = createContext(initialState);

ExaminationContext.displayName = "ExaminationContext";

const ExaminationProvider = ({ children }) => {
  const { user } = useAuth();
  const userType = user?.userType;

  const [state, dispatch] = useReducer(examinationReducer, initialState);

  const _handleFetchExamTypes = async () => {
    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolExamTypes } = await getAllExamTypes(
          state.currentPage
        );
        return schoolExamTypes;

      case accountType.TEACHER:
        const { data: teacherExamTypes } = await getTeacherAllExamTypes(state.currentPage);
        return teacherExamTypes;
      default:
        throw new Error(`No case for type ${userType} found in examinationContext`);
    }
  };

  const fetchExamTypes = async () => {
    dispatch({
      type: examinationActions.UPDATE_LOADING_STATE,
      payload: { isLoading: true },
    });

    try {
      const response = await _handleFetchExamTypes();

      // const { data } = await getAllExamTypes(state.currentPage);

      if (response?.status === true) {
        const { data: examTypes, totalCount, pageCount } = response;
        const examinations = [...examTypes];

        dispatch({
          type: examinationActions.REQUEST_SUCCESS,
          payload: {
            examinations,
            totalCount,
            dataPerPage: pageCount,
            currentPageCount: examinations.length, // <- The current fetched page length
          },
        });
      } else {
        throw new Error(response?.message ?? "Something went wrong");
      }
    } catch (err) {
      //handle LOGS
    } finally {
      dispatch({
        type: examinationActions.UPDATE_LOADING_STATE,
        payload: { isLoading: false },
      });
    }
  };
  useEffect(() => {
    fetchExamTypes();
  }, [state.currentPage]);

  /// Function responsible for incrementing the current page count which will
  /// trigger a side-effect and cause new exam type data to be fetched
  const fetchNextData = () => {
    const newPageNumber = state.currentPage + 1;
    dispatch({
      type: examinationActions.GO_TO_NEXT_PAGE,
      payload: { currentPage: newPageNumber },
    });
  };

  /// Function responsible for decrementing the current page count which will
  /// trigger a side-effect and cause new exam type data to be fetched
  const fetchPreviousData = () => {
    const newPageNumber = state.currentPage - 1;
    dispatch({
      type: examinationActions.GO_TO_PREVIOUS_PAGE,
      payload: { currentPage: newPageNumber },
    });
  };

  /// Function responsible for deleting exam type
  const deleteExam = async (data) => {
    /// Caching the current state of the examinations, which will be used to
    /// reverse the deleted exam type in event of a failed response status from deleting
    const cachedExamTypes = [...state.examinations];

    const payload = {
      examTypeId: data.id,
    };

    /// This action is used to quickly updated the UI using the "DELETE_EXAM_TYPE"
    /// dispatch action
    const updatedExamTypes = state.examinations.filter(
      (exam) => exam.id !== data.id
    );

    dispatch({
      type: examinationActions.DELETE_EXAM_TYPE,
      payload: {
        examinations: updatedExamTypes,
        // totalCount: state.totalCount - 1,
        // currentPageCount: state.currentPageCount - 1,
      },
    });

    try {
      const { data } = await deleteExamType(payload);

      if (data.status === true) {
        showSuccessToast(data?.message ?? "Exam type successfully deleted");
      } else {
        throw new Error(data?.message ?? "Couldn't delete exam type");
      }
    } catch (err) {
      showErrorToast(err.message);

      /// Reversing delete action
      dispatch({
        type: examinationActions.REVERSE_DELETE_EXAM_TYPE,
        payload: {
          examinations: cachedExamTypes,
          // totalCount: state.totalCount + 1,
          // currentPageCount: state.currentPageCount + 1,
        },
      });
    }
  };

  /// This function is responsible for updating the exam types cached in the
  /// reducer after a successful update of an exam type has occurred
  const handleUpdateExamType = async (value) => {
    const cachedExaminationTypes = [...state.examinations];

    const indexOfExamType = cachedExaminationTypes.findIndex(
      (item) => item.id === value.id
    );

    if (indexOfExamType === -1) {
      return;
    }

    const updatedExamTypeData = { ...value };

    cachedExaminationTypes.splice(indexOfExamType, 1, updatedExamTypeData);

    dispatch({
      type: examinationActions.UPDATE_EXAM_TYPE,
      payload: { examinations: cachedExaminationTypes },
    });
  };

  const addNewExamTypes = async (newExamTypes) => {
    const compiledExamTypes = [...newExamTypes, ...state.examinations];

    dispatch({
      type: examinationActions.APPEND_NEW_EXAM_TYPES,
      payload: { examinations: compiledExamTypes },
    });
  };

  const value = {
    state,
    fetchNextData,
    fetchPreviousData,
    addNewExamTypes,
    handleUpdateExamType,
    deleteExam,
  };

  return (
    <ExaminationContext.Provider value={value}>
      {children}
    </ExaminationContext.Provider>
  );
};

const useExamination = () => {
  const context = useContext(ExaminationContext);

  if (context === undefined) {
    throw new Error("useExamination must be used within ExaminationContext");
  }

  return context;
};

export { ExaminationProvider, useExamination };
