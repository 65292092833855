import React from "react";
import { Eye } from "../../../../../shared/components/icons";
import { debugPrint } from "../../../../../utils/debugPrint";
import CheckBox from "../../../../../shared/components/ui/CheckBox";
import TickBox from "../../../../../shared/components/ui/TickBox";

const PayrollCard = ({
  data1,
  data2,
  data3,
  data4,
  data5,
  actionIcon,
  onActionIconClicked,
  showCheckBox = false,
  checked = false,
  onChange = () => {},
}) => {
  return (
    <tbody>
      <tr className="border-b border-b-[#EAEBF0]">
        {showCheckBox && (
          <td className="px-6 py-7 font-body text-sm font-medium text-[#5F6D7E]">
            <TickBox value={checked} updateValue={onChange} />
          </td>
        )}
        <td className="px-6 py-7 font-body text-sm font-medium text-[#5F6D7E]">
          {data1}
        </td>
        <td className="flex items-center whitespace-nowrap px-6 py-7 font-medium">
          {data2}
        </td>

        <td className="px-6 py-7 text-center font-body text-sm font-medium text-[#5F6D7E]">
          {data3}
        </td>

        <td className="px-6 py-7 text-center font-body text-sm font-medium text-[#5F6D7E]">
          {data4}
        </td>

        <td className="px-6 py-7 font-body text-sm font-medium text-[#272D37]">
          {data5}
        </td>

        {actionIcon && (
          <td
            className="relative flex items-center justify-center px-6 py-7 text-center font-medium"
            onClick={onActionIconClicked}
          >
            <span className="cursor-pointer">{actionIcon}</span>
          </td>
        )}
      </tr>
    </tbody>
  );
};

export default PayrollCard;
