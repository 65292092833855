import { useField } from "formik";
import DatePicker from "react-datepicker";
import { getCalendarYear } from "../../../utils/calendarFunctions";

/**
 * @typedef {Object} Props
 * @property {string} [label="Date of Birth"] - The label text for the DatePickerNew component.
 * @property {string} [labelClass="mb-2"] - Tailwind CSS class name(s) for styling the label.
 * @property {string} [height] - The height of the DatePicker component, styled using Tailwind CSS.
 * @property {'select' | 'scroll'} [dropdownMode="select"] - The dropdown mode for the DatePicker, either "select" or "scroll".
 * @property {Date} [minDate=getCalendarYear()] - The minimum year range of calendar
 * @property {Date} [maxDate=new Date()] - The maximum year range of calendar
 * @property {Object} [props] - Additional props to pass down to the DatePicker component.
 */

/**
 * @description - DatePicker UI component
 * @param {Props} props - The properties of the DatePickerNew component
 * @return {React.JSX.Element} - The rendered DatePickerNew component
 */
const DatePickerNew = ({
  label = "Date of Birth",
  labelClass = "mb-2",
  height,
  dropdownMode = "select", // can either be "scroll" or "select"
  minDate = getCalendarYear(),
  maxDate = new Date(),
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  return (
    <div className="z-50 flex w-full cursor-pointer flex-col px-[5px]">
      <label
        className={`text-[14px] font-semibold capitalize text-[#7D8592] ${labelClass}`}
      >
        {label}
      </label>
      <DatePicker
        className={`w-full rounded-2xl border border-[#D8E0F0] py-[12px] px-[14px] ${height}`}
        placeholderText="Select date"
        dateFormat="yyyy-MM-dd"
        closeOnScroll={true}
        // fixedHeight={true}
        peekNextMonth
        showYearDropdown
        showMonthDropdown
        dropdownMode={dropdownMode}
        yearDropdownItemNumber={50}
        scrollableMonthYearDropdown={true}
        selected={field.value != null ? Date.parse(field.value) : null}
        // selected={field.value}
        onChange={date => helpers.setValue(date)}
        minDate={minDate}
        maxDate={maxDate}
      />

      {/* {error && touched && (
        <span className="text-[14px] text-accent">{error}</span>
      )} */}
    </div>
  );
};

export default DatePickerNew;
