import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import Layout from "../shared/components/layouts/Layout";

import { accountType } from "../constants/accountType";
import { routesNotForStudent, routesNotForTeacher } from "../config/routes";

import { useAuth } from "../store/authContext";

const ProtectedRoute = () => {
  let location = useLocation();
  const navigate = useNavigate();

  const { user } = useAuth();

  const {
    userType,

    areSubjectsCreated,
    areSubjectsAssignedToClasses,
    areExamTypesCreated,
    // areResultCustomsCommentCreated,
  } = user || {};

  const navigatorOrchestrator = () => {
    if (userType === accountType.TEACHER) {
      if (routesNotForTeacher.includes(location.pathname)) {
        navigate("/");
      }
    }

    if (userType === accountType.STUDENT) {
      if (routesNotForStudent.includes(location.pathname)) {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (
      userType === accountType.SCHOOL &&
      (areSubjectsCreated === false ||
        areSubjectsAssignedToClasses === false ||
        areExamTypesCreated === false)
    ) {
      if (!areSubjectsCreated) {
        navigate("/academic/subjects", { replace: true });
        return;
      }

      if (!areSubjectsAssignedToClasses) {
        navigate("/academic/class ", { replace: true });
        return;
      }

      if (!areExamTypesCreated) {
        navigate("/assessments/exam-types", { replace: true });
        return;
      }
    }
    navigatorOrchestrator();
  }, [
    userType,
    areSubjectsCreated,
    areSubjectsAssignedToClasses,
    areExamTypesCreated,
    // areResultCustomsCommentCreated,

    location,
  ]);

  return user ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" replace={true} state={{ from: location }} />
  );
};

export default ProtectedRoute;
