import { Form, Formik } from "formik";

import Button from "../ui/Button";
import TextFieldFormik from "../TextFieldFormik";

import {
  areTwoObjectsEqual,
  removeWhiteSpacesFromObjValues,
} from "../../../utils/helperFunctions";
import { updateExamType } from "../../../features/school/services/schoolService";
import { showErrorToast, showSuccessToast } from "../../../utils/toastHandler";

import { updateExamTypeSchema } from "../../../utils/validationSchemas/updateExamTypeSchema";

import { useExamination } from "../../../store/examinationStore/examinationContext";

const UpdateExamTypeModal = ({ exam, onSuccess }) => {
  const { handleUpdateExamType } = useExamination();

  const { examType, totalScore } = exam;

  const initialValues = {
    examType,
    totalScore: totalScore.toString(),
  };

  /// Values before modification by Formik
  const originalValues = { ...initialValues };

  const formHandler = async (values, setSubmitting) => {
    setSubmitting(true);

    const trimmedValues = removeWhiteSpacesFromObjValues(values);

    const payload = {
      examTypeId: exam?.id,
      examType: trimmedValues.examType,
      totalScore: +trimmedValues.totalScore,
    };

    try {
      const { data } = await updateExamType(payload);

      if (data?.status === true) {
        // TODO: Close modal here
        showSuccessToast(data?.message ?? "Updated successfully");
        const updatedExamType = {
          ...exam,
          examType: trimmedValues.examType,
          totalScore: +trimmedValues.totalScore,
        };

        /// Call to to examinationContext
        handleUpdateExamType(updatedExamType);

        /// Close modal
        onSuccess();
      } else {
        throw new Error(data?.message ?? "Something went wrong");
      }
    } catch (err) {
      showErrorToast(err?.message ?? "Something went wrong");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={updateExamTypeSchema}
      onSubmit={(values, { setSubmitting }) => {
        formHandler(values, setSubmitting);
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        isValid,
        isSubmitting,
        touched,
      }) => (
        <Form
          onSubmit={handleSubmit}
          className="mt-[16px] flex w-[480px] flex-col"
          autoComplete="off"
        >
          <div className="mb-[64px] space-y-[20px]">
            <TextFieldFormik name="examType" label="Exam Type" />
            <TextFieldFormik name="totalScore" label="Total Score" />
          </div>

          <Button
            type="submit"
            height="h-[48px]"
            width="w-auto"
            padding="py-[8px]"
            onClick={handleSubmit}
            isLoading={isSubmitting}
            disabled={areTwoObjectsEqual(values, originalValues)}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateExamTypeModal;
