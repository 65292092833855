import { promotionActions } from "./promotionActions";

export const initialState = {
  currentPage: 1,
  isInitial: true,
  isLoading: false,
  isPromoting: false,
  isPublishing: false,
  error: null,
  publishedErrorMessages: [],
  allStudents: [], // This will hold all students data from API call
  paginatedStudentData: [], // This will hold paginated students data
  checkedStudents: [], // This will hold selected students from the loaded data
  cachedFilterParams: {
    currentSession: "",
    currentTerm: "",
    promotingFrom: "",
  },
};

const promotionReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case promotionActions.UPDATE_LOADING_STATE:
      return {
        ...state,
        isLoading: payload.isLoading,
      };

    case promotionActions.INITIAL_DEFAULT_LOAD:
      return {
        ...state,
        isLoading: payload.isLoading,
        isInitial: payload.isInitial, // FIXME: This should be set by default
        error: null,
        checkedStudents: [],
      };

    case promotionActions.CACHE_FILTER_PARAMS:
      return {
        ...state,
        cachedFilterParams: payload.cachedFilterParams,
      };

    case promotionActions.REQUEST_SUCCESS:
      return { ...state, allStudents: payload.allStudents, currentPage: 1 };

    case promotionActions.ADD_ERROR:
      return { ...state, error: payload.error };

    case promotionActions.UPDATE_CHECKED_STUDENTS:
      return { ...state, checkedStudents: payload.checkedStudents };

    case promotionActions.DELETE_STUDENTS_FROM_ALL_STUDENTS:
      return {
        ...state,
        allStudents: payload.allStudents,
        checkedStudents: payload.checkedStudents, // This will become an empty array []
      };

    case promotionActions.UPDATE_IS_PROMOTING_STATE:
      return { ...state, isPromoting: payload.isPromoting };

    case promotionActions.UPDATE_IS_PUBLISHING_STATE:
      return { ...state, isPublishing: payload.isPublishing };

    case promotionActions.UPDATE_PUBLISHED_ERRORS:
      return {
        ...state,
        publishedErrorMessages: payload.publishedErrorMessages,
      };

    case promotionActions.CLEAR_PUBLISHED_ERRORS:
      return {
        ...state,
        publishedErrorMessages: [], // Resetting the error back to an empty array
      };

    case promotionActions.UPDATE_PAGINATED_DATA:
      return {
        ...state,
        paginatedStudentData: payload.paginatedStudentData,
      };

    case promotionActions.INCREMENT_PAGE_COUNT:
      return {
        ...state,
        currentPage: state.currentPage + 1,
        checkedStudents: [],
      };
    case promotionActions.DECREMENT_PAGE_COUNT:
      return {
        ...state,
        currentPage: state.currentPage - 1,
        checkedStudents: [],
      };

    default:
      throw new Error(`No case for type ${type} found in promotionReducer`);
  }
};

export default promotionReducer;
