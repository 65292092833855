import { createContext, useContext, useEffect, useReducer } from "react";

import teacherReducer, { initialState } from "./teacherReducer";
import { teacherActions } from "./teacherActions";

import {
  deleteTeacher,
  getFilteredTeachers,
} from "../../features/school/services/schoolService";
import { getTeacherTeachers } from "../../features/teacher/services/teacherService";
import { getStudentTeachers } from "../../features/student/services/studentService";

import { accountType } from "../../constants/accountType";

import { showErrorToast, showSuccessToast } from "../../utils/toastHandler";

import { useAuth } from "../authContext";

const TeacherContext = createContext(initialState);

TeacherContext.displayName = "TeacherContext";

const TeacherProvider = ({ children }) => {
  const { user } = useAuth();

  const [state, dispatch] = useReducer(teacherReducer, initialState);

  /// This function uses the [user] object from [useAuth] to fetch teacher data
  /// based on the user type [school, teacher]
  const _handleTeacherDataFetch = async () => {
    const { currentPage, cachedClassFilter, cachedSubjectFilter } = state;

    switch (user?.userType) {
      case accountType.SCHOOL:
        const { data: schoolTeacherData } = await getFilteredTeachers(
          currentPage,
          cachedClassFilter,
          cachedSubjectFilter
        );

        return schoolTeacherData;

      case accountType.TEACHER:
        const { data: teacherTeachersData } = await getTeacherTeachers(
          currentPage,
          cachedClassFilter,
          cachedSubjectFilter
        );
        return teacherTeachersData;

      case accountType.STUDENT:
        const { data: studentTeachersData } = await getStudentTeachers(
          currentPage,
          cachedClassFilter,
          cachedSubjectFilter
        );
        return studentTeachersData;


      default:
        throw new Error("Something went wrong. Please try again later");
    }
  };

  useEffect(() => {
    const fetchTeachersData = async () => {
      dispatch({
        type: teacherActions.INITIALIZE_DEFAULT_LOAD,
        payload: { isLoading: true, isInitial: false },
      });

      try {
        const data = await _handleTeacherDataFetch();

        const { status, message, dataPerPage, totalFilteredCount } = data;

        if (status === true) {
          const { data: innerData } = data;

          dispatch({
            type: teacherActions.REQUEST_SUCCESS,
            payload: {
              allTeachers: [...innerData],
              currentPageCount: innerData?.length ?? 1,
              dataPerPage: dataPerPage,
              totalCount: totalFilteredCount,
            },
          });
        } else {
          throw new Error(message ?? "Something went wrong");
        }
      } catch (err) {
        showErrorToast(err?.message ?? "Something went wrong");
        dispatch({
          type: teacherActions.ADD_ERROR,
          payload: { error: err?.message ?? "Something went wrong" },
        });
      } finally {
        dispatch({
          type: teacherActions.UPDATE_LOADING_STATE,
          payload: { isLoading: false },
        });
      }
    };

    fetchTeachersData();
  }, [state.currentPage]);

  const handleFilterUpdate = (filterParams) => {
    const { subjectFilter, classFilter } = filterParams;

    dispatch({
      type: teacherActions.CACHE_FILTER_PARAMS,
      payload: {
        currentPage: 1,
        cachedSubjectFilter: subjectFilter,
        cachedClassFilter: classFilter,
      },
    });
  };

  const handleTeacherEdit = (teacherAuthId, updatedData) => {
    let previousData = [...state.allTeachers];

    let indexOfTeacher;

    let found = previousData.find((data, index) => {
      indexOfTeacher = index;
      return data["teacherAuthId"] === teacherAuthId;
    });

    found = { ...found, ...updatedData };

    previousData[indexOfTeacher] = found;

    dispatch({
      type: teacherActions.UPDATE_TEACHER_DETAIL,
      payload: { allTeachers: previousData },
    });
  };

  /// Functions that handles deletion of teacher. The function first performs an
  /// optimistic deletion of teacher's data to quickly update the UI, and
  /// rolls-back the deletion action if an error occurs
  const handleDeleteTeacher = async (teacherAuthId) => {
    const cachedTeachersData = [...state.allTeachers];

    const indexOfTeacher = cachedTeachersData.findIndex(
      (value) => value.teacherAuthId === teacherAuthId
    );

    if (indexOfTeacher === -1) {
      showErrorToast("Error deleting teacher");
      return;
    }

    const removedData = cachedTeachersData[indexOfTeacher];

    cachedTeachersData.splice(indexOfTeacher, 1);

    dispatch({
      type: teacherActions.UPDATE_TEACHER_DETAIL,
      payload: { allTeachers: [...cachedTeachersData] },
    });

    try {
      const { data } = await deleteTeacher(teacherAuthId);

      if (data?.status === true) {
        showSuccessToast(data?.message ?? "Success");
      } else {
        throw new Error(
          data?.message ?? "Couldn't delete teacher at this time"
        );
      }
    } catch (e) {
      showErrorToast(e?.message ?? "Something went wrong");

      cachedTeachersData.splice(indexOfTeacher, 0, removedData);

      dispatch({
        type: teacherActions.UPDATE_TEACHER_DETAIL,
        payload: { allTeachers: [...cachedTeachersData] },
      });
    }
  };

  const fetchNextData = () => {
    dispatch({ type: teacherActions.INCREMENT_PAGE_COUNT });
  };

  const fetchPreviousData = () => {
    dispatch({ type: teacherActions.DECREMENT_PAGE_COUNT });
  };

  const values = {
    state,
    handleFilterUpdate,
    handleTeacherEdit,
    handleDeleteTeacher,
    fetchNextData,
    fetchPreviousData,
  };

  return (
    <TeacherContext.Provider value={values}>{children}</TeacherContext.Provider>
  );
};

const useTeacher = () => {
  const context = useContext(TeacherContext);

  if (context === undefined) {
    throw new Error("useStudent must be used within StudentContext");
  }

  return context;
};

export { TeacherProvider, useTeacher };
