import { studentDashboardActions } from './studentDashboardActions';

export const initialState = {
  isLoading: false,
  firstName: "",
  subjects: 0,
  classes: 0,
  // library: 20,
  // assignment: 20,
  error: null,
};

const studentDashboardReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case studentDashboardActions.UPDATE_LOADING_STATE:
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    case studentDashboardActions.REQUEST_SUCCESS:
      return {
        ...state,
        firstName: payload.firstName,
        subjects: payload.subjects,
        classes: payload.classes,
      };
    case studentDashboardActions.REQUEST_FAILURE:
      return {
        ...state,
        error: payload.error,
      };
    default:
      throw new Error(
        `No case for type ${type} found in studentDashboardReducer`
      );
  }
};

export default studentDashboardReducer;
