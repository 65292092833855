/**
 *
 * @description This function converts string(s) to word casing
 *
 * @param {value} str - String to be converted
 * @returns {string} return string in word-casing e.g. flower man - Flower Man
 */
export const toWordCase = value => {
  if (value?.length === 0) {
    return value;
  }
  return value
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");
};

/**
 *
 * @description This function converts a string to camelCasing
 *
 * @param {string} str - String to be converted
 * @returns {string} return string in camel-casing
 */
export const toCamelCase = str => {
  return str
    .toLowerCase()
    .trim()
    .split(/\s+/)
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("");
};
