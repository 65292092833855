import { useEffect, useState } from "react";

import Button from "../ui/Button";
import NormalSelect from "../ui/NormalSelect";
import NormalTextInput from "../ui/NormalTextInput";

import {
  areTwoObjectsEqual,
  removeWhiteSpacesFromObjValues,
} from "../../../utils/helperFunctions";

import { useGrades } from "../../../store/resultCommentsStore/resultCommentsContext";

// TODO: This component needs to use Formik to have adequate validation
// FIXME: Use Formik && Yup to handle validation

export default function AddGrade({
  closeModal,
  showHeading = true,
  gradeDataToEdit = null,
}) {
  const { state, handleUpdateSingleGrade, handleAddSingleGrade } = useGrades();

  const { isAddingGrade, isEditingGrade } = state;

  const [input, setInput] = useState(
    gradeDataToEdit !== null
      ? {
          ...gradeDataToEdit,
        }
      : {
          comment: "",
          commentType: "",
          grade: "",
          gradePoint: "",
          maxAverage: "",
          minAverage: "",
        }
  );

  const testingOne = areTwoObjectsEqual(gradeDataToEdit, input);

  const checkForObjectEquality = () => {
    if (gradeDataToEdit !== null) {
      return areTwoObjectsEqual(gradeDataToEdit, input);
    }

    return false;
  };

  const [minAverageError, setMinAverageError] = useState(false);
  const [maxAverageError, setMaxAverageError] = useState(false);

  const onChangeText = (field, value) => {
    setInput({ ...input, [field]: value });
  };

  useEffect(() => {
    if (input.minAverage && input.maxAverage) {
      if (+input.maxAverage < +input.minAverage) {
        setMinAverageError(false);
        setMaxAverageError(true);
      } else {
        setMaxAverageError(false);
      }
    } else {
      setMaxAverageError(false);
    }
  }, [input.maxAverage]);

  useEffect(() => {
    if (input.minAverage && input.maxAverage) {
      if (+input.minAverage > +input.maxAverage) {
        setMinAverageError(true);
        setMaxAverageError(false);
      } else {
        setMinAverageError(false);
      }
    } else {
      setMinAverageError(false);
    }
  }, [input.minAverage]);

  const handleSubmit = async () => {
    try {
      if (gradeDataToEdit !== null) {
        await handleUpdateSingleGrade(input);
      } else {
        await handleAddSingleGrade(input);
      }

      closeModal();
    } catch (e) {}
  };

  const shouldDisable =
    input.comment === "" ||
    input.grade === "" ||
    input.gradePoint === "" ||
    input.maxAverage === ""
      ? true
      : false;

  return (
    <div className="h-auto w-[400px] px-[10px]">
      <form id="form">
        {showHeading && (
          <div className="mb-[30px] flex flex-row items-center justify-between ">
            <h3 className="mt-[8px] text-[22px] font-semibold leading-[33px]">
              Add Grade
            </h3>
            <span
              onClick={closeModal}
              className="flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-[14px] bg-[#F4F9FD] p-[14px] text-2xl text-black"
            >
              ×
            </span>
          </div>
        )}
        <div
          className={`mb-[30px] flex flex-row justify-between space-x-[16px]`}
        >
          <NormalSelect
            label={"Grade"}
            className="flex-1"
            options={["A", "B", "C", "D", "F"]}
            value={input?.grade}
            handleSelect={({ value }) => onChangeText("grade", value)}
          />
          <NormalSelect
            label={"Grade Point"}
            className="flex-1"
            options={[1, 2, 3, 4, 5]}
            value={input?.gradePoint}
            handleSelect={({ value }) => onChangeText("gradePoint", value)}
          />
        </div>
        <div
          className={`mb-[30px] flex flex-row justify-between space-x-[16px]`}
        >
          <NormalTextInput
            label={"Mark From"}
            className="flex-1"
            value={input?.minAverage}
            errorMessage={`${
              minAverageError
                ? `Should be equal to or less than ${input?.maxAverage}`
                : ""
            }`}
            onChange={(value) => onChangeText("minAverage", value)}
          />

          <NormalTextInput
            label={"Mark To"}
            className="flex-1"
            value={input?.maxAverage}
            errorMessage={`${
              maxAverageError
                ? `Should be equal to or greater than ${input?.minAverage}`
                : ""
            }`}
            onChange={(value) => onChangeText("maxAverage", value)}
          />
        </div>
        <div
          className={`mb-[30px] flex flex-row justify-between space-x-[16px]`}
        >
          <NormalTextInput
            label={"Comments"}
            className="flex-1"
            value={input?.comment}
            onChange={(value) => onChangeText("comment", value)}
          />
        </div>
        <div className="my-[30px] flex w-full justify-end ">
          <Button
            type="button"
            extraClasses="self-end"
            disabled={shouldDisable || minAverageError ? true : false}
            isLoading={isAddingGrade || isEditingGrade}
            onClick={handleSubmit}
          >
            {gradeDataToEdit !== null ? "Update" : "Add"}
          </Button>
        </div>
      </form>
    </div>
  );
}
