import { Route, Routes } from "react-router-dom";

import ResultsDashboard from "./ResultsDashboard";
import ResultTerminalReportSheet from "./ResultTerminalReportSheet";

import { ResultsProvider } from "../../../../store/resultsStore/resultsContext";

const Results = () => {
  return (
    <ResultsProvider>
      <Routes >
        <Route index element={<ResultsDashboard />} />
        <Route path=":id" element={<ResultTerminalReportSheet />} />
      </Routes>
    </ResultsProvider>
  );
};

export default Results;
