const LoadingSpinner = ({
  width = "w-6",
  height = "h-6",
  spinnerColor = "border-white",
}) => {
  return (
    <svg
      className={`${width} ${height} ${spinnerColor} mr-3 animate-spin rounded-full border-l-4`}
      viewBox="0 0 24 24"
    ></svg>
  );
};

export default LoadingSpinner;
