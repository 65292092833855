import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import imag from "../../assets/images/logo.png";
import { sendPasswordResendCode } from "../../services/authService";

import { PointingRightArrow } from "../components/icons";

import TextFieldFormik from "../components/TextFieldFormik";
import Button from "../components/ui/Button";

import { forgotPasswordSchema } from "../../utils/validationSchemas/forgotPasswordSchema";
import { removeWhiteSpacesFromObjValues } from "../../utils/helperFunctions";
import { showErrorToast, showSuccessToast } from "../../utils/toastHandler";
import RegistrationFormLayout from "../components/layouts/RegistrationFormLayout";

const ForgotPassword = () => {
  let navigate = useNavigate();

  const initialValue = {
    email: "",
  };

  const formHandler = async email => {
    email = removeWhiteSpacesFromObjValues(email);

    try {
      const response = await sendPasswordResendCode(email);

      const { data } = response;

      if (data.status === true) {
        showSuccessToast(data.message);
        navigate("/verify-sent-code", {
          replace: true,
          state: {
            email: email?.email,
          },
        });
      } else {
        throw new Error("Please enter a valid email");
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  return (
    <RegistrationFormLayout>
      <div className="flex h-screen w-full flex-col items-center justify-center bg-white">
        <div className="mb-[74px] flex items-center justify-center text-center">
          <img
            src={imag}
            alt="image for login reset and verify pages"
            className="h-[34.11px] w-[144.8px]"
          />
        </div>
        <h1 className="mb-[18px] text-center text-[24px] font-bold leading-[29.05px] text-[#0A1629]">
          Reset
        </h1>

        <p className="mb-4 text-[14px] font-normal leading-[20px] text-[#8692A6] lg:text-justify self-start">
          Enter Email Address to recover your password
        </p>
        <Formik
          initialValues={initialValue}
          validationSchema={forgotPasswordSchema}
          onSubmit={formHandler}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col justify-center lg:w-[403px]">
              <TextFieldFormik
                extraClasses="mt-[2]"
                label="Username/Email"
                name="email"
                placeholder="Enter username/email"
              />

              <Button
                type="submit"
                extraClasses="mt-5 self-center"
                isLoading={isSubmitting}
              >
                <div className="flex items-center justify-center space-x-[2px]">
                  <span className="text-[16px] font-bold leading-[19.36px]">
                    Proceed
                  </span>
                  {/* <PointingRightArrow color="bg-white" /> */}
                </div>
              </Button>
            </Form>
          )}
        </Formik>
        <p className="mt-4 text-center text-[14px] font-normal leading-[16.94px] text-[#4F4F4F]">
          Don’t have an account{" "}
          <span className="text-center text-[14px] font-semibold leading-[16.94px] text-[#2969FF]">
            <Link to="/register">Create account</Link>
          </span>
        </p>
      </div>
    </RegistrationFormLayout>
  );
};

export default ForgotPassword;
