import SelectFormik from "../../../shared/components/ui/SelectFormik";
import TextFieldFormik from "../../../shared/components/TextFieldFormik";
import Button from "../../../shared/components/ui/Button";
import InputFile from "../../components/ui/InputFile";
// import { PointingRightArrow } from "../../../shared/components/icons";

const SchoolSuperAdmin = ({
  values,
  errors,
  touched,
  setTouched,
  setFieldValue,
  isSubmitting,
  onSubmit,
}) => {
  const shouldDisableButton = () => {
    if (
      // errors.firstName ||
      // values.firstName === "" ||
      // errors.lastName ||
      // values.lastName === "" ||
      errors.fullName ||
      values.fullName === "" ||
      errors.email ||
      values.email === "" ||
      errors.homeAddress ||
      values.homeAddress === "" ||
      errors.principalPhone ||
      values.principalPhone === ""
    )
      return true;

    return false;
  };

  return (
    <div className='inline-flex h-full w-[403px] flex-col items-center justify-between'>
      <div className='mb-[34px] flex flex-col items-center gap-[18px]'>
        <h2 className='text-center font-display text-sm uppercase text-[#3F8CFF] '>
          Step 3/3
        </h2>

        <h1 className='text-center font-body text-[24px] font-bold leading-normal text-[#0A1629]'>
          Enter Head of school info
        </h1>
      </div>

      <div className='flex w-full flex-col gap-[14px]'>
        <TextFieldFormik
          extraClasses=''
          label='Full Name'
          name='fullName'
          placeholder='James Doe'
        />

        <TextFieldFormik
          extraClasses=''
          label='Email Address'
          name='email'
          placeholder='youremail@gmail.com'
        />

        {/* Add signature field here */}
        {/* <InputFile
          name="signature"
          label="Signature"
          fileName={values.signature?.name}
          error={errors.signature}
          touched={touched.signature}
          onChange={(event) => {
            setTouched({ signature: true }, false);
            setFieldValue("signature", event.target.files[0]);
          }}
        /> */}

        <TextFieldFormik
          // style={{ height: "70px" }}
          // extraClasses="h-[70px]"
          height='h-[70px]'
          label='Home address'
          name='homeAddress'
          placeholder='Owerri, Imo'
        />

        <TextFieldFormik
          extraClasses=''
          label='Phone Number'
          name='principalPhone'
          placeholder='Enter number'
        />
      </div>

      <Button
        onClick={onSubmit}
        extraClasses='self-center mt-[20px]'
        isLoading={isSubmitting}
        disabled={shouldDisableButton()}
        // type="submit"
      >
        <div className='flex items-center justify-center'>
          <span className='font-body text-base font-bold'>Save</span>
        </div>
      </Button>
    </div>
  );
};

export default SchoolSuperAdmin;
