import { Form, Formik } from "formik";

import TextFieldFormik from "../components/TextFieldFormik";
import TextAreaInputFormik from "../components/ui/TextAreaInputFormik";
import SvgSupport from "../components/icons/Support";
import { ForwardButton } from "../components/ui/Buttons";

import { supportInitialValues } from "../../utils/initialValues/supportInitialValues";
import { supportSchema } from "../../utils/validationSchemas/supportSchema";
import { removeWhiteSpacesFromObjValues } from "../../utils/helperFunctions";
import { showErrorToast, showSuccessToast } from "../../utils/toastHandler";

import { postSupportRequestSchool } from "../../features/school/services/schoolService";

const Support = () => {
  const formHandler = async (value, { resetForm }) => {
    const { email: customerEmail, message } = value;

    let payload = {
      customerEmail,
      message,
    };

    payload = removeWhiteSpacesFromObjValues(payload);

    try {
      const { data } = await postSupportRequestSchool(payload);

      if (data?.status === false) {
        throw new Error(data?.message ?? "Fail to send message");
      }

      resetForm();
      showSuccessToast(data?.message ?? "Success");
    } catch (error) {
      showErrorToast(error?.message ?? "Something went wrong. Try again");
    }
  };

  return (
    <div className="flex h-screen w-full flex-col rounded-[22px] bg-[#FAFAFC] lg:p-[50px]">
      <div className="mb-[24px] flex items-center justify-between">
        <h2 className="text-[36px] font-semibold leading-[54px] text-black">
          Support
        </h2>
      </div>

      <div className="lg:mx-[64px] grid h-full grid-cols-12 content-center justify-items-center gap-x-[80px]">
        {/* Support logo */}
        <div className="col-span-6 w-full self-center hidden md:block">
          <SvgSupport className="text-[320px]" />
        </div>

        {/* Support form */}
        <div className="col-span-6 h-full w-full">
          <div className="flex flex-col">
            <h1 className="mb-[40px] text-[36px] font-bold">Talk to us</h1>

            <Formik
              initialValues={supportInitialValues}
              validationSchema={supportSchema}
              onSubmit={formHandler}
            >
              {({ handleChange, isSubmitting }) => (
                <Form>
                  <div className="mb-[72px] flex flex-col space-y-[28px]">
                    <TextFieldFormik
                      label="Email"
                      name="email"
                      placeholder="youremail@gmail.com"
                    />

                    <TextAreaInputFormik
                      label="Message"
                      name="message"
                      placeholder="Type a message"
                      row="7"
                    />

                    <ForwardButton
                      type="submit"
                      label="Send"
                      extraClasses="self-center"
                      isLoading={isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>

            <p className="mb-[20px] self-center text-[14px] text-[#7D8592]">
              Or contact support @bleautech.org
            </p>
            <p className="self-center text-[14px] text-[#7D8592]">
              Call: 080123456789
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
