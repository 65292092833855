import * as Yup from "yup";
import { phoneRegExp } from "../phoneRegExp";

/// Create school account validation schema
export const addStudentSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  middleName: Yup.string(),
  lastName: Yup.string().required("Last name is required"),
  studentEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Enter valid number")
    .required("Phone number is required"),
  currentClass: Yup.string().required("Class is needed"),
  countryCode: Yup.string().required("Country code is needed"),
  subClass: Yup.string().required("Subclass class is needed"),
  currentYear: Yup.string().required("Current year is needed"),
  currentTerm: Yup.string().required("Current term is needed"),
  gender: Yup.string().required("Gender is needed"),
});
