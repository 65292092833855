import { Navigate } from "react-router-dom";

import { useAuth } from "../store/authContext";

const HomeRoute = () => {
  const { user } = useAuth();

  return user ? (
    <Navigate to="dashboard" replace={true} />
  ) : (
    <Navigate to="login" replace={true} />
  );
};

export default HomeRoute;
