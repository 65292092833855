import * as React from "react";

const SvgClasses = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 35 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#classes_svg__a)" fill="#DE6944">
      <path d="M31.924.534H3.076A3.08 3.08 0 0 0 0 3.611v23.242a3.08 3.08 0 0 0 3.076 3.076h1.367v2.46a3.08 3.08 0 0 0 3.077 3.077h19.96a3.08 3.08 0 0 0 3.077-3.076v-2.461h1.367A3.08 3.08 0 0 0 35 26.853V3.61A3.08 3.08 0 0 0 31.924.534ZM3.076 2.585h28.848c.565 0 1.025.46 1.025 1.026v5.673H2.051V3.611c0-.566.46-1.026 1.025-1.026Zm14.038 13.366a1.02 1.02 0 0 1 .777 0l9.978 4.083c.387.159.637.531.637.95v.332H6.494v-.332c0-.419.25-.791.637-.95l9.983-4.082Zm7.427 11.927v-4.512h3.965v4.512H24.54Zm-6.016 0v-4.512h3.965v4.512h-3.965Zm-6.015 0v-4.512h3.965v4.512H12.51Zm-2.051-4.512v4.512H6.494v-4.512h3.965Zm18.047 9.024c0 .565-.46 1.025-1.026 1.025H7.52c-.566 0-1.026-.46-1.026-1.025v-2.461h22.012v2.46Zm3.418-4.512h-1.367v-6.895c0-1.254-.75-2.372-1.912-2.847l-9.978-4.082a3.058 3.058 0 0 0-2.33 0l-9.982 4.082a3.065 3.065 0 0 0-1.912 2.848v6.894H3.076c-.565 0-1.025-.46-1.025-1.025V11.335h30.898v15.518c0 .565-.46 1.025-1.025 1.025Z" />
      <path d="M5.472 6.96a1.025 1.025 0 1 0 0-2.05 1.025 1.025 0 0 0 0 2.05ZM9.847 6.96a1.025 1.025 0 1 0 0-2.051 1.025 1.025 0 0 0 0 2.05ZM14.222 6.96a1.025 1.025 0 1 0 0-2.05 1.025 1.025 0 0 0 0 2.05Z" />
    </g>
    <defs>
      <clipPath id="classes_svg__a">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0h35v35H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgClasses;
