import { useState } from "react";
import { ErrorMessage, useField } from "formik";

import { Eye, EyeOff } from "./icons";

const TextFieldFormik = ({
  label,
  labelFieldSpacing = "space-y-[2px]",
  placeholderStyle = "placeholder-[#7D8592] placeholder-opacity-  text-[14px] leading-[24px] text-[14px]",
  type = "text",
  autoComplete = "off",
  width = "w-full",
  height = "",
  onChange,
  extraClasses,
  inputClasses,
  showError = true,
  disabled = false,
  ...props
}) => {
  const [field, meta] = useField(props);

  const [passwordShown, setPasswordShown] = useState(false);

  const toggleObscureStatus = () => {
    setPasswordShown(prevState => !prevState);
  };

  return (
    <div
      className={`flex w-full flex-col justify-start ${extraClasses} ${labelFieldSpacing}`}
    >
      {label && (
        <label
          className='font-body text-[16px] font-semibold leading-[24px] text-[#7D8592]'
          htmlFor={field.name}
        >
          {label}
        </label>
      )}
      <div className='relative block'>
        <input
          disabled={disabled}
          className={`${
            type === "password" ? "pr-10" : "pr-4"
          } rounded-[8px] border border-[#D8E0F0] py-2 pl-4 focus:border-blue focus:ring-blue ${width} ${height} ${inputClasses} ${placeholderStyle}`}
          type={passwordShown ? "text" : type}
          autoComplete={autoComplete}
          onChange={onChange}
          {...field}
          {...props}
        />
        {type === "password" ? (
          <span
            className='absolute inset-y-0 right-0 mr-[8px] flex items-center pr-[4px]'
            onClick={() => {
              toggleObscureStatus();
            }}
          >
            {passwordShown ? <EyeOff /> : <Eye />}
          </span>
        ) : null}
      </div>
      {showError && (
        <ErrorMessage
          name={field.name}
          component='span'
          className='text-[14px] text-accent'
        />
      )}
    </div>
  );
};

export default TextFieldFormik;
