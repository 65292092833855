import { createContext, useContext, useReducer } from "react";

import { marksActions } from "./marksActions";
import marksReducer, { initialState } from "./marksReducer";

import { getSchoolStudentsResults } from "../../features/school/services/schoolService";
import { useAuth } from "../authContext";
import { accountType } from "../../constants/accountType";
import { getTeachersStudentResult } from "../../features/teacher/services/teacherService";
import { showErrorToast, showInfoToast } from "../../utils/toastHandler";

import { getStudentsStudentResult } from "../../features/student/services/studentService";
import { debugPrint } from "../../utils/debugPrint";

const MarksContext = createContext(null);

MarksContext.displayName = "MarksContext";

const MarksProvider = ({ children }) => {
  const { user } = useAuth();

  const { userType } = user || {};

  const [state, dispatch] = useReducer(marksReducer, initialState);

  const _handleMarksDataFetch = async (
    currentPage,
    classFilter,
    termFilter,
    yearFilter
  ) => {
    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolStudentMarks } = await getSchoolStudentsResults(
          currentPage,
          termFilter,
          classFilter,
          yearFilter
        );
        return schoolStudentMarks;
      case accountType.TEACHER:
        const { data: teacherStudentMarks } = await getTeachersStudentResult(
          currentPage,
          classFilter,
          termFilter,
          yearFilter
        );
        return teacherStudentMarks;
      case accountType.STUDENT:
        const { data: studentsStudentResult } = await getStudentsStudentResult(
          classFilter,
          termFilter,
          yearFilter
        );
        return studentsStudentResult;

      default:
        break;
    }
  };

  /// This function is responsible for fetching the first set of students' marks
  /// data.
  ///
  /// This function takes an Object variable as an argument which should contain
  /// "termFilter", "classFilter", "subClassFilter", and "yearFilter"
  ///
  /// One key thing this function does is to change the "state.isInitial"
  /// property from "false" to "true" which indicates that a request to get marks
  /// data has been made for the first time or a fresh request is being made using
  /// previous or new filter params
  const fetchInitialData = async (filterParams) => {
    dispatch({
      type: marksActions.INITIALIZE_DEFAULT_LOAD,
      payload: { isLoading: true, isInitial: false, currentPage: 1 },
    });

    const { termFilter, classFilter, subClassFilter, yearFilter } =
      filterParams;

    /// Caching the filter params data to be used when fetching paginated data
    dispatch({
      type: marksActions.CACHE_FILTER_PARAMS,
      payload: {
        cachedTermFilter: termFilter,
        cachedClassFilter: classFilter,
        cachedSubClassFilter: subClassFilter,
        cachedYearFilter: yearFilter,
      },
    });

    try {
      const responseData = await _handleMarksDataFetch(
        1,
        classFilter,
        termFilter,
        yearFilter
      );

      const { data, status, message } = responseData;

      if (status === true && data == null) {
        showInfoToast(message ?? "No result data found");
        return;
      }

      if (status === true) {
        /// When fetching student marks for a "school" or "teacher" account the
        /// data is returned as an array. But for a "student" account, the data
        /// is returned as an object.
        ///
        /// For the data to conform to the UI structure in place, for "student",
        /// the data is pushed into an array
        let studentResultData = [];

        const isDataAnArray = Array.isArray(data);

        if (isDataAnArray) {
          // "school" and "teacher" account types
          studentResultData = [...data];
        } else {
          // "student" account type
          studentResultData.push(data);
        }

        dispatch({
          type: marksActions.REQUEST_SUCCESS,
          payload: { allStudents: [...studentResultData], message: message },
        });
      } else {
        throw new Error(message ?? "Something went wrong");
      }
    } catch (err) {
      showErrorToast(err?.message ?? "Something went wrong. Try again");
      dispatch({
        type: marksActions.ADD_ERROR,
        payload: { error: err.message },
      });
    } finally {
      dispatch({
        type: marksActions.UPDATE_LOADING_STATE,
        payload: { isLoading: false },
      });
    }
  };

  const fetchPaginatedData = async (page) => {
    dispatch({
      type: marksActions.UPDATE_LOADING_STATE,
      payload: { isLoading: true },
    });

    const {
      currentPageCount,
      cachedTermFilter,
      cachedClassFilter,
      cachedYearFilter,
    } = state;

    const newPageNumber = currentPageCount + 1;

    try {
      const response = await getSchoolStudentsResults(
        newPageNumber,
        cachedTermFilter,
        cachedClassFilter,
        cachedYearFilter
      );

      if (response.status === true) {
        const { data } = response.data;
        const { data: innerData } = data;

        dispatch({
          type: marksActions.REQUEST_SUCCESS,
          payload: { allStudents: [...innerData] },
        });
      } else {
        throw new Error(response?.message ?? "Error getting data");
      }
    } catch (error) {
      //handle LOGS

      dispatch({
        type: marksActions.ADD_ERROR,
        payload: { error: error.message },
      });
    } finally {
      dispatch({
        type: marksActions.UPDATE_LOADING_STATE,
        payload: { isLoading: false },
      });
    }
  };

  /// This function is responsible for updating students result on the
  /// "Assessment" dashboard using the argument passed in.
  ///
  /// NOTE => The function is called from "AddScore" component after successfully
  /// uploading updated students result
  const handleBulkResultUpload = (resultData) => {
    const successfullyUpdatedResults = [];

    /// Checking to see if the returned resultData has a "status" of "true" to
    /// indicate that the result for that particular student was updated
    /// successfully then pushing that data to "successfullyUpdatedResults"
    for (const dataKey in resultData) {
      if (resultData.hasOwnProperty(dataKey)) {
        const responseData = resultData[dataKey];

        if (responseData.status === true) {
          const { data } = responseData;

          successfullyUpdatedResults.push(data);
        }
      }
    }

    if (successfullyUpdatedResults.length >= 1) {
      const cachedStudents = [...state.allStudents];

      let pointer = 0;

      while (pointer < successfullyUpdatedResults.length) {
        for (let index = 0; index < cachedStudents.length; index++) {
          const cachedStudent = cachedStudents[index];
          const updatedStudent = successfullyUpdatedResults[pointer];
          if (
            cachedStudent.hasOwnProperty("studentAuthId") &&
            updatedStudent.hasOwnProperty("studentAuthId")
          ) {
            if (cachedStudent.studentAuthId === updatedStudent.studentAuthId) {
              const newStudentResultInfo = {
                ...updatedStudent,
                student: cachedStudent.student, // <- Response data don't have this info
              };

              cachedStudents[index] = { ...newStudentResultInfo };
              break;
            }
          }
        }
        pointer++;
      }

      dispatch({
        type: marksActions.UPDATE_STUDENTS_MARKS,
        payload: { allStudents: cachedStudents },
      });
    }
  };

  /// This function takes the response data from a successful single student
  /// marks upload and updates the "state.allStudents" to reflect the new student
  /// marks data
  const handleSingleStudentResultUpload = (resultData) => {
    if (resultData.status === true) {
      const { data } = resultData || {};

      if (data == null) {
        showErrorToast(
          resultData?.message ?? "Something went wrong. Try again"
        );
        return;
      }

      const cachedStudents = [...state.allStudents];

      for (let index = 0; index < cachedStudents.length; index++) {
        const cachedStudent = cachedStudents[index];

        if (
          cachedStudent.hasOwnProperty("studentAuthId") &&
          data.hasOwnProperty("studentAuthId")
        ) {
          if (cachedStudent.studentAuthId === data.studentAuthId) {
            const newStudentResult = {
              ...data,
              student: cachedStudent.student,
            };

            cachedStudents[index] = { ...newStudentResult };
            break;
          }
        }
      }

      dispatch({
        type: marksActions.UPDATE_STUDENTS_MARKS,
        payload: { allStudents: [...cachedStudents] },
      });
    }
  };

  
  const values = {
    state,
    fetchInitialData,
    handleBulkResultUpload,
    handleSingleStudentResultUpload,
  };

  return (
    <MarksContext.Provider value={values}>{children}</MarksContext.Provider>
  );
};

const useMarks = () => {
  const context = useContext(MarksContext);

  if (context === undefined) {
    throw new Error("useMarks must be used within MarksContext");
  }

  return context;
};

export { MarksProvider, useMarks };
