import { createContext, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  clearUserDataAndToken,
  getUserData,
  saveUserToken,
} from "../services/authService";

const AuthContext = createContext();

AuthContext.displayName = "AuthContext";

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState();

  const retrieveUserFromStorage = () => {
    try {
      const userData = getUserData();
      setUser(userData);
    } catch (err) {
      //handle LOGS
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    retrieveUserFromStorage();
  }, []);

  const handleUserToken = (token) => {
    saveUserToken(token);
  };

  const handleLogin = () => {
    retrieveUserFromStorage();
  };

  const handleLogout = () => {
    clearUserDataAndToken();
    setUser(null);
    navigate("login", { replace: true });
  };

  const _updateData = (newUserData) => {
    const rememberMe = localStorage.getItem("rememberMe");

    const stringifiedUserData = JSON.stringify(newUserData);

    if (rememberMe == null || rememberMe === false) {
      sessionStorage.setItem("user", stringifiedUserData);
    } else {
      localStorage.setItem("user", stringifiedUserData);
    }

    setUser(newUserData);
  };

  const handleSchoolInitialPageStatuses = (onboardingStatusToUpdate) => {
    const newUserData = { ...user, [onboardingStatusToUpdate]: true };

    _updateData(newUserData);
  };

  /// This function is responsible for updating "schoolCurrentTerm" and
  /// "schoolCurrentYear" after it's been updated/toggle in the "SessionDashboard"
  const updateSchoolCurrentSessionData = (
    updatedSchoolCurrentTerm,
    updatedSchoolCurrentYear
  ) => {
    if (updatedSchoolCurrentTerm == null || updatedSchoolCurrentYear == null) {
      return;
    }
    const newUserData = {
      ...user,
      schoolCurrentTerm: updatedSchoolCurrentTerm,
      schoolCurrentYear: updatedSchoolCurrentYear,
    };

    _updateData(newUserData);
  };

  const value = {
    user,
    handleUserToken,
    handleLogin,
    handleLogout,
    handleSchoolInitialPageStatuses,
    updateSchoolCurrentSessionData,
  };

  return (
    !loading && (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    )
  );
};

export { AuthProvider, useAuth };
