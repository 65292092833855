import * as React from "react";

const SvgBackArrow = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m.21 5.387.083-.094 5-5a1 1 0 0 1 1.497 1.32l-.083.094L3.415 5H15a1 1 0 0 1 .117 1.993L15 7H3.415l3.292 3.293a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.32.083l-.094-.083-5-5a1 1 0 0 1-.083-1.32Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBackArrow;
