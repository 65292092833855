import { PropTypes } from "prop-types";

const LabelAndBorderedContext = ({
  label,
  content,
  width = "w-[232px]",
}) => {
  return (
    <div className={`mb-[15px] mr-[16px] flex flex-col justify-start ${width}`}>
      <p className="mb-[2px] text-[14px] font-semibold text-[#7D8592]">
        {label}
      </p>
      <div
        className={`no-scrollbar flex h-[48px] items-center justify-start overflow-x-auto rounded-[14px] border border-[#D8E0F0] px-4 `}
      >
        {Array.isArray(content) ? (
          content.map((arrayData, index) => (
            <span
              key={index}
              className="mr-[4px] flex-shrink-0 rounded-[8px] bg-chip px-[10px] py-[4px] text-[14px] last:mr-0"
            >
              {arrayData}
            </span>
          ))
        ) : (
          <span className="flex-shrink-0 text-[14px]">{content}</span>
        )}
      </div>
    </div>
  );
};

LabelAndBorderedContext.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default LabelAndBorderedContext;
