import { useToggleBodyScroll } from "../../../hooks/useToggleBodyScroll";

const UndismissibleModal = ({
  header,
  children,
  showModal = false,
  setShowModal,
  width,
  maxHeight,
  showCloseButton = true,
  showBackdrop = true,
  extraHeaderClass,
}) => {
  /// This hook prevents the parent view from being scrollable when the modal
  /// window is open
  useToggleBodyScroll(showModal);

  return (
    <>
      {showModal ? (
        <div
          className={`${
            showBackdrop && "bg-modal"
          } fixed inset-0 top-0 left-0 z-50 flex h-screen w-[screen] items-center justify-center overflow-y-auto overflow-x-hidden py-[24px] outline-none drop-shadow-lg focus:outline-none`}
        >
        
          {/* Content */}
          <div
            className={`absolute overflow-y-auto rounded-[24px] bg-white p-[30px] ${
              width ? `min-w-[${width}]` : "min-w-fit"
            } ${maxHeight ? `max-h-[${maxHeight}]` : "max-h-[736px]"}`}
          >
            {/* Header */}
            {/* bg-accent */}
            <div
              className={`z-500 flex items-start justify-between rounded ${`w-[${width}]`}`}
            >
              {header}
              {showCloseButton && (
                <span
                  onClick={() => {
                    setShowModal(false);
                  }}
                  className="flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-[14px] bg-[#F4F9FD] p-[14px] text-2xl text-black"
                >
                  ×
                </span>
              )}
            </div>

            {/* Body */}
            {children}
            
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UndismissibleModal;
