import { Link, NavLink } from "react-router-dom";

import { PointingRightArrow } from "../icons";

const NormalButton = ({
  clicked,
  label,
  extraClasses,
  disabled = false,
  showIcon = true,
  type = "button",
  layout = "justify-center items-center self-center",
}) => {
  const commonClasses =
    "bg-primary space-x-2 h-[48px] w-[170px] rounded-[14px] text-white";

  return (
    <>
      <button
        className={`${commonClasses} ${extraClasses} ${layout} ${
          disabled ? `disabled:cursor-not-allowed disabled:opacity-50` : ``
        }`}
        type={type}
        disabled={disabled}
        onClick={clicked}
      >
        <span>{label}</span>
        {showIcon && <PointingRightArrow color="bg-white" />}
      </button>
    </>
  );
};

export default NormalButton;
