import * as React from "react";

const SvgThreeCircle = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 5a5 5 0 1 1 10 0A5 5 0 0 1 6 5Zm8 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0ZM12 15a5 5 0 1 1 10 0 5 5 0 0 1-10 0Zm8 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0ZM5 10a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm0 2a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgThreeCircle;
