export const examinationActions = {
  UPDATE_LOADING_STATE: "UPDATE_LOADING_STATE",
  REQUEST_SUCCESS: "REQUEST_SUCCESS",
  ADD_ERROR: "ADD_ERROR",
  APPEND_NEW_EXAM_TYPES: "APPEND_NEW_EXAM_TYPES",
  UPDATE_EXAM_TYPE: "UPDATE_EXAM_TYPE",
  DELETE_EXAM_TYPE: "DELETE_EXAM_TYPE",
  REVERSE_DELETE_EXAM_TYPE: "REVERSE_DELETE_EXAM_TYPE",
  GO_TO_NEXT_PAGE: "GO_TO_NEXT_PAGE",
  GO_TO_PREVIOUS_PAGE: "GO_TO_PREVIOUS_PAGE",
};
