import { PropTypes } from "prop-types";

import ImageAvatar from "../ImageAvatar";

const ImageTitleSubtitle = ({
  image,
  avatarHeight = "h-[50px]",
  avatarWidth = "w-[50px]",
  contentPosition = "",
  imagePlaceHolderText,
  placeHolderStyle,
  title,
  titleStyle = "",
  subtitle,
  classes,
  borderRadius = "rounded-full",
}) => {
  return (
    <div className={`flex space-x-[18px] items-center ${classes}`}>
      <span
        className={`flex items-center justify-center mt-3 mb-3 overflow-hidden bg-grey ${borderRadius} ${avatarHeight} ${avatarWidth}`}
      >
        <ImageAvatar imageUrl={image} fullName={imagePlaceHolderText}  />
      </span>
      <div className={`flex flex-col ${contentPosition}`}>
        <p className={`text-sm text-[#272D37] font-body font-medium ${titleStyle}`}>{title}</p>
       
        <p className="text-sm text-[#91929E]">{subtitle}</p>
      </div>
    </div>
  );
};

ImageTitleSubtitle.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  classes: PropTypes.string,
  borderRadius: PropTypes.string,
};

export default ImageTitleSubtitle;
