import * as React from "react";

const SvgPointingRightArrow = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m15.79 5.387-.083-.094-5-5a1 1 0 0 0-1.497 1.32l.083.094L12.585 5H1a1 1 0 0 0-.117 1.993L1 7h11.585l-3.292 3.293a1 1 0 0 0-.083 1.32l.083.094a1 1 0 0 0 1.32.083l.094-.083 5-5a1 1 0 0 0 .083-1.32Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPointingRightArrow;
