import { Form, Formik } from "formik";

import TextFieldFormik from "../TextFieldFormik";
import TextAreaInputFormik from "../ui/TextAreaInputFormik";
import TermsAndButtonSection from "./TermsAndButtonSection";

import { updatePrincipalCommentSchema } from "../../../utils/validationSchemas/updatePrincipalCommentSchema";
import { updateStudentResultComment } from "../../../features/school/services/schoolService";

import { showErrorToast, showSuccessToast } from "../../../utils/toastHandler";

import { accountType } from "../../../constants/accountType";

import { useResults } from "../../../store/resultsStore/resultsContext";
import { useAuth } from "../../../store/authContext";

const ResultTerminalReportFooter = ({ studentResult }) => {
  const { user } = useAuth();
  const { userType } = user || {};

  const { updateStudentResultCommentInState } = useResults();

  const principalComment = studentResult?.principalComment ?? "";
  const principalName = studentResult?.principalName ?? "";

  const initialValues = {
    principalComment: principalComment,
    principalName: principalName,
  };

  const formHandler = async (values, setSubmitting) => {
    // Handle form submit logic here
    setSubmitting(true);

    const payload = {
      schoolAuthId: studentResult?.schoolAuthId ?? "",
      studentAuthId: studentResult?.studentAuthId ?? "",
      studentYear: studentResult?.year ?? "",
      studentTerm: studentResult?.term ?? "",
      studentClass: studentResult?.class ?? "",
      comment: values.principalComment.trim(),
    };

    try {
      const { data } = await updateStudentResultComment(payload);

      if (data?.status === false) {
        throw new Error(data?.message ?? "Couldn't update principal's comment");
      }

      const updatedStudentResultData = {
        ...studentResult,
        principalComment: values.principalComment.trim(),
      };

      /// This function updates the principal's comment on the student result in state
      updateStudentResultCommentInState(updatedStudentResultData);

      showSuccessToast(data?.message ?? "Comment updated successfully");
    } catch (err) {
      showErrorToast(err?.message ?? "Something went wrong. Please try again");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={updatePrincipalCommentSchema}
      onSubmit={(values, { setSubmitting }) => {
        formHandler(values, setSubmitting);
      }}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <Form
          onSubmit={handleSubmit}
          className="flex flex-col"
          autoComplete="off"
        >
          <div className="flex space-x-[48px]">
            <TextAreaInputFormik
              label="Principal's comment"
              name="principalComment"
              placeholder="Type here..."
              disabled={userType !== accountType.SCHOOL}
            />
            <TextFieldFormik
              name="principalName"
              label="Principal's name"
              labelFieldSpacing="space-y-[6px]"
              disabled={true}
            />
            <TermsAndButtonSection
              isLoading={isSubmitting}
              isDisabled={
                values.principalComment?.trim() === principalComment
                  ? true
                  : false
              }
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResultTerminalReportFooter;
