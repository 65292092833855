import useGetAllBanks from "../../../../../hooks/useGetAllBanks";
import TextFieldFormik from "../../../TextFieldFormik";
import NormalSelect from "../../../ui/NormalSelect";

const TeacherEditableSalaryInfo = ({
  values,
  setFieldTouched,
  setFieldValue,
}) => {
  const { isLoading, banks } = useGetAllBanks();

  return (
    <div>
      <p className="mt-[56px] mb-[24px] text-[24px] font-bold">Salary Info</p>
      <div className="grid grid-cols-2 gap-2">
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Salary"
          name="salary"
          width="w-[232px]"
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Account Name"
          name="accountName"
          width="w-[232px]"
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Account Number"
          name="accountNumber"
          width="w-[232px]"
          height="h-[48px]"
        />
        <NormalSelect
          label="Banks"
          placeholder="Select bank"
          value={values.bank}
          options={
            isLoading ? ["Loading..."] : banks.map(bank => bank?.name ?? "")
          }
          onFocus={() => {
            setFieldTouched("bank", true, false);
          }}
          handleSelect={option => {
            setFieldValue("bank", option.value);

            for (const bank of banks) {
              if (bank.name === option.value) {
                setFieldValue("bankCode", bank?.code ?? "");

                break;
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default TeacherEditableSalaryInfo;
