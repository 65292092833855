import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ClassCard from "../../../../shared/components/ClassCard";
import PaginationIndicator from "../../../components/pagination/PaginationIndicator";
import { AddSubjectToClassButton } from "../../../../shared/components/ui/AddSubjectToClassButton";
import { AddButtonPlain } from "../../../../shared/components/ui/Buttons";
import deleteIcon from "../../../../assets/svg/delete.svg";
import menuLeft from "../../../../assets/svg/menu-left.svg";
import { schoolClasses } from "../../../../assets/lists/schoolClasses";
import { accountType } from "../../../../constants/accountType";

import DismissibleModal from "../../../../shared/components/modals/DismissibleModal";
import TickBox from "../../../components/ui/TickBox";
import { useClassSubjectContext } from "../../../../store/classStore/classContext";
import { useAuth } from "../../../../store/authContext";

import RangeYearPicker from "../../../components/calendar/RangeYearPicker";

const ClassDashboard = () => {
  const navigate = useNavigate();

  const { user, handleSchoolInitialPageStatuses } = useAuth();

  const { state } = useClassSubjectContext();

  const { isInitialSubjectAssigned } = state;

  const {
    userType,
    studentClasses,
    teacherClasses,
    studentSubjects,
    areSubjectsAssignedToClasses,
  } = user || {};

  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState([]);

  const handleSelect = (value, name) => {
    if (value) {
      setSelected([...selected, name]);
    } else {
      setSelected(selected.filter(item => item !== name));
    }
  };

  const selectAll = value => {
    if (value) {
      // if true
      setSelected(getClassesToDisplay); // select all
    } else {
      // if false
      setSelected([]); // unselect all
    }
  };

  useEffect(() => {
    if (userType === accountType.SCHOOL && !areSubjectsAssignedToClasses) {
      setShowModal(true);
    }
  }, [areSubjectsAssignedToClasses, userType]);

  const getClassesToDisplay = () => {
    if (userType === accountType.TEACHER) {
      return teacherClasses;
    }

    if (userType === accountType.STUDENT) {
      return studentClasses;
    }

    return schoolClasses;
  };

  const handleNavigation = selectedClass => {
    navigate(selectedClass);
  };

  return (
    <>
      <DismissibleModal
        showModal={showModal}
        setShowModal={setShowModal}
        height="h-[327px]"
        width="w-[584px]"
        contentStyle="items-center justify-center"
        onCloseButtonClicked={() => setShowModal(false)}
      >
        <p className="text-[22px] font-medium">
          Welcome Admin, kindly assign subjects to classes
        </p>
      </DismissibleModal>

      <div className="flex h-full w-full flex-col p-[0px] lg:p-[20px]">
        <div className="mb-[16px] w-full">
          <h2 className="font-body text-[28px] font-semibold leading-[38px] text-[#272D37]">
            Classes
          </h2>
        </div>

        <div className="flex w-full flex-1 flex-col items-start rounded-[10px] bg-[#fff] shadow-sm">
          {/* card header */}
          <div className="w-full flex-col items-start gap-[16px] self-stretch border-b-[1px] border-b-[#EAEBF0] py-[16px] px-[24px]">
            <div className="flex w-full items-center justify-between">
              {/* <div className="flex h-full w-[40px] items-center justify-center">
                <img src={deleteIcon} className="h-auto w-5" alt="delete" />
              </div> */}

              <div className="hidden items-center gap-4 md:flex">
                {userType === accountType.SCHOOL &&
                  areSubjectsAssignedToClasses === false &&
                  isInitialSubjectAssigned === true && (
                    <AddButtonPlain
                      showIcon={false}
                      className="bg-primary"
                      clicked={() =>
                        handleSchoolInitialPageStatuses(
                          "areSubjectsAssignedToClasses"
                        )
                      }
                    >
                      NEXT
                    </AddButtonPlain>
                  )}

                {/* <RangeYearPicker
                  onDateSelected={date =>
                    console.log("ClassDashboard - date -- ", date)
                  }
                /> */}

                <div className="self-end">
                  <AddSubjectToClassButton />
                </div>
              </div>

              <div className="flex h-[24px] w-[24px] items-center justify-end md:hidden">
                <img src={menuLeft} className="h-full w-full" alt="menu-left" />
              </div>
            </div>
          </div>
          {/* Card Table */}

          <table className="w-full">
            <thead className="h-full w-full">
              <tr className="flex h-full w-full items-center justify-between border-b-[1px] border-b-[#EAEBF0] px-6 py-8">
                <th className="flex items-center self-stretch">
                  {userType === accountType.SCHOOL &&
                    areSubjectsAssignedToClasses === false &&
                    isInitialSubjectAssigned === true && (
                      <TickBox
                        name="all"
                        value={selected.length === getClassesToDisplay().length}
                        updateValue={selectAll}
                      />
                    )}
                  <p className="ml-12 font-body text-sm font-bold leading-[18px] tracking-wide text-[#5F6D7E]">
                    {" "}
                    All Classes
                  </p>
                </th>

                <th className="font-body  text-sm font-bold leading-[18px] tracking-wide text-[#5F6D7E]">
                  {userType === accountType.SCHOOL &&
                    areSubjectsAssignedToClasses === false &&
                    isInitialSubjectAssigned === true &&
                    "Action"}
                </th>
              </tr>
            </thead>
          </table>

          {getClassesToDisplay()?.map((item, index) => (
            <ClassCard
              key={index}
              text={item.toUpperCase()}
              onPressIcon={
                userType === accountType.STUDENT
                  ? null
                  : () => handleNavigation(item)
              }
              name={item}
              value={selected.includes(item)}
              updateValue={handleSelect}
              showExtra={
                userType === accountType.SCHOOL &&
                areSubjectsAssignedToClasses === false &&
                isInitialSubjectAssigned === true
              }
            />
          ))}
        </div>

        {false && (
          <PaginationIndicator
            classes="self-end mt-[12px]"
            currentPageNumber={null}
            itemPerPage={null}
            itemCount={null}
            totalCount={null}
            onForwardArrowClick={null}
            onBackArrowClick={null}
          />
        )}
      </div>
    </>
  );
};

export default ClassDashboard;
