import { useRef } from "react";

import { Image } from "../icons";

const InputFileSingle = ({ label, fileName, error, touched, onChange }) => {
  const fileRef = useRef(null);

  return (
    <div className="mb-[20px] flex w-full cursor-pointer flex-col">
      <label className="mb-2 text-[14px] font-semibold capitalize text-[#7D8592]">
        {label}
      </label>
      <input
        ref={fileRef}
        hidden
        id="files"
        className="w-full rounded-2xl border border-[#D8E0F0] bg-red-200 placeholder-[#DADADA] placeholder-opacity-[50px] file:mr-[4px] file:rounded-[14px] file:border file:border-[#D8E0F0] file:bg-white file:px-[12px] file:text-[#DADADA] file:hover:bg-[#D8E0F0] file:hover:text-grey-darker "
        type="file"
        onChange={onChange}
      />
      <div
        htmlFor="files"
        className="flex w-full justify-between rounded-2xl border border-[#D8E0F0] py-[12px] px-[14px] placeholder-[#DADADA] placeholder-opacity-[50px]"
        onClick={() => {
          fileRef.current.click();
        }}
      >
        <span className={`${!fileName && "text-[#DADADA]"} truncate`}>
          {fileName ? fileName : "IMG-/0001"}
        </span>
        <span className="self-center text-[#DADADA]">
          <Image />
        </span>
      </div>
      {error && touched && (
        <span className="text-[14px] text-accent">{error}</span>
      )}
    </div>
  );
};

export default InputFileSingle;
