import { useLocation, Navigate } from "react-router-dom";

import ResultTerminalReportBody from "../../../../shared/components/results/ResultTerminalReportBody";
import ResultTerminalReportFooter from "../../../../shared/components/results/ResultTerminalReportFooter";
import ResultTerminalReportHeader from "../../../../shared/components/results/ResultTerminalReportHeader";

const ResultTerminalReportSheet = () => {
  const location = useLocation();

  const studentResult = location?.state?.studentResult;
  
  return (
    <>
      {studentResult ? (
        <div className="flex flex-col">
          <ResultTerminalReportHeader studentResult={studentResult} />
          <ResultTerminalReportBody studentResult={studentResult} />
          <ResultTerminalReportFooter studentResult={studentResult} />
        </div>
      ) : (
        <Navigate to="/assessments/results" />
      )}
    </>
  );
};

export default ResultTerminalReportSheet;
