import { useEffect, useState } from "react";
import axios from "axios";

import { getJwtToken } from "../services/authService";

const useAxiosFetch = (dataUrl) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const source = axios.CancelToken.source();

    const fetchData = async (url) => {
      setIsLoading(true);

      try {
        const response = await axios.get(url, {
          cancelToken: source.token,
          headers: {
            authorization: getJwtToken(),
            "Content-Type": "application/json",
            accept: "application/json",
          },
        });

        if (isMounted) {
          setData(response.data);
          setError(null);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
          setData(null);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData(dataUrl);

    const cleanUp = () => {
      isMounted = false;
      source.cancel();
    };

    return cleanUp;
  }, [dataUrl]);

  return { data, isLoading, error };
};

export default useAxiosFetch;
