import LabelAndBorderedContext from "../../../ui/LabelAndBorderedContext";

const TeacherJobInfo = ({ jobInfo }) => {
  return (
    <div>
      <p className="mt-[56px] mb-[24px] text-[24px] font-bold">Job info</p>
      <div className="grid grid-cols-2">
        {Object.keys(jobInfo).map((data, index) => {
          return !jobInfo[data] || jobInfo[data]?.length === 0 ? null : (
            <LabelAndBorderedContext
              key={index}
              label={data}
              content={jobInfo[data]}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TeacherJobInfo;
