import { MailBox } from "../../shared/components/icons";
import LinkButton from "../../shared/components/ui/LinkButton";

const PasswordRecoveryEmail = () => {
  return (
    
    <div className="grid h-screen w-screen place-items-center bg-white ">
      <div className=" grid place-items-center ">
        <MailBox width={146} height={167} />
        <div className="md:w-[590.23px] p-[20px]">
          <p className="mb-[43.53px] text-center text-[24px] font-normal leading-[32.23px]">
            Please follow the password recovery instructions we have sent to
            your email address.
          </p>
        </div>
        <LinkButton
          to="/login"
          label="Back to login"
          showIcon={false}
          disabled={false}
        />
      </div>
    </div>
  );
};

export default PasswordRecoveryEmail;
