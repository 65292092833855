import PropTypes from "prop-types";

import * as Icons from "../../components/icons";

const AddButtonFilled = ({
  extraClasses,
  label,
  bgColor = "bg-primary",
  contentColor = "text-white",
  onClick,
}) => {
  return (
    <button
      className={`inline-flex h-[48px] items-center justify-center rounded-[14px] px-[15px] text-[14px] font-semibold ${extraClasses} ${bgColor}`}
      onClick={onClick}
    >
      <span className="mr-[12px] inline-flex h-[14px] w-[14px]">
        <Icons.Plus className={`flex-0 ${contentColor}`} />
      </span>
      <span className={contentColor}>{label}</span>
    </button>
  );
};

AddButtonFilled.propTypes = {
  extraClasses: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func, // TODO: Maybe add required later
};

export default AddButtonFilled;