import GridTileCard from "../GridTileCard";
import ImageTitleSubtitle from "../ui/ImageTitleSubtitle";
import Button from "../ui/Button";
import daleteIcon from "../../../assets/svg/delete.svg";

const ResultTile = ({
  id,
  name,
  profileImage,
  totalScore,
  averageScore,
  position,
  status,
  onClick,
}) => {
  return (
    <tbody className=" h-full w-full">
      <tr className="border-b-[1px] border-b-[#EAEBF0] ">
        <td className="py-[12px] px-[24px]">
          <input
            id="teacher-checkbox"
            type="checkbox"
            value=""
            className=" h-[20px] w-[20px] rounded  border-[1px] text-primary focus:ring-[#D8E0F0]"
          />
        </td>

        <td className="text-[14px] font-medium leading-[20px] text-[#5F6D7E]">
          {` ${id}`}
        </td>

        <td className=" pl-8 text-center">
          <ImageTitleSubtitle
            classes="c"
            image={profileImage}
            imagePlaceHolderText={name}
            title={name}
          />
        </td>

        <td className=" pl-6 lg:pl-8 ">
          <p className="co place-self-center text-[16px]">{totalScore}</p>
        </td>

        {/* <td>

      <p className="col-span-2 place-self-center text-[16px]">{averageScore}</p>
      </td> */}

        <td className="pl-8">
          <p className="col-span-1 place-self-center text-[16px]">{position}</p>
        </td>
        <td className="px-4">
          <p className="flex items-center justify-center rounded-md bg-[#F0FAF0] text-center text-[13px] font-medium leading-[18px] text-[#2D8A39]">
            <span className="mr-[4px] mb-[12px] text-2xl font-bold">.</span>
            <span>{status}</span>
          </p>
        </td>

        <td className="pl-12">
          <p
            className="cursor-pointer text-[14px] font-semibold"
            onClick={() => {
              if (averageScore === 0) {
                return;
              }

              onClick();
            }}
          >
            View
          </p>
        </td>
      </tr>
    </tbody>
  );
};

export default ResultTile;
