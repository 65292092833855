import { PageSearchIcon } from "./icons";

const PageSearchIconAndLabel = ({ label }) => {
  return (
    <div className="flex flex-col items-center justify-center">

      <PageSearchIcon className="mb-[16px] text-[128px] mt-12" />

      <p className="text-[18px] leading-[24px] text-[#DADADA]">
        {label ?? "No data found"}
      </p>
    </div>
  );
};

export default PageSearchIconAndLabel;
