import * as React from "react";

const SvgAssignment = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.267 11a5.137 5.137 0 0 0-4.4-5.075V2.567a1.1 1.1 0 0 0-1.1-1.1h-3.3V.733A.733.733 0 0 0 11.732 0H5.866a.733.733 0 0 0-.733.733v.734h-3.3a1.1 1.1 0 0 0-1.1 1.1v16.5a1.1 1.1 0 0 0 1.1 1.1h12.833v.366a1.467 1.467 0 0 0 2.934 0v-3.666a.367.367 0 0 0-.367-.367v-.488A5.14 5.14 0 0 0 21.267 11Zm-.734 0a4.4 4.4 0 1 1-8.8 0 4.4 4.4 0 0 1 8.8 0Zm-4.767-8.8c.203 0 .367.164.367.367v3.3c-.245.001-.49.021-.733.058V3.667a.733.733 0 0 0-.734-.734h-2.2V2.2h3.3ZM5.867.733h5.867V4.4H5.866V.733Zm5.867 4.4a.733.733 0 0 0 .733-.733v-.733h2.2v2.416a5.13 5.13 0 0 0 .367 9.929v.488a.367.367 0 0 0-.367.367v1.1H2.933v-14.3h2.2V4.4c0 .405.329.733.733.733h5.867Zm-9.9 14.3a.367.367 0 0 1-.367-.366v-16.5c0-.203.165-.367.367-.367h3.3v.733h-2.2a.733.733 0 0 0-.733.734v14.3c0 .405.328.733.733.733h11.733v.733H1.833Zm15.033 1.1a.733.733 0 0 1-1.466 0v-3.3h1.466v3.3ZM16.5 16.5h-.733v-.385c.121.009.242.018.366.018s.245-.01.367-.018v.385Z"
      fill="currentColor"
    />
    <path
      d="M4.4 7.333v2.934c0 .202.164.366.367.366H7.7a.367.367 0 0 0 .367-.366V7.59l.644-.753-.556-.476-.519.606h-2.87a.367.367 0 0 0-.366.366Zm.733.367h1.875l-.796.927-.453-.453-.518.519.733.733a.367.367 0 0 0 .26.107h.014a.367.367 0 0 0 .263-.128l.822-.958V9.9h-2.2V7.7Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgAssignment;
