import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import * as Icons from "../../../shared/components/icons";

export const AddButtonBordered = ({
  children = "Button Name",
  className,
  clicked,
  isLoading = false,
  disabled = false,
}) => {
  return (
    <button
      disabled={disabled}
      className={`${className} ${
        disabled
          ? "cursor-not-allowed bg-inActive-shade1 text-white"
          : "border-[2px] border-[#0078CE] bg-white text-[#0078CE]"
      } relative inline-flex h-[48px] transform items-center justify-center rounded-[14px] px-[15px] text-[14px] font-semibold`}
      onClick={clicked}
    >
      {isLoading ? (
        <div className="h-[24px] w-[24px] place-self-center self-center">
          <svg
            className="h-full w-full animate-spin self-center rounded-full border-l-[4px] border-primary"
            viewBox="0 0 24 24"
          ></svg>
        </div>
      ) : (
        <>
          <Icons.Plus className="flex-0 absolute top-0 bottom-0 left-[16px] h-full" />
          {children}
        </>
      )}
    </button>
  );
};

export const AddButtonPlain = ({
  children = "Button Name",
  className,
  clicked,
  showIcon = true,
  disabled = false,
  isLoading = false,
  bg = "primary",
}) => {
  return (
    <button
      disabled={disabled}
      className={`${className} ${
        disabled
          ? "cursor-not-allowed bg-inActive-shade1"
          : `cursor-pointer bg-primary`
      } relative inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border-[1px]  border-[#D0D5DD] px-[16px] py-[10px] text-[14px] font-semibold text-white shadow-sm`}
      onClick={clicked}
    >
      {!isLoading && showIcon && (
        <Icons.Plus className="flex-0 absolute top-0 bottom-0 left-[16px] h-full" />
      )}
      {isLoading ? (
        <div className="h-[16px] w-[16px] place-self-center self-center">
          <svg
            className="h-full w-full animate-spin self-center rounded-full border-l-[4px] border-white"
            viewBox="0 0 24 24"
          ></svg>
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export const BackButton = ({ children = "back", className, clicked }) => {
  return (
    <button
      className={[
        "flex transform justify-items-center rounded-[14px] bg-[transparent] text-center text-[14px] font-semibold capitalize text-[#0078CE]",
        className,
      ].join(" ")}
      onClick={clicked}
    >
      <Icons.BackArrow className="mt-[3px] mr-[10px] grid" />
      {children}
    </button>
  );
};

export const ForwardButton = ({
  type = "button",
  isLoading = false,
  disabled = false,
  label = "Button",
  extraClasses,
  width = "w-[170px]",
  height = "h-[48px]",
  padding,
  buttonColor = "bg-primary",
  onClick,
}) => {
  const loadingClasses =
    "flex justify-center items-center disabled:cursor-not-allowed disabled:opacity-50";
  return (
    <button
      onClick={onClick}
      className={`${buttonColor} ${extraClasses} ${width} ${height} ${padding} rounded-[14px] text-white ${
        (isLoading || disabled) && loadingClasses
      }`}
      type={type}
      disabled={isLoading ? true : disabled}
    >
      {isLoading ? (
        <svg
          className="mr-3 h-6 w-6 animate-spin rounded-full border-l-4 border-white"
          viewBox="0 0 24 24"
        ></svg>
      ) : (
        <div className="flex items-center justify-center space-x-2 ">
          <span>{label}</span>
          <Icons.PointingRightArrow color="bg-white " />
        </div>
      )}
    </button>
  );
};

export const DownloadButton = ({
  children = "Download",
  className,
  clicked,
}) => {
  return (
    <button
      className={[
        "inline-flex h-[30px] items-center whitespace-nowrap rounded-[8px] px-[15px] text-[12px] font-bold",
        className,
      ].join(" ")}
      style={{ backgroundColor: "#E8F1FF", color: "#1565D8" }}
      onClick={clicked}
    >
      <span className="mr-[12px] inline-flex h-[16px] w-[15px] items-center justify-center">
        <Icons.Download className="flex-0  h-full" fontSize="20px" />
      </span>
      {children}
    </button>
  );
};

export const PlainButton = ({
  type = "button",
  className,
  backgroundColor = "bg-[#437EF7]",
  loadingSpinnerColor = "border-primary",
  width = "w-full",
  height = "h-[36px]",
  disabled = false,
  isLoading = false,
  icon,
  bg,
  color,
  href = null,
  children = "Text",
  clicked,
}) => {
  if (href) {
    return (
      <NavLink
        to={href}
        className={[
          `inline-flex h-[48px] items-center justify-center rounded-[5px] px-[23px] text-[16px] font-semibold text-white`,
          className,
        ].join(" ")}
      >
        {children}
      </NavLink>
    );
  }

  // className={`inline-flex items-center justify-center rounded-[14px] px-[24px] text-[16px] font-semibold text-white ${
  //   disabled ? "disabled" : null
  // } ${width} ${height} ${backgroundColor}`}

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={clicked}
      className={`px[24px] inline-flex items-center justify-center rounded-md text-[13px] font-semibold text-white ${
        disabled ? "disabled" : null
      } ${width} ${height} ${className} ${
        disabled
          ? "cursor-not-allowed bg-inActive-shade1"
          : `${backgroundColor}`
      }`}
    >
      {isLoading ? (
        <div className="h-[24px] w-[24px] place-self-center self-center">
          <svg
            className={`h-full w-full animate-spin self-center rounded-full border-l-[4px] ${loadingSpinnerColor}`}
            viewBox="0 0 24 24"
          ></svg>
        </div>
      ) : (
        <>
          {icon && (
            <span className="flex-0  absolute top-0 bottom-0 left-[16px] h-full">
              {icon}
            </span>
          )}
          {children}
        </>
      )}
    </button>
  );
};

export const SquareButton = ({
  className,
  clicked,
  href = null,
  icon = (
    <Icons.VerticalEllipsis className="flex-0 text-[22px]" color="#0A1629" />
  ),
}) => {
  let iconContainer = (
    <span className="flex-0 inline-flex h-[18px] w-[3px] items-center justify-center">
      {icon}
    </span>
  );
  if (href) {
    return (
      <NavLink
        to={href}
        className={[
          "inline-fle flex-0 h-[44px] w-[44px] items-center justify-center whitespace-nowrap rounded-[14px]",
          className,
        ].join(" ")}
        style={{ backgroundColor: "#F4F9FD" }}
      >
        {iconContainer}
      </NavLink>
    );
  }

  return (
    <button
      onClick={clicked}
      className={[
        "flex-0 inline-flex h-[44px] w-[44px] items-center justify-center whitespace-nowrap rounded-[14px]",
        className,
      ].join(" ")}
      style={{ backgroundColor: "#F4F9FD" }}
    >
      {iconContainer}
    </button>
  );
};

export const DeleteButton = ({
  children = "Button Name",
  className,
  width = "w-[144px]",
  clicked,
  showIcon = true,
  disabled = false,
  isLoading = false,
  backgroundColor = "bg-primary",
}) => {
  return (
    <button
      disabled={disabled}
      className={`${className} ${width} ${
        disabled
          ? "cursor-not-allowed bg-inActive-shade1"
          : `cursor-pointer ${backgroundColor}`
      } inline-flex h-[48px] items-center justify-center whitespace-nowrap rounded-[14px] px-[2px] text-[14px] font-semibold text-danger`}
      onClick={clicked}
    >
      {showIcon && (
        <span className="mr-[12px] inline-flex h-[14px] w-[14px]">
          <Icons.Delete className="flex-0 h-full" />
        </span>
      )}
      {isLoading ? (
        <div className="h-[16px] w-[16px] place-self-center self-center">
          <svg
            className="h-full w-full animate-spin self-center rounded-full border-l-[4px] border-white"
            viewBox="0 0 24 24"
          ></svg>
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export const CheckButton = ({
  checked = false,
  children = "Button Name",
  className,
  width = "w-[144px]",
  onClick,
  disabled = false,
  isLoading = false,
  backgroundColor = "bg-primary",
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleClick = event => {
    if (event == null) {
      return;
    }

    event.stopPropagation();

    const checkStatus = event.currentTarget.checked;

    setIsChecked(checkStatus);
    onClick(checkStatus);
  };
  // const handleChange = (event) => {
  //   event.stopPropagation();
  //   const checkStatus = event.currentTarget.checked;

  //   onClick(checkStatus);
  // };

  return (
    <label
      htmlFor="selectAll"
      className="inline-flex h-[48px] items-center justify-center whitespace-nowrap rounded-[14px] border-[1px] border-primary px-[16px] text-[14px] font-semibold text-grey-darker"
    >
      <input
        className="mr-[18px] border-primary"
        type="checkbox"
        // checked={checked}
        id="selectAll"
        checked={isChecked}
        onChange={handleClick}
      />
      Select All
    </label>
    // </div>
  );
};

// TODO: Every iteration of outlined-button should use this as a base
export const OutlinedButton = ({
  type = "button",
  className,
  extraClasses,
  width = "w-full",
  height = "h-[36px]",
  disabled = false,
  isLoading = false,
  icon,
  href = null,
  borderColor = "border-[#DAE0E6]",

  children = "Text",
  onClick,
}) => {
  if (href) {
    return (
      <NavLink
        to={href}
        className={`px[24px] inline-flex items-center  justify-center rounded-[5px] font-semibold text-white ${width} ${height} ${extraClasses}`}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`px[24px] inline-flex items-center justify-center rounded-md text-[13px] font-semibold ${
        disabled ? "disabled" : null
      } ${width} ${height} ${extraClasses} ${
        disabled
          ? "cursor-not-allowed bg-inActive-shade1 text-white"
          : `border-[1px] ${borderColor} bg-white text-[14px] font-semibold leading-[20px] text-[#2F80ED]`
      }`}
    >
      {isLoading ? (
        <div className="h-[24px] w-[24px] place-self-center self-center">
          <svg
            className="h-full w-full animate-spin self-center rounded-full border-l-[4px] border-primary"
            viewBox="0 0 24 24"
          ></svg>
        </div>
      ) : (
        <>
          {icon && (
            <span className="flex-0 absolute top-0 bottom-0 left-[16px] h-full">
              {icon}
            </span>
          )}
          {children}
        </>
      )}
    </button>
  );
};
