import * as React from "react";

const SvgSubject = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 35 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.428 24.784a1.026 1.026 0 0 0-1.346-.539c-3.419 1.465-4.88 4.799-4.94 4.94a1.025 1.025 0 0 0 1.885.808c.011-.027 1.19-2.717 3.863-3.863.52-.223.761-.826.538-1.346ZM32.275 21.878a1.025 1.025 0 0 0-.802-1.887c-5.262 2.237-7.493 7.367-7.585 7.585a1.025 1.025 0 0 0 1.886.804c.02-.046 2.018-4.596 6.501-6.502ZM31.202 17.411a1.025 1.025 0 0 0-.617-1.955c-.761.24-1.5.536-2.216.886V2.05C28.37.92 27.45 0 26.32 0h-8.272c-1.554 0-2.94.735-3.828 1.875A4.846 4.846 0 0 0 10.39 0H2.05C.92 0 0 .92 0 2.05v19.825c0 1.13.92 2.05 2.05 2.05h18.553c-.864 1.558-1.23 2.699-1.25 2.763a1.024 1.024 0 0 0 1.954.62c.006-.02.628-1.934 2.173-4.108 2.036-2.866 4.634-4.814 7.722-5.789ZM18.047 2.051h8.271v14.317c-4.653-.08-8.704-.133-11.074 1.726V4.854a2.806 2.806 0 0 1 2.803-2.803Zm-7.656 0a2.806 2.806 0 0 1 2.802 2.803v13.24c-2.381-1.869-6.46-1.806-11.142-1.724V2.05h8.34ZM2.05 18.42c4.534-.08 8.449-.145 10.14 1.518.487.478.792 1.117.925 1.936H2.051V18.42Zm19.856 3.454h-6.586c.133-.819.438-1.458.925-1.936 1.537-1.511 4.911-1.595 8.918-1.538a18.358 18.358 0 0 0-3.257 3.474ZM33.975 31.104a1.025 1.025 0 1 0 0-2.051 1.025 1.025 0 0 0 0 2.05Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSubject;
