import PropTypes from "prop-types";

import { getCurrentDate } from "../../utils/calendarFunctions";

const WelcomeNameAndDate = ({ name }) => {
  return (
    <div className="flex flex-1 flex-col ">
      <h2 className=" text-[28px] font-semibold leading-[38px] text-[#272D37]">
        {`Good Morning, ${name}`}
        {/* the admin text which is ${name} will be after the word Ford! if u want the admin text to show */}
      </h2>
      <p className="text-[16px] font-normal mb-5 leading-[24px] text-[#5F6D7E]">
        {getCurrentDate()}
      </p>
    </div>
  );
};

WelcomeNameAndDate.propTypes = {
  name: PropTypes.string.isRequired,
};

export default WelcomeNameAndDate;
