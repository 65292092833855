import Button from "../../ui/Button";
import Select from "../../ui/Select";

import { getYearRange } from "../../../../utils/getYearRange";

const FormAddStudent2 = ({ values, errors, onClick, isSubmitting }) => {
  const years = getYearRange();

  const arrayOfSubClasses = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
  ];

  return (
    <>
      <Select
        label={`gender`}
        name="gender"
        useUpperCase
        showDefaultOption={false}
        options={["male", "female"]}
      />

      <Select
        label={`current year`}
        name="currentYear"
        showDefaultOption={false}
        options={years}
      />

      <Select
        label={`current class`}
        showDefaultOption={false}
        useUpperCase
        name="currentClass"
        options={["jss1", "jss2", "jss3", "sss1", "sss2", "sss3"]}
      />

      <Select
        showDefaultOption={false}
        label={`sub class`}
        name="subClass"
        options={arrayOfSubClasses}
      />

      <Select
        showDefaultOption={false}
        label={`current term`}
        useUpperCase
        name="currentTerm"
        options={["first", "second", "third"]}
      />
      <div className="flex w-full justify-end ">
        <Button
          type="submit"
          extraClasses=" my-[30px] self-end"
          isLoading={isSubmitting}
          disabled={
            errors.gender ||
            values.gender === "" ||
            errors.currentClass ||
            values.currentClass === "" ||
            errors.subClass ||
            values.subClass === "" ||
            errors.currentYear ||
            values.currentYear === "" ||
            errors.currentTerm ||
            values.currentTerm === ""
              ? true
              : false
          }
        >
          Submit Now
        </Button>
      </div>
    </>
  );
};

export default FormAddStudent2;
