import { NavLink } from "react-router-dom";

import * as Icons from "./icons/index";

export const SideNavItem = ({
  title,
  icon,
  clicked,
  active,
  children,
  href,
  className,
  navItems,
}) => {
  const newSnip = isActive => {
    return (
      <>
        <div
          className={`${
            isActive && "mb-[8px] border-l-[4px] border-primary bg-[#F3F9FE]"
          } flex w-[288px] items-center justify-between px-[28px] py-[12px]`}
        >
          <div className="flex w-full gap-[16px]">
            <span
              className={`h-[24px] w-[24px] ${
                isActive ? "text-primary" : "text-black"
              }`}
            >
              {icon}
            </span>
            {title}
          </div>
          {children ? (
            <span className="flex items-center justify-center text-[12px]">
              <Icons.DownArrow className="h-full" />
            </span>
          ) : null}
        </div>

        {isActive && (
          <div
            className={`flex w-full flex-col items-start overflow-hidden transition-all duration-300 ease-in-out
        ${
          children
            ? "h-auto translate-y-0 opacity-100"
            : "h-0 -translate-y-6 opacity-0"
        } `}
          >
            {children}
          </div>
        )}

        <div
          className={`flex w-full flex-col gap-1 overflow-hidden
        text-left transition-all duration-300 ease-in-out
        ${
          active && !isActive && children
            ? "h-auto translate-y-0 opacity-100"
            : "h-0 -translate-y-6 opacity-0"
        }`}
        >
          {children}
        </div>
      </>
    );
  };

  return (
    <NavLink
      className={navData =>
        `${
          navData.isActive ? "text-primary" : "text-inActive"
        } mb-[4px] flex h-auto w-full flex-col items-start py-[0px] px-[28px] text-[15px] font-semibold`
      }
      to={href}
      children={navData => newSnip(navData.isActive)}
      onClick={clicked}
    ></NavLink>
  );
};
