import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, FieldArray } from "formik";
import _ from "lodash";

import StudentAndScore from "../ui/StudentAndScore";
import PageLoading from "../ui/PageLoading";
import NormalSelect from "../ui/NormalSelect";
import Button from "../ui/Button";
import DismissibleModal from "./DismissibleModal";
import PaginationIndicator from "../pagination/PaginationIndicator";
import { AddButtonPlain } from "../ui/Buttons";

import {
  fetchSchoolDetails,
  getStudentResultsBySubjects,
  uploadBulkStudentResult,
} from "../../../features/school/services/schoolService";

import {
  getTeacherStudentResultsBySubjects,
  teacherUploadBulkStudentResult,
} from "../../../features/teacher/services/teacherService";

import { addStudentsResultSchema } from "../../../utils/validationSchemas/addStudentsResultSchema";
import { showErrorToast, showSuccessToast } from "../../../utils/toastHandler";
import { accountType } from "../../../constants/accountType";
import { paginateData } from "../../../utils/clientSidePagination";

import useGetSchoolSubjects from "../../../hooks/useGetSchoolSubjects";
import useGetExamTypes from "../../../hooks/useGetExamTypes";

import { useMarks } from "../../../store/marksStore/marksContext";
import { useAuth } from "../../../store/authContext";
import { debugPrint } from "../../../utils/debugPrint";

const AddBulkScoresModal = ({
  schoolAuthId,
  studentClass,
  studentTerm,
  studentYear,
  onCloseModal,
}) => {
  const { user } = useAuth();

  const { userType } = user || {};

  const navigate = useNavigate();

  const { handleBulkResultUpload } = useMarks();

  const { isLoading: isGetExamTypesLoading, examTypes } = useGetExamTypes();

  const { isLoading: isGetSchoolSubjectsLoading, subjects } =
    useGetSchoolSubjects(studentClass);

  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPage = () => {
    setCurrentPage(prevState => prevState + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevState => prevState - 1);
  };

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [bulkUploadErrorArray, setBulkUploadErrorArray] = useState([]);

  useEffect(() => {
    if (bulkUploadErrorArray.length >= 1) {
      setShowErrorModal(true);
    }
  }, [bulkUploadErrorArray]);

  const [scoreSearchParams, setScoreSearchParams] = useState({
    examType: "",
    studentSubject: "",
  });

  const [studentResults, setStudentResults] = useState({
    isInitial: true, // <- Used to indicate we're fetching data for the first time
    examType: "",
    studentSubject: "",
    isLoading: false,
    error: null,
    maxScore: null,
    data: [],
  });

  /// Student result data to be used by formik for input values
  const [initialValues, setInitialValues] = useState({
    results: [],
  });

  const handleExamTypeNavigation = () => {
    navigate("/assessments/exam-types");
  };

  const handleSubjectNavigation = () => {
    navigate("/academic/subjects");
  };

  const _handleFetchInitialStudentResults = async () => {
    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolStudentResults } =
          await getStudentResultsBySubjects(
            studentClass,
            scoreSearchParams.studentSubject,
            scoreSearchParams.examType,
            studentTerm,
            studentYear
          );
        return schoolStudentResults;

      case accountType.TEACHER:
        const { data: teacherStudentResults } =
          await getTeacherStudentResultsBySubjects(
            studentClass,
            scoreSearchParams.studentSubject,
            scoreSearchParams.examType,
            studentTerm,
            studentYear
          );

        return teacherStudentResults;

      default:
        throw new Error("Something went wrong");
    }
  };

  const fetchInitialStudentResults = async () => {
    setStudentResults(prevState => {
      return {
        ...prevState,
        isLoading: true,
        isInitial: false,
        data: [],
        error: null,
      };
    });

    /// Resetting the initial values on fresh data-set fetch
    // TODO: Reset initialValues here

    try {
      const responseData = await _handleFetchInitialStudentResults();

      if (responseData?.status === true) {
        const { data: innerData, maxScoreForExamType } = responseData;

        const resultData = innerData?.map(value => {
          const id = value?.studentId ?? value?.id;
          const studentAuthId = value?.studentAuthId;
          const score = value?.subjectAndExamTypeScore;
          const firstName = value?.firstName;
          const lastName = value?.lastName;

          const fullName = `${firstName} ${lastName}`;
          const obj = {
            id: id,
            studentAuthId: studentAuthId,
            fullName: fullName,
            studentScore: score,
          };

          return obj;
        });

        setInitialValues(() => {
          return { results: [...resultData] };
        });

        setStudentResults(prevState => {
          return {
            ...prevState,
            maxScore: maxScoreForExamType, // <- Maximum score for the current selected exam type
            data: [...innerData],
            examType: scoreSearchParams.examType, // <- Caching examType to studentResults state
            studentSubject: scoreSearchParams.studentSubject, // <- Caching studentSubject to studentResults state
          };
        });
      } else {
        throw new Error(responseData?.message ?? "Couldn't retrieve data");
      }
    } catch (err) {
      showErrorToast(err?.message ?? "Something went wrong. Try again");
    } finally {
      setStudentResults(prevState => {
        return { ...prevState, isLoading: false };
      });
    }
  };

  /// This function is responsible for setting the filter params which are
  /// "Exam Types" and "Subjects" with their values used for making a get request
  /// to fetch the students result data
  const handleFilterSelection = (name, value) => {
    const updatedObj = {
      [name]: value,
    };

    setScoreSearchParams(prevState => {
      return { ...prevState, ...updatedObj };
    });
  };

  const shouldShowFilterSearchButton = () => {
    if (
      scoreSearchParams.examType !== studentResults.examType ||
      scoreSearchParams.studentSubject !== studentResults.studentSubject
    ) {
      if (scoreSearchParams.examType && scoreSearchParams.studentSubject) {
        return true;
      }
      return false;
    }

    return false;
  };

  /// This function is responsible for updating the students' score cached
  /// in state [studentResults.data.subjectAndExamTypeScore] to the returned
  /// updated value from a successful form submission
  const updateCachedStudentsScoreAfterSuccessfulUpload = newScores => {
    const cachedStudentScores = [...studentResults.data];

    let pointer = 0;

    while (pointer < newScores.length) {
      for (let index = 0; index < cachedStudentScores.length; index++) {
        const cachedStudentScore = cachedStudentScores[index];
        const updatedStudentScore = newScores[pointer];

        if (
          cachedStudentScore.hasOwnProperty("studentAuthId") &&
          updatedStudentScore.hasOwnProperty("studentAuthId") &&
          updatedStudentScore.hasOwnProperty("studentScores")
        ) {
          if (
            cachedStudentScore.studentAuthId ===
            updatedStudentScore.studentAuthId
          ) {
            const examTypeValues = Object.values(
              updatedStudentScore.studentScores
            );
            if (examTypeValues.length >= 1) {
              const updatedScore = examTypeValues[0];

              const newStudentResultInfo = {
                ...cachedStudentScore,
                subjectAndExamTypeScore: updatedScore,
              };

              cachedStudentScores[index] = newStudentResultInfo;
            }
            break;
          }
        }
      }

      pointer++;
    }

    setStudentResults(prevState => {
      return { ...prevState, data: [...cachedStudentScores] };
    });
  };

  const _handleUploadBulkStudentResult = async payload => {
    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolStudentResultsData } =
          await uploadBulkStudentResult(payload);
        return schoolStudentResultsData;

      case accountType.TEACHER:
        const { data: teacherStudentResultsData } =
          await teacherUploadBulkStudentResult(payload);

        return teacherStudentResultsData;

      default:
        throw new Error("Something went wrong");
    }
  };

  /// Function for handling form submission
  const formHandler = async (value, setSubmitting) => {
    /// Extracting only the scores from the data held in "studentResults"
    const originalStudentScoreList = studentResults.data.map(
      result => result?.subjectAndExamTypeScore
    );

    /// Extracting only the scores from the form data
    const formStudentScoreList = value?.results.map(
      result => +result?.studentScore
    );

    if (originalStudentScoreList.length === formStudentScoreList.length) {
      if (_.isEqual(originalStudentScoreList, formStudentScoreList)) {
        /// No students score was changed/updated
        setSubmitting(false);
        showSuccessToast("Success");

        return;
      }

      /// This variable will only hold student score values that has been changed
      /// from their original values
      const changedScoreList = [];

      /// Extracting student score values that are different from their original
      /// initial values
      for (let index = 0; index < value.results.length; index++) {
        const formStudentScore = +value.results[index].studentScore;
        if (formStudentScore !== originalStudentScoreList[index]) {
          const newStudentResultData = {
            studentAuthId: value.results[index].studentAuthId,
            schoolAuthId: schoolAuthId,
            studentClass: studentClass,
            currentYear: studentYear,
            currentTerm: studentTerm,
            studentSubject: studentResults.studentSubject,
            studentScores: {
              [studentResults.examType]: formStudentScore,
            },
          };

          changedScoreList.push(newStudentResultData);
        }
      }

      const payload = {
        bulkData: changedScoreList,
      };

      try {
        const responseData = await _handleUploadBulkStudentResult(payload);

        if (responseData?.status === true) {
          const { response } = responseData;

          /// Returning response data back to the MarksContext
          handleBulkResultUpload(response);

          updateCachedStudentsScoreAfterSuccessfulUpload(changedScoreList);

          const arrayOfErrors = [];

          if (response != null) {
            for (const data in response) {
              const singleData = response[data];

              if (singleData?.status === false) {
                arrayOfErrors.push(singleData);
              }
            }

            if (arrayOfErrors.length >= 1) {
              setBulkUploadErrorArray(arrayOfErrors);
            }
          }

          if (arrayOfErrors.length === changedScoreList.length) {
            // None of the uploaded student marks went successfully
            return;
          }

          showSuccessToast(responseData?.message ?? "Successfully uploaded");

          // Close modal here
          onCloseModal();
        } else {
          throw new Error(responseData?.message ?? "Couldn't upload result");
        }
      } catch (err) {
        showErrorToast(err.message);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <DismissibleModal
        showModal={showErrorModal}
        setShowModal={setShowErrorModal}
        height="h-auto max-h-[500px]"
        width="w-[500px]"
        headerText="Error Message"
        contentStyle="mt-[32px]"
        onCloseButtonClicked={() => {
          setShowErrorModal(false);
          setBulkUploadErrorArray([]);
        }}
      >
        <table className="table-fixed border-collapse overflow-auto">
          <thead className="text-[15.65px] leading-[22px]">
            <tr className="border-b-[4px] border-[#B8B8B8]">
              <th className="w-[64px] p-[10px] text-left font-normal">S/N</th>
              <th className="w-full p-[10px] text-center font-normal">
                Message
              </th>
            </tr>
            <tr></tr>
          </thead>
          <tbody className="bg-white">
            {bulkUploadErrorArray?.map((data, index) => (
              <tr
                key={data?.student ?? index}
                className="cursor-default border-b-[2px] border-[#E5E5E5]"
              >
                <td className="border-r-[1px] border-grey-light p-[10px]">
                  {index + 1}
                </td>
                <td className="border-r-[1px] border-grey-light p-[10px]">
                  {data?.message}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </DismissibleModal>
      <div className="flex w-[576px] flex-col">
        <NormalSelect
          label="Exam Types"
          className="mb-[16px]"
          placeholder={
            isGetExamTypesLoading
              ? "Loading..."
              : !isGetExamTypesLoading && examTypes?.length === 0
              ? "No exam type found"
              : "Select"
          }
          options={examTypes?.map(exam => Object.keys(exam)[0])}
          handleSelect={event => handleFilterSelection("examType", event.value)}
        />

        {!isGetExamTypesLoading && examTypes?.length === 0 && (
          <span
            className="mt-[8px] mb-[16px] cursor-pointer text-[12px] text-accent"
            onClick={handleExamTypeNavigation}
          >
            click here to add an exam type
          </span>
        )}

        <NormalSelect
          label="Subject"
          placeholder={
            isGetSchoolSubjectsLoading
              ? "Loading..."
              : !isGetSchoolSubjectsLoading && subjects?.length === 0
              ? "No subjects found"
              : "Select"
          }
          options={subjects?.map(subject => subject)}
          handleSelect={event =>
            handleFilterSelection("studentSubject", event.value)
          }
        />

        {!isGetSchoolSubjectsLoading && subjects?.length === 0 && (
          <span
            className="mt-[8px] mb-[16px] cursor-pointer text-[12px] text-accent"
            onClick={handleSubjectNavigation}
          >
            click here to add an exam type
          </span>
        )}

        {shouldShowFilterSearchButton() && !studentResults.isLoading && (
          <AddButtonPlain
            className={`mt-[16px] self-end bg-primary px-[50px]`}
            showIcon={false}
            clicked={fetchInitialStudentResults}
          >
            Search
          </AddButtonPlain>
        )}

        {studentResults.isInitial ? null : (
          <>
            {studentResults.isLoading && <PageLoading classes="mt-[24px]" />}
            {studentResults.error && <p>Error has occurred</p>}
            {!studentResults.isLoading && studentResults.error === null ? (
              <>
                {studentResults.data.length > 0 &&
                !shouldShowFilterSearchButton() &&
                !studentResults.isLoading ? (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={addStudentsResultSchema(
                      studentResults.maxScore
                    )}
                    onSubmit={(values, { setSubmitting }) => {
                      formHandler(values, setSubmitting);
                    }}
                  >
                    {({ values, errors, handleSubmit, isSubmitting }) => (
                      <div>
                        <Form
                          onSubmit={handleSubmit}
                          className="flex flex-col"
                          autoComplete="off"
                        >
                          <FieldArray
                            name={"results"}
                            render={arrayHelpers => (
                              <div className="mt-[26px] grid grid-cols-3 gap-x-[112px]">
                                <p className="col-span-2 text-[18px] font-semibold text-[#7D8592]">
                                  Students
                                </p>
                                <p className="mb-[18px] text-[18px] font-semibold text-[#7D8592]">
                                  Score
                                </p>
                                {paginateData(values.results, currentPage).map(
                                  (student, index) => {
                                    const id = student?.id;
                                    const score = student?.studentScore;
                                    const fullName = student?.fullName;

                                    return (
                                      <StudentAndScore
                                        key={id}
                                        fullName={fullName}
                                        id={id}
                                        score={score}
                                        classes="mb-[32px] col-span-2"
                                        name={`results.${index}`}
                                        error={
                                          errors?.results &&
                                          errors.results[index] !== undefined
                                            ? errors.results[index]
                                            : null
                                        }
                                        onChange={updatedScore => {
                                          const newStudentData = {
                                            ...values.results[index],
                                            studentScore:
                                              updatedScore.target.value,
                                          };

                                          arrayHelpers.replace(
                                            index,
                                            newStudentData
                                          );
                                        }}
                                      />
                                    );
                                  }
                                )}

                                <Button
                                  extraClasses="col-start-3"
                                  type="submit"
                                  height="h-[48px]"
                                  width="w-auto"
                                  padding="py-[8px]"
                                  onClick={handleSubmit}
                                  isLoading={isSubmitting}
                                >
                                  Submit
                                </Button>
                              </div>
                            )}
                          />
                        </Form>

                        <PaginationIndicator
                          classes="self-end"
                          currentPageNumber={currentPage}
                          itemCount={paginateData(values.results, 1)?.length}
                          totalCount={values.results?.length}
                          onBackArrowClick={handlePreviousPage}
                          onForwardArrowClick={handleNextPage}
                        />
                      </div>
                    )}
                  </Formik>
                ) : null}
              </>
            ) : null}
          </>
        )}

        {/* <PaginationIndicator
        classes="self-end"
        currentPageNumber={currentPage}
        itemPerPage={dataPerPage}
        itemCount={currentPageCount}
        totalCount={totalCount}
        onForwardArrowClick={onForwardArrowClick}
        onBackArrowClick={onBackArrowClick}
      /> */}
      </div>
    </>
  );
};

export default AddBulkScoresModal;
