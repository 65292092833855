import { useState } from "react";
import { Formik, Form } from "formik";

import { BackButton } from "../ui/Buttons";

import FormAddStudent1 from "./addStudent/FormAddStudent1";
import FormAddStudent2 from "./addStudent/FormAddStudent2";

import { addStudentSchema } from "../../../utils/validationSchemas/addStudentSchema";
import { initialValues } from "../../../utils/initialValues/studentInitialValues";

import {
  removeWhiteSpacesFromObjValues,
  removeInitialZeroFromPhoneNumber,
} from "../../../utils/helperFunctions";
import { removeEmptyKeyValuesFromObject } from "../../../utils/objectHelperFunctions";

import { showErrorToast, showSuccessToast } from "../../../utils/toastHandler";
import { getYearRange } from "../../../utils/getYearRange";

import { addStudent } from "../../../features/school/services/schoolService";

export default function AddStudent({
  closeModal,
  shouldModalCloseNow = false,
  onIncrementStudent,
}) {
  const [step, setStep] = useState(1);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const [totalNumberOfSchoolDetails, setTotalNumberOfSchoolDetails] =
    useState(3);

  const [fetchedYears, setFetchedYears] = useState([]);

  const arrayOfSubClasses = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
  ];

  const years = getYearRange();
  const mb = 0;

  // const { incrementStudentCount } = useContext(UserContext);

  const formHandler = async (values, { resetForm }) => {
    const formattedData = removeWhiteSpacesFromObjValues(values);

    // Destructure all the values from the formatted data now
    const {
      studentEmail,
      firstName,
      middleName,
      lastName,

      phoneNumber,
      gender,
      currentClass,
      subClass,
      currentYear,
      currentTerm,
      countryCode,
    } = formattedData;

    try {
      //before uploading, let me adjust the student phone number to fit the API and prepare the data again

      //log the new number
      const countryCodeAndPhoneNumber = `${countryCode}${removeInitialZeroFromPhoneNumber(
        phoneNumber
      )}`;

      //prepared student data
      const studentInformation = {
        studentEmail,
        firstName,
        middleName,
        lastName,
        phoneNumber: countryCodeAndPhoneNumber,
        gender: gender.replace(gender[0], gender[0].toUpperCase()),
        currentClass,
        subClass: subClass.toUpperCase(),
        currentYear,
        currentTerm: currentTerm.replace(
          currentTerm[0],
          currentTerm[0].toUpperCase()
        ),
      };

      const payload = removeEmptyKeyValuesFromObject(studentInformation);

      //get the response obj
      const response = await addStudent(payload);

      //destructure to get only the data
      const { data } = response;

      if (data.status === true) {
        if (onIncrementStudent) onIncrementStudent();

        //function to reset the form

        //-----
        setShowSuggestion(true);

        window.location.reload();

        // TODO: Add delay of 1 second
        showSuccessToast(data.message);

        return;
        // setStep(1);
        // resetForm();
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const renderStepForm = (
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    isSubmitting
  ) => {
    switch (step) {
      case 1:
        return (
          <FormAddStudent1
            values={values}
            errors={errors}
            onClick={() => setStep(prevState => prevState + 1)}
          />
        );
      case 2:
        return (
          <FormAddStudent2
            values={values}
            errors={errors}
            isSubmitting={isSubmitting}
            touched={touched}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            onClick={() => setStep(prevState => prevState + 1)}
          />
        );
      default:
        return <h1>Default step</h1>;
    }
  };

  return (
    <div className="no-scrollbar h-[512px] w-[576px] overflow-y-scroll px-[10px]">
      <Formik
        initialValues={initialValues}
        validationSchema={addStudentSchema}
        onSubmit={formHandler}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
          setFieldValue,
          isValid,
          setValue,
        }) => (
          <>
            <div className="mb-[24px] flex flex-row items-center justify-between">
              {
                <>
                  {step === 1 ? (
                    <h3 className="mt-[8px] text-[22px] font-semibold leading-[33px]">
                      {showSuggestion
                        ? "You can add more student or cancel"
                        : "Add Student"}
                    </h3>
                  ) : (
                    <BackButton
                      clicked={() => setStep(prevState => prevState - 1)}
                    />
                  )}
                </>
              }
            </div>
            <Form>
              {renderStepForm(
                values,
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                isSubmitting
              )}
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
