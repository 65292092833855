import Button from "../ui/Button";
import TermTile from "./TermTile";

import { accountType } from "../../../constants/accountType";

import { useAuth } from "../../../store/authContext";

const TermsAndButtonSection = ({ isLoading, isDisabled }) => {
  const { user } = useAuth();
  const { userType } = user || {};
  return (
    // <div className="flex w-full items-start justify-between">
    //   <TermTile label="Term ends" detail="30/02/22" />
    //   <div className="flex flex-col items-end space-y-[32px]">
    //     <TermTile label="Term begins" detail="30/02/22" />
    //     <Button type="submit" isLoading={isLoading} disabled={isDisabled}>
    //       <div className="flex items-center justify-center">
    //         <span>Save</span>
    //       </div>
    //     </Button>
    //   </div>
    // </div>

    <>
      {userType === accountType.SCHOOL && (
        <Button
          type="submit"
          isLoading={isLoading}
          disabled={isDisabled}
          width="w-[320px]"
        >
          <div className="flex items-center justify-center">
            <span>Save</span>
          </div>
        </Button>
      )}
    </>
  );
};

export default TermsAndButtonSection;
