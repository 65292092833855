import { useState, useEffect, useRef } from "react";
import TickBox from "./ui/TickBox";
import GridTileCard from "./GridTileCard";
import ImageTitleSubtitle from "./ui/ImageTitleSubtitle";
import IconAndLabel from "./ui/IconAndLabel";
import MoreVertButton from "./ui/MoreVertButton";
import deleteIcon from "../../../src/assets/svg/delete.svg";
import { Edit } from "./icons";
import UndismissibleModal from "./modals/UndismissibleModal";
import AddSingleStudentResultModal from "./modals/AddSingleStudentResultModal";
import {
  gradeBgColor,
  gradeColor,
  gradeTextColor,
} from "../../utils/gradeColorUtil";
import { debugPrint } from "../../utils/debugPrint";

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef();

  const handleOutsideClick = e => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    isOpen && (
      <div onClick={onClose}>
        <div
          ref={modalRef}
          className="pdx-4 absolute left-[-60px] bottom-1 z-50 flex w-[120px] flex-col justify-center gap-1 rounded bg-[#F4F9FD] p-2 font-body"
        >
          {children}
        </div>
      </div>
    )
  );
};

const MarkTile = ({
  id,
  firstName,
  lastName,
  profileImage,
  resultTotal,
  gradePoint,
  gradeComment,
  studentAuthId,
  classFilter,
  termFilter,
  yearFilter,
  subtitle,
  value,
  name,
  updateValue,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditClick = () => {
    setShowModal(true);
  };
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const gradeBackgroundColor = gradeColor(gradeComment);
  const gradeTextColorCode = gradeTextColor(gradeComment);
  const gradeContainerColor = gradeBgColor(gradeComment);

  return (
    <>
      <UndismissibleModal
        showModal={showModal}
        setShowModal={setShowModal}
        header={<p className="text-[22px]  font-bold">Add Score</p>}
        maxHeight="560px"
      >
        <AddSingleStudentResultModal
          studentId={id}
          studentAuthId={studentAuthId}
          studentClass={classFilter}
          studentTerm={termFilter}
          studentYear={yearFilter}
          closeModal={() => setShowModal(false)}
        />
      </UndismissibleModal>

      <tbody key={id}>
        <tr className="border-b border-b-[#EAEBF0]">
          <td className="p-4">
            <div className="flex items-center">
              <TickBox
                name={name}
                value={value}
                updateValue={updateValue}
              ></TickBox>
            </div>
          </td>
          <td className="px-6 py-7 font-body text-sm font-medium text-[#5F6D7E]">
            {" "}
            {id}
          </td>
          <th
            scope="row"
            className="flex items-center whitespace-nowrap px-6 py-7 font-medium"
          >
            <ImageTitleSubtitle
              classes=""
              image={profileImage}
              imagePlaceHolderText={`${firstName} ${lastName}`}
              title={`${firstName} ${lastName}`}
            />
          </th>

          <td className="px-6 py-7 text-center font-body text-sm font-medium text-[#5F6D7E]">
            {resultTotal}
          </td>

          <td className="px-6 py-7 text-center font-body text-sm font-medium text-[#5F6D7E]">
            {gradePoint ?? "-- --"}
          </td>

          <td className="px-6 py-7 font-body text-sm font-medium text-[#272D37]">
            <div
              className={`inline-flex w-[90px] cursor-pointer items-center justify-center gap-1 rounded-[5px] py-[2px] text-sm ${gradeContainerColor} ${gradeTextColorCode}`}
            >
              <div
                className={`h-[6px] w-[6px] rounded-full ${gradeBackgroundColor}`}
              ></div>{" "}
              {gradeComment ?? "-- --"}
            </div>
          </td>

          <td className="relative px-6 py-7 text-center font-medium">
            <span
              className="absoluste cursor-pointer text-[24px]  font-medium text-[#272D37]"
              onClick={openModal}
            >
              ...
            </span>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
              {/* Content of the modal */}
              <div
                className="flex cursor-pointer items-center gap-4 rounded bg-white px-[8px] py-[4px]"
                onClick={handleEditClick}
              >
                <Edit /> Edit
              </div>
              <div className="flex cursor-pointer items-center gap-4 rounded bg-white px-[8px] py-[4px]">
                <img src={deleteIcon} className="h-auto w-[1em]" alt="delete" />
                <span className="text-sm ">Delete</span>
              </div>
            </Modal>
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default MarkTile;
