export const initialValues = {
  studentEmail: "",
  firstName: "",
  middleName: "",
  lastName: "",
  countryCode: "+234",
  phoneNumber: "",
  gender: "",
  currentClass: "",
  subClass: "",
  currentYear: "",
  currentTerm: "",
};
