import { useState } from "react";

import * as Icons from "../../../shared/components/icons";
import AddSubject from "../forms/AddSubject";
import UndismissibleModal from "../modals/UndismissibleModal";

import { accountType } from "../../../constants/accountType";
import { useAuth } from "../../../store/authContext";

export const AddSubjectButton = () => {
  const { user } = useAuth();
  const userType = user?.userType;

  const [showSubjectModal, setShowSubjectModal] = useState(false);

  const shouldShowSubjectModal = () => {
    setShowSubjectModal((prev) => !prev);
  };

  const addSubjectButtonContent = () => {
    return (
      <>
        <UndismissibleModal
          showModal={showSubjectModal}
          showCloseButton={false}
          maxHeight="200px"
        >
          <AddSubject closeModal={shouldShowSubjectModal} />
        </UndismissibleModal>
        <button
          className={
            "inline-flex h-[40px] items-center justify-center whitespace-nowrap rounded-[5px] w-[156px] px-[15px] text-[14px] font-semibold text-white"
          }
          style={{ background: "#437EF7" }}
          onClick={shouldShowSubjectModal}
        >
          <span className="mr-[12px] inline-flex h-[14px] w-[14px]">
            <Icons.Plus className="flex-0 h-full" />
          </span>
          Add Subject
        </button>
      </>
    );
  };

  return (
    <>{userType === accountType.SCHOOL ? addSubjectButtonContent() : null}</>
  );
};
