import { Form, Formik } from "formik";

import TextFieldFormik from "../../TextFieldFormik";
import { PlainButton } from "../../ui/Buttons";

import { fundWalletSchema } from "../../../../utils/validationSchemas/fundWalletSchema";

import { fundWallet } from "../../../../features/school/services/schoolService";
import { showErrorToast } from "../../../../utils/toastHandler";
import { debugPrint } from "../../../../utils/debugPrint";

const FundWalletForm = ({ paymentMethod, paymentType, onIsQuerying }) => {
  const initialValue = {
    amount: "",
  };

  const formHandler = async (values) => {
    onIsQuerying(true);

    const payload = {
      amount: +values.amount.trim(),
      paymentMethod: paymentMethod,
      paymentType: paymentType,
      callbackUrl: `${window.location.origin}${window.location.pathname}?modal=true`,
    };

    debugPrint("Log to teach something");

    debugPrint("FundWalletForm - formHandler -- payload ->", payload);

    try {
      const { data } = await fundWallet(payload);


      debugPrint("FundWalletForm - formHandler -- data ->", data);

      if (data?.status === false) {
        throw new Error(data?.message ?? "Something went wrong. Try again");
      }

      window.location.href = data?.authorizationUrl;
    } catch (err) {
      showErrorToast(err?.message ?? "Failed");
    } finally {
      onIsQuerying(false);
    }
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={fundWalletSchema}
      onSubmit={formHandler}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        setTouched,
        resetForm,
      }) => (
        <Form className="flex w-full flex-col px-[48px]">
          <div className="mb-[42px] flex justify-center space-x-[20px]">
            {/* Amount input */}

            <div className="flex items-end space-x-[6px]">
              <div className="flex flex-col">
                <p className="mb-[8px] text-[14px] font-bold text-[#7D8592]">
                  Amount
                </p>
                <div className="flex h-[48px] w-[64px] items-center justify-center rounded-[14px] border-[1px] border-[#D8E0F0] font-medium text-[#7D8592]">
                  NGN
                </div>
              </div>

              <div className="relative">
                <TextFieldFormik
                  name="amount"
                  placeholder="20,000"
                  placeholderStyle="placeholder:text-[#D8E0F0]"
                  height="h-[48px]"
                  labelFieldSpacing="space-y-[6px]"
                  showError={false}
                />
                {touched.amount && errors.amount && (
                  <span className="absolute bottom-[-24px] left-0 text-[14px] text-accent">
                    {errors.amount}
                  </span>
                )}
              </div>
            </div>

            {/* Gateway */}
            <div className="flex flex-col">
              <label className="mb-[8px] text-[14px] font-bold text-[#7D8592]">
                Select Gateway
              </label>
              <select
                defaultValue="Paystack"
                className="h-[48px] w-[192px] rounded-[14px] border-[1px] border-[#D8E0F0] text-black"
              >
                <option value="Paystack">Paystack</option>
              </select>
            </div>
          </div>

          <PlainButton
            className="self-end"
            width="w-[128px]"
            type="submit"
            isLoading={isSubmitting}
            loadingSpinnerColor="border-white"
            disabled={errors.amount || values.amount === "" ? true : false}
          >
            Proceed
          </PlainButton>
        </Form>
      )}
    </Formik>
  );
};

export default FundWalletForm;
