import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CreatePayrollOtpModal from "./payroll/components/CreatePayrollOtpModal";
import PageLoading from "../../../shared/components/ui/PageLoading";
import PageSearchIconAndLabel from "../../../shared/components/PageSearchIconAndLabel";
import PayrollCard from "./payroll/components/PayrollCard";
import PayrollTableHeader from "./payroll/components/PayrollTableHeader";
import Modal from "../../../shared/components/ui/Modal";
import SearchField from "../../../shared/components/ui/SearchField";

import { AddButtonPlain } from "../../../shared/components/ui/Buttons";
import { Delete } from "../../../shared/components/icons";

import {
  createPayroll,
  makePayrollPayment,
  resendPayrollOtpCode,
} from "../services/schoolService";
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from "../../../utils/toastHandler";
import { getCreatePayrollStatus, payrollStatus } from "./payroll/payrollStatus";

import { usePayroll } from "../store/payrollStore/payrollContext";

import { debugPrint } from "../../../utils/debugPrint";

const CreatePayroll = () => {
  const { state, fetchAllTeachers, addPayroll, updatePayroll } = usePayroll();

  const { isTeacherLoading, teacherError, teachers } = state || {};

  const location = useLocation();
  const navigate = useNavigate();

  const createPayrollObj = location?.state?.createPayrollObj;

  const { year, month, role } = createPayrollObj || {};

  useEffect(() => {
    if (createPayrollObj == null) {
      navigate("/back-office/payroll-manager");

      return;
    }

    fetchAllTeachers();
  }, []);

  const [selectedStaffIds, setSelectedStaffIds] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [payrollState, setPayrollState] = useState({
    isLoading: false,
    createPayrollSuccess: false,
    payroll: null,
    resendOtpCount: 1,

    payrollStatus: payrollStatus.PROCESSING,
  });

  const handleSelectAll = () => {
    if (selectedStaffIds.length === teachers.length) {
      // Deselect all
      setSelectedStaffIds([]);

      return;
    }

    const staffIds = teachers.map(item => item.id);

    setSelectedStaffIds(staffIds);
  };

  const updateSelectedStaff = staffId => {
    const cachedSelectedStaffIds = [...selectedStaffIds];

    let selectedIndex = -1;

    for (let index = 0; index < selectedStaffIds.length; index++) {
      if (staffId === selectedStaffIds[index]) {
        selectedIndex = index;

        break;
      }
    }

    if (selectedIndex === -1) {
      // Add staff to list
      cachedSelectedStaffIds.push(staffId);
    } else {
      // Remove staff from list
      cachedSelectedStaffIds.splice(selectedIndex, 1);
    }

    setSelectedStaffIds(cachedSelectedStaffIds);
  };

  const updatePayrollStatus = (status, shouldCloseModal = false) => {
    setPayrollState(prevState => {
      return { ...prevState, payrollStatus: status };
    });

    if (shouldCloseModal) {
      setShowModal(false);
    }
  };

  const createPayrollHandler = async () => {
    setPayrollState(prevState => {
      return { ...prevState, isLoading: true };
    });
    try {
      const arrayOfRemovedStaffIds = [];

      if (selectedStaffIds.length >= 1) {
        const teacherIds = teachers.map(item => item.id);
        let pointer = 0;

        while (teacherIds.length < pointer) {
          if (!selectedStaffIds.includes(teacherIds[pointer])) {
            arrayOfRemovedStaffIds.push(teacherIds[pointer]);
          }

          pointer++;
        }
      }

      const payload = {
        year: Number(year),
        month: month.toLowerCase(),
        role: role.toLowerCase(),
        arrayOfRemovedStaffIds: arrayOfRemovedStaffIds,
      };

      debugPrint("CreatePayroll - createPayrollHandler -- payload ->", payload);

      const { data } = await createPayroll(payload);

      debugPrint("CreatePayroll - createPayrollHandler -- data ->", data);

      const { status, message } = data;

      if (status === true) {
        debugPrint("CreatePayroll - createPayrollHandler -- true");
        if (message?.toLowerCase() === "payroll already prepared") {
          showInfoToast(message);

          return;
        }

        showSuccessToast(message);

        const { data: payroll } = data;

        setPayrollState(prevState => {
          return { ...prevState, payroll: payroll };
        });

        updatePayrollStatus(payrollStatus.PENDING_APPROVAL);

        setShowModal(true);

        // Adding created payroll to payrolls in state
        addPayroll(payroll);
      } else {
        throw new Error(message ?? "Something went wrong. Contact admin");
      }
    } catch (err) {
      showErrorToast(err?.message);
    } finally {
      setPayrollState(prevState => {
        return { ...prevState, isLoading: false };
      });
    }
  };

  const approvePayrollHandler = async () => {
    setShowModal(true);

    try {
      const { schoolAuthId, id } = payrollState.payroll;

      const payload = {
        schoolAuthId: schoolAuthId,
        payrollId: id,
      };

      const { data } = await resendPayrollOtpCode(payload);

      const { status, message } = data;

      if (status === false) {
        throw new Error(message ?? "Problem sending OTP. Try again later.");
      }
    } catch (err) {
      showErrorToast(err?.message ?? "Failed");
    }
  };

  const handleMakePayment = async () => {
    setPayrollState(prevState => {
      return { ...prevState, isLoading: true };
    });
    try {
      const payload = {
        payrollId: payrollState.payroll.id,
        paymentType: "payNow",
      };

      const { data } = await makePayrollPayment(payload);

      const { status, message } = data;

      if (status === true) {
        showSuccessToast(message ?? "Success");

        // Change the payroll "PayrollProcessingState" to "completed"
        const updatedPayroll = {
          ...payrollState.payroll,
          PayrollProcessingState: payrollStatus.COMPLETED,
        };

        // Updating the payroll in state
        updatePayroll(updatedPayroll);

        navigate(-1);
      } else {
        throw new Error(
          message ?? "Failed to trigger payment. Please contact support"
        );
      }
    } catch (err) {
      showErrorToast(err.message);
    } finally {
      setPayrollState(prevState => {
        return { ...prevState, isLoading: false };
      });
    }
  };

  const payrollHandler = async () => {

    if (payrollState.payrollStatus === payrollStatus.PROCESSING) {
      await createPayrollHandler();

      return;
    }

    if (payrollState.payrollStatus === payrollStatus.PENDING_APPROVAL) {
      await approvePayrollHandler();
    }

    if (payrollState.payrollStatus === payrollStatus.APPROVED) {
      await handleMakePayment();
    }
  };

  const uiBuilder = () => {
    if (teacherError ?? false) {
      return (
        <div className="flex h-full items-center justify-center">
          <PageSearchIconAndLabel label={teacherError} />
        </div>
      );
    }

    if (isTeacherLoading ?? false) {
      return (
        <div className="flex h-full items-center justify-center">
          <PageLoading />
        </div>
      );
    }

    if (teachers?.length === 0) {
      return (
        <div className="flex h-full items-center justify-center">
          <PageSearchIconAndLabel label="No teachers found" />
        </div>
      );
    }

    return (
      <>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-[16px]">
            <Delete />
            <SearchField />
          </div>
          <div className="mb-[16px] flex items-center justify-between gap-[12px]">
            <AddButtonPlain
              showIcon={false}
              className={`h-[40px] bg-[#437EF7] px-[40px]`}
              clicked={payrollHandler}
              isLoading={payrollState.isLoading}
            >
              {payrollState.createPayrollSuccess === false &&
                getCreatePayrollStatus(payrollState.payrollStatus)}
            </AddButtonPlain>
          </div>
        </div>

        <div className="relative h-full w-full overflow-x-auto scroll-smooth rounded-b-[10px]">
          <table className="w-full text-left text-sm rtl:text-right">
            <PayrollTableHeader
              label1="ID"
              label2="All Staffs"
              label3="Role"
              label4="Salary Duration"
              label5="Salary"
              label6="Action"
              showCheckBox={true}
              checked={teachers?.length === selectedStaffIds?.length}
              onChange={handleSelectAll}
            />

            {teachers?.map((staff, index) => (
              <PayrollCard
                key={staff?.id ?? index}
                data1={staff?.id ?? "-- --"}
                data2={`${staff?.firstName} ${staff?.lastName}`}
                data3={role}
                data4={staff?.salaryDuration ?? "-- --"}
                data5={staff?.salary ?? "-- --"}
                actionIcon={<Delete />}
                showCheckBox={true}
                checked={selectedStaffIds.includes(staff.id)}
                onChange={() => updateSelectedStaff(staff.id)}
              />
            ))}
          </table>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal showModal={showModal} onClose={() => setShowModal(false)}>
        <CreatePayrollOtpModal
          payroll={payrollState.payroll}
          onUpdatePayrollStatus={updatePayrollStatus}
        />
      </Modal>
      <div className="mb-[24px] flex flex-col overflow-y-auto">
        <h1 className="mb-[24px] text-[28px] font-semibold text-[#272D37]">
          Payroll Manager
        </h1>

        <div className="flex h-[600px] w-full flex-col rounded-[10px] border-[1px] border-solid border-[#EAEBF0] px-[16px] py-[24px]">
          {uiBuilder()}
        </div>
      </div>
    </>
  );
};

export default CreatePayroll;
