import { teacherDashboardActions } from "./teacherDashboardActions";

export const initialState = {
  isLoading: false,
  firstName: "",
  students: 0,
  teachers: 0,
  classes: [], // Using [teacherClasses] from the login response
  subjects: [],
  error: null,
};

const teacherDashboardReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case teacherDashboardActions.UPDATE_LOADING_STATE:
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    case teacherDashboardActions.REQUEST_SUCCESS:
      return {
        ...state,
        firstName: payload.firstName,
        students: payload.students,
        teachers: payload.teachers,
        classes: [...payload.classes],
        subjects: [...payload.subjects],
      };
    case teacherDashboardActions.REQUEST_FAILURE:
      return {
        ...state,
        error: payload.error,
      };
    default:
      throw new Error(
        `No case for type ${type} found in teacherDashboardReducer`
      );
  }
};

export default teacherDashboardReducer;
