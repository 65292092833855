import CheckMark from "./../../../components/icons/CheckMark.js";

const SuccessModal = () => {
  return (
    <div className='flex h-[320px] w-[576px] flex-col items-center justify-center'>
      <p className='mb-[31px] text-[24px] font-semibold text-black-shade1'>
        Success
      </p>

      <div className='mb-[39px] rounded-full border-[4px] border border-success p-[8px]'>
        <CheckMark className='text-[32px] text-success' />
      </div>

      <p className='text-[20px] font-medium tracking-[-0.02em]'>
        Your payment was successful
      </p>
    </div>
  );
};

export default SuccessModal;
