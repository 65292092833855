import { useState } from "react";
import Tooltip from "rc-tooltip";

import MoreVertButton from "./MoreVertButton";
import IconAndLabel from "./IconAndLabel";
import SvgBooks from "../icons/Books";
import UndismissibleModal from "../modals/UndismissibleModal";
import { Delete, Edit } from "../icons";

import { reduceStringLength } from "../../../utils/helperFunctions";
import { accountType } from "../../../constants/accountType";

import { useSubject } from "../../../store/subjectStore/subjectContext";
import { useAuth } from "../../../store/authContext";

import { debugPrint } from "../../../utils/debugPrint";

function SubjectPane({ subject = "mathematics" }) {
  const { user } = useAuth();

  const { userType } = user;

  const { handleDeleteSubject } = useSubject();

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <UndismissibleModal
        showModal={showModal}
        setShowModal={setShowModal}
        header={<p className="text-[22px] font-bold">Edit Exam Type</p>}
      />
      <div
        className={`flex h-[88px] w-full items-center  justify-between border-t-2 bg-white   px-6  ${
          userType === accountType.SCHOOL && "cursor-pointer"
        }`}
      >
        <div className="flex items-center  justify-center space-x-6">
          <input
            name="rememberMe"
            type="checkbox"
            id="rememberMe"
            className=" h-[20px] w-[20px] items-center rounded  border-[1px] text-primary focus:ring-[#D8E0F0]"
          />
          {/* <SvgBooks className="mr-[28px]" /> */}
          <Tooltip
            id={subject}
            placement="topLeft"
            mouseLeaveDelay={0}
            destroyTooltipOnHide={true}
            trigger={["hover"]}
            overlay={<p className="capitalize">{subject}</p>}
            overlayClassName="w-[300px]"
          >
            <p className="col-span-7 cursor-default justify-self-start capitalize">
              {reduceStringLength(subject, 14)}
            </p>
          </Tooltip>
        </div>
        {userType === accountType.SCHOOL && (
          <MoreVertButton>
            <div className="rounded-[14px] bg-white ">
              {/* No editing for subjects. Remove for now */}
              {/* <IconAndLabel
                label="Edit"
                onClick={() => debugPrint("Edit clicked")}
              >
                <Edit className="text-black" />
              </IconAndLabel> */}
              <IconAndLabel
                label="Delete"
                onClick={() => handleDeleteSubject(subject)}
              >
                <Delete className="text-danger" />
              </IconAndLabel>
            </div>
          </MoreVertButton>
        )}
      </div>
    </>
  );
}

export default SubjectPane;
