import * as React from "react";

const SvgPointingUpArrow = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m6.613.21.094.083 5 5a1 1 0 0 1-1.32 1.497l-.094-.083L7 3.415V15a1 1 0 0 1-1.993.117L5 15V3.415L1.707 6.707a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1-.083-1.32l.083-.094 5-5A1 1 0 0 1 6.613.21Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPointingUpArrow;
