import GridTileCard from "../../../shared/components/GridTileCard";
import Button from "../../../shared/components/ui/Button";

const StudentResultCard = ({
  studentClass = "-- --",
  studentTerm = "-- --",
  studentYear = "-- --",
  reason,
  published = false,
  hasPaidForResult = false,
  onClick,
}) => {
  return (
    <GridTileCard
      classes="mb-[20px] last:mb-0 first:mb-0"
      verticalPadding="py-[20px]"
    >
      <p className="col-span-4 text-[16px] font-bold uppercase">
        {studentClass}
      </p>
      <p className="col-span-2 place-self-center text-[16px]">{studentTerm}</p>
      <p className="col-span-1 place-self-center text-[16px]">{studentYear}</p>
      <p className="col-span-3 place-self-center text-center text-[16px]">
        {reason != null
          ? reason
          : hasPaidForResult === false
          ? "You're yet to pay for your result"
          : "Published"}
      </p>

      <Button
        width="w-auto"
        extraClasses="col-span-2"
        disabled={published === false || hasPaidForResult === false}
        // onClick={published && hasPaidForResult ? () => onClick : null}
        onClick={onClick}
      >
        <span className="font-bold">VIEW</span>
      </Button>
    </GridTileCard>
  );
};

export default StudentResultCard;
