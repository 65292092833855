import TextFieldFormik from "../../../shared/components/TextFieldFormik";
import SelectFormik from "../../../shared/components/ui/SelectFormik";
import LinkButton from "../../../shared/components/ui/LinkButton";
import CountrySelect from "../../../shared/components/ui/CountrySelect";
import StateSelect from "../../../shared/components/ui/StateSelect";

const Wrapper = ({ children, extraClasses }) => {
  return <div className={`w-full ${extraClasses}`}>{children}</div>;
};

// const Wrapper = ({ children, extraClasses }) => {
//   return <div className={`w-full ${extraClasses}`}>{children}</div>;
// };

const SchoolContactInfo = ({ values, errors }) => {
  const shouldDisableButton = () => {
    if (
      errors.phone ||
      values.phone === "" ||
      errors.country ||
      values.country === "" ||
      errors.state ||
      values.state === "" ||
      errors.LGA ||
      values.LGA === "" ||
      errors.postalCode ||
      values.postalCode === "" ||
      errors.address ||
      values.address === ""
    )
      return true;

    return false;
  };

  return (
    <div className='flex flex-col items-stretch justify-center p-8'>
      <h2 className='text-center font-display text-sm uppercase text-[#3F8CFF] mb-[8px]'>
        Step 2/4
      </h2>
      <h1 className='mb-[51px] text-center text-[36px] font-bold'>
        Enter contact info
      </h1>
      <div className='space-y-[16px]'>
        <div className='flex flex-col'>
          <span className='mb-2 text-[14px] font-semibold text-[#7D8592]'>
            Mobile Number
          </span>
          <div className='flex flex-row space-x-[16px]'>
            <TextFieldFormik name='phone' placeholder='Enter mobile number' />
          </div>
        </div>

        <CountrySelect name='country' label='Country' />
        <StateSelect name='state' country={values.country} label='State' />

        <TextFieldFormik
          extraClasses='mb-[15px]'
          label='LGA'
          name='LGA'
          placeholder='Enter LGA'
        />
        <TextFieldFormik
          extraClasses='mb-[15px]'
          label='Postal'
          name='postalCode'
          placeholder='Enter postal code'
        />

        <TextFieldFormik
          extraClasses='mb-[15px]'
          label='Address'
          name='address'
          placeholder='Enter address'
        />
      </div>

      <LinkButton
        to={"school-registration-info"}
        extraClasses='mt-[47.78px]'
        label='Next'
        disabled={shouldDisableButton()}
      />
    </div>
  );
};

export default SchoolContactInfo;
