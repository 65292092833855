import { PropTypes } from "prop-types";

const PageLoading = ({ classes, full = false, size = "96px" }) => {
  return (
    <span
      className={`${classes} flex items-center justify-center ${
        full && `h-full w-full overflow-hidden`
      }`}
    >
      <svg
        className={`h-[${size}] w-[${size}] animate-spin rounded-full border-l-4 border-r-2 border-primary`}
        viewBox="0 0 24 24"
      ></svg>
    </span>
  );
};

PageLoading.propTypes = {
  classes: PropTypes.string,
  full: PropTypes.bool,
  size: PropTypes.string,
};

export default PageLoading;
