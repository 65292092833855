import { useState, useEffect } from "react";

import AttendanceCard from "../../../shared/components/AttendanceCard";
import LatestEvents from "../../../shared/components/LatestEvents";
import StrengthCard from "../../../shared/components/StrengthCard";
import PageLoading from "../../../shared/components/ui/PageLoading";
import { AddStudentButton } from "../../../shared/components/ui/AddStudentButton";
import { AddTeacherButton } from "../../../shared/components/ui/AddTeacherButton";

import useAxiosFetch from "../../../hooks/useAxiosFetch";
import urlSettings from "../../../config/apiConfig/apiConstants";
import WelcomeNameAndDate from "../../../shared/components/WelcomeNameAndDate";
import PageSearchIconAndLabel from "../../../shared/components/PageSearchIconAndLabel";
import WalletCard from "../../../shared/components/wallet/WalletCard";
import Modal from "../../../shared/components/ui/Modal";
import AddTeacher from "../../../shared/components/forms/AddTeacher";
import AddStudent from "../../../shared/components/forms/AddStudent";

const SchoolDashboard = () => {
  const [dashboardData, setDashboardData] = useState();

  const [showAddTeacherModal, setShowAddTeacherModal] = useState(false);
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);

  // TODO: Use the constant OR .env base url here
  const { isLoading, data, error } = useAxiosFetch(
    `${urlSettings.SCHOOL_URL}get-school-details`
  );

  useEffect(() => {
    setDashboardData(data);
  }, [data]);

  let studentCount, teacherCount, subjectCount, adminCount;

  if (dashboardData) {
    const {
      totalNumberOfStudents,
      totalNumberOfTeachers,
      totalSubjects,
      adminCount: adminNumber,
    } = dashboardData;

    studentCount = totalNumberOfStudents;
    teacherCount = totalNumberOfTeachers;
    subjectCount = totalSubjects;
    adminCount = adminNumber;
  }

  const incrementStudentCount = () => {
    const cachedCopy = { ...dashboardData };
    let { totalNumberOfStudents } = cachedCopy;
    totalNumberOfStudents = totalNumberOfStudents + 1;

    const updatedData = { ...cachedCopy, totalNumberOfStudents };
    setDashboardData(updatedData);
  };

  const onIncrementTeacherCount = () => {
    const cachedCopy = { ...dashboardData };
    let { totalNumberOfTeachers } = cachedCopy;
    totalNumberOfTeachers = totalNumberOfTeachers + 1;

    const updatedData = { ...cachedCopy, totalNumberOfTeachers };
    setDashboardData(updatedData);
  };

  return (
    <>
      <Modal
        showModal={showAddTeacherModal}
        onClose={() => setShowAddTeacherModal(false)}
      >
        <AddTeacher incrementTeacherCount={onIncrementTeacherCount} />
      </Modal>
      <Modal
        showModal={showAddStudentModal}
        onClose={() => setShowAddStudentModal(false)}
      >
        <AddStudent onIncrementStudent={incrementStudentCount} />
      </Modal>
      {error ? (
        <PageSearchIconAndLabel label="Oops! Something went wrong" />
      ) : isLoading ? (
        <PageLoading />
      ) : (
        <>
          <div className="gap-x grid grid-cols-12 content-start justify-items-stretch px-[4px] md:px-[px]">
            <div className="col-span-12">
              <WelcomeNameAndDate name="Admin" />
            </div>

            {/* <div className="col-span-4 grid grid-cols-2 items-center gap-x-2 justify-self-end">
              <AddStudentButton onIncrementStudent={incrementStudentCount} />

              <AddTeacherButton
                incrementTeacherCount={onIncrementTeacherCount}
              />
            </div> */}

            {/* Dashboard cards */}

            <div className="col-span-12">
              <div className="gap-x-4 gap-y-6 space-y-[16px] md:flex md:flex-col md:space-y-0 xl:grid xl:grid-cols-12 xl:gap-y-0 xl:space-x-0 2xl:gap-y-0 2xl:space-x-0">
                <div className="col-span-6">
                  <WalletCard />
                </div>

                <div className="col-span-3">
                  <StrengthCard.Student
                    value={studentCount ?? 0}
                    onAddClicked={() => setShowAddStudentModal(true)}
                  />
                </div>
                <div className="col-span-3">
                  <StrengthCard.Teacher
                    value={teacherCount ?? 0}
                    onAddClicked={() => setShowAddTeacherModal(true)}
                  />
                </div>

                {/* <StrengthCard.Subject value={subjectCount ?? 0} />
                <StrengthCard.Admin value="0" /> */}
              </div>
            </div>

            {/* Event panel */}
            <div className="col-span-4">
              <div className="grid grid-cols-1 gap-x-[36px]">
                {/* <AttendanceCard /> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SchoolDashboard;
