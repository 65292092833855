import { useState } from "react";

import UndismissibleModal from "./modals/UndismissibleModal";
import StudentProfile from "./modals/profile/student/StudentProfile";
import GridTileCard from "./GridTileCard";
import MoreVertButton from "./ui/MoreVertButton";
import { Delete, Edit } from "./icons";
import IconAndLabel from "./ui/IconAndLabel";
import AddGrade from "./forms/AddGrade";

import { useGrades } from "../../store/resultCommentsStore/resultCommentsContext";
import { debugPrint } from "../../utils/debugPrint";

export default function GradePane({
  data,
  onEdit = () => null,
  onDelete = () => null,
  //
  moreVert = true,
  extraGradeStyles,
}) {
  const [showModal, setShowModal] = useState(false);

  const [gradeDataToEdit, setGradeDataToEdit] = useState(null);

  const { state, handleGetAllGrades, handleEditSingleGrade, currentlyEditing } =
    useGrades();

  const { allGrades, error, isAddingGrade, isGettingGrade, message } = state;
  return (
    <>
      <UndismissibleModal 
        header={<p className="text-[22px]  font-bold ">Edit Grade</p>}
        showModal={showModal}
        setShowModal={setShowModal}
        width="108px"
      >
        <div className="pt-[30px]">
          <AddGrade
            gradeDataToEdit={gradeDataToEdit}
            handleEdit={() => null}
            showHeading={false}
            closeModal={() => setShowModal(false)}
          />
        </div>
      </UndismissibleModal>
      <div className=" border-t-[1px]">
      <GridTileCard
        gridColumns="grid-cols-5"
        classes="mb-[20px] last:mb-0 items-center cursor-pointer"
        verticalPadding="py-[20px]"
      >
        
        {/* Grade */}
        <div className="flex flex-col justify-self-start ">
          <p
            className={`text-[14px] font-semibold leading-[18px]  text-[#5F6D7E] ${extraGradeStyles}`}
          >
            {data?.grade ?? "Grades"}
          </p>
        </div>

        {/* Grade point */}
        <div className="flex flex-col justify-self-center">
          <p className="text-[14px] font-semibold leading-[18px]  text-[#5F6D7E]">
            {data?.gradePoint ?? "Point"}
          </p>
        </div>

        {/* Mark from */}
        <div className="flex flex-col justify-self-center">
          {/* {showHeader && (
            <p className="text-[16px] text-[#91929E]">Mark From</p>
          )} */}
          <p className="text-[14px] font-semibold leading-[18px]  text-[#5F6D7E]">
            {data?.minAverage ?? "Mark from"}
          </p>
        </div>

        {/* Mark to */}
        <div className="col-span-1 flex flex-col justify-self-center">
          <p className="text-[14px] font-semibold leading-[18px]  text-[#5F6D7E]">
            {data?.maxAverage ?? "mark to"}
          </p>
        </div>
        {!moreVert && (
          
          <div className="col-span-1 flex flex-col justify-self-end">
            
            <p className="text-[14px] font-semibold leading-[18px]  text-[#5F6D7E]">Action</p>
          </div>
        )}
        {/* More vert */}
        {moreVert && (
          <span
            className="col-span-1 place-self-end"
            onClick={() => {
              setShowModal(true);
              setGradeDataToEdit(data);
            }}
          >
            <span className=" text-[30px] mr-3 text-[#101828]">...</span>
          </span>
        )}
      </GridTileCard>
      </div>

     
    </>
  );
}
