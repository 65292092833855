import SchoolDashboard from "../features/school/components/SchoolDashboard";

import { TeacherDashboardProvider } from "../features/teacher/store/teacherDashboardStore/teacherDashboardContext";
import TeacherDashboard from "../features/teacher/components/TeacherDashboard";

import { StudentDashboardProvider } from "../features/student/store/studentDashboardContext";
import StudentDashboard from "../features/student/components/StudentDashboard";

import { accountType } from "../constants/accountType";

import { useAuth } from "../store/authContext";

const DashboardSelector = () => {
  const { user } = useAuth();

  switch (user?.userType) {
    // case "school":
    //   return <TeacherDashboard />;
    case accountType.SCHOOL:
      return <SchoolDashboard />;
    case accountType.TEACHER:
      return (
        <TeacherDashboardProvider>
          <TeacherDashboard />
        </TeacherDashboardProvider>
      );
    case accountType.STUDENT:
      return (
        <StudentDashboardProvider>
          <StudentDashboard />
        </StudentDashboardProvider>
      );
    default:
      return <SchoolDashboard />;
  }
};

export default DashboardSelector;
