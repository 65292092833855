import TextFieldFormik from "../../TextFieldFormik";
import CountrySelect from "../../ui/CountrySelect";
import StateSelect from "../../ui/StateSelect";
import NormalButton from "../../ui/NormalButton";

const FormAddTeacher1 = ({ values, errors, onClick }) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between space-x-[16px]">
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="First Name"
          name="firstName"
          placeholder="First"
        />
        <TextFieldFormik 
          extraClasses="mb-[15px]"
          label="Last Name"
          name="lastName"
          placeholder="Last"
        />
      </div>
      <TextFieldFormik
        extraClasses="mb-[15px]"
        label="Middle Name (optional)"
        name="middleName"
        placeholder="Middle"
      />
      <TextFieldFormik
        extraClasses="mb-[15px]"
        label="Email Address"
        name="teacherEmail"
        placeholder="youremail@gmail.com"
      />

      <div className={`flex flex-row space-x-[16px]`}>
        <CountrySelect name="country" label="Country of origin" />
        <StateSelect
          name="state"
          country={values.country}
          label="State of origin"
        />
      </div>
      <TextFieldFormik
        extraClasses="mb-[15px]"
        label="Residential address"
        name="address"
        placeholder="No. 1 Your Address"
      />

      <NormalButton
        showIcon={false}
        layout="self-end"
        clicked={onClick}
        label="Next"
        disabled={
          (errors.firstName ||
            values.firstName === "" ||
            errors.middleName ||
            values.middleName === "" ||
            errors.lastName ||
            values.lastName === "" ||
            errors.teacherEmail ||
            values.teacherEmail === "",
          errors.country ||
          values.country === "" ||
          errors.state ||
          values.state === "" ||
          errors.address ||
          values.address === ""
            ? true
            : false)
        }
      />
    </div>
  );
};

export default FormAddTeacher1;
