const Chip = ({ name = "mathematics", total, onRemove }) => {
  return (
    <div className="relative flex h-[30px] items-center rounded-[12px] bg-grey py-[3px] px-[6px] text-[12px]">
      <p className="font-normal">{`${name}`}</p>
      {total && (
        <p className="ml-[4px] text-[12px] font-semibold">{`  — ${total}`}</p>
      )}

      <span className="ml-[4px] flex h-[16px] w-[16px] items-center justify-center rounded-full bg-grey-darker pb-[2px]">
        <button type="button" className="text-grey-light" onClick={onRemove}>
          x
        </button>
      </span>
    </div>
  );
};

export default Chip;
