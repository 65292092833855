import { createContext, useContext, useEffect, useReducer } from "react";

import { teacherDashboardActions } from "./teacherDashboardActions";
import teacherDashboardReducer, {
  initialState,
} from "./teacherDashboardReducer";

import { useAuth } from "../../../../store/authContext";

import { getTeacherDetails } from "../../services/teacherService";
import { debugPrint } from "../../../../utils/debugPrint";

const TeacherDashboardContext = createContext(null);

TeacherDashboardContext.displayName = "TeacherDashboardContext";

const TeacherDashboardProvider = ({ children }) => {
  const { user } = useAuth();

  const [state, dispatch] = useReducer(teacherDashboardReducer, initialState);

  useEffect(() => {
    const fetchTeacherDashboardData = async () => {
      dispatch({
        type: teacherDashboardActions.UPDATE_LOADING_STATE,
        payload: { isLoading: true },
      });

      try {
        const { data } = await getTeacherDetails(user?.id);

        debugPrint("teacherDashboardContext - useEffect -- fetchTeacherDashboardData -> data =", data);

        if (data.status === true) {
          const { data: innerData } = data;

          const { firstName, studentsCount, teachersCount, classes, subjects } =
            innerData;

          dispatch({
            type: teacherDashboardActions.REQUEST_SUCCESS,
            payload: {
              firstName,
              students: studentsCount,
              teachers: teachersCount,
              classes: [...classes],
              subjects: [...subjects],
            },
          });
        } else {
          throw new Error(data?.message ?? "Something went wrong");
        }
      } catch (e) {
        dispatch({
          type: teacherDashboardActions.REQUEST_FAILURE,
          payload: { error: e?.message ?? "Something went wrong" },
        });
      } finally {
        dispatch({
          type: teacherDashboardActions.UPDATE_LOADING_STATE,
          payload: { isLoading: false },
        });
      }
    };

    fetchTeacherDashboardData();
  }, []);

  const values = {
    state,
  };

  return (
    <TeacherDashboardContext.Provider value={values}>
      {children}
    </TeacherDashboardContext.Provider>
  );
};

const useTeacherDashboard = () => {
  const context = useContext(TeacherDashboardContext);

  if (context === undefined) {
    throw new Error(
      "useTeacherDashboard must be used within TeacherDashboardContext"
    );
  }

  return context;
};

export { TeacherDashboardProvider, useTeacherDashboard };
