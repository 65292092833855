import { useEffect, useState } from "react";

import { downloadStudentPdfResultSchool } from "../../../features/school/services/schoolService";
import { studentDownloadPdfResult } from "../../../features/student/services/studentService";

import { showErrorToast, showInfoToast } from "../../../utils/toastHandler";

import { accountType } from "../../../constants/accountType";

import client from "../../../config/apiConfig/apiConfig";

import { useAuth } from "../../../store/authContext";

const DownloadResultPDF = ({
  schoolAuthId,
  studentAuthId,
  studentClass,
  studentTerm,
  studentYear,
}) => {
  const { user } = useAuth();

  const { userType } = user || {};

  const [download, setDownload] = useState(false);

  const [progress, setProgress] = useState(100);

  const _handleDownloadSelector = async () => {
    switch (userType) {
      case accountType.SCHOOL:
        const schoolStudentResult = await client.schoolClient.get(
          "download-result-sheet",
          {
            responseType: "blob",
            params: { studentAuthId, studentClass, studentTerm, studentYear },
            onDownloadProgress: (progressEvent) => {
              const loaded = progressEvent.loaded;
              const total = progressEvent.total;
              if (typeof loaded !== "number" || typeof total !== "number") {
                setProgress(0);
                return;
              }
              const percentCompleted = Math.round((loaded * 100) / total);
              setProgress(percentCompleted);
            },
          }
        );
        return schoolStudentResult;
      case accountType.STUDENT:
        const studentsStudentResult = await client.studentClient.get(
          "download-result-sheet",
          {
            responseType: "blob",
            params: {
              schoolAuthId,
              studentAuthId,
              studentClass,
              studentTerm,
              studentYear,
            },
            onDownloadProgress: (progressEvent) => {
              const loaded = progressEvent.loaded;
              const total = progressEvent.total;
              if (typeof loaded !== "number" || typeof total !== "number") {
                setProgress(0);
                return;
              }
              const percentCompleted = Math.round((loaded * 100) / total);
              setProgress(percentCompleted);
            },
          }
        );
        return studentsStudentResult;

      default:
        throw new Error("Something went wrong");
    }
  };

  useEffect(() => {
    const handleDownloadPDF = async () => {
      if (
        studentAuthId == null ||
        studentClass == null ||
        studentTerm == null ||
        studentYear == null
      ) {
        showInfoToast("Something went wrong. Reload and try again");
        return;
      }
      try {
        const response = await _handleDownloadSelector();

        const { data, status } = response;

        if (status === 200) {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "result.pdf");
          link.click();
          setTimeout(() => window.URL.revokeObjectURL(url), 0);
          setProgress(100);
          // setDownload(false);
        } else {
          throw new Error(response?.message ?? "Could't download result");
        }
      } catch (err) {
        showErrorToast(
          err?.message ?? "Something went wrong. Please try again"
        );
        setProgress(100);
      } finally {
        setDownload(false);
      }
    };

    if (download) {
      handleDownloadPDF();
      // somethingNew();
    }
  }, [download]);

  return (
    <>
      {userType === accountType.SCHOOL || userType === accountType.STUDENT ? (
        <button
          disabled={download}
          className={`${
            download
              ? "cursor-not-allowed bg-inActive-shade1 text-white"
              : "rounded-[4.47px] bg-[#E5E5E5] px-[17.89px] py-[13.42px] transition delay-100 ease-in hover:bg-black hover:text-[#E5E5E5]"
          } flex h-[48px] w-[176px] items-center justify-center`}
          onClick={() => setDownload(true)}
        >
          {download ? (
            <div className="h-[24px] w-[24px] place-self-center self-center">
              <svg
                className="h-full w-full animate-spin self-center rounded-full border-l-[4px] border-white"
                viewBox="0 0 24 24"
              ></svg>
            </div>
          ) : (
            <span>Download PDF</span>
          )}
        </button>
      ) : null}
    </>
  );
};

export default DownloadResultPDF;
