import { Outlet } from "react-router-dom";

import { PayrollProvider } from "../../store/payrollStore/payrollContext";

const Payroll = () => {
  return (
    <PayrollProvider>
      <Outlet />
    </PayrollProvider>
  );
};

export default Payroll;
