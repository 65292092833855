import { createContext, useContext, useEffect, useReducer } from "react";

import {
  activateASession,
  getAllClassSession,
  toggleASession,
} from "../../features/school/services/schoolService";

import { showErrorToast, showSuccessToast } from "../../utils/toastHandler";

import { sessionActions } from "./sessionActions";
import sessionReducer, { initialState } from "./sessionReducer";

import { useAuth } from "../authContext";
import { debugPrint } from "../../utils/debugPrint";

const SessionContext = createContext(initialState);

SessionContext.displayName = "SessionContext";

const SessionProvider = ({ children }) => {
  const { updateSchoolCurrentSessionData } = useAuth();

  const [state, dispatch] = useReducer(sessionReducer, initialState);

  // TODO: Add pagination to the dependency array
  useEffect(() => {
    const handleGetAllSessions = async () => {
      dispatch({
        type: sessionActions.UPDATE_SESSION_LOADING_STATE,
        payload: { isSessionLoading: true },
      });
      try {
        const { data } = await getAllClassSession();

        debugPrint("sessionContext - handleGetAllSessions -- data ->", data);

        if (data?.status === true) {
          const { data: sessions } = data;

          debugPrint(
            "sessionContext - handleGetAllSessions -- sessions ->",
            sessions
          );

          dispatch({
            type: sessionActions.SESSION_REQUEST_SUCCESS,
            payload: { allSessions: sessions },
          });
        } else {
          throw new Error(data?.message);
        }
      } catch (error) {
        dispatch({
          type: sessionActions.SESSION_REQUEST_FAILURE,
          payload: { error: error?.message ?? "Something went wrong" },
        });
      } finally {
        dispatch({
          type: sessionActions.UPDATE_SESSION_LOADING_STATE,
          payload: { isSessionLoading: false },
        });
      }
    };

    handleGetAllSessions();
  }, []);

  /// This method will take the "id" of the session as an argument, which it'll
  /// use to optimistically update the data in state before attempting a remote
  /// update.
  ///
  /// NOTE: If the update fails, the state of the session will be re-updated to
  /// the initial value before a remote update was attempted
  const toggleSession = async id => {
    const immutableSessions = [...state.allSessions];

    const cachedSessions = [...state.allSessions];

    const indexOfSelectedSession = cachedSessions.findIndex(
      item => item.id === id
    );

    if (indexOfSelectedSession === -1) {
      return;
    }

    // This value will be used to roll-back the optimistic update if the remote
    // update fails
    const selectedSession = cachedSessions[indexOfSelectedSession];

    const statusOfSelectedSession = selectedSession.status;

    /// If the status of the session selected is "false" then set all sessions
    /// in state to "false" and set only the currently toggled session to "true"
    if (statusOfSelectedSession === false) {
      for (let index = 0; index < cachedSessions.length; index++) {
        cachedSessions[index].status = false;
      }
    }

    const updatedSession = {
      ...selectedSession,
      status: !statusOfSelectedSession,
    };

    cachedSessions.splice(indexOfSelectedSession, 1, updatedSession);

    dispatch({
      type: sessionActions.TOGGLE_SESSION,
      payload: { allSessions: cachedSessions },
    });

    try {
      const { data } = await toggleASession({ sessionId: id });

      if (data?.status === false) {
        throw new Error(data?.message ?? "Something went wrong");
      }

      const { term, year } = selectedSession;

      // Updating the "schoolCurrentTerm" and "schoolCurrentYear" cached in the
      // [authContext] and storage (either session or local storage).
      //
      // Refer to [_handleSavingUserDataForSchool] in [authService]
      updateSchoolCurrentSessionData(term, year);

      showSuccessToast(data?.message ?? "Successfully updated");
    } catch (error) {
      showErrorToast(error?.message ?? "Couldn't updated session");

      // Rolling back the optimistic update of the session status
      if (statusOfSelectedSession === false) {
        dispatch({
          type: sessionActions.TOGGLE_SESSION,
          payload: { allSessions: immutableSessions },
        });

        return;
      }

      cachedSessions.splice(indexOfSelectedSession, 1, selectedSession);
      dispatch({
        type: sessionActions.TOGGLE_SESSION,
        payload: { allSessions: cachedSessions },
      });
    }
  };

  const activateSession = async payload => {
    try {
      dispatch({
        type: sessionActions.UPDATE_IS_ACTIVATING_STATE,
        payload: { isActivating: true },
      });

      const { data } = await activateASession(payload);

      if (data?.status === true) {
        const newlyAddedSession = data?.classDetails;

        const cachedSessions = [...state.allSessions];

        // Set all previous sessions to "false"
        for (const element of cachedSessions) {
          element.status = false;
        }

        cachedSessions.splice(0, 0, newlyAddedSession);

        dispatch({
          type: sessionActions.SESSION_REQUEST_SUCCESS,
          payload: { allSessions: cachedSessions },
        });

        showSuccessToast(data?.message ?? "Successfully activated");
      } else {
        throw new Error(data?.message ?? "Something went wrong");
      }
    } catch (error) {
      showErrorToast(error?.message ?? "Something went wrong");
    } finally {
      dispatch({
        type: sessionActions.UPDATE_IS_ACTIVATING_STATE,
        payload: { isActivating: false },
      });
    }
  };

  const value = {
    state,
    activateSession,
    toggleSession,
  };

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

const useSession = () => {
  const context = useContext(SessionContext);

  if (context === undefined) {
    throw new Error("useSession must be used within SessionContext");
  }

  return context;
};

export { SessionProvider, useSession };
