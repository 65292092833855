import { toast } from "react-toastify";

export const showSuccessToast = (msg = "Success!") => {
  return toast.success(msg);
};

export const showErrorToast = (msg = "Something went wrong") => {
  return toast.error(msg);
};

export const showInfoToast = (msg = "Try again") => {
  return toast.info(msg);
};
