import * as React from "react";

const SvgDashboard = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.323 6.333H1.385A1.387 1.387 0 0 1 0 4.948V1.385C0 .621.621 0 1.385 0h5.938c.764 0 1.385.621 1.385 1.385v3.563c0 .764-.621 1.385-1.385 1.385ZM1.385 1.187a.198.198 0 0 0-.198.198v3.563c0 .11.09.198.198.198h5.938c.11 0 .198-.089.198-.198V1.385a.198.198 0 0 0-.198-.198H1.385ZM7.323 19H1.385A1.387 1.387 0 0 1 0 17.614V9.303c0-.764.621-1.385 1.385-1.385h5.938c.764 0 1.385.62 1.385 1.385v8.313c0 .764-.621 1.385-1.385 1.385ZM1.385 9.104a.198.198 0 0 0-.198.198v8.313c0 .109.09.197.198.197h5.938c.11 0 .198-.088.198-.198V9.303a.198.198 0 0 0-.198-.198H1.385ZM17.615 19h-5.938a1.387 1.387 0 0 1-1.385-1.386v-3.562c0-.764.621-1.386 1.385-1.386h5.938c.764 0 1.385.622 1.385 1.386v3.563C19 18.378 18.38 19 17.615 19Zm-5.938-5.146a.198.198 0 0 0-.198.198v3.563c0 .109.089.197.198.197h5.938c.109 0 .198-.088.198-.198v-3.562a.198.198 0 0 0-.198-.198h-5.938ZM17.615 11.083h-5.938a1.387 1.387 0 0 1-1.385-1.385V1.385c0-.764.621-1.385 1.385-1.385h5.938C18.379 0 19 .621 19 1.385v8.313c0 .764-.621 1.385-1.385 1.385Zm-5.938-9.896a.198.198 0 0 0-.198.198v8.313c0 .11.089.198.198.198h5.938c.109 0 .198-.089.198-.198V1.385a.198.198 0 0 0-.198-.198h-5.938Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDashboard;
