import { useState } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import axios from "axios";

import ProfileMainInfo from "../ProfileMainInfo";
import SchoolInfoSection from "../SchoolInfoSection";
import LocationInfo from "../LocationInfo";
import ParentGuardianInfo from "./ParentGuardianInfo";
import EditingStudentProfile from "./EditingStudentProfile";
import ImageAvatar from "../../../ImageAvatar";
import { Edit } from "../../../icons";

import { studentProfileSchema } from "../../../../../utils/validationSchemas/studentProfileSchema";

import {
  extractDifferenceInObject,
  removeWhiteSpacesFromObjValues,
} from "../../../../../utils/helperFunctions";
import urlSettings from "../../../../../config/apiConfig/apiConstants";

import { updateStudentProfile } from "../../../../../features/school/services/schoolService";

import {
  showErrorToast,
  showSuccessToast,
} from "../../../../../utils/toastHandler";
import { removeNullOrUndefinedValuesFromObject } from "../../../../../utils/objectHelperFunctions";
import { accountType } from "../../../../../constants/accountType";

import { useStudent } from "../../../../../store/studentStore/studentContext";
import { useAuth } from "../../../../../store/authContext";

const StudentProfile = ({
  studentAuthId,
  profileImage,
  mainInfo,
  schoolInfo,
  locationInfo,
  guardianInfo,
  onEditProfileSuccess,
}) => {
  const { user } = useAuth();

  const { userType } = user;

  const { handleStudentEdit } = useStudent();
  // const { handleStudentEdit } = useContext(StudentContext);

  const [isEditing, setIsEditing] = useState(false);

  const handleOnCancelEdit = () => {
    setIsEditing(false);
  };

  /// This is the original form data object before any update/edit is made by
  /// the user. It is used to compare the potential edited object values, and
  /// if an edit was made make a call to the database to update data
  const originalValues = {
    profileImage: null,
    firstName: mainInfo?.["First Name"] ?? "",
    lastName: mainInfo?.["Last Name"] ?? "",
    middleName: mainInfo?.["Middle Name"] ?? "",
    gender: mainInfo?.["Gender"] ?? "",
    dateOfBirth: mainInfo?.["Date of Birth"] ?? null,
    phoneNumber: mainInfo?.["Contact"] ?? "",
    hobbies: mainInfo?.["Hobbies"],
    skills: mainInfo?.["Skills"], // <- Main info end here
    currentClass: (schoolInfo?.["Current Class"]).toUpperCase(),
    subClass: (schoolInfo?.["Sub Class"]).toUpperCase(), // TODO: Remove this in production
    currentTerm: schoolInfo?.["Current Term"],
    currentYear: schoolInfo?.["Current Year"],
    firstSchoolLeavingCertificateUrl:
      schoolInfo?.["First School Leaving Certificate"] ?? "",
    formerSchoolName: schoolInfo?.["Former School Name"] ?? "", // <- School info end here
    country: locationInfo?.["Country"] ?? "",
    state: locationInfo?.["State"] ?? "",
    LGA: locationInfo?.["LGA"] ?? "",
    community: locationInfo?.["Community"] ?? "",
    homeTown: locationInfo?.["Home Town"] ?? "",
    address: locationInfo?.["Address"] ?? "", // <- Location info ends here
    fatherFullName: guardianInfo?.["Father's Name"] ?? "",
    motherFullName: guardianInfo?.["Mother's Name"] ?? "",
    guardianFullName: guardianInfo?.["Guardian's Name"] ?? "",
    guardianAddress: guardianInfo?.["Guardian's Address"] ?? "",
    guardianEmail: guardianInfo?.["Email"] ?? "",
    guardianOccupation: guardianInfo?.["Occupation"] ?? "",
    guardianPhoneNumber: guardianInfo?.["Phone Number"] ?? "", // <- Guardian info ends here
  };

  const initialValues = { ...originalValues };

  const formHandler = async (values) => {
    const formattedData = removeWhiteSpacesFromObjValues(values);

    const isEqual = _.isEqual(formattedData, originalValues);

    /// No change/update was made to the user's data
    if (isEqual) {
      handleOnCancelEdit();
      return;
    }

    const diff = extractDifferenceInObject(originalValues, formattedData);

    const { profileImage, firstName, lastName, middleName, ...params } = diff;

    let profileUploadedUrl = null;

    try {
      /// Upload new profile image if selected
      if (profileImage) {
        const profileFormData = new FormData();
        profileFormData.append("file", profileImage);
        profileFormData.append("upload_preset", "project-image");

        const profileCloudinary = await axios.post(
          urlSettings.CLOUDINARY_URL,
          profileFormData
        );

        profileUploadedUrl = profileCloudinary.data.secure_url;
      }

      // const payload = {
      //   ...params,
      //   studentAuthId: mainInfo?.["User ID"],
      //   currentClass: currentClass.toLowerCase(),
      //   passportImageUrl: profileUploadedUrl,
      //   firstSchoolLeavingCertificateUrl: null,
      //   birthCertificateUrl: null,
      // };

      // FIXME: Add firstName, lastName, middleName when backend has been fixed
      const obj = {
        ...params,
        studentAuthId: studentAuthId,
        passportImageUrl: profileUploadedUrl,
      };

      const payload = removeNullOrUndefinedValuesFromObject(obj);

      const response = await updateStudentProfile(payload);

      if (response.data.status === true) {
        showSuccessToast("Success");
        handleStudentEdit(studentAuthId, payload);
        handleOnCancelEdit();
        onEditProfileSuccess();
      } else {
        throw new Error("Could not update data");
      }

      // TODO: Handle response
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  return (
    <div className="h-[500px] w-[1088px] p-[10px]">
      <>
        {isEditing ? (
          <Formik
            initialValues={initialValues}
            validationSchema={studentProfileSchema}
            onSubmit={formHandler}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              setFieldTouched,
              setTouched,
              setFieldValue,
            }) => (
              <Form
                onSubmit={handleSubmit}
                className="flex flex-col"
                // autoComplete="off"
                autoComplete="new-password"
              >
                <EditingStudentProfile
                  profileImage={profileImage}
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  setTouched={setTouched}
                  isSubmitting={isSubmitting}
                  onCancel={handleOnCancelEdit}
                  onSubmit={handleSubmit}
                />
              </Form>
            )}
          </Formik>
        ) : (
          <div className="flex flex-col">
            <div className="mb-[42px] flex items-center justify-between">
              <div className="mr-[488px] flex items-center">
                {/* Image component */}
                <div className="mr-[21px] flex h-[107px] w-[107px] items-center justify-center overflow-hidden rounded-full bg-grey bg-cover bg-center">
                  <ImageAvatar
                    imageUrl={profileImage}
                    fullName={`${mainInfo?.["First Name"]} ${mainInfo?.["Last Name"]}`}
                  />
                </div>
                <div>
                  <h1 className="text-[24px] font-extrabold">{`${mainInfo?.["First Name"]} ${mainInfo?.["Last Name"]}`}</h1>
                  <p className="text-[14px]">{`ID: ${mainInfo?.["User ID"]}`}</p>
                </div>
              </div>
              {/* TODO: Extract "EditButton" component */}

              {userType === accountType.SCHOOL && (
                <div
                  className="flex cursor-pointer items-center space-x-[6px]"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  <h1>Edit profile</h1>
                  <span className="rounded-[14px] bg-[#F4F9FD] p-[8px]">
                    <Edit />
                  </span>
                </div>
              )}
            </div>
            <div className="relative h-[2px] w-full overflow-visible bg-grey"></div>

            <div className="grid grid-cols-2">
              <ProfileMainInfo mainInfo={mainInfo} />
              <SchoolInfoSection schoolInfo={schoolInfo} />
              <LocationInfo locationInfo={locationInfo} />
              <ParentGuardianInfo guardianInfo={guardianInfo} />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default StudentProfile;
