import { useEffect } from "react";

import DashBoardTopItem from "../../../../shared/components/DashBoardTopItem";
import GradePane from "../../../../shared/components/GradePane";
import PageSearchIconAndLabel from "../../../../shared/components/PageSearchIconAndLabel";
import PageLoading from "../../../../shared/components/ui/PageLoading";
import SearchBar from "../../../../shared/components/ui/SearchBar";
import { AddGradeButton } from "../../../../shared/components/ui/AddGradeButton";

import { useGrades } from "../../../../store/resultCommentsStore/resultCommentsContext";

function ResultCommentsDashboard(props) {
  const { state, handleGetAllGrades, handleDeleteSingleGrade } = useGrades();

  const {
    isLoading,
    allGrades,
    error,
    isAddingGrade,
    errorGettingGrades,
    message,
  } = state;

  useEffect(() => {
    handleGetAllGrades();
  }, []);

  return (
    <>
      {isLoading ? (
        <PageLoading full />
      ) : errorGettingGrades ? (
        <p>failed</p>
      ) : (
        <div>
          <h2 className='mb-[10px] text-[36px] font-semibold leading-[54px] text-black '>
            Grades
          </h2>

          <div className='flex h-fit  w-auto flex-col rounded-[10px] bg-[#FAFAFC] p-[5px] shadow-md'>
            <div className=' float-right flex items-end justify-end px-6 pt-6 '>
              {/* button for adding grade is here */}
              <button className=' inline-flex h-[40px] w-[156px] transform items-center justify-center gap-[6px] rounded-[5px] border-b-[1px] bg-[#437EF7] py-[8px] px-[12px] text-[14px] font-semibold text-white shadow-sm'>
                Add Grades
              </button>
            </div>
            <div className='mb-[30px] flex w-full flex-row justify-between '>
              <DashBoardTopItem extraClassName={``}>
                <div className='w-full'></div>
              </DashBoardTopItem>

              {/* <div className="flex flex-row items-center justify-between gap-x-2">
              <SearchBar onChange={(text) => null} />
              <AddGradeButton />
            </div> */}
            </div>

            {allGrades.length === 0 && (
              <div className='flex h-full items-center justify-center'>
                <PageSearchIconAndLabel />
              </div>
            )}
            {allGrades.length >= 1 && (
              <>
                <GradePane moreVert={false} />

                {allGrades?.reverse()?.map((item, index) => {
                  return (
                    <GradePane
                      key={item?.id ?? index}
                      data={item}
                      moreVert={true}
                      extraGradeStyles={`ml-[20px]`}
                      onDelete={() => handleDeleteSingleGrade(item)}
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ResultCommentsDashboard;
