import TeacherSideNav from "../features/teacher/components/TeacherSideNav";
import SchoolSideNav from "../features/school/components/SchoolSideNav";
import StudentSideNav from "../features/student/components/StudentSideNav";

import { useAuth } from "../store/authContext";
import { accountType } from "../constants/accountType";

const SideNavSelector = () => {
  const { user } = useAuth();

  switch (user?.userType) {
    case accountType.SCHOOL:
      return <SchoolSideNav />;
    case accountType.TEACHER:
      return <TeacherSideNav />;
    case accountType.STUDENT:
      return <StudentSideNav />;
    default:
      return <SchoolSideNav />;
  }
}; 

export default SideNavSelector;
