import TextFieldFormik from "../../../shared/components/TextFieldFormik";
import LinkButton from "../../../shared/components/ui/LinkButton";
import InputFile from "../../../shared/components/ui/InputFile";
import SelectFormik from "../../components/ui/SelectFormik";
import { generateYears } from "../../../utils/calendarFunctions";

const SchoolRegistrationInfo = ({
  values,
  errors,
  touched,
  setTouched,
  setFieldValue,
}) => {
  const shouldDisableButton = () => {
    if (
      errors.logoUrl ||
      values.logoUrl === null ||
      errors.currentTerm ||
      values.currentTerm === "" ||
      errors.currentYear ||
      values.currentYear === "" ||
      errors.schoolRegistrationNumber ||
      values.schoolRegistrationNumber === "" ||
      errors.schoolApprovalCertificateUrl
    ) {
      return true;
    }

    return false;
  };
  // const shouldDisableButton = () => {
  //   if (
  //     errors.logoUrl ||
  //     values.logoUrl === null ||
  //     errors.currentTerm ||
  //     values.currentTerm === "" ||
  //     errors.currentYear ||
  //     values.currentYear === "" ||
  //     errors.schoolRegistrationNumber ||
  //     values.schoolRegistrationNumber === "" ||
  //     errors.yearOfRegistration ||
  //     values.yearOfRegistration === "" ||
  //     errors.schoolApprovalCertificateUrl ||
  //     values.address === null
  //   ) {
  //     return true;
  //   }

  //   return false;
  // };

  return (
    <div className='inline-flex w-[403px] flex-col items-center justify-between'>
      <div className='mb-[34px] flex flex-col items-center gap-[18px]'>
        <h2 className='text-center font-display text-sm uppercase text-[#3F8CFF]'>
          Step 3/4
        </h2>
        <h1 className='text-center font-body text-[24px] font-bold leading-normal text-[#0A1629]'>
          Enter school registration
        </h1>
      </div>

      <div className='flex w-full flex-col'>
        <InputFile
          name='logoUrl'
          extraClasses='mb-[16px]'
          fileName={values.logoUrl?.name}
          error={errors.logoUrl}
          touched={touched.logoUrl}
          onChange={event => {
            setTouched({ logoUrl: true }, false);
            setFieldValue("logoUrl", event.target.files[0]);
          }}
        />

        <SelectFormik
          showDefaultOption={false}
          className='flex flex-1'
          hiddenLabel={"Term"}
          label='Current Term'
          name='currentTerm'
          options={["First term", "Second term", "Third term"]}
        />

        <SelectFormik
          showDefaultOption={false}
          className='flex flex-1'
          label='Current Year'
          name='currentYear'
          hiddenLabel={"Year"}
          options={generateYears()}
        />

        <SelectFormik
          showDefaultOption={false}
          // className="w-full grow"
          label='Year of Reg'
          name='yearOfRegistration'
          hiddenLabel={"Year of Registration"}
          options={generateYears()}
        />

        <TextFieldFormik
          extraClasses='mb-[16px]'
          label='School registration number'
          name='schoolRegistrationNumber'
          placeholder='Enter number'
        />

        <InputFile
          name='schoolApprovalCertificateUrl'
          label='School approval certificate'
          placeholder='Click to upload approval certificate'
          fileName={values.schoolApprovalCertificateUrl?.name}
          error={errors.schoolApprovalCertificateUrl}
          touched={touched.schoolApprovalCertificateUrl}
          onChange={event => {
            setTouched({ schoolApprovalCertificateUrl: true }, false);
            setFieldValue(
              "schoolApprovalCertificateUrl",
              event.target.files[0]
            );
          }}
        />
      </div>

      <LinkButton
        to={"/onboarding/school-super-admin"}
        // to={"school-super-admin"}
        extraClasses='mt-[20px] red'
        style={{ width: "100%" }}
        label='Next'
        disabled={shouldDisableButton()}
      />
    </div>
  );
};

export default SchoolRegistrationInfo;
