export const studentActions = {
  INITIALIZE_DEFAULT_LOAD: "INITIALIZE_DEFAULT_LOAD",
  UPDATE_LOADING_STATE: "UPDATE_LOADING_STATE",
  CACHE_FILTER_PARAMS: "CACHE_FILTER_PARAMS",
  REQUEST_SUCCESS: "REQUEST_SUCCESS",
  ADD_ERROR: "ADD_ERROR",
  UPDATE_STUDENT_DETAIL: "UPDATE_STUDENT_DETAIL",
  INCREMENT_PAGE_COUNT: "INCREMENT_PAGE_COUNT",
  DECREMENT_PAGE_COUNT: "DECREMENT_PAGE_COUNT",
  RESET_PAGE_COUNT: "RESET_PAGE_COUNT",
};
