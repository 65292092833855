import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import axios from "axios";

import FormAddTeacher1 from "./addTeacher/FormAddTeacher1";
import FormAddTeacher2 from "./addTeacher/FormAddTeacher2";
import FormAddTeacher3 from "./addTeacher/FormAddTeacher3";

import { BackButton } from "../ui/Buttons";

import { teacherValidationSchema } from "../../../utils/validationSchemas/teacherValidationSchema";
import { initialValues } from "../../../utils/initialValues/teacherInitialValues";
import {
  removeInitialZeroFromPhoneNumber,
  removeWhiteSpacesFromObjValues,
} from "../../../utils/helperFunctions";
import { removeEmptyKeyValuesFromObject } from "../../../utils/objectHelperFunctions";

import { showErrorToast, showSuccessToast } from "../../../utils/toastHandler";

import { addTeacher } from "../../../features/school/services/schoolService";
import urlSettings from "../../../config/apiConfig/apiConstants";

import useGetSchoolSubjects from "../../../hooks/useGetSchoolSubjects";
import { debugPrint } from "../../../utils/debugPrint";
import FormAddTeacher4 from "./addTeacher/FormAddTeacher4";

export default function AddTeacher({ closeModal, incrementTeacherCount }) {
  const location = useLocation();

  const pathName = location?.pathname;

  const [step, setStep] = useState(1);

  const [showSuggestion, setShowSuggestion] = useState(false);

  const { subjects } = useGetSchoolSubjects();

  const formHandler = async (values, { resetForm }) => {
    // Trim out white space
    const formattedData = removeWhiteSpacesFromObjValues(values);

    //destructure the formattedData
    const {
      firstName,
      middleName,
      lastName,
      teacherEmail,
      country,
      state,
      address,
      countryCode,
      phoneNumber,
      dateOfBirth,
      profileImageUrl,
      classes,
      subjects,
      formerEmployerName,
      formerJobTitle,
      yearsOfExperience,
      LGA,
      documents,
      salary,
      salaryDuration,
      accountName,
      accountNumber,
      bank,
      bankCode,
    } = formattedData;

    try {
      /// Format the number and join the countryCode With the phoneNumber
      const countryCodeAndPhoneNumber = `${countryCode}${removeInitialZeroFromPhoneNumber(
        phoneNumber
      )}`;

      // classSubjects: This is hard-coded
      const classSubjects = {
        jss7: "Mathematics",
      };

      const profileFormData = new FormData();
      profileFormData.append("file", profileImageUrl);
      profileFormData.append("upload_preset", "project-image");

      const profileCloudinary = await axios.post(
        urlSettings.CLOUDINARY_URL,
        profileFormData
      );

      const profileUploadedUrl = profileCloudinary.data.secure_url;

      let documentUploadedUrl;
      if (documents != null) {
        const documentFormData = new FormData();
        documentFormData.append("file", documents);
        documentFormData.append("upload_preset", "project-image");

        const documentCloudinary = await axios.post(
          urlSettings.CLOUDINARY_URL,
          documentFormData
        );

        documentUploadedUrl = documentCloudinary.data.secure_url;
      }

      const payloadClasses = classes.map(obj => obj.value);
      const payloadSubjects = subjects.map(obj => obj.value);

      let teacherInformation = {
        firstName,
        middleName,
        lastName,
        teacherEmail,
        country,
        state,
        address,
        phoneNumber: countryCodeAndPhoneNumber,
        dateOfBirth,
        profileImageUrl: profileUploadedUrl,
        classes: payloadClasses,
        subjects: payloadSubjects,
        classSubjects,
        formerEmployerName,
        formerJobTitle,
        yearsOfExperience,
        LGA,
        salary,
        salaryDuration,
        accountName,
        accountNumber,
        bank,
        bankCode,
      };

      debugPrint(
        "AddTeacher - formHandler -- teacherInformation ->",
        teacherInformation
      );

      if (documentUploadedUrl) {
        teacherInformation.documents = [documentUploadedUrl];
      }

      teacherInformation = removeEmptyKeyValuesFromObject(teacherInformation);

      const response = await addTeacher(teacherInformation);

      const { data } = response;

      if (data.status === true) {
        showSuccessToast(data.message);

        /// Only increment teacher count if on the dashboard
        if (pathName === "/dashboard") {
          incrementTeacherCount();
        }

        setShowSuggestion(true);

        window.location.reload();

        setStep(1);
        resetForm();

        return;
      } else {
        throw new Error(data?.message);
      }
    } catch (error) {
      showErrorToast(error?.message ?? "Something went wrong");
    }
  };

  const buildAddTeacherForm = (
    values,
    errors,
    touched,
    setFieldTouched,
    setTouched,
    setFieldValue,
    isSubmitting
  ) => {
    switch (step) {
      case 1:
        return (
          <FormAddTeacher1
            values={values}
            errors={errors}
            onClick={() => setStep(prevState => prevState + 1)}
          />
        );
      case 2:
        return (
          <FormAddTeacher2
            values={values}
            errors={errors}
            touched={touched}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            onClick={() => setStep(prevState => prevState + 1)}
          />
        );
      case 3:
        return (
          <FormAddTeacher3
            subjects={subjects}
            values={values}
            errors={errors}
            touched={touched}
            setTouched={setTouched}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            onClick={() => setStep(prevState => prevState + 1)}
          />
        );
      case 4:
        return (
          <FormAddTeacher4
            values={values}
            errors={errors}
            touched={touched}
            setTouched={setTouched}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
          />
        );
      default:
        return <h1>Default step</h1>;
    }
  };

  return (
    <div className="no-scrollbar h-[544px] w-[576px] overflow-y-auto overflow-x-hidden px-[10px]">
      <Formik
        initialValues={initialValues}
        validationSchema={teacherValidationSchema}
        onSubmit={formHandler}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
          setFieldValue,
          setTouched,
          resetForm,
        }) => (
          <>
            {/* Add teacher and close modal button */}
            <div className="mb-[24px] flex flex-row items-center justify-between">
              {
                <>
                  {step === 1 ? (
                    <h3 className="mt-[8px] text-[22px] font-semibold leading-[33px]">
                      {showSuggestion ? "ADD MORE TEACHERS" : "Add teacher"}
                    </h3>
                  ) : (
                    <BackButton
                      clicked={() => setStep(prevState => prevState - 1)}
                    />
                  )}
                </>
              }
            </div>
            <Form>
              {buildAddTeacherForm(
                values,
                errors,
                touched,
                setFieldTouched,
                setTouched,
                setFieldValue,
                isSubmitting
              )}
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
