const ProfileInputFile = ({
  name,
  touched,
  errors,
  setFieldValue,
  setTouched,
  onFileSelected,
}) => {
  return (
    <div className="flex flex-col">
      <label className="block">
        <span className="sr-only">Choose profile photo</span>
        <input
          name={name}
          type="file"
          className="block w-full cursor-pointer text-sm text-slate-500 file:mr-4 file:cursor-pointer file:rounded-full file:border-0 file:bg-primary-dark file:py-2 file:px-4 file:text-sm file:font-semibold file:text-white hover:file:bg-primary"
          onChange={(event) => {
            setTouched({ ...touched, [name]: true }, false);
            setFieldValue(name, event.target.files[0]);
            onFileSelected(event);
          }}
        />
      </label>
      {errors[name] && touched[name] && (
        <span className="ml-[8px] text-[14px] text-accent">{errors[name]}</span>
      )}
    </div>
  );
};

export default ProfileInputFile;
