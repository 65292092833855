import { resultsActions } from "./resultsActions";

export const initialState = {
  isInitial: true,
  isLoading: false,
  error: null,
  currentPage: 0,
  currentPageCount: 0,
  totalCount: 0, // <- This holds the value of the total number of student in the DB
  dataPerPage: 0,
  allStudents: [], // <- Array that holds all the students result data
  cachedClassFilter: "",
  cachedSubClassFilter: "",
  cachedTermFilter: "",
  cachedYearFilter: "",
  filterParams: {
    classFilter: "",
    subClassFilter: "",
    termFilter: "",
    yearFilter: "",
  },
};

const resultsReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case resultsActions.UPDATE_LOADING_STATE:
      return {
        ...state,
        isLoading: payload.isLoading,
      };

    case resultsActions.INITIALIZE_DEFAULT_LOAD:
      return {
        ...state,
        isLoading: payload.isLoading,
        isInitial: payload.isInitial,
        error: null, // <- Resetting error to null
        currentPage: payload.currentPage, // <- Resetting currentPage value to 1
        allStudents: [], // <- Resetting allStudents
      };

    case resultsActions.UPDATE_FILTER_PARAMS:
      const updatedFilterParams = {
        ...state.filterParams,
        ...payload.updatedFilterObj,
      };

      return {
        ...state,
        filterParams: updatedFilterParams,
      };

    case resultsActions.CACHE_FILTER_PARAMS:
      return {
        ...state,
        cachedTermFilter: payload.cachedTermFilter,
        cachedClassFilter: payload.cachedClassFilter,
        cachedSubClassFilter: payload.cachedSubClassFilter,
        cachedYearFilter: payload.cachedYearFilter,
      };

    case resultsActions.REQUEST_SUCCESS:
      return {
        ...state,
        allStudents: [...payload.allStudents],
        error: null,
      };

    case resultsActions.ADD_ERROR:
      return {
        ...state,
        error: payload.error,
      };

    default:
      throw new Error(`No case for type ${type} found in resultsReducer`);
  }
};

export default resultsReducer;
