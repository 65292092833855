import { useEffect } from "react";
import { useNavigate, Navigate, useParams } from "react-router-dom";

import SubjectCard from "../../../../shared/components/SubjectCard";
import PageLoading from "../../../../shared/components/ui/PageLoading";
import DashBoardTopItem from "../../../../shared/components/DashBoardTopItem";
import PageSearchIconAndLabel from "../../../../shared/components/PageSearchIconAndLabel";
import PaginationIndicator from "../../../components/pagination/PaginationIndicator";
import { BackArrow } from "../../../../shared/components/icons";
import { AddSubjectToClassButton } from "../../../../shared/components/ui/AddSubjectToClassButton";

import { schoolClasses } from "../../../../assets/lists/schoolClasses";

import { useClassSubjectContext } from "../../../../store/classStore/classContext";
import { useAuth } from "../../../../store/authContext";
import { accountType } from "../../../../constants/accountType";

function ClassSubjects() {
  const { user } = useAuth();

  const { userType, teacherClasses } = user;

  const navigate = useNavigate();

  const routePath = useParams();
  const selectedClass = routePath.id;

  const { fetchSubjectsByClass, state, resetError } = useClassSubjectContext();

  const { listOfSubjects, isLoading, error, errorMessage } = state;

  useEffect(() => {
    if (userType === accountType.STUDENT) {
      navigate(-1, { replace: true });
      return;
    }

    if (userType === accountType.TEACHER) {
      if (teacherClasses == null || !teacherClasses?.includes(selectedClass)) {
        navigate(-1, { replace: true });
        return;
      }
    }

    fetchSubjectsByClass(selectedClass);
  }, []);

  const handleBackNavigation = () => {
    navigate(-1);
  };
  if (isLoading == null) return <h1>-- --</h1>;

  return (
    <>
      {schoolClasses.includes(selectedClass) ? (
        <div className="flex h-full w-auto flex-col place-items-stretch rounded-[22px] bg-[#FAFAFC] p-[50px]">
          <div className="flex w-full flex-row justify-between">
            <DashBoardTopItem>
              <div className="flex w-full flex-row">
                <div
                  className="flex cursor-pointer items-center space-x-[14px]"
                  onClick={handleBackNavigation}>
                  <BackArrow className="text-primary" />
                  <p className="text-[18px] font-bold">
                    {`${selectedClass.toUpperCase()} ${
                      listOfSubjects.length > 0 ? "Subjects" : "Subject"
                    }`}
                  </p>
                </div>
              </div>
            </DashBoardTopItem>

            {!isLoading && (
              <div className=" flex flex-row items-center justify-between gap-x-2 ">
                <AddSubjectToClassButton selectedClass={selectedClass} />
              </div>
            )}
          </div>
      
          {isLoading ? (
            <PageLoading classes="h-full w-full" />
          ) : error ? (
            <div className="h-full w-full items-center justify-center flex">
              <PageSearchIconAndLabel label="Error retrieving data. Reload page" />
            </div>
          ) : listOfSubjects.length === 0 ? (
            <div className="flex h-full items-center justify-center">
              <PageSearchIconAndLabel label="Assign subject to class" />
            </div>
          ) : (
            <div className="grid grid-cols-5 grid-rows-1 gap-x-[28px] gap-y-[30px] pt-[20px]">
              {listOfSubjects.map((item, index) => (
                <SubjectCard key={index} text={item} onPressIcon={() => null} />
              ))}
            </div>
          )}

          {false && (
            <PaginationIndicator
              classes="self-end mt-[12px]"
              currentPageNumber={null}
              itemPerPage={null}
              itemCount={null}
              totalCount={null}
              onForwardArrowClick={null}
              onBackArrowClick={null}
            />
          )}
        </div>
      ) : (
        <Navigate to="/academic/class" />
      )}
    </>
  );
}

export default ClassSubjects;
