import TextFieldFormik from "../../../TextFieldFormik";
import CustomDatePicker from "../../../ui/CustomDatePicker";

const TeacherEditableMainInfo = ({ values, setFieldValue }) => {
  return (
    <div>
      <p className="mt-[56px] mb-[24px] text-[24px] font-bold">Main info</p>
      <div className="grid grid-cols-2 gap-2">
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="First Name"
          name="firstName"
          width="w-[232px]"
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Last Name"
          name="lastName"
          width="w-[232px]"
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Middle Name"
          name="middleName"
          width="w-[232px]"
          height="h-[48px]"
        />
        <CustomDatePicker
          name="dateOfBirth"
          value={values["dateOfBirth"]}
          setFieldValue={setFieldValue}
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Phone Number"
          name="phoneNumber"
          width="w-[232px]"
          height="h-[48px]"
        />
      </div>
    </div>
  );
};

export default TeacherEditableMainInfo;
