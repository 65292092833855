export const getCurrentDate = () => {
  const today = new Date();

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const formattedDate = today.toLocaleDateString("en-uk", options);

  return formattedDate;
};

/// This function is responsible for generating an Array of years starting from
/// the current year and going back the stipulated [timeSpan] argument passed
/// into the function
export const generateYears = (timeSpan = 100) => {
  const year = new Date().getFullYear() + 1;
  const years = Array.from(new Array(timeSpan), (_, index) => year - index);

  return years;
};

/**
 * @description - The function for minimum DateTime
 * @param {int} range - The age that the minimum year should be based on
 * @returns {DateTime} - The minimum allowed date time
 */
export const getCalendarYear = (range = 75) => {
  const date = new Date();
  const dateCopy = new Date(date);

  dateCopy.setFullYear(new Date().getFullYear() - range);

  return dateCopy;
};
