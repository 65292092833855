import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PinField from "react-pin-field";

import RegistrationFormLayout from "../../shared/components/layouts/RegistrationFormLayout";
import Button from "../../shared/components/ui/Button";

import { PointingRightArrow } from "../../shared/components/icons";

import {
  validatePasswordResetCode,
  sendPasswordResendCode,
} from "../../services/authService";

import { showErrorToast, showSuccessToast } from "../../utils/toastHandler";

// import { PointingRightArrow } from "../../components/icons";
// import RegistrationFormLayout from "../../components/layouts/RegistrationFormLayout";
// import Button from "../../components/ui/Button";
// import { showErrorToast } from "../../utils/toastHandler";

// import { resendEmailCode, verifyAccount } from "../../services/authService";

const VerifySentCode = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const [email, setEmail] = useState(null);
  const [sentPasswordResetCode, setSentPasswordResetCode] = useState(null);
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const validate = () => {
      const email = location?.state?.email;

      if (email == null) {
        navigate("/", { replace: true });

        return;
      }

      setEmail(email);
    };

    validate();
  }, []);

  const handleEmailCodeComplete = value => {
    setSentPasswordResetCode(value);
    setShowError(false);
  };

  const handleOnSubmit = async () => {
    if (sentPasswordResetCode === null || sentPasswordResetCode.length < 4) {
      setShowError(true);
      return;
    }

    setIsSubmitting(true);

    //prepare an object containing his email, passwordCode..
    try {
      const payload = {
        email,
        passwordCode: sentPasswordResetCode,
      };

      const response = await validatePasswordResetCode(payload);

      const { data } = response;

      if (
        data.status === true ||
        data.status === 200 ||
        data.status == "SUCCESS"
      ) {
        showSuccessToast(
          "Verification successful!!, you can now reset your password!"
        );
        // Navigating to the password reset screen
        navigate("/create-new-password", {
          replace: true,
          state: {
            email,
          },
        });
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      //handle LOGS

      showErrorToast(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const resendEmailVerificationCode = async () => {
    try {
      const payload = {
        email,
      };

      const response = await sendPasswordResendCode(payload);

      const { data } = response;

      if (data.status === true) {
        showSuccessToast(data.message);
      } else {
        throw new Error(
          "We don't recognize that email!, please input a valid email!"
        );
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  return (
    <RegistrationFormLayout>
      <div className="flex flex-col items-center justify-center">
        <h1 className="mb-[102px] text-center text-[24px] font-bold">
          Enter The Code Sent To Your Email
        </h1>

        <div>
          <p className="mb-[27px] text-[14px] text-[#7D8592]">
            Code sent from email to you
          </p>
          <div className="mb-[24px] flex items-stretch justify-between">
            <PinField
              className="mr-[9px] h-[49.96px] w-[58px] rounded-[14px] border-[1px] border-[#D8E0F0] text-center"
              length={6}
              onComplete={handleEmailCodeComplete}
            />
            {showError && (
              <p className="text-[14px] text-accent">Fields cannot be empty</p>
            )}
          </div>
        </div>

        {/* //TODO: Debounce the click of this button */}
        <p
          onClick={resendEmailVerificationCode}
          className="mt-[34.04px] mb-[30px] cursor-pointer self-center font-semibold text-[#7D8592]"
        >
          Resend code
        </p>

        <Button
          onClick={handleOnSubmit}
          extraClasses="self-center"
          isLoading={isSubmitting}
        >
          <div className="flex items-center justify-center space-x-2">
            <span>Continue</span>
            <PointingRightArrow color="bg-white" />
          </div>
        </Button>
      </div>
    </RegistrationFormLayout>
  );
};

export default VerifySentCode;
