import blueLogo from "../../assets/svg/blue-logo.svg";
import { useAuth } from "../../store/authContext";
import { toTitleCase } from "../../utils/helperFunctions";

const SchoolCard = ({ className }) => {
  const { user } = useAuth();

  const { logoUrl, schoolName, state, country } = user;

  return (

      <div className="flex flex-col items-center justify-center gap-[12px] self-stretch rounded-[10px] bg-[#F8F9FB] py-[12px] px-[16px]">
        <img src={blueLogo} className="h-[50px] w-[50px]" alt="blue logo" />
        <p className="text-center font-body text-base font-medium leading-5 ">
          {toTitleCase(schoolName)}
        </p>
      </div>

  );
};

export default SchoolCard;
