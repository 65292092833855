import { PropTypes } from "prop-types";

/**
 * @typedef {Object} Props
 * @property {string} [name] - Name of the TickBox
 * @property {boolean} [value] - Is checked value for the TickBox
 * @property {string} [size] - Height and width of the TickBox
 * @property {function} [updateValue] - Function that updated the TickBox check status
 * @property {React.Node} [children] - Children component
 */

/**
 * @description - UI component for displaying a CheckBox/TickBox
 * @param {Props} props - The properties for the TickBox Component
 * @returns {React.JSX.Element} - The rendered TickBox component
 */
const TickBox = ({
  name = "",
  value = false,
  size = "20px",
  updateValue = () => {},
  children,
}) => {
  // handle checkbox change
  const handleChange = () => {
    updateValue(!value, name);
  };

  return (
    // <div className="my-5">
    <div>
      <input
        type="checkbox"
        id={`${name}-checkbox`}
        name={name}
        checked={value}
        onChange={handleChange}
        className={`h-${size} w-${size} rounded-[6px] border-[1px] focus:ring-0`}
      />
      <label htmlFor={`${name}-checkbox`}>{children}</label>
    </div>
  );
};

TickBox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  size: PropTypes.string,
  updateValue: PropTypes.func,
  children: PropTypes.node,
};

export default TickBox;
