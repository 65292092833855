import { classSubjectActions } from "./classActions";

export const classInitialState = {
  isLoading: null,
  error: null,
  errorMessage: "",
  allSchoolSubjects: [], // -> This will hold all the subjects the school offers ||TODO: Not needed. DELETE
  listOfSubjects: [], // -> Holds the subjects offered by a particular class
  dataPerPage: 0,
  totalClass: 0,
  selectedClass: null,
  // Will be used to check for newly onboarded school subject assign status 👇🏿
  isInitialSubjectAssigned: false,  
};

export const classSubjectsReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case classSubjectActions.IS_LOADING:
      return {
        ...state,
        isLoading: payload.isLoading,
      };

    case classSubjectActions.SHOW_ERROR:
      return {
        ...state,
        error: payload.error,
        errorMessage: payload?.message ?? "Something went wrong. Try again",
      };
    case classSubjectActions.CACHE_ALL_SUBJECTS:
      return {
        ...state,
        allSchoolSubjects: [...payload.allSchoolSubjects],
      };

    case classSubjectActions.UPDATE_SCHOOL_CLASSES:
      return {
        ...state,
        schoolClasses: [...payload.schoolClasses],
      };

    case classSubjectActions.UPDATE_CLASS_SUBJECTS:
      return {
        ...state,
        listOfSubjects: [...payload.listOfSubjects],
      };
    case classSubjectActions.UPDATE_DESIRED_CLASS:
      return {
        ...state,
        selectedClass: payload.selectedClass,
      };
    case classSubjectActions.UPDATE_INITIAL_SUBJECT_ASSIGNING:
      return {
        ...state,
        isInitialSubjectAssigned: true,
      };

    default:
      return state;
  }
};
