/// This function will remove key-value pairs with an empty String i.e. ""
export const removeEmptyKeyValuesFromObject = obj => {
  for (const key in obj) {
    if (obj[key] === "") {
      delete obj[key];
    }
  }

  return obj;
};

/// This function will remove "null" or "undefined" key-value pairs from an Object
/// NOTE: This is done in an immutable way
export const removeNullOrUndefinedValuesFromObject = obj => {
  return Object.entries(obj).reduce(
    (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
    {}
  );
};

export const isEmpty = obj => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
};
