import { payrollActions } from "./payrollActions";

export const initialState = {
  currentPage: 1,
  isLoading: false,
  payrolls: [], // Holds the payrolls
  error: null,

  selectedStaffIds: [],

  isTeacherLoading: false,
  teacherError: null,
  teachers: [],

  isCreatingPayroll: false,
};

const payrollReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case payrollActions.UPDATE_LOADING_STATE: {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    }
    case payrollActions.REQUEST_SUCCESS:
      return {
        ...state,
        payrolls: payload.payrolls,
      };

    case payrollActions.ADD_PAYROLL_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case payrollActions.SELECT_ALL_STAFF: {
      // When the user toggles the select all option
      return {
        ...state,
        selectedStaffIds: [...payload.staffIds],
      };
    }
    case payrollActions.UPDATE_SELECTED_STAFF: {
      // TODO: Extract logic to a helper function
      const cachedSelectedStaffIds = [...state.selectedStaffIds];

      let selectedIndex = -1;

      for (let index = 0; index < state.selectedStaffIds.length; index++) {
        if (payload.staffId === state.selectedStaffIds[index]) {
          selectedIndex = index;

          break;
        }
      }

      if (selectedIndex === -1) {
        // Add staff to list
        cachedSelectedStaffIds.push(payload.staffId);
      } else {
        // Remove staff from list
        cachedSelectedStaffIds.splice(selectedIndex, 1);
      }

      return {
        ...state,
        selectedStaffIds: [...cachedSelectedStaffIds],
      };
    }
    case payrollActions.CLEAR_SELECTED_STAFF: {
      return {
        ...state,
        selectedStaffIds: [],
      };
    }

    case payrollActions.UPDATE_PAYROLL: {
      const indexOfPayroll = state.payrolls.findIndex(
        item => item.id === payload.payroll.id
      );

      if (indexOfPayroll !== -1) {
        const cachedPayrolls = [...state.payrolls];

        cachedPayrolls[indexOfPayroll] = payload.payroll;

        return { ...state, payrolls: [...cachedPayrolls] };
      }
      break;
    }

    // For creating payroll
    case payrollActions.UPDATE_TEACHER_LOADING_STATE:
      return { ...state, isTeacherLoading: payload.isTeacherLoading };
    case payrollActions.REQUEST_SUCCESS_TEACHER:
      return { ...state, teachers: payload.teachers };
    case payrollActions.ADD_TEACHERS_ERROR:
      return { ...state, teacherError: payload.error };
    case payrollActions.UPDATE_CREATING_PAYROLL_LOADING_STATE:
      return { ...state, isCreatingPayroll: payload.isCreatingPayroll };
    case payrollActions.ADD_CREATED_PAYROLL: {
      const updatedPayroll = [payload.payroll, ...state.payrolls];
      return { ...state, payrolls: updatedPayroll };
    }
    default:
      throw new Error(`No case for type ${type} found in payrollReducer`);
  }
};

export default payrollReducer;
