import { studentActions } from "./studentActions";

export const initialState = {
  isInitial: true,
  isLoading: false,
  error: null,
  currentPage: 1,
  currentPageCount: 0,
  dataPerPage: 0,
  totalCount: 0, // <- This holds the value of the total number of student in the DB
  allStudents: [], // <- Array that holds all the students result data
  cachedTermFilter: "",
  cachedClassFilter: "",
  cachedSubClassFilter: "",
  cachedYearFilter: "",
};

const studentReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case studentActions.UPDATE_LOADING_STATE:
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    case studentActions.INITIALIZE_DEFAULT_LOAD:
      return {
        ...state,
        isLoading: payload.isLoading,
        isInitial: payload.isInitial,
        // currentPage: payload.currentPage, // <- Resetting currentPage value to 1
        allStudents: [], // <- Resetting "allStudents" back to an empty array
      };

    case studentActions.CACHE_FILTER_PARAMS:
      return {
        ...state,
        cachedTermFilter: payload.cachedTermFilter,
        cachedClassFilter: payload.cachedClassFilter,
        cachedSubClassFilter: payload.cachedSubClassFilter,
        cachedYearFilter: payload.cachedYearFilter,
      };

    case studentActions.REQUEST_SUCCESS:
      return {
        ...state,
        allStudents: [...payload.allStudents],
        currentPageCount: payload.currentPageCount,
        dataPerPage: payload.dataPerPage,
        totalCount: payload.totalCount,
      };
    case studentActions.ADD_ERROR:
      return {
        ...state,
        error: payload.error,
      };

    case studentActions.UPDATE_STUDENT_DETAIL:
      return {
        ...state,
        allStudents: [...payload.allStudents],
      };
    case studentActions.INCREMENT_PAGE_COUNT:
      return {
        ...state,
        currentPage: state.currentPage + 1,
      };
    case studentActions.DECREMENT_PAGE_COUNT:
      return {
        currentPage: state.currentPage - 1,
      };
    case studentActions.RESET_PAGE_COUNT:
      return {
        ...state,
        currentPage: 1,
        error: null,
      };

    default:
      throw new Error(`No case for type ${type} found in marksReducer`);
  }
};

export default studentReducer;
