import { teacherActions } from "./teacherActions";

export const initialState = {
  isInitial: true,
  isLoading: false,
  error: null,
  currentPage: 1,
  currentPageCount: 0,
  dataPerPage: 0,
  totalCount: 0, // <- This holds the value of the total number of student in the DB
  allTeachers: [], // <- Array that holds all the teachers result data
  cachedClassFilter: "",
  cachedSubjectFilter: "",
};

const teacherReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case teacherActions.UPDATE_LOADING_STATE:
      return {
        ...state,
        isLoading: payload.isLoading,
        // error: null, // -> Resetting error state
      };
    case teacherActions.INITIALIZE_DEFAULT_LOAD:
      return {
        ...state,
        isLoading: payload.isLoading,
        isInitial: payload.isInitial,
        // currentPage: payload.currentPage, // <- Resetting currentPage value to 1
        allStudents: [], // <- Resetting "allStudents" back to an empty array
      };

    case teacherActions.CACHE_FILTER_PARAMS:
      return {
        ...state,
        cachedClassFilter: payload.cachedClassFilter,
        cachedSubjectFilter: payload.cachedYearFilter,
      };

    case teacherActions.REQUEST_SUCCESS:
      return {
        ...state,
        allTeachers: [...payload.allTeachers],
        currentPageCount: payload.currentPageCount,
        dataPerPage: payload.dataPerPage,
        totalCount: payload.totalCount,
      };
    case teacherActions.ADD_ERROR:
      return {
        ...state,
        error: payload.error,
      };

    case teacherActions.INCREMENT_PAGE_COUNT:
      return {
        ...state,
        currentPage: state.currentPage + 1,
      };

    case teacherActions.DECREMENT_PAGE_COUNT:
      return {
        ...state,
        currentPage: state.currentPage - 1,
      };

    case teacherActions.UPDATE_TEACHER_DETAIL:
      return {
        ...state,
        allTeachers: [...payload.allTeachers],
      };

    default:
      throw new Error(`No case for type ${type} found in marksReducer`);
  }
};

export default teacherReducer;
