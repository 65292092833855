import React, { useState } from "react";
import { useField, ErrorMessage } from "formik";
/// NOTE => The [options] passed in has to be an array of options
const Select = ({
  label,
  options,
  className,
  selectClasses,
  extraClasses,
  showDefaultOption = true,
  byDefault = "please select",
  hiddenLabel,
  useUpperCase = false,
  ...props
}) => {
  const [field, helpers] = useField(props);

  //this state changes the visual look of the selected option....by default it should be grayed out!
  const [changeOptionColor, seChangeOptionColor] = useState("#353935");

  return (
    <div className={`${className} mb-[20px] flex flex-col `}>
      {label && (
        <label
          htmlFor={field.name}
          className="mb-[2px] text-[14px] font-semibold capitalize text-[#7D8592]"
        >
          {label}
        </label>
      )}

      <select
        value={field.value}
        className={
          selectClasses
            ? `${selectClasses}`
            : "h-[44px] rounded-2xl border border-[#D8E0F0] bg-white focus:border-primary focus:bg-white focus:ring-primary"
        }
        style={{ color: changeOptionColor, textTransform: "capitalize" }}
        {...field}
        {...props}
      >
        {/* //has nothing to do here, just incase their was not array passed */}
        {showDefaultOption && (
          <option value="" disabled>
            {field.value}
          </option>
        )}
        {/* ///// */}
        <option value="" disabled>
          {`Select ${label ? label : hiddenLabel}`}
        </option>
        {options?.map((opt, index) => (
          <option key={index} value={opt}>
            {useUpperCase && opt.toString().toUpperCase()}
            {!useUpperCase && opt}
          </option>
        ))}
      </select>

      <ErrorMessage
        name={field.name}
        component="span"
        className="text-[14px] text-accent"
      />
    </div>
  );
};

export default Select;
