import { allObjectValuesAreValid } from "../../../../utils/helperFunctions";

const LocationInfo = ({ locationInfo }) => {
  return (
    <>
      {allObjectValuesAreValid(locationInfo) ? (
        <div>
          <p className="mt-[56px] mb-[24px] text-[24px] font-bold">Location</p>
          <div className="grid grid-cols-2">
            {Object.keys(locationInfo).map((data, index) => {
              return !locationInfo[data] ||
                locationInfo[data]?.length === 0 ? null : (
                <div
                  key={index}
                  className="mb-[15px] mr-[16px] flex flex-col justify-start"
                >
                  <p className="mb-[2px] text-[14px] font-semibold text-[#7D8592]">
                    {data}
                  </p>
                  <div className="flex h-[48px] w-[232px] items-center justify-start rounded-[14px] border border-[#D8E0F0] p-4">
                    <span className="text-[14px]">{locationInfo[data]}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default LocationInfo;
