import * as React from "react";

const SvgImage = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm3 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0h10a5 5 0 0 1 5 5v10a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5Zm10 2H5a3 3 0 0 0-3 3v10c0 .508.126.987.35 1.407l8.881-7.401a4 4 0 0 1 5.267.127l.154.149L18 10.661V5a3 3 0 0 0-3-3Zm3 11.417a.992.992 0 0 1-.222-.134l-.093-.084-2.463-2.519a2 2 0 0 0-2.573-.243l-.137.105-8.671 7.226c.356.15.748.232 1.159.232h10a3 3 0 0 0 3-3v-1.583Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgImage;
