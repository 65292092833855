import React from "react";
import { NavLink } from "react-router-dom";
import * as Icons from "../components/icons";

const LatestEvents = ({ children, href = "#" }) => {
  return (
    <div className="inline-block rounded-[14px] bg-white px-[42px] pt-[28px] pb-[29px]">
      <div className="mb-[40px] flex items-center justify-between">
        <h3 className="text-[22px] font-semibold leading-[33px]">
          Latest Events <span className="text-[12px]">(Coming soon)</span>
        </h3>
        {/* <NavLink
          to={href}
          className="inline-flex items-center text-[16px] font-medium text-blue-600"
        >
          View all
          <span className="ml-[12px] inline-flex h-[10px] w-[6px] items-center justify-center">
            <Icons.RightArrow className="h-full" />
          </span>
        </NavLink> */}
      </div>
      {children}
    </div>
  );
};

export default LatestEvents;

export const Item = ({
  title = "Presentation of the new department",
  time = "Today | 5:00 PM",
  duration = "4h",
  up = false,
  barColor = "#3F8CFF",
}) => {
  return (
    <div className="mb-[26px] flex h-[104px] w-full items-center">
      <div
        className="mr-[16px] h-full w-[4px] rounded-[4px]"
        style={{ backgroundColor: barColor }}
      ></div>
      <div className="w-220 inline-flex h-full flex-col justify-between py-5">
        <h3 className="inline-block h-[48px] overflow-hidden overflow-ellipsis text-[18px] font-semibold leading-[24px]">
          {title}
        </h3>
        <p className="overflow-hidden overflow-ellipsis whitespace-nowrap text-[14px]  font-medium leading-[21px] text-gray-500">
          {time}
        </p>
      </div>
      <div className="ml-auto inline-flex h-full  flex-col justify-between py-5">
        <span className="ml-auto inline-flex h-[16px] w-[12px] items-center justify-center">
          {up ? (
            <Icons.PointingUpArrow className="h-full" color="#FFBD21" />
          ) : (
            <Icons.PointingDownArrow className="h-full" color="#0AC947" />
          )}
        </span>
        <div
          className="inline-flex h-[36px] items-center rounded-[8px] bg-green-100 px-[12px] text-gray-400"
          style={{ backgroundColor: "#F4F9FD", color: "#7D8592" }}
        >
          <span className="mr-[10px] inline-flex h-[16px] w-[16px] items-center justify-center">
            <Icons.Timer className="h-full" />
          </span>
          <span className="text-[12px] font-semibold leading-[16px]">
            {duration}
          </span>
        </div>
      </div>
    </div>
  );
};

LatestEvents.Item = Item;

export const Company = ({
  title = "Presentation of the new department",
  time = "Today | 5:00 PM",
  duration = "4h",
  up = false,
}) => {
  return (
    <Item
      title={title}
      time={time}
      duration={duration}
      up={up}
      barColor="#3F8CFF"
    />
  );
};
LatestEvents.Company = Company;

export const Birthday = ({
  title = "Presentation of the new department",
  time = "Today | 5:00 PM",
  duration = "4h",
  up = false,
  barColor = "#3F8CFF",
}) => {
  return (
    <Item
      title={title}
      time={time}
      duration={duration}
      up={up}
      barColor="#DE92EB"
    />
  );
};
LatestEvents.Birthday = Birthday;

export const Student = ({
  title = "Presentation of the new department",
  time = "Today | 5:00 PM",
  duration = "4h",
  up = false,
  barColor = "#3F8CFF",
}) => {
  return (
    <Item
      title={title}
      time={time}
      duration={duration}
      up={up}
      barColor="#FDC748"
    />
  );
};
LatestEvents.Student = Student;

export const Night = ({
  title = "Presentation of the new department",
  time = "Today | 5:00 PM",
  duration = "4h",
  up = false,
  barColor = "#3F8CFF",
}) => {
  return (
    <Item
      title={title}
      time={time}
      duration={duration}
      up={up}
      barColor="#6D5DD3"
    />
  );
};
LatestEvents.Night = Night;
