import { useLocation } from "react-router-dom";

import SubjectResultDetails from "./SubjectResultDetails";

const SpreadSheetTableBody = () => {
  const location = useLocation();

  const studentResult = location?.state?.studentResult;

  return (
    <tbody className="bg-white">
      {Object.keys(studentResult.resultCompilationSheet).map(
        (subjectResult, index) => {
          const id = studentResult?.student?.id;
          const serialNumber = index + 1;
          const subject = subjectResult;
          const totalScore =
            studentResult?.resultCompilationSheet[subjectResult]?.totalScore;
          const grade = studentResult?.resultCompilationSheet[subjectResult]?.subjectGrade;
          const remark = studentResult?.resultCompilationSheet[subjectResult]?.subjectComment;

          return (
            <SubjectResultDetails
              key={`${id}-${serialNumber}`}
              serialNumber={serialNumber}
              subject={subject}
              examTypeScores={studentResult?.resultCompilationSheet[subject]}
              totalScore={totalScore}
              grade={grade}
              remark={remark}
              subjectDetails={
                studentResult?.resultCompilationSheet[subjectResult]
              }
            />
          );
        }
      )}
    </tbody>
  );
};

export default SpreadSheetTableBody;
