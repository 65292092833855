import { accountType } from "../../constants/accountType";
import { useAuth } from "../../store/authContext";
import * as Icons from "../../shared/components/icons/index";
import TickBox from "./ui/TickBox";

const ClassCard = ({
  text = "JSS 1",
  className,
  onPressIcon,
  value,
  name,
  updateValue,
  showExtra = true,
}) => {
  const { user } = useAuth();
  const userType = user?.userType;

  return (
    // <div
    //   onClick={onPressIcon}
    //   className={[
    //     "inline-flex h-[90px] w-full items-center rounded-[24px] border-[1px] bg-white pl-[30px] pr-[21px]",
    //     `${userType !== accountType.STUDENT && "cursor-pointer"}`,
    //     className,
    //   ].join(" ")}
    //   style={{ borderColor: "#D8E0F0" }}
    // >
    //   <span
    //     className={[
    //       "flex-0 mr-[27px] h-[20px] w-[20px] items-center justify-start",
    //     ].join(" ")}
    //   >
    //     <Icons.Company className="flex-0 h-full text-[22px]" color="#7D8592" />
    //   </span>
    //   <h3 className="text-uppercase inline-block w-[165px] overflow-hidden overflow-ellipsis whitespace-nowrap text-[16px] font-medium">
    //     {text}
    //   </h3>

    // </div>
    <tbody className="w-full">
      <tr className="flex h-full w-full items-center justify-between border-b-[1px] border-b-[#EAEBF0] px-6 py-6">
        <td className="flex h-full items-center self-stretch">
          {showExtra && (
            <TickBox
              name={name}
              value={value}
              updateValue={updateValue}
            ></TickBox>
          )}

          <p className="ml-12 font-body text-sm font-medium text-[#272D37]">
            {text}
          </p>
        </td>

        <td
          onClick={onPressIcon}
          className="cursor-pointer pr-4 font-body text-[24px] font-medium text-[#272D37]"
        >
          {showExtra ? "..." : null}
        </td>
      </tr>
    </tbody>
  );
};

export default ClassCard;
