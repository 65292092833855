import { Link } from "react-router-dom";

// import { PointingRightArrow } from "../icons";

const LinkButton = ({
  to,
  label,
  extraClasses,
  disabled = true,
  // showIcon = true,
}) => {
  const commonClasses =
    "bg-primary flex justify-center items-center self-center space-x-2 h-[48px] w-full rounded-[14px] text-white";

  return (
    <>
      {disabled ? (
        <button
          className={`${commonClasses} ${extraClasses} disabled:cursor-not-allowed disabled:opacity-50 ) `}
          type="submit"
          disabled
        >
          <span>{label}</span>
          {/* <PointingRightArrow color="bg-white" /> */}
        </button>
      ) : (
        <Link to={to} className={`${commonClasses} ${extraClasses}`}>
          <span>{label}</span>
          {/* {showIcon && <PointingRightArrow color="bg-white" />} */}
        </Link>
      )}
    </>
  );
};

export default LinkButton;
