import TextFieldFormik from "../../../TextFieldFormik";

const StudentEditableGuardianInfo = () => {
  return (
    <div>
      <p className="mt-[56px] mb-[24px] text-[24px] font-bold">
        Parent / Guardian info
      </p>
      <div className="grid grid-cols-2 gap-2">
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Father's Full Name"
          name="fatherFullName"
          width="w-[232px]"
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Mother's Full Name"
          name="motherFullName"
          width="w-[232px]"
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Guardian's Full Name"
          name="guardianFullName"
          width="w-[232px]"
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Guardian's Address"
          name="guardianAddress"
          width="w-[232px]"
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Guardian's Phone Number"
          name="guardianPhoneNumber"
          width="w-[232px]"
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Guardian's Email"
          name="guardianEmail"
          width="w-[232px]"
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Guardian's Occupation"
          name="guardianOccupation"
          width="w-[232px]"
          height="h-[48px]"
        />
      </div>
    </div>
  );
};

export default StudentEditableGuardianInfo;
