import StrengthCard from "../../../shared/components/StrengthCard";
import WelcomeNameAndDate from "../../../shared/components/WelcomeNameAndDate";
import MarkAttendance from "./MarkAttendance";
import PageLoading from "../../../shared/components/ui/PageLoading";
import AttendanceCard from "../../../shared/components/AttendanceCard";
import LatestEvents from "../../../shared/components/LatestEvents";

import { useTeacherDashboard } from "../store/teacherDashboardStore/teacherDashboardContext";

const TeacherDashboard = () => {
  const { state } = useTeacherDashboard();

  const { isLoading, error, firstName, students, teachers, classes, subjects } =
    state;

  return (
    <>
      {error ? (
        <p>There's an error</p>
      ) : isLoading ? (
        <PageLoading />
      ) : (
        <>
          <div className="grid grid-cols-12 content-start justify-items-stretch gap-x-6">
            {/* Welcome text and Mark Attendance button */}
            <div className="col-span-12 mb-[32px]">
              <div className="flex items-start justify-between">
                <WelcomeNameAndDate name={firstName} />
                <MarkAttendance />
              </div>
            </div>

            {/* Dashboard cards */}
            <div className="col-span-8">
              <div className="row-span-2 grid grid-cols-2 gap-6">
                <StrengthCard.Student value={students} />
                <StrengthCard.Teacher value={teachers} />
                <StrengthCard.Subject value={subjects?.length ?? 0} />
                <StrengthCard.Classes value={classes?.length ?? 0} />
              </div>
            </div>

            {/* Event panel */}
            <div className="col-span-4">
              <div className="grid grid-cols-1 gap-y-[36px]">
                <AttendanceCard />

                <div className="flex flex-col">
                  <LatestEvents href="/admin/academic/event-calender">
                    {/* <LatestEvents.Company
                      title="Presentation of the new department"
                      time="Today | 5:00 PM"
                      duration="4h"
                    />
                    <LatestEvents.Student
                      title="PTA online meeting"
                      time="Today | 6:00 PM"
                      duration="4h"
                    ></LatestEvents.Student>
                    <LatestEvents.Birthday
                      title="Ray’s Birthday"
                      time="Tomorrow | 2:00 PM"
                      duration="4h"
                    ></LatestEvents.Birthday> */}
                  </LatestEvents>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TeacherDashboard;
