import { convertToReadableDate } from "../../../../utils/helperFunctions";

const ProfileMainInfo = ({ mainInfo }) => {
  return (
    <div>
      <p className="mt-[56px] mb-[24px] text-[24px] font-bold">Main info</p>
      <div className="grid grid-cols-2">
        {Object.keys(mainInfo)
          .filter(
            (value) =>
              value !== "First Name" &&
              value !== "Last Name" &&
              value !== "Middle Name"
          )
          .map((data, index) => {
            return !mainInfo[data] || mainInfo[data]?.length === 0 ? null : (
              <div
                key={index}
                className="mb-[15px] mr-[16px] flex flex-col justify-start"
              >
                <p className="mb-[2px] text-[14px] font-semibold text-[#7D8592]">
                  {data}
                </p>
                <div className="no-scrollbar flex h-[48px] w-[240px] items-center justify-start overflow-y-hidden overflow-x-scroll rounded-[14px] border border-[#D8E0F0] p-4 py-7">
                  <span className="text-[14px]">
                    {Number.isNaN(Date.parse(mainInfo[data])) === false
                      ? convertToReadableDate(mainInfo[data])
                      : mainInfo[data]}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ProfileMainInfo;
