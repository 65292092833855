import { useState } from "react";

import * as Icons from "../../../shared/components/icons";
import UndismissibleModal from "../modals/UndismissibleModal";
import AddSubjectToClass from "./../forms/AddSubjectToClass";

import { accountType } from "../../../constants/accountType";

import { useAuth } from "../../../store/authContext";

/// [selectedClass] props will be used to determine if the subjects added to a
/// particular class should be returned to the [classContext] to optimistically
/// update the UI
export const AddSubjectToClassButton = ({ selectedClass }) => {
  const { user } = useAuth();

  const userType = user?.userType;

  const [showSubjectModal, setShowSubjectModal] = useState(false);

  const shouldShowSubjectModal = () => {
    setShowSubjectModal(prev => !prev);
  };

  const handleCloseModal = () => {
    setShowSubjectModal(false);
  };

  const addSubjectToClassContent = () => {
    return (
      <>
        <UndismissibleModal
          showModal={showSubjectModal}
          // showCloseButton={true}
          maxHeight="400px"
          header={
            <h3 className="mt-[8px] mb-[18px] text-[22px] font-semibold leading-[33px]">
              Add Subjects
            </h3>
          }
          setShowModal={setShowSubjectModal}
        >
          <AddSubjectToClass
            selectedClassProp={selectedClass}
            closeModal={handleCloseModal}
          />
        </UndismissibleModal>
        <button
          className="inline-flex h-[40px] w-[156px] transform items-center justify-center gap-[6px] rounded-[5px] bg-[#437EF7] py-[8px] px-[12px] text-[14px] font-semibold text-white shadow-sm"
          onClick={shouldShowSubjectModal}
        >
          {/* <span className="mr-[12px] inline-flex h-[14px] w-[14px]">
            <Icons.Plus className="flex-0 h-full" />
          </span> */}
          Assign Subject
        </button>
      </>
    );
  };

  return (
    <>{userType === accountType.SCHOOL ? addSubjectToClassContent() : null}</>
  );
};
