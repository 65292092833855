import { useEffect, useState } from "react";

import SubjectGradeDetailsModal from "../modals/SubjectGradeDetailsModal";
import UndismissibleModal from "../modals/UndismissibleModal";

const SubjectResultDetails = ({
  serialNumber,
  subject,
  examTypeScores,
  totalScore,
  grade,
  remark,
  subjectDetails,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const [
    studentScoresExcludingTotalScore,
    setStudentScoresExcludingTotalScore,
  ] = useState([]);

  useEffect(() => {
    const extractStudentScoresExcludingTotalScore = () => {
      const objectKeys = Object.keys(examTypeScores);

      /// Removing keys that have value of "subjectGrade" OR "totalScore"
      const arrayKeysWithoutTotalScoreAndStudentGrade = objectKeys.filter(
        (value) => value !== "subjectGrade" && value !== "totalScore"
      );

      const scoreValues = [];

      let pointer = 0;

      while (pointer < arrayKeysWithoutTotalScoreAndStudentGrade.length) {
        for (const data in examTypeScores) {
          if (data === arrayKeysWithoutTotalScoreAndStudentGrade[pointer]) {
            scoreValues.push(examTypeScores[data]);
            break;
          }
        }
        pointer++;
      }

      return scoreValues;
    };

    const result = extractStudentScoresExcludingTotalScore();

    setStudentScoresExcludingTotalScore(result);
  }, [examTypeScores]);

  return (
    <>
      {/* <UndismissibleModal
        showModal={showModal}
        setShowModal={setShowModal}
        header={<p className="text-[22px] font-bold">{subject}</p>}
        width="w-[640px]"
      >
        <SubjectGradeDetailsModal subjectDetails={subjectDetails} />
      </UndismissibleModal> */}
      <tr
        className="cursor-default border-b-[2px] border-[#E5E5E5] duration-100 ease-in hover:bg-[#B8B8B8]"
        onClick={handleShowModal}
      >
        <td className="border-r-[1px] border-grey-light p-[10px]">
          {serialNumber}
        </td>
        <td className="border-r-[1px] border-grey-light p-[10px]">{subject}</td>
        {studentScoresExcludingTotalScore?.map((score, index) => (
          <td
            key={`${score}${index}`}
            className="border-r-[1px] border-grey-light p-[10px]"
          >
            {score}
          </td>
        ))}
        <td className="border-r-[1px] border-grey-light p-[10px]">
          {totalScore}
        </td>
        <td className="border-r-[1px] border-grey-light p-[10px]">{grade}</td>
        {/* <td className="border-r-[1px] border-grey-light p-[10px]">{remark}</td> */}
        {/* <td className="p-[10px]">Dominic Winner Austin</td> */}
      </tr>
    </>
  );
};

export default SubjectResultDetails;
