import React from "react";

import ResultCommentsDashboard from "./ResultCommentsDashboard";

import { ResultCommentsProvider } from "../../../../store/resultCommentsStore/resultCommentsContext";

function ResultComments(props) {
  return (
    <ResultCommentsProvider>
      <ResultCommentsDashboard />
    </ResultCommentsProvider>
  );
}

export default ResultComments;
