import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { useLocation } from "react-router-dom";

import bleautechWhiteLogo from "../../../assets/images/logo_white.png";
import StepperReg from "../StepperReg";
import { debugPrint } from "../../../utils/debugPrint";

const SideBarLayout = ({ children }) => {
  const stepperData = {
    firstIsActive: false,
    firstIsCompleted: false,
    secondIsActive: false,
    secondIsCompleted: false,
    thirdIsActive: false,
    thirdIsCompleted: false,
  };

  const location = useLocation();

  debugPrint("SideBarLayout - location --", location);

  const [stepperState, setStepperState] = useState(stepperData);

  useEffect(() => {
    const { pathname } = location;
    if (pathname === "/register") {
      setStepperState(prevState => {
        return { ...prevState, firstIsActive: true };
      });

      return;
    }

    if (pathname === "/enter-code") {
      setStepperState(prevState => {
        return { ...prevState, firstIsActive: true };
      });

      return;
    }

    if (pathname === "/onboarding/school-contact-info") {
      setStepperState(prevState => {
        return {
          ...prevState,
          firstIsCompleted: true,
          secondIsActive: true,
          secondIsCompleted: false,
        };
      });
    }

    if (pathname === "/onboarding/school-registration-info") {
      setStepperState(prevState => {
        return { ...prevState, secondIsCompleted: true };
      });
    }

    if (pathname === "/onboarding/school-super-admin") {
      setStepperState(prevState => {
        return { ...prevState, thirdIsCompleted: true };
      });
    }
  }, [location]);

  return (
    <div className='grid h-screen w-screen grid-cols-12 gap-10 overflow-y-auto bg-[#F4F9FD] lg:py-[20px] lg:pl-[20px] lg:pr-[40px]'>
      <div className='sticky top-0 lg:col-span-5 xl:col-span-4 2xl:col-span-3 lg:block h-[screen] overflow-hidden rounded-[24px] bg-blue pl-[40px] pt-[69px] hidden'>
        <img
          src={bleautechWhiteLogo}
          className='mb-[147.12px] h-auto w-[151px]'
          alt='the company logo'
        />
        <div className='flex h-[248px] w-[272px] flex-shrink-0 flex-col justify-between'>
          <h1 className='font-body text-[36px] font-[700] text-white'>
            Get started
          </h1>

          <div className='flex flex-col gap-[6px]'>
            <StepperReg
              label='Register admin'
              isActive={stepperState.firstIsActive}
              isCompleted={stepperState.firstIsCompleted}
            />
            <StepperReg
              label='Enter school registration'
              isActive={stepperState.secondIsActive}
              isCompleted={stepperState.secondIsCompleted}
            />
            <StepperReg
              label='Enter head school info'
              showVertStep={false}
              isActive={stepperState.thirdIsActive}
              isCompleted={stepperState.thirdIsCompleted}
            />
          </div>
        </div>
      </div>

      <div className='lg:col-span-7 xl:col-span-8 2xl:col-span-9 flex h-full lg:w-full w-screen items-center justify-center lg:rounded-[24px] lg:py-[80px] shadow-md'>
        {children}
      </div>
    </div>
  );
};

SideBarLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SideBarLayout;
