import { createContext, useContext, useEffect, useReducer } from "react";

import payrollReducer, { initialState } from "./payrollReducer";
import { payrollActions } from "./payrollActions";
import {
  getSchoolPayroll,
  getAllSchoolPayrolls,
  getAllSchoolTeachers,
  createPayroll,
} from "../../services/schoolService";
import { showErrorToast } from "../../../../utils/toastHandler";

import { debugPrint } from "../../../../utils/debugPrint";
import { isEmpty } from "../../../../utils/objectHelperFunctions";

const PayrollContext = createContext(null);

PayrollContext.displayName = "PayrollContext";

const PayrollProvider = ({ children }) => {
  const [state, dispatch] = useReducer(payrollReducer, initialState);

  useEffect(() => {
    const fetchAllSchoolPayroll = async () => {
      dispatch({
        type: payrollActions.UPDATE_LOADING_STATE,
        payload: { isLoading: true },
      });

      try {
        const { data } = await getAllSchoolPayrolls();

        const { status, message } = data;

        if (status === true) {
          const { data: payrolls } = data || {};

          if (isEmpty(payrolls)) {
            return dispatch({
              type: payrollActions.REQUEST_SUCCESS,
              payload: {
                payrolls: [],
              },
            });
          }

          dispatch({
            type: payrollActions.REQUEST_SUCCESS,
            payload: {
              payrolls: [...payrolls],
            },
          });
        } else {
          throw new Error(message ?? "Something went wrong");
        }
      } catch (err) {
        dispatch({
          type: payrollActions.ADD_PAYROLL_ERROR,
          payload: { error: err?.message ?? "Something went wrong" },
        });
      } finally {
        dispatch({
          type: payrollActions.UPDATE_LOADING_STATE,
          payload: { isLoading: false },
        });
      }
    };

    fetchAllSchoolPayroll();
  }, []);

  // Function responsible for clearing selected staff in state
  const clearSelectedStaff = () => {
    dispatch({ type: payrollActions.CLEAR_SELECTED_STAFF });
  };

  const selectAllStaffIds = staffIds => {
    dispatch({
      type: payrollActions.SELECT_ALL_STAFF,
      payload: { staffIds: staffIds },
    });
  };

  const updateSelectedStaff = staffId => {
    debugPrint("payrollContent - updatedSelectedStaff -- staffId ->", staffId);
    dispatch({
      type: payrollActions.UPDATE_SELECTED_STAFF,
      payload: { staffId: staffId },
    });
  };

  // For creating payroll
  const fetchAllTeachers = async () => {
    dispatch({
      type: payrollActions.UPDATE_TEACHER_LOADING_STATE,
      payload: { isTeacherLoading: true },
    });

    try {
      const { data } = await getAllSchoolTeachers();

      const { status, message } = data;

      if (status === true) {
        const { data: teachers } = data;

        dispatch({
          type: payrollActions.REQUEST_SUCCESS_TEACHER,
          payload: {
            teachers: [...teachers],
          },
        });
      } else {
        throw new Error(message ?? "Something went wrong");
      }
    } catch (err) {
      dispatch({
        type: payrollActions.ADD_TEACHERS_ERROR,
        payload: {
          teacherError: err?.message ?? "Something went wrong",
        },
      });
    } finally {
      dispatch({
        type: payrollActions.UPDATE_TEACHER_LOADING_STATE,
        payload: {
          isTeacherLoading: false,
        },
      });
    }
  };

  const addPayroll = payroll => {
    dispatch({
      type: payrollActions.ADD_CREATED_PAYROLL,
      payload: {
        payroll: payroll,
      },
    });
  };

  const updatePayroll = payroll => {
    dispatch({
      type: payrollActions.UPDATE_PAYROLL,
      payload: {
        payroll: payroll,
      },
    });
  };

  const values = {
    state,
    clearSelectedStaff,
    updateSelectedStaff,
    selectAllStaffIds,
    fetchAllTeachers,
    addPayroll,
    updatePayroll,
  };

  return (
    <PayrollContext.Provider value={values}>{children}</PayrollContext.Provider>
  );
};

const usePayroll = () => {
  const context = useContext(PayrollContext);

  if (context === undefined) {
    throw new Error("usePayroll must be used within PayrollContext");
  }

  return context;
};

export { PayrollProvider, usePayroll };
