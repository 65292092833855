import { useEffect, useState } from "react";

import TextFieldFormik from "../../../TextFieldFormik";
import { Selector } from "../../../MultiCheckBox";

import { schoolClassListLabelAndValue } from "../../../../../assets/lists/schoolClasses";

const TeacherEditableJobInfo = ({
  subjects,
  isSubjectsLoading,
  values,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
}) => {
  const [activeSubjects, setActiveSubjects] = useState();

  const [activeClasses, setActiveClasses] = useState();

  const [subjectsLabelAndValue, setSubjectsLabelAndValue] = useState();

  useEffect(() => {
    if (subjects != null) {
      const mappedValue = subjects?.map((data) => {
        return { label: data, value: data };
      });

      setSubjectsLabelAndValue(mappedValue);
    }
  }, [subjects]);

  useEffect(() => {
    const mappedSubjectValues = values["subjects"]?.map((data) => {
      return { label: data, value: data };
    });

    setActiveSubjects(mappedSubjectValues);

    const mappedClassValues = values["classes"]?.map((data) => {
      return { label: data?.toUpperCase(), value: data };
    });

    setActiveClasses(mappedClassValues);
  }, []);

  return (
    <div>
      <p className="mt-[56px] mb-[24px] text-[24px] font-bold">Job info</p>
      <div className="grid grid-cols-2 gap-2">
        <Selector
          label="Subjects"
          value={activeSubjects}
          placeholder="Select subject(s)"
          options={subjectsLabelAndValue}
          noOptionsMessage="No available subjects"
          onFocus={() => setFieldTouched("subjects", true, false)}
          handleSelection={(array) => {
            setFieldValue("subjects", array);
            setActiveSubjects(array);
          }}
        />

        <Selector
          label="Classes"
          value={activeClasses}
          placeholder="Select class(es)"
          options={schoolClassListLabelAndValue}
          noOptionsMessage="No available classes"
          onFocus={() => setFieldTouched("classes", true, false)}
          handleSelection={(array) => {
            setFieldValue("classes", array);
            setActiveClasses(array);
          }}
        />

        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Years of Experience"
          name="yearsOfExperience"
          width="w-[232px]"
          height="h-[48px]"
        />
      </div>
    </div>
  );
};

export default TeacherEditableJobInfo;
