import { useState } from "react";

import IconAndLabel from "./ui/IconAndLabel";
import Modal from "../components/ui/Modal";
import MoreVertButton from "./ui/MoreVertButton";
import StudentTeacherModal from "../../features/student/modals/StudentTeacherModal";
import TeacherProfile from "./modals/profile/teacher/TeacherProfile";
import TickBox from "./ui/TickBox";
import { Delete, Edit } from "./icons";

import { accountType } from "../../constants/accountType";

import { useAuth } from "../../store/authContext";

import { debugPrint } from "../../utils/debugPrint";

/**
 * @typedef {Object} MainInfo
 * @property {string} ['User ID'] - teacher ID
 * @property {string} ['First Name' ]- first name
 * @property {string} ['Middle Name'] - middle name
 * @property {string} Email - Teacher email
 * @property {string} Contact - Phone number
 * @property {string} '[Date of Birth'] - Date of birth
 *
 */

/**
 * @typedef {Object} JobInfo
 * @property {string[]} Subjects - Subjects teacher is teaching
 * @property {string[]} Classes - Classes teacher is in-charge of
 * @property {string} ['Year of Experience'] - Teacher's years of experience
 */

/**
 * @typedef {Object} LocationInfo
 * @property {string} Country - Country of the teacher
 * @property {string} State - State of the teacher
 * @property {string} LGA - Local government area
 * @property {string} Address - Address of the teacher
 */

/**
 * @typedef {Object} SalaryInfo
 * @property {string} accountName - Account Name of the teacher
 * @property {string} accountNumber - Account Number of the teacher
 * @property {string} bank - Bank of the teacher
 */

/**
 * @typedef {Object} Props
 * @property {string} teacherAuthId - The auth ID of the teacher
 * @property {string} [profileImage] - URL string of the teacher
 * @property {MainInfo} mainInfo - The main info body of the teacher
 * @property {JobInfo} jobInfo - The job info body of the teacher
 * @property {LocationInfo} locationInfo - The location info body of the teacher
 * @property {function} onDelete - Callback function to trigger delete teacher
 * @property {*} name - Value to be used on the TickBox
 * @property {boolean} isSelected - Boolean value that indicates if a teacher card has been selected
 * @property {function} updateValue - Callback function to toggle {isSelected} value
 *
 */

/**
 * @description - UI component for displaying a Teacher Card
 * @param {Props} props - The properties for the TeacherCard component
 * @return {React.JSX.Element} - The rendered teacher card component
 */
const TeacherCard = ({
  teacherAuthId,
  profileImage,
  mainInfo,
  jobInfo,
  locationInfo,
  otherInfo,
  salaryInfo,
  onDelete,
  name,
  isSelected,
  updateValue,
}) => {
  const { user } = useAuth();
  const userType = user?.userType;

  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleEditClick = () => {
    setShowModal(true);
  };
  const handleDeleteClick = () => {
    debugPrint("TeacherCard - handleDeleteClick -- clicked!!!");
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <Modal showModal={showModal} onClose={() => setShowModal(false)}>
        {userType === accountType.STUDENT ? (
          <StudentTeacherModal
            profileImage={profileImage}
            id={mainInfo["User ID"]}
            fullName={`${mainInfo["First Name"]} ${mainInfo["Last Name"]}`}
            phoneNumber={mainInfo["Contact"]}
            subjects={jobInfo["Subjects"]}
            classInCharge={jobInfo["Classes"]}
          />
        ) : (
          <TeacherProfile
            teacherAuthId={teacherAuthId}
            profileImage={profileImage}
            mainInfo={mainInfo}
            jobInfo={jobInfo}
            locationInfo={locationInfo}
            otherInfo={otherInfo}
            salaryInfo={salaryInfo}
            onEditProfileSuccess={() => setShowModal(false)}
          />
        )}
      </Modal>
      <tbody>
        {/* h-full w-full */}
        <tr className="border-b-[1px] border-b-[#EAEBF0]">
          <td className="pl-8">
            <div className="flex items-center">
              <TickBox
                name={name}
                value={isSelected}
                updateValue={updateValue}
              />
            </div>
          </td>

          <td className="px-6 py-8 font-body text-sm font-medium leading-5 text-[#272D37]">
            {mainInfo?.["User ID"]}
          </td>

          <td className="flex items-center gap-4 py-8 px-6">
            <div className="h-[32px] w-[32px] rounded-full text-center">
              {profileImage && (
                <img
                  src={profileImage}
                  className="h-full w-full rounded-full object-cover"
                  alt="img"
                />
              )}
            </div>
            <a className="font-body text-sm font-medium capitalize text-[#272D37]">{`${mainInfo?.["First Name"]} ${mainInfo?.["Last Name"]}`}</a>
          </td>

          <td className="py-8 px-6 font-body text-sm font-medium text-[#272D37]">
            {" "}
            {jobInfo?.["Subjects"].length}
          </td>
          <td className="py-8 px-6 font-body text-sm font-medium text-[#272D37]">
            {" "}
            {jobInfo?.["Classes"].length}
          </td>
          {/* <td
            className='py-8 px-6 font-body text-[24px] font-medium text-[#272D37] cursor-pointer'
            onClick={() => setShowModal(true)}
          >
            {" "}
            ...
          </td> */}

          <td className="flex cursor-pointer items-center justify-center py-8 px-6 font-body font-medium text-[#272D37] ">
            {userType === accountType.SCHOOL && (
              <MoreVertButton>
                <div className="rounded-[24px] bg-white p-6">
                  <IconAndLabel label="Edit" onClick={handleEditClick}>
                    <Edit className="text-black" />
                  </IconAndLabel>
                  <IconAndLabel label="Delete" onClick={onDelete}>
                    <Delete className="text-danger" />
                  </IconAndLabel>
                </div>
              </MoreVertButton>
            )}
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default TeacherCard;
