import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";

import TextFieldFormik from "../../../shared/components/TextFieldFormik";
import Button from "../../../shared/components/ui/Button";
import SideBarLayout from "../../components/layouts/SideBarLayout";

import { createSchoolAccountSchema } from "../../../utils/validationSchemas/createSchoolAccountSchema";
import { removeWhiteSpacesFromObjValues } from "../../../utils/helperFunctions";
import { showErrorToast, showSuccessToast } from "../../../utils/toastHandler";

import { createSchoolAccount } from "../../../services/authService";

const CreateSchoolAccount = () => {
  let navigate = useNavigate();

  const initialValues = {
    schoolName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const formHandler = async values => {
    const trimmedValues = removeWhiteSpacesFromObjValues(values);

    const { confirmPassword, ...params } = trimmedValues;

    try {
      const response = await createSchoolAccount(params);

      if (response.status === true) {
        showSuccessToast("Success");

        navigate("/enter-code", {
          replace: true,
          state: {
            email: response?.schoolDetails?.email,
            id: response?.schoolDetails?.id,
          },
        });
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  return (
    <SideBarLayout>
      <div className='inline-flex h-full md:w-[403px] w-full px-6 md:px-0 flex-col items-center justify-center'>
        <div className='flex flex-col items-center gap-[18px]'>
          <h2 className='text-center font-display text-sm font-bold uppercase text-[#3F8CFF] '>
            Step 1/4
          </h2>
          <h1 className='mb-[20px] text-center font-body text-[24px] font-bold leading-normal text-[#0a1629]'>
            {" "}
            Register admin
          </h1>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={createSchoolAccountSchema}
          onSubmit={formHandler}
        >
          {({ handleChange, isSubmitting }) => (
            <Form className='flex w-full flex-col gap-3'>
              <div className='flex flex-col gap-[14px]'>
                <TextFieldFormik
                  extraClasses=''
                  label='School Name'
                  name='schoolName'
                  placeholder='Enter school name'
                />

                <TextFieldFormik
                  extraClasses=''
                  label='Email Address'
                  name='email'
                  placeholder='youremail@gmail.com'
                />
                <TextFieldFormik
                  extraClasses=''
                  label='Password'
                  name='password'
                  placeholder='Enter Password'
                  type='password'
                />
                <TextFieldFormik
                  extraClasses=''
                  label='Confirm Password'
                  name='confirmPassword'
                  placeholder='Enter Password'
                  type='password'
                />
                {/* TODO: Ellis -> Talk to backend how receiving BleauTech mail will receive and what endpoint to hit */}
                <div className='flex items-center'>
                  <input
                    name='rememberMe'
                    type='checkbox'
                    id='rememberMe'
                    className='mr-2 h-[16px] w-[16px] rounded border-[#D8E0F0] text-primary focus:ring-[#D8E0F0]'
                    onChange={handleChange}
                  />
                  <label
                    htmlFor='rememberMe'
                    className='text-sm font-medium text-[#7D8592]'
                  >
                    Yes, I want to receive Bleautech emails
                  </label>
                </div>
              </div>

              <Button
                type='submit'
                extraClasses='my-[20px] self-center'
                isLoading={isSubmitting}
              >
                <div className='flex items-center justify-center'>
                  <span>Sign Up</span>
                </div>
              </Button>
            </Form>
          )}
        </Formik>

        <div className='item flex justify-center'>
          <p className='text-center font-body text-base font-[600] leading-normal text-[#828282]'>
            Already have an account?{" "}
            <Link className='cursor-pointer text-[#2969FF]' to='/login'>
              Login
            </Link>
          </p>
        </div>
      </div>
    </SideBarLayout>
  );
};

export default CreateSchoolAccount;
