import TextFieldFormik from "../../../TextFieldFormik";

const TeacherEditableOtherInfo = () => {
  return (
    <div>
      <p className="mt-[56px] mb-[24px] text-[24px] font-bold">Other info</p>
      <div className="grid grid-cols-2 gap-2">
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Former Job Title"
          name="formerJobTitle"
          width="w-[232px]"
          height="h-[48px]"
        />
        <TextFieldFormik
          extraClasses="mb-[15px]"
          label="Former Employer Name"
          name="formerEmployerName"
          width="w-[232px]"
          height="h-[48px]"
        />
      </div>
    </div>
  );
};

export default TeacherEditableOtherInfo;
