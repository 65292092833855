import * as Yup from "yup";

// export const fundWalletSchema = Yup.object().shape({
//   amount: Yup.number()
//     .min(100, "Amount must be at least 100")
//     .required("Please enter amount")
//     .typeError("Enter valid amount"),
// });

// export const fundWalletSchema = Yup.object().shape({
//    amount: Yup.number()
//      .min(100, "Amount must be at least 100")
//      .required("Please enter amount")
//      .typeError("Enter valid amount")
//      .matches(/^(?!0\d)\d+(\.\d{1,2})?(?<!\.\d{3,})$/, "Enter valid amount"),
//  });

export const fundWalletSchema = Yup.object().shape({
  amount: Yup.number()
    .min(100, "Amount must be at least 100")
    .required("Please enter amount")
    .typeError("Enter valid amount")
    .test(
      "decimal-places",
      "Enter valid amount",
      (value) => {
        if (value === undefined || value === null) return false;
        const strValue = value.toString();
        const decimalIndex = strValue.indexOf(".");
        if (decimalIndex === -1) return true; // Whole number
        const decimalPlaces = strValue.length - decimalIndex - 1;
        return decimalPlaces <= 2; // Up to 2 decimal places
      }
    ),
});
