import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NormalSelect from "../../../../shared/components/ui/NormalSelect";
import PageLoading from "../../../../shared/components/ui/PageLoading";
import GridTileCard from "../../../../shared/components/GridTileCard";
import ResultTile from "../../../../shared/components/results/ResultTile";
import PaginationIndicator from "../../../components/pagination/PaginationIndicator";
import PageSearchIconAndLabel from "../../../../shared/components/PageSearchIconAndLabel";
import StudentResultCard from "../../../../features/student/components/StudentResultCard";
import { AddButtonPlain } from "../../../../shared/components/ui/Buttons";

import { alphabets } from "../../../../assets/lists/alphabet";
import { schoolTerms } from "../../../../assets/lists/schoolTerms";
import { schoolClasses } from "../../../../assets/lists/schoolClasses";
import { getYearRange } from "../../../../utils/getYearRange";
import { paginateData } from "../../../../utils/clientSidePagination";
import { positionFormatter } from "../../../../utils/helperFunctions";

import { accountType } from "../../../../constants/accountType";
import { useResults } from "../../../../store/resultsStore/resultsContext";
import { useAuth } from "../../../../store/authContext";

import SearchField from "../../../components/ui/SearchField";

import deleteIcon from "../../../../assets/svg/delete.svg";
import menu from "../../../../assets/svg/menu-left.svg";

const ResultsDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuth();

  const { userType } = user || {};

  const { state, fetchInitialData, handleFilterSelection } = useResults();

  const { isInitial, isLoading, error, allStudents, filterParams } = state;

  const [classesOption, setClassesOption] = useState([...schoolClasses]);

  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPage = () => {
    setCurrentPage(prevState => prevState + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevState => prevState - 1);
  };

  useEffect(() => {
    const checkUserTypeAndMaybeSetClassFilter = () => {
      if (userType === accountType.TEACHER) {
        setClassesOption([...user?.teacherClasses]);
      }
    };

    checkUserTypeAndMaybeSetClassFilter();
  }, []);

  useEffect(() => {
    const handleFilter = () => {
      // Check if all three necessary filter parameters are present
      if (
        filterParams.termFilter &&
        filterParams.classFilter &&
        filterParams.subClassFilter &&
        filterParams.yearFilter
      ) {
        fetchInitialData(filterParams);
      }
    };

    // Check if any of the filter parameters changes and trigger filtering
    handleFilter();
  }, [
    filterParams.termFilter,
    filterParams.classFilter,
    filterParams.subClassFilter,
    filterParams.yearFilter,
  ]);

  const handleNavigation = (id, studentResult) => {
    navigate(id, {
      state: {
        studentResult,
      },
    });
  };

  return (
    <div className="flex h-fit  w-full flex-col overflow-x-scroll  scroll-smooth  rounded-[10px] bg-[#fff] pb-12 pt-6 shadow-sm">
      <div className="mb-[24px] flex items-center justify-between px-6">
        <h2 className="text-[28px] font-semibold leading-[38px] text-black">
          {userType === accountType.STUDENT ? "My Results" : "Result "}
        </h2>

        {userType === accountType.SCHOOL && (
          <AddButtonPlain className="  w-[100px] bg-[#2F80ED]">
            <span className=" text-[14px] font-semibold leading-[20px] text-white">
              Pay
            </span>
          </AddButtonPlain>
        )}
      </div>

      {userType !== accountType.STUDENT && (
        <div className=" ml-5 mb-6 flex items-center  justify-between">
          <div className=" flex ">
            <SearchField className="ml-auto " />
          </div>

          <div className=" hidden gap-3 md:hidden xl:flex xl:items-end xl:justify-end xl:pr-4 ">
            <NormalSelect
              label=""
              placeholder="Term"
              className={``}
              value={filterParams.termFilter}
              options={schoolTerms}
              handleSelect={event =>
                handleFilterSelection("termFilter", event.value)
              }
            />
            <NormalSelect
              label=""
              placeholder="Class"
              className={``}
              options={classesOption}
              value={filterParams.classFilter}
              handleSelect={event =>
                handleFilterSelection("classFilter", event.value)
              }
            />
            <NormalSelect
              label=""
              placeholder="SubClass"
              className={``}
              value={filterParams.subClassFilter}
              options={alphabets}
              handleSelect={event =>
                handleFilterSelection("subClassFilter", event.value)
              }
            />
            <NormalSelect
              label=""
              placeholder="Year"
              className={``}
              value={filterParams.yearFilter}
              options={getYearRange()}
              handleSelect={event =>
                handleFilterSelection("yearFilter", event.value)
              }
            />

            {/* <button className=" border-[1px] rounded-[7px] h-[44px] w-[100px] text-[14px] leading-[20px] font-semibold text-[#2F80ED]">Promote</button> */}

            {/* <AddButtonPlain
            className={`px-[50px]`}
            showIcon={false}
            clicked={() => fetchInitialData(filterParams)}
            disabled={!isFilterButtonActive}
          >
            Filter
          </AddButtonPlain> */}
          </div>
        </div>
      )}

      {isInitial ? (
        <div className="flex h-full items-center justify-center">
          <PageSearchIconAndLabel label="Search for Result" />
        </div>
      ) : isLoading ? (
        <PageLoading classes="h-full" />
      ) : error ? (
        <div className="flex h-full items-center justify-center">
          <PageSearchIconAndLabel label={error ?? "Couldn't find result"} />
        </div>
      ) : allStudents.length === 0 ? (
        <div className="flex h-full items-center justify-center">
          <PageSearchIconAndLabel />
        </div>
      ) : userType === accountType.STUDENT ? (
        <div className="">
          <GridTileCard classes="mb-[20px]">
            <p className="col-span-4 text-[16px] font-semibold">Class</p>
            <p className="col-span-2 place-self-center text-[16px] font-semibold">
              Term
            </p>
            <p className="col-span-1 place-self-center text-[16px] font-semibold">
              Year
            </p>
            <p className="col-span-3 place-self-center text-[16px] font-semibold">
              Status
            </p>

            <p className="col-span-2 place-self-center text-[16px] font-semibold">
              Action
            </p>
          </GridTileCard>

          {allStudents.map((studentResult, index) => {
            const published = studentResult?.published;
            const hasPaidForResult = studentResult?.hasPaidForResult;
            const reason = studentResult?.reason;
            const studentClass = studentResult?.class;
            const studentTerm = studentResult?.term;
            const studentYear = studentResult?.year;

            const studentAuthId = studentResult?.studentAuthId;

            return (
              <div
                key={`${studentAuthId}${index}`}
                className="col-span-4 mb-[24px]"
              >
                <StudentResultCard
                  studentClass={studentClass}
                  studentTerm={studentTerm}
                  studentYear={studentYear}
                  published={published}
                  hasPaidForResult={hasPaidForResult}
                  reason={reason}
                  onClick={() => handleNavigation(studentAuthId, studentResult)}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <>
          <table className=" w-full">
            <thead classes=" h-full w-full">
              <tr className="border-b-[1px] border-t-[1px] border-b-[#EAEBF0]">
                <th className=" items-start px-[24px] text-start">
                  <input
                    name="rememberMe"
                    type="checkbox"
                    id="rememberMe"
                    className=" h-[20px] w-[20px] items-center rounded  border-[1px] text-primary focus:ring-[#D8E0F0]"
                  />
                </th>

                <th className="py-[12px] px-[24px]  text-left font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]">
                  ID
                </th>

                <th className="py-[12px] px-[24px]  text-left font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]">
                  Students
                </th>
                <th className="py-[12px] px-[px]  text-left font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]">
                  Total Score
                </th>
                <th className="py-[12px] px-[24px]  text-left font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]">
                  Position
                </th>
                <th className="py-[12px] px-[24px]  text-left font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]">
                  Result Status
                </th>
                <th className="py-[12px] px-[24px]  text-left font-body text-sm font-medium leading-[18px] tracking-wide text-[#272D37]">
                  Actions
                </th>
              </tr>
            </thead>

            {paginateData(allStudents, currentPage)?.map(studentResult => {
              const id = studentResult?.student?.id;
              const studentId = studentResult?.studentId ?? id;
              const firstName = studentResult?.student?.firstName;
              const lastName = studentResult?.student?.lastName;
              const profileImage = studentResult?.student?.passportImageUrl;
              const studentAuthId = studentResult?.studentAuthId;

              const resultTotal = studentResult?.resultTotal;
              const resultAverage = studentResult?.resultAverage;
              const position = studentResult?.position;

              const published = studentResult?.published;

              const statusMessage = published ? "Published" : "Not published";

              const gradeComment = studentResult?.gradeComment;
              return (
                <ResultTile
                  key={studentId}
                  id={studentId}
                  name={`${firstName} ${lastName}`}
                  profileImage={profileImage}
                  totalScore={resultTotal}
                  averageScore={resultAverage}
                  position={positionFormatter(position)}
                  status={statusMessage}
                  onClick={
                    resultAverage > 0
                      ? () => handleNavigation(studentId, studentResult)
                      : null
                  }
                />
              );
            })}

            <PaginationIndicator
              classes="self-end"
              currentPageNumber={currentPage}
              itemCount={paginateData(allStudents, currentPage)?.length}
              totalCount={allStudents?.length}
              onBackArrowClick={handlePreviousPage}
              onForwardArrowClick={handleNextPage}
            />
          </table>
        </>
      )}
    </div>
  );
};

export default ResultsDashboard;
