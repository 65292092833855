import * as React from "react";

const SvgPlus = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.457 8.26H8.26v6.197H6.196V8.26H0V6.196h6.196V0H8.26v6.196h6.196V8.26Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPlus;
