import * as Icons from "./icons/index";

const SubjectCard = ({ text = "Mathematics", className, onPressIcon }) => {
  return (
    <div
      className={[
        "inline-flex h-[91px] w-full items-center rounded-[24px] border-[1px] bg-white pl-[30px] pr-[21px] cursor-default",
        className,
      ].join(" ")}
      style={{ borderColor: "#D8E0F0" }}
    >
      <span
        className={[
          "flex-0 mr-27 h-[20px] w-[20px] items-center justify-start mr-[10px]",
        ].join(" ")}
      >
        <Icons.ThreeCircle
          className="flex-0 h-full text-[22px]"
          color="#7D8592"
        />
      </span>
      <h3 className="inline-block w-[165px]  overflow-hidden overflow-ellipsis whitespace-nowrap text-[16px] font-medium">
        {text}
      </h3>
      {/* <button
        className="flex-0 ml-auto flex h-[44px] w-[44px] items-center justify-center rounded-[14px]"
        style={{ backgroundColor: "#F4F9FD" }}
        onClick={onPressIcon}
      >
        <span className="flex-0 inline-flex h-[18px] w-[3px] items-center justify-center">
          <Icons.VerticalEllipsis
            className="flex-0 h-full text-[22px]"
            color="#0A1629"
          />
        </span>
      </button> */}
    </div>
  );
};

export default SubjectCard;
