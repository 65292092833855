import { useState } from "react";

import Button from "../ui/Button";
import Chip from "../ui/Chip";

import NormalTextInput from "../ui/NormalTextInput";
import { BackButton } from "../ui/Buttons";

import { addSubjects } from "../../../features/school/services/schoolService";
import { showErrorToast, showSuccessToast } from "../../../utils/toastHandler";
import { useSubject } from "../../../store/subjectStore/subjectContext";

export default function AddSubject({ closeModal }) {
  const { updateAllSubjectsList } = useSubject();

  const [activeStep, setActiveStep] = useState(1);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const [subject, setSubject] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subjects, setSubjects] = useState([]);

  const pendSubject = event => {
    event.preventDefault();

    if (subject?.trim() === "") {
      return;
    }

    setSubjects(oldSubjects => [...oldSubjects, subject]);

    setSubject("");
  };

  const handleRemoveSubject = index => {
    const recentSubjects = [...subjects];
    recentSubjects.splice(index, 1);
    setSubjects(recentSubjects);
  };

  const handleKeyPress = event => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      pendSubject(event);
    }
  };

  const subjectSubjects = async event => {
    if (subject !== "") {
      pendSubject(event);
    }
    setIsSubmitting(true);

    const processedSubjects = [];

    // Removing white spaces from added subjects
    for (const item of subjects) {
      const trimmedValue = item?.trim();
      processedSubjects.push(trimmedValue);
    }
    try {
      const response = await addSubjects({ listOfSubjects: processedSubjects });

      const { data } = response;
      if (data.status === true) {
        // updateLocalSubjects([...localSubjects, ...subjects]);
        setSubjects([]);
        // setShowSuggestion(!showSuggestion);

        updateAllSubjectsList(processedSubjects);
        closeModal(); // Close the modal window
        showSuccessToast(data?.message ?? "Success");
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      showErrorToast(error.message, "");
    }
    setIsSubmitting(false);
  };

  const handleSAddSubject = value => {
    setSubject(value);
  };

  return (
    <div className="w-[400px] px-[10px]">
      <form id="form">
        <div className="flex flex-row items-center justify-between ">
          {
            <>
              {activeStep === 1 ? (
                <h3 className="mt-[8px] text-[22px] font-semibold leading-[33px]">
                  {showSuggestion ? "Add more subjects" : "Add Subject"}
                </h3>
              ) : (
                <BackButton clicked={() => setActiveStep(activeStep - 1)} />
              )}
            </>
          }
          <span
            onClick={closeModal}
            className="flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-[14px] bg-[#F4F9FD] p-[14px] text-2xl text-black"
          >
            ×
          </span>
        </div>
        <div className="my-[10px] bg-white p-[5px]">
          {subjects.length > 0 ? (
            <div className="flex h-auto max-h-[120px] w-full flex-row flex-wrap gap-[10px] overflow-x-hidden overflow-y-scroll">
              {subjects.map((subject, index) => (
                <Chip
                  key={index}
                  index={index}
                  name={subject}
                  onRemove={() => handleRemoveSubject(index)}
                />
              ))}
            </div>
          ) : (
            <p className="text text-center text-[14px] font-normal text-gray-700">
              If you add a subject, it shows up here. If you are done adding
              subject,{" "}
              <span className="font-bold">
                please press the <span className="text-danger">"ENTER"</span> key.
              </span>
            </p>
          )}
        </div>
        <div className="mt-[20px] grid gap-[20px] bg-white">
          <NormalTextInput
            label={"Subject"}
            name="subject"
            value={subject}
            placeholder={"English Language"}
            onChange={text => handleSAddSubject(text)}
            onKeyPress={handleKeyPress}
          />

          <div className="mt-[30px] flex w-full justify-end ">
            <Button
              type="button"
              extraClasses="self-end"
              disabled={subjects.length > 0 ? false : true}
              onClick={subjectSubjects}
              isLoading={isSubmitting}
            >
              {subjects.length > 0 ? "Add Subjects" : "Add Subject"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
