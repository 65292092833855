const _routes = {
  HOME: "/",
  DASHBOARD: "dashboard" || "dashboard/modal",

  // Auth
  LOGIN: "login",
  ONBOARDING: "onboarding/*",
  ENTER_CODE: "enter-code",
  CREATE_NEW_PASSWORD: "create-new-password",
  VERIFY_SENT_CODE: "verify-sent-code",
  REGISTER: "register",
  FORGOT_PASSWORD: "forgot-password",

  // Users
  USERS: "users",
  TEACHERS: "teachers",
  STUDENTS: "students",

  // Assessments
  ASSESSMENTS: "assessments",
  MARKS: "marks",
  EXAM_TYPES: "exam-types",
  RESULT_COMMENTS: "result-comments",
  PROMOTION: "promotion",
  RESULTS: "results/*",

  // Back office
  BACK_OFFICE: "back-office",
  SESSION: "session",

  // Academic
  ACADEMIC: "academic",
  SUBJECTS: "subjects",
  CLASS: "class/*",

  ACCOUNTING: "accounting",
  SETTINGS: "settings",
  SUPPORT: "support",
  PASSWORD_RECOVERY_EMAIL: "/password-recovery-email",
};

export const routes = Object.freeze(_routes);

/// Routes teacher account shouldn't be able to access
export const routesNotForTeacher = [
  "/users/admin",

  "/assessments/exam-types",
  "/assessments/promotion",
  "/assessments/result-comments",

  "/back-office/session",
  "/back-office/library",
  "/back-office/notice-board",
  "/back-office/school-manager",

  "/account/*",

  "/settings/*",
  "/support",
];

/// Routes student account shouldn't be able to access
export const routesNotForStudent = [
  "/academics/syllabus",
  "/academics/event-calendar",
  "/academics/schedule",

  "/users/admin",
  "/users/students",
  "/users/parents",

  "/assessments/marks",
  "/assessments/exam-types",
  "/assessments/result-comments",
  "/assessments/promotion",

  "/back-office/*",

  "/account/*",

  "/settings/*",
  "/support",
];
