import { createContext, useReducer, useContext } from "react";

import { resultCommentsActions } from "./resultCommentsActions";
import gradeReducer, { initialState } from "./resultCommentsReducer";

import {
  addGrade,
  getAllGrades,
  updateSingleGrade,
  deleteSingleGrade,
  getSingleGrade,
} from "../../features/school/services/schoolService";

import {
  addTeacherGradeComment,
  deleteTeacherGradeComment,
  getAllTeacherComments,
  updateTeacherGradeComment,
} from "../../features/teacher/services/teacherService";

import { showErrorToast, showSuccessToast } from "../../utils/toastHandler";

import { accountType } from "../../constants/accountType";
import { useAuth } from "../authContext";

import { debugPrint } from "../../utils/debugPrint";

const ResultCommentsContext = createContext(initialState);

ResultCommentsContext.displayName = "GradesContext";

const ResultCommentsProvider = ({ children }) => {
  const { user } = useAuth();
  const userType = user?.userType;

  const [state, dispatch] = useReducer(gradeReducer, initialState);

  const _handleGetAllGrades = async () => {
    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolGrades } = await getAllGrades();
        return schoolGrades;
      case accountType.TEACHER:
        const { data: teacherGrades } = await getAllTeacherComments(
          user?.schoolAuthId
        );
        return teacherGrades;
      default:
        throw new Error(`No case for type ${userType} found in gradeContext`);
    }
  };

  const handleGetAllGrades = async () => {
    try {
      dispatch({
        type: resultCommentsActions.GET_ALL_GRADES,
        payload: { isLoading: true },
      });

      const response = await _handleGetAllGrades();

      if (response?.status === true) {
        const { data: grades } = response;

        dispatch({
          type: resultCommentsActions.GET_ALL_GRADES,
          payload: {
            isLoading: false,
            allGrades: grades,
            errorGettingGrades: false,
          },
        });
      } else {
        throw new Error(response?.message ?? "Couldn't fetch grade comment");
      }
    } catch (error) {
      showErrorToast(error?.message ?? "Something went wrong");
      dispatch({
        type: resultCommentsActions.GET_ALL_GRADES,
        payload: { isLoading: false, errorGettingGrades: true },
      });
    }
  };

  const _handleUpdateSingleGrade = async (payload) => {
    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolData } = await updateSingleGrade(payload);
        return schoolData;
      case accountType.TEACHER:
        const teacherPayload = { ...payload, commentType: accountType.TEACHER };
        const { data: teacherData } = await updateTeacherGradeComment(
          teacherPayload
        );
        return teacherData;
      default:
        throw new Error(
          `No case for type ${userType} found in _handleUpdateSingleGrade`
        );
    }
  };

  const handleUpdateSingleGrade = async (gradePayload) => {
    dispatch({
      type: resultCommentsActions.UPDATE_EDITING_STATE,
      payload: { isEditingGrade: true },
    });

    try {
      const {
        schoolAuthId,
        grade,
        gradePoint,
        minAverage,
        maxAverage,
        comment,
        id,
      } = gradePayload;

      const payload = {
        schoolAuthId,
        grade,
        gradePoint,
        minAverage,
        maxAverage,
        comment,
        commentId: id,
      };

      const response = await _handleUpdateSingleGrade(payload);

      if (response?.status === true) {
        const { data: innerData } = response;

        const cachedGrades = [...state.allGrades];

        const indexOfGrade = cachedGrades.findIndex(
          (value) => value?.id === innerData?.id
        );

        if (indexOfGrade === -1) {
          throw new Error("Error updating grade comment");
        }

        cachedGrades.splice(indexOfGrade, 1, innerData);

        showSuccessToast(response?.message ?? "Success");

        dispatch({
          type: resultCommentsActions.UPDATE_GRADES,
          payload: { allGrades: [...cachedGrades] },
        });
      } else {
        throw new Error(response?.message ?? "Couldn't update. Try again");
      }
    } catch (error) {
      showErrorToast(error?.message ?? "Something went wrong");
    } finally {
      dispatch({
        type: resultCommentsActions.UPDATE_EDITING_STATE,
        payload: { isEditingGrade: false },
      });
    }
  };

  /// This method is called in [handleAddSingleGrade] and is responsible for
  /// routing to the right API based on the type of account
  const _handleAddSingleGrade = async (payload) => {
    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolResponse } = await addGrade(payload);
        return schoolResponse;
      case accountType.TEACHER:
        const teacherPayload = { ...payload, schoolAuthId: user?.schoolAuthId };
        const { data: teacherResponse } = await addTeacherGradeComment(
          teacherPayload
        );
        return teacherResponse;

      default:
        throw new Error(
          `No case for type ${userType} found in _handleAddSingleGrade`
        );
    }
  };

  /// This function handles adding new grade comment to the database and updating
  /// the state
  const handleAddSingleGrade = async (gradePayload) => {
    try {
      dispatch({
        type: resultCommentsActions.UPDATE_ADDING_STATE,
        payload: { isAddingGrade: true },
      });

      const { grade, gradePoint, minAverage, maxAverage, comment } =
        gradePayload;

      const payload = {
        commentType: userType,
        grade,
        gradePoint,
        minAverage,
        maxAverage,
        comment,
      };

      const response = await _handleAddSingleGrade(payload);

      if (response?.status === true) {
        const { data: innerData } = response;

        const updatedGrades = [...state.allGrades];

        updatedGrades.splice(0, 0, innerData);

        dispatch({
          type: resultCommentsActions.UPDATE_GRADES,
          payload: { allGrades: updatedGrades },
        });

        showSuccessToast(response?.message ?? "Success");
      } else {
        throw new Error(response?.message ?? "Couldn't add grade at this time");
      }
    } catch (error) {
      showErrorToast(error?.message ?? "Something went wrong");
    } finally {
      dispatch({
        type: resultCommentsActions.UPDATE_ADDING_STATE,
        payload: { isAddingGrade: false },
      });
    }
  };

  /// This function is called in [handleDeletingSingleGrade] and is responsible
  /// routing to the right api based on the userType
  const _handleDeleteSingleGrade = async (payload) => {
    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolData } = await deleteSingleGrade(payload);
        return schoolData;
      case accountType.TEACHER:
        const { data: teacherData } = await deleteTeacherGradeComment(payload);
        return teacherData;
      default:
        throw new Error(
          `No case for type ${userType} found in _handleDeleteSingleGrade`
        );
    }
  };

  const handleDeleteSingleGrade = async (gradePayload) => {
    const cachedGradeComments = [...state.allGrades];

    const indexOfGradeComment = cachedGradeComments.findIndex(
      (item) => item?.id === gradePayload?.id
    );

    if (indexOfGradeComment === -1) {
      showErrorToast("Error deleting grade comment");
      return;
    }

    const gradeCommentToDelete = cachedGradeComments[indexOfGradeComment];

    cachedGradeComments.splice(indexOfGradeComment, 1);

    dispatch({
      type: resultCommentsActions.UPDATE_GRADES,
      payload: { allGrades: cachedGradeComments },
    });

    try {
      const { schoolAuthId, id } = gradePayload;

      const payload = {
        schoolAuthId,
        commentId: id,
      };

      const response = await _handleDeleteSingleGrade(payload);

      if (response?.status === false) {
        throw new Error(
          response?.message ?? "Couldn't delete grade comment at this time"
        );
      }

      showSuccessToast(response?.message ?? "Success");
    } catch (error) {
      showErrorToast(error?.message ?? "Something went wrong");

      cachedGradeComments.splice(indexOfGradeComment, 0, gradeCommentToDelete);

      dispatch({
        type: resultCommentsActions.UPDATE_GRADES,
        payload: { allGrades: cachedGradeComments },
      });
    }
  };

  const value = {
    state,
    handleGetAllGrades,
    handleUpdateSingleGrade,
    handleAddSingleGrade,
    handleDeleteSingleGrade,
  };

  return (
    <ResultCommentsContext.Provider value={value}>{children}</ResultCommentsContext.Provider>
  );
};

const useGrades = () => {
  const context = useContext(ResultCommentsContext);

  if (context === undefined) {
    throw new Error("useGrades must be used within GradesContext");
  }

  return context;
};

export { ResultCommentsProvider, useGrades };
