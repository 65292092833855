import styles from "./LoadingShimmer.module.css";

const LoadingShimmer = () => (
  <div className="relative h-[320px]">
    <div
      className={`absolute top-0 left-0 h-full w-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 ${styles.animateShimmer}`}
    ></div>
  </div>
);

export default LoadingShimmer;
