import { resultCommentsActions } from "./resultCommentsActions";

export const initialState = {
  allGrades: [],
  isLoading: false,
  isAddingGrade: false,
  isEditingGrade: false,
  error: false,
  errorGettingGrades: false,
  message: "",
  currentlyEditing: null,
};

const gradeReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case resultCommentsActions.UPDATE_LOADING_STATE:
      return { ...state, isLoading: payload.isLoading };
    case resultCommentsActions.UPDATE_ADDING_STATE:
      return { ...state, isAddingGrade: payload.isAddingGrade };
    case resultCommentsActions.UPDATE_EDITING_STATE:
      return { ...state, isEditingGrade: payload.isEditingGrade };
    case resultCommentsActions.UPDATE_GRADES:
      return {
        ...state,
        allGrades: [...payload.allGrades],
      };

    case resultCommentsActions.GET_ALL_GRADES:
      return {
        ...state,
        isLoading: payload.isLoading,
        allGrades: payload.allGrades,
        message: payload.message,
        errorGettingGrades: payload.errorGettingGrades,
      };

    default:
      throw new Error(`No case for type ${type} found in gradeReducer`);
  }
};

export default gradeReducer;
