import * as Yup from "yup";

// export const addSingleStudentResultSchema = (maxScore) =>
//   Yup.object().shape({
//     result: Yup.object().shape({
//       id: Yup.string(),
//       studentAuthId: Yup.string(),
//       studentScore: Yup.number()
//         .max(maxScore, `Should be ${maxScore} or less`)
//         .typeError("Enter valid number"),
//       fullName: Yup.string(),
//     }),
//   });
export const addSingleStudentResultSchema = (maxScore) =>
  Yup.object().shape({
    id: Yup.string(),
    // studentAuthId: Yup.string(),
    studentScore: Yup.number()
      .max(maxScore, `Should be ${maxScore} or less`)
      .typeError("Enter valid number"),
    fullName: Yup.string(),
  });
