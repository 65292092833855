import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import imag from "../../assets/images/logo.png";
import RegistrationFormLayout from "../../shared/components/layouts/RegistrationFormLayout";
import TextFieldFormik from "../../shared/components/TextFieldFormik";
import Button from "../../shared/components/ui/Button";
import { PointingRightArrow } from "../../shared/components/icons";

import { loginSchema } from "../../utils/validationSchemas/loginSchema";

import { removeWhiteSpacesFromObjValues } from "../../utils/helperFunctions";
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from "../../utils/toastHandler";

import {
  loginUser,
  resendEmailCode,
  saveUserDataToStorage,
} from "../../services/authService";

import { useAuth } from "../../store/authContext";
import { accountType } from "../../constants/accountType";

import { debugPrint } from "../../utils/debugPrint";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { handleLogin, handleUserToken } = useAuth();

  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const formHandler = async values => {
    const { email, password, rememberMe } = values;

    let loginDetails = {
      email: email,
      password: password,
    };

    loginDetails = removeWhiteSpacesFromObjValues(loginDetails);

    try {
      const response = await loginUser(loginDetails);

      debugPrint("Login - formHandler = response ->", response);

      // Save user's token to local storage
      handleUserToken(response?.token);

      if (response.status === true) {
        const { isAccountVerified, email: responseEmail, id } = response.data;

        const userType = response.data?.userType;
        // Check for user type "userType"
        if (
          userType === accountType.TEACHER ||
          userType === accountType.STUDENT
        ) {
          if (response.data?.hasChangedDefaultPassword === false) {
            navigate("/create-new-password", {
              state: {
                email: responseEmail,
                isDefaultPassword: true, // Will be used to point to the right API
              },
            });

            showInfoToast("Please proceed to change your default password");

            return;
          }
        }

        if (isAccountVerified === false) {
          /// User has not verified their email

          const payload = {
            email: responseEmail,
          };

          /// Making call to the API to send verification code to user's email address
          await resendEmailCode(payload);

          navigate("/enter-code", { state: { email: responseEmail, id: id } });
          return;
        }
        const { hasOnboarded } = response.data;

        if (hasOnboarded === false) {
          navigate("/onboarding/school-contact-info", {
            state: { hasOnboarded: hasOnboarded },
          });
          return;
        }

        showSuccessToast("Success");

        // Save user data to storage here
        saveUserDataToStorage(response, rememberMe);

        handleLogin();

        const { state } = location;

        // if (userType === accountType.SCHOOL) {
        //   if (!response?.areSubjectsCreated) {
        //     navigate("/academic/subjects", {replace: true})
        //   }

        //   return;
        // }

        let navigationPath = state ? state?.from?.pathname : "/dashboard";

        navigate(navigationPath, { replace: true });
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  return (
    <RegistrationFormLayout>
      <div className="flex h-full w-full flex-col items-stretch justify-center p-6">
        <div className=" mb-[34px] flex items-center justify-center text-center">
          <img
            src={imag}
            alt="Showing the school logo"
            className="h-[34.11px] w-[144.8px]"
          />
        </div>
        <h1 className="mb-[34px] text-center text-[24px] font-bold text-[#0A1629]">
          Login
        </h1>

        <Formik
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={formHandler}
        >
          {({ handleChange, isSubmitting }) => (
            <Form className="flex flex-col">
              <div className="space-y-[17.78px]">
                <TextFieldFormik
                  extraClasses="mb-[15px]"
                  label="Email Address"
                  name="email"
                  placeholder="youremail@gmail.com"
                  autoComplete="on"
                />

                <TextFieldFormik
                  extraClasses="mb-[15px]"
                  label="Password"
                  name="password"
                  placeholder="********"
                  type="password"
                  autoComplete="on"
                />
              </div>

              <div className="mt-[17.78px] flex flex-row items-center justify-between">
                {/* TODO: Fixed the checkbox to match the design */}
                <div className="items-center lg:flex ">
                  <input
                    name="rememberMe"
                    type="checkbox"
                    id="rememberMe"
                    className="mr-2 h-[16px] w-[16px] rounded border-[#D8E0F0] text-primary focus:ring-[#D8E0F0]"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="rememberMe"
                    className="text-[14px] font-normal text-[#7D8592]"
                  >
                    Remember me
                  </label>
                </div>
                <Link
                  to="/forgot-password"
                  className="text-[14px]  font-semibold text-[#2969FF]"
                >
                  Forgot Password?
                </Link>
              </div>

              <Button
                type="submit"
                extraClasses="self-center mt-[25px]"
                isLoading={isSubmitting}
              >
                <div className="flex items-center justify-center space-x-2">
                  <span className=" ">Sign In</span>
                  {/* <PointingRightArrow color="bg-white" /> */}
                </div>
              </Button>
            </Form>
          )}
        </Formik>

        <Link
          to="/register"
          className="mt-[14px] text-center text-[16px] font-semibold text-blue"
        >
          Don't have an account?
        </Link>
        {/* <Link
          to='/register'
          className='mt-[14px] text-center text-[16px] font-semibold text-blue'
        >
          Don't have an account?
        </Link> */}
      </div>
    </RegistrationFormLayout>
  );
};

export default Login;
