export const gradeColor = grade => {
  const value = grade?.trim()?.toLowerCase();

  if (value === "excellent") {
    return "bg-[#2D8A39]";
  }

  if (value === "very good") {
    return "bg-[#2D8A39]";
  }

  if (value === "good") {
    return "bg-[#2969FF]";
  }

  if (value === "poor") {
    return "bg-[#EEA23E]";
  }

  if (value === "very poor") {
    return "bg-[#F83737]";
  }

  return "bg-[#2969FF]";
};

export const gradeTextColor = grade => {
  const value = grade?.trim()?.toLowerCase();

  if (value === "excellent") {
    return "text-[#2D8A39]";
  }

  if (value === "very good") {
    return "text-[#2D8A39]";
  }

  if (value === "good") {
    return "text-[#2969FF]";
  }

  if (value === "poor") {
    return "text-[#EEA23E]";
  }

  if (value === "very poor") {
    return "text-[#F83737]";
  }

  return "text-[#2969FF]";
};

export const gradeBgColor = grade => {
  const value = grade?.trim()?.toLowerCase();

  if (value === "excellent") {
    return "bg-[#F0FAF0]";
  }

  if (value === "very good") {
    return "bg-[#00D097]";
  }

  if (value === "good") {
    return "bg-[#B3D1FF]";
  }

  if (value === "poor") {
    return "bg-[#FFF8EB]";
  }

  if (value === "very poor") {
    return "bg-[#FFF2F0]";
  }

  return "bg-[#B3D1FF]";
};
