export const schoolClasses = ["jss1", "jss2", "jss3", "sss1", "sss2", "sss3"];

export const schoolClassListLabelAndValue = [
  { value: "jss1", label: "JSS1" },
  { value: "jss2", label: "JSS2" },
  { value: "jss3", label: "JSS3" },
  { value: "sss1", label: "SSS1" },
  { value: "sss2", label: "SSS2" },
  { value: "sss3", label: "SSS3" },
];
