import { useState } from "react";

import DashBoardTopItem from "../../../../shared/components/DashBoardTopItem";
import NormalSelect from "../../../../shared/components/ui/NormalSelect";
import PageLoading from "../../../../shared/components/ui/PageLoading";
import SearchBar from "../../../../shared/components/ui/SearchBar";
import { AddButtonPlain } from "../../../../shared/components/ui/Buttons";
import menuLeft from "../../../../assets/svg/menu-left.svg";
import deleteIcon from "../../../../assets/svg/delete.svg";
import { getYearRange } from "../../../../utils/getYearRange";

import { useSession } from "../../../../store/sessionStore/sessionContext";
import { debugPrint } from "../../../../utils/debugPrint";
import TickBox from "../../../components/ui/TickBox";

const SessionDashboard = () => {
  const currentYear = new Date().getFullYear() + 1;

  const years = getYearRange(currentYear);
  const { state, activateSession, toggleSession } = useSession();

  const { allSessions, isSessionLoading, isActivating } = state;

  const [selectedSession, setSelectedSession] = useState({
    term: "",
    year: "",
  });

  const isAddButtonDisabled = !selectedSession.term || !selectedSession.year;

  const handleActivate = () => {
    const formatTerm = selectedSession.term.split(" ");

    debugPrint("SessionDashboard - handleActivate -- formatTerm ->", formatTerm);

    const payload = {
      term: formatTerm[0],
      year: parseInt(selectedSession.year),
    };

    activateSession(payload);
  };

  return (
    <div className="flex flex-col pb-8">
      <h1 className="mb-[16px] text-[28px] font-semibold leading-[38px] text-black">
        Session
      </h1>
      <div className="flex w-full flex-1 flex-col items-start rounded-[10px] bg-[#fff] shadow-sm">
        <div className="flex flex-row items-center justify-between self-stretch border-b-[1px] border-b-[#EAEBF0] py-6 pr-6 lg:flex-col">
          <div className="ml-10 flex h-full w-[40px] items-center justify-center lg:hidden">
            <img src={deleteIcon} className="h-auto w-5" alt="delete" />
          </div>

          <div className="hidden w-full items-center justify-end gap-36 md:flex">
            <NormalSelect
              className={"w-[30px]"}
              placeholder="Select Term"
              options={["First Term", "Second Term", "Third Term"]}
              value={selectedSession.term}
              handleSelect={event => {
                setSelectedSession({ ...selectedSession, term: event.value });
              }}
            />

            <NormalSelect
              className={"w-[30px]"}
              options={years}
              value={selectedSession.year}
              placeholder="Select Year"
              handleSelect={event => {
                setSelectedSession({ ...selectedSession, year: event.value });
              }}
            />

            <AddButtonPlain
              className={`px-[30px] py-3 text-white ${
                isAddButtonDisabled ? "bg-gray-500" : "bg-[#437EF7]"
              } `}
              showIcon={false}
              clicked={handleActivate}
              disabled={isAddButtonDisabled}
              isLoading={isActivating}
            >
              Add Session
            </AddButtonPlain>
          </div>
          <div className="flex h-[24px] w-[24px] items-center justify-end md:hidden">
            <img src={menuLeft} className="h-full w-full" alt="menu-left" />
          </div>
        </div>

        {isSessionLoading ? (
          <div className="flex h-full w-full items-center justify-center">
            <PageLoading />
          </div>
        ) : (
          <div className="relative h-full w-full overflow-x-auto rounded-b-[10px]">
            <table className="w-full bg-white text-left text-sm rtl:text-right">
              <thead className="">
                <tr className="border-b-[1px] border-b-[#EAEBF0]">
                  <th
                    scope="col"
                    className="py-8 pl-14 font-body text-sm text-[#5F6D7E]"
                  >
                    Year
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-8 text-center font-body text-sm text-[#5F6D7E]"
                  >
                    Term
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-8 text-center font-body text-sm text-[#5F6D7E]"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="py-8 pr-6 text-center font-body text-sm text-[#5F6D7E] lg:pr-0"
                  >
                    Active
                  </th>
                </tr>
              </thead>

              <tbody>
                {allSessions?.map((session, index) => {
                  return (
                    <tr
                      key={session?.id ?? index}
                      className="border-b-[1px] border-b-[#EAEBF0] text-[#5F6D7E]"
                    >
                      <th
                        scope="row"
                        className="whitespace-nowrap py-8 pl-14 text-sm font-medium"
                      >
                        {session?.year}
                      </th>
                      <td className="px-6 py-8 text-center text-sm">
                        {session?.term ?? "-- --"}
                      </td>
                      <td className="px-6 py-8 text-center">
                        <div
                          className={`${
                            session?.status === true
                              ? "bg-[#F0FAF0] text-[#2D8A39]"
                              : "bg-[#FFF2F0] text-[#F83737]"
                          }  inline-flex cursor-pointer items-center gap-1 rounded-[5px] py-[2px] px-[8px] text-center text-sm`}
                          onClick={() => toggleSession(session?.id)}
                        >
                          <span
                            className={`${
                              session?.status === true
                                ? "bg-[#2D8A39]"
                                : "bg-[#E2341D]"
                            } mb-[2px] inline-block h-[6px] w-[6px] items-center rounded`}
                          ></span>

                          {session?.status === true ? "Active" : "Inactive"}
                        </div>
                      </td>

                      <td className="flex justify-center py-8 pr-6 lg:pr-0">
                        <div className="flex items-center">
                          <TickBox
                            value={session?.status}
                            updateValue={() => toggleSession(session?.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default SessionDashboard;
