import * as Yup from "yup";
import {
  IMAGE_SIZE_LIMIT,
  SUPPORTED_IMAGE_FORMAT,
} from "../supportedImageTypes";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const studentProfileSchema = Yup.object().shape({
  // Main info section
  profileImage: Yup.mixed()
    .optional()
    .test("fileSize", "File too large", (value) => {
      if (!value) {
        return true;
      }
      return value && value.size <= IMAGE_SIZE_LIMIT;
    })
    .test("fileFormat", "Unsupported Format", (value) => {
      if (!value) {
        return true;
      }

      return value && SUPPORTED_IMAGE_FORMAT.includes(value.type);
    }),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  middleName: Yup.string(),
  countryCode: Yup.string(),
  phone: Yup.string().matches(phoneRegExp, "Enter valid number"),
  gender: Yup.string().required("Gender is required"),
  dateOfBirth: Yup.date().nullable(),

  // School info section
  currentClass: Yup.string().required("Class is required"),
  subClass: Yup.string().required("Sub class is required"),
  currentTerm: Yup.string().required("Current term is required"),
  currentYear: Yup.string().required("Current year is required"),
  formerSchoolName: Yup.string(),

  // Location info section
  country: Yup.string(),
  state: Yup.string(),
  LGA: Yup.string(),
  community: Yup.string(),
  homeTown: Yup.string(),
  address: Yup.string(),

  // Guardian info section
  fatherFullName: Yup.string(),
  motherFullName: Yup.string(),
  guardianFullName: Yup.string(),
  guardianAddress: Yup.string(),
  guardianPhoneNumber: Yup.string(),
  guardianEmail: Yup.string(),
  guardianOccupation: Yup.string(),
});
