import * as Yup from "yup";
import {
  IMAGE_SIZE_LIMIT,
  SUPPORTED_IMAGE_FORMAT,
} from "../supportedImageTypes";

// TODO: Extract to separate JS file
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const teacherProfileSchema = Yup.object().shape({
  // Main info section
  profileImage: Yup.mixed()
    .optional()
    .test("fileFormat", "Unsupported Format", (value) => {
      if (!value) {
        return true;
      }

      return value && SUPPORTED_IMAGE_FORMAT.includes(value.type);
    })
    .test("fileSize", "File too large", (value) => {
      if (!value) {
        return true;
      }
      return value && value.size <= IMAGE_SIZE_LIMIT;
    }),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  middleName: Yup.string(),
  countryCode: Yup.string(),
  phoneNumber: Yup.string().matches(phoneRegExp, "Enter valid number"),
  teacherEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  dateOfBirth: Yup.string().required("Date of birth is required"),

  // Job info section
  subjects: Yup.array()
    .min(1, "Pick a subject")
    .required("Subject is required"),
  classes: Yup.array().min(1, "Pick a class").required("Class is required"),
  yearsOfExperience: Yup.number("Enter a valid number"),

  // Location info section
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  LGA: Yup.string().required("LGA is required"),
  community: Yup.string(),
  homeTown: Yup.string(),
  address: Yup.string().required("Address is required"),

  // Other info section
  formerEmployerName: Yup.string(),
  formerJobTitle: Yup.string(),
  newDocuments: Yup.array()
    .nullable()
    .test("fileFormat", "Unsupported Format", (files) => {
      let valid = true;

      if (files) {
        for (const file of files) {
          if (!SUPPORTED_IMAGE_FORMAT.includes(file.type)) {
            valid = false;
            break;
          }
        }
      }

      return valid;
    })
    .test("fileSize", "File too large", (files) => {
      let valid = true;

      if (files) {
        for (const file of files) {
          const fileSize = file.size;

          if (fileSize > IMAGE_SIZE_LIMIT) {
            valid = false;
            break;
          }
        }
      }

      return valid;
    }),

    // Salary info section
    salary: Yup.number(),
    accountName: Yup.string(),
    accountNumber: Yup.string(),
    bank: Yup.string(),
});
