import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import DismissibleModal from "../modals/DismissibleModal";
import FundWallet from "./fundWallet/FundWallet";
import LoadingShimmer from "../ui/loadingShimmer/LoadingShimmer";
import SuccessModal from "../modals/SuccessModal";
import { OutlinedButton, PlainButton } from "../ui/Buttons";

import { PaymentMethod } from "../../../constants/paymentMethod";
import { getSchoolWalletDetails } from "../../../features/school/services/schoolService";

const WalletCard = ({ className }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [showModal, setShowModal] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.CARD);

  const [walletDetails, setWalletDetails] = useState({
    isLoading: false,
    error: null,
    walletBalance: 0.0,
  });

  const fetchWalletDetails = async () => {
    setWalletDetails(prevState => {
      return { ...prevState, isLoading: true, error: null };
    });

    try {
      const { data: walletResponse } = await getSchoolWalletDetails();

      const { data } = walletResponse;

      if (data?.status === false) {
        throw new Error(data?.message ?? "Couldn't get wallet details");
      }

      const walletBalance = data?.walletBalance ?? 0.0;

      setWalletDetails(prevState => {
        return { ...prevState, walletBalance: walletBalance };
      });
    } catch (err) {
      setWalletDetails(prevState => {
        return { ...prevState, error: err.message };
      });
    } finally {
      setWalletDetails(prevState => {
        return { ...prevState, isLoading: false };
      });
    }
  };

  useEffect(() => {
    fetchWalletDetails();
  }, []);

  useEffect(() => {
    const hasModal = searchParams.has("modal");

    if (hasModal) {
      setShowSuccessModal(true);
    }
  }, []);

  const paymentTypeHandler = type => {
    setPaymentMethod(type);
  };

  const resetCard = () => {
    setShowModal(false);
    setPaymentMethod(PaymentMethod.CARD);
  };

  const closeSuccessModal = () => {
    const searchKeys = searchParams.keys();

    for (const searchKey of searchKeys) {
      searchParams.delete(searchKey);
    }

    setSearchParams(searchParams);

    setShowSuccessModal(false);
  };

  return (
    <React.Fragment className={className}>
      <DismissibleModal
        showModal={showModal}
        headerText="Fund Wallet"
        centerHeading={true}
        onCloseButtonClicked={resetCard}
      >
        <FundWallet
          paymentMethod={paymentMethod}
          paymentTypeHandler={value => paymentTypeHandler(value)}
        />
      </DismissibleModal>

      <DismissibleModal
        showModal={showSuccessModal}
        // headerText="Fund Wallet"
        centerHeading={true}
        onCloseButtonClicked={closeSuccessModal}
      >
        <SuccessModal />
      </DismissibleModal>

      {walletDetails.isLoading && <LoadingShimmer />}

      {!walletDetails.isLoading && (
        <div className="flex flex-col rounded-[14px] bg-white px-[20px] py-[16px] shadow-md md:mb-0 xl:mb-0 2xl:mb-0 ">
          <p className="mb-[20px] self-start text-[28px] font-semibold text-[#272D37]">
            ₦{" "}
            {walletDetails.walletBalance.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </p>

          <p className="mb-[20px] text-[14px] font-medium leading-[20px] text-[#272D37]">
            Wallet balance
          </p>

          <div className=" flex justify-between gap-[8px]">
            <OutlinedButton>Pay Fees</OutlinedButton>

            <PlainButton clicked={() => setShowModal(true)} className="">
              Fund Account
            </PlainButton>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default WalletCard;
