import * as React from "react";

const SvgMailBox = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 167 167"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M43.4 104.104a5.262 5.262 0 0 1-1.65.271 5.262 5.262 0 0 1-1.65-.271L0 90.744v29.287a5.22 5.22 0 0 0 5.219 5.219H78.28a5.22 5.22 0 0 0 5.219-5.219V90.744l-40.1 13.36Z"
      fill="#0078CE"
    />
    <path
      d="M78.281 73.063H5.22A5.22 5.22 0 0 0 0 78.28v1.462l41.75 13.913L83.5 79.743V78.28a5.22 5.22 0 0 0-5.219-5.219Z"
      fill="#0078CE"
    />
    <path
      d="M125.25 41.75H62.625c-3.622 0-7.077.605-10.438 1.482v-11.92H88.72a5.22 5.22 0 0 0 5.219-5.218V5.219A5.22 5.22 0 0 0 88.718 0h-41.75a5.22 5.22 0 0 0-5.218 5.219v42.334c-6.252 3.653-11.502 8.851-15.166 15.072h12.87c6.889-7.64 17.368-11.993 28.86-9.926 15.155 2.724 25.624 16.867 25.624 32.252v50.737H20.874v5.218a5.22 5.22 0 0 0 5.219 5.219h88.718v15.656a5.221 5.221 0 0 0 5.219 5.219h10.438a5.221 5.221 0 0 0 5.219-5.219v-15.656h26.093a5.22 5.22 0 0 0 5.219-5.219V83.5c0-23.056-18.694-41.75-41.75-41.75Z"
      fill="#DE6944"
    />
  </svg>
);

export default SvgMailBox;
