import React from "react";
import * as Icons from "../../components/icons";

const SearchField = ({
  className,
  iconClassName,
  inputClassName,
  iconColor = "#333333",
  placeholder = "Search",
  onChange = () => {},
  value,
}) => {
  return (
//     display: flex;
// height: 40px;
// padding: 10px 14px;
// align-items: center;
// gap: 8px;
// flex-shrink: 0;
// align-self: stretch;

    <label className="relative block">
      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
        <Icons.Search
          className={["text-[17px]", iconClassName].join(" ")}
          color={iconColor}
        />
      </span>
      <input
        value={value}
        onChange={(event) => onChange(event.target.value)}
        className={[
          "block lg:w-[260px] w-full rounded-lg border-[#D0D5DD] py-[8px] pl-9 pr-3 transition duration-150 ease-in shadow-sm placeholder:text-inActive-shade1 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm",
          inputClassName,
        ].join(" ")}
        type="text"
        placeholder={placeholder}
      />
    </label>
  );
};
export default SearchField;