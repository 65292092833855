import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SideNavLink = ({ href, children, inactive = false }) => {
  const navigate = useNavigate();

  let location = useLocation();
  const { pathname } = location;

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (pathname === `/${href}`) {
      setIsActive(true);

      return;
    }

    setIsActive(false);
  }, [pathname]);

  const handleNavigation = () => {
    if (href) {
      navigate(href);
    }
  };

  return (
    <div
      className={`${inactive && "cursor-not-allowed"} ${
        isActive && "bg-[#F9FAFB]"
      } flex h-auto w-full items-center py-[4px] pr-[12px] pl-[24px] text-[16px] font-normal leading-[24px] duration-100 ease-in hover:border-l-[4px] hover:bg-[#F9FAFB] hover:text-black`}
      onClick={!inactive ? handleNavigation : null}
    >
      <div
        className={`flex w-full flex-col py-[8px] px-[12px] font-semibold  ${
          isActive ? "text-[16px] text-[#344054]" : "text-[15px] text-[#5F6D7E]"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default SideNavLink;
