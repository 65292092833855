import * as React from "react";

const SvgPointingDownArrow = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m6.613 15.79.094-.083 5-5a1 1 0 0 0-1.32-1.497l-.094.083L7 12.585V1A1 1 0 0 0 5.007.883L5 1v11.585L1.707 9.293a1 1 0 0 0-1.32-.083l-.094.083a1 1 0 0 0-.083 1.32l.083.094 5 5a1 1 0 0 0 1.32.083Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPointingDownArrow;
