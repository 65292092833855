import client from "../../../config/apiConfig/apiConfig";
import { removeEmptyKeyValuesFromObject } from "../../../utils/objectHelperFunctions";
import { debugPrint } from "../../../utils/debugPrint";

export const getTeacherDetails = async (teacherAuthId) => {
  const queryParameters = {
    teacherAuthId,
  };

  const response = client.teacherClient.get("get-teacher-details", {
    params: queryParameters,
  });

  return response;
};

/// Function responsible for fetching students of a particular teacher
export const getTeacherStudents = async (
  pageNumber = 1,
  studentClass = "",
  studentTerm = "",
  studentYear = ""
) => {
  let queryParameters = {
    page: +pageNumber,
    studentClass,
    studentTerm,
    studentYear,
  };

  queryParameters = removeEmptyKeyValuesFromObject(queryParameters);

  const response = client.teacherClient.get("get-teacher-students", {
    params: queryParameters,
  });

  return response;
};

export const getTeachersStudentResult = async (
  pageNumber = 1,
  studentClass,
  studentTerm,
  studentYear
) => {
  let queryParameters = {
    page: +pageNumber,
    studentClass,
    studentTerm,
    studentYear,
  };

  queryParameters = removeEmptyKeyValuesFromObject(queryParameters);

  const response = client.teacherClient.get("get-student-results", {
    params: queryParameters,
  });

  return response;
};

/// Method that fetches teachers of a school
export const getTeacherTeachers = async (
  pageNumber = 1,
  teacherClass,
  subject
) => {
  let queryParameters = {
    page: +pageNumber,
    teacherClass,
    subject,
  };

  queryParameters = removeEmptyKeyValuesFromObject(queryParameters);

  const response = client.teacherClient.get("sort-teachers-app", {
    params: queryParameters,
  });

  return response;
};

export const getTeacherSubjectsByClass = async (studentClass) => {
  const queryParameters = {
    studentClass,
  };

  const response = client.teacherClient.get("get-subjects-by-class", {
    params: queryParameters,
  });

  return response;
};

export const getTeacherAllExamTypes = async (pageNumber = 1) => {
  const queryParameter = {
    page: +pageNumber,
  };

  const response = client.teacherClient.get("get-all-exam-types", {
    params: queryParameter,
  });

  return response;
};

export const getTeacherAllSubjects = async (pageNumber = 1) => {
  const queryParameter = {
    page: +pageNumber,
  };

  const response = client.teacherClient.get("get-all-subjects", {
    params: queryParameter,
  });

  return response;
};

export const getSingleStudentResultForTeacher = async (
  studentAuthId,
  studentClass,
  studentSubject,
  examType,
  studentTerm,
  studentYear
) => {
  const queryParameters = {
    studentAuthId: studentAuthId,
    studentClass: studentClass,
    studentSubject: studentSubject,
    examType: examType,
    studentTerm: studentTerm,
    studentYear: studentYear,
  };

  const response = client.teacherClient.get(
    "get-single-student-results-by-subjects",
    { params: queryParameters }
  );

  return response;
};

/// This function is responsible for getting teacher's grade comments
export const getAllTeacherComments = async (schoolAuthId, page = 1) => {
  const queryParameters = {
    schoolAuthId,
    page: +page,
  };

  const response = client.teacherClient.get("get-all-comments", {
    params: queryParameters,
  });

  return response;
};

/// This function is responsible for creating teacher's grade comment
export const addTeacherGradeComment = async (payload) => {
  const response = client.teacherClient.post("create-comment", payload);

  return response;
};

/// This function is responsible for deleting teacher's grade comment
export const deleteTeacherGradeComment = async (payload) => {
  const response = client.teacherClient.delete("delete-comments", {
    data: payload,
  });

  return response;
};

/// This function is responsible for updating teacher's grade comment
export const updateTeacherGradeComment = async (payload) => {
  const response = client.teacherClient.put("update-comments", payload);

  return response;
};

/// This function is responsible for uploading a single student's result
export const teacherSingleStudentResultUpload = async (payload) => {
  const response = client.teacherClient.post("upload-students-scores", payload);

  return response;
};

/// This function is for fetching a single student's result
export const teacherGetSingleStudentResult = async (
  studentAuthId,
  studentClass,
  studentSubject,
  examType,
  studentTerm,
  studentYear
) => {
  const queryParameters = {
    studentAuthId: studentAuthId,
    studentClass: studentClass,
    studentSubject: studentSubject,
    examType: examType,
    studentTerm: studentTerm,
    studentYear: studentYear,
  };

  const response = client.teacherClient.get(
    "get-single-student-results-by-subjects",
    { params: queryParameters }
  );

  return response;
};

/// Function for getting results of students
export const getTeacherStudentResultsBySubjects = async (
  studentClass,
  studentSubject,
  examType,
  studentTerm,
  studentYear
) => {
  const queryParameters = {
    studentClass: studentClass,
    studentSubject: studentSubject,
    examType: examType,
    studentTerm: studentTerm,
    studentYear: +studentYear,
  };

  const response = client.teacherClient.get(
    "get-students-results-by-subjects",
    {
      params: queryParameters,
    }
  );

  return response;
};



/// Function for submitting bulk student results
export const teacherUploadBulkStudentResult = async (payload) => {
  const response = client.teacherClient.post(
    "bulk-upload-students-scores",
    payload
  );

  return response;
};
