import { useState, useEffect } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({ name, value, setFieldValue }) => {
  const [formattedDate, setFormattedDate] = useState(null);

  useEffect(() => {
    if (value) {
      const newDate = new Date(value);

      setFormattedDate(newDate);
    }
  }, [value]);

  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ padding: "8px", color: "#fff" }}>
        <CalendarContainer className={className}>
          <div style={{ position: "relative" }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  return (
    <div className="mb-[15px] flex flex-col justify-start">
      <p className="mb-[2px] text-[14px] font-semibold text-[#7D8592]">
        Date of Birth
      </p>
      <DatePicker
        className="h-[48px] w-[232px] cursor-pointer rounded-[14px] border border-[#D8E0F0] focus:border-primary focus:ring-primary"
        dateFormat="yyyy-MM-dd"
        closeOnScroll={true}
        selected={formattedDate}
        onChange={(date) => {
          // const validDate = date.toISOString().split("T")[0];

          setFieldValue(name, date);
        }}
        calendarContainer={MyContainer}
      />
    </div>
  );
};

export default CustomDatePicker;
